import { Logger, LoggerProvider, ClientListener, ClientMessageListener, ItemUpdate, SubscriptionListener } from "./ls_web_client_api";

// Generated by Haxe 4.3.4
var $hx_exports = {};
(function ($global) { "use strict";
var $estr = function() { return js_Boot.__string_rec(this,''); },$hxEnums = $hxEnums || {},$_;
function $extend(from, fields) {
	var proto = Object.create(from);
	for (var name in fields) proto[name] = fields[name];
	if( fields.toString !== Object.prototype.toString ) proto.toString = fields.toString;
	return proto;
}
var EReg = function(r,opt) {
	this.r = new RegExp(r,opt.split("u").join(""));
};
EReg.__name__ = true;
EReg.prototype = {
	match: function(s) {
		if(this.r.global) {
			this.r.lastIndex = 0;
		}
		this.r.m = this.r.exec(s);
		this.r.s = s;
		return this.r.m != null;
	}
	,__class__: EReg
};
var HxOverrides = function() { };
HxOverrides.__name__ = true;
HxOverrides.dateStr = function(date) {
	var m = date.getMonth() + 1;
	var d = date.getDate();
	var h = date.getHours();
	var mi = date.getMinutes();
	var s = date.getSeconds();
	return date.getFullYear() + "-" + (m < 10 ? "0" + m : "" + m) + "-" + (d < 10 ? "0" + d : "" + d) + " " + (h < 10 ? "0" + h : "" + h) + ":" + (mi < 10 ? "0" + mi : "" + mi) + ":" + (s < 10 ? "0" + s : "" + s);
};
HxOverrides.cca = function(s,index) {
	var x = s.charCodeAt(index);
	if(x != x) {
		return undefined;
	}
	return x;
};
HxOverrides.substr = function(s,pos,len) {
	if(len == null) {
		len = s.length;
	} else if(len < 0) {
		if(pos == 0) {
			len = s.length + len;
		} else {
			return "";
		}
	}
	return s.substr(pos,len);
};
HxOverrides.remove = function(a,obj) {
	var i = a.indexOf(obj);
	if(i == -1) {
		return false;
	}
	a.splice(i,1);
	return true;
};
HxOverrides.now = function() {
	return Date.now();
};
var IntIterator = function(min,max) {
	this.min = min;
	this.max = max;
};
IntIterator.__name__ = true;
IntIterator.prototype = {
	hasNext: function() {
		return this.min < this.max;
	}
	,next: function() {
		return this.min++;
	}
	,__class__: IntIterator
};
var Lambda = function() { };
Lambda.__name__ = true;
Lambda.has = function(it,elt) {
	var x = $getIterator(it);
	while(x.hasNext()) if(x.next() == elt) {
		return true;
	}
	return false;
};
Lambda.exists = function(it,f) {
	var x = $getIterator(it);
	while(x.hasNext()) if(f(x.next())) {
		return true;
	}
	return false;
};
Lambda.count = function(it,pred) {
	var n = 0;
	if(pred == null) {
		var _ = $getIterator(it);
		while(_.hasNext()) {
			_.next();
			++n;
		}
	} else {
		var x = $getIterator(it);
		while(x.hasNext()) if(pred(x.next())) {
			++n;
		}
	}
	return n;
};
Lambda.empty = function(it) {
	return !$getIterator(it).hasNext();
};
Lambda.find = function(it,f) {
	var v = $getIterator(it);
	while(v.hasNext()) {
		var v1 = v.next();
		if(f(v1)) {
			return v1;
		}
	}
	return null;
};
Math.__name__ = true;
var Reflect = function() { };
Reflect.__name__ = true;
Reflect.field = function(o,field) {
	try {
		return o[field];
	} catch( _g ) {
		return null;
	}
};
Reflect.fields = function(o) {
	var a = [];
	if(o != null) {
		var hasOwnProperty = Object.prototype.hasOwnProperty;
		for( var f in o ) {
		if(f != "__id__" && f != "hx__closures__" && hasOwnProperty.call(o,f)) {
			a.push(f);
		}
		}
	}
	return a;
};
Reflect.copy = function(o) {
	if(o == null) {
		return null;
	}
	var o2 = { };
	var _g = 0;
	var _g1 = Reflect.fields(o);
	while(_g < _g1.length) {
		var f = _g1[_g];
		++_g;
		o2[f] = Reflect.field(o,f);
	}
	return o2;
};
var Std = function() { };
Std.__name__ = true;
Std.string = function(s) {
	return js_Boot.__string_rec(s,"");
};
Std.parseInt = function(x) {
	var v = parseInt(x);
	if(isNaN(v)) {
		return null;
	}
	return v;
};
Std.random = function(x) {
	if(x <= 0) {
		return 0;
	} else {
		return Math.floor(Math.random() * x);
	}
};
var StringBuf = function() {
	this.b = "";
};
StringBuf.__name__ = true;
StringBuf.prototype = {
	__class__: StringBuf
};
var StringTools = function() { };
StringTools.__name__ = true;
StringTools.startsWith = function(s,start) {
	if(s.length >= start.length) {
		return s.lastIndexOf(start,0) == 0;
	} else {
		return false;
	}
};
StringTools.endsWith = function(s,end) {
	var elen = end.length;
	var slen = s.length;
	if(slen >= elen) {
		return s.indexOf(end,slen - elen) == slen - elen;
	} else {
		return false;
	}
};
StringTools.isSpace = function(s,pos) {
	var c = HxOverrides.cca(s,pos);
	if(!(c > 8 && c < 14)) {
		return c == 32;
	} else {
		return true;
	}
};
StringTools.ltrim = function(s) {
	var l = s.length;
	var r = 0;
	while(r < l && StringTools.isSpace(s,r)) ++r;
	if(r > 0) {
		return HxOverrides.substr(s,r,l - r);
	} else {
		return s;
	}
};
StringTools.rtrim = function(s) {
	var l = s.length;
	var r = 0;
	while(r < l && StringTools.isSpace(s,l - r - 1)) ++r;
	if(r > 0) {
		return HxOverrides.substr(s,0,l - r);
	} else {
		return s;
	}
};
StringTools.trim = function(s) {
	return StringTools.ltrim(StringTools.rtrim(s));
};
StringTools.replace = function(s,sub,by) {
	return s.split(sub).join(by);
};
var Type = function() { };
Type.__name__ = true;
Type.enumEq = function(a,b) {
	if(a == b) {
		return true;
	}
	try {
		var e = a.__enum__;
		if(e == null || e != b.__enum__) {
			return false;
		}
		if(a._hx_index != b._hx_index) {
			return false;
		}
		var enm = $hxEnums[e];
		var params = enm.__constructs__[a._hx_index].__params__;
		var _g = 0;
		while(_g < params.length) {
			var f = params[_g];
			++_g;
			if(!Type.enumEq(a[f],b[f])) {
				return false;
			}
		}
	} catch( _g ) {
		return false;
	}
	return true;
};
var LSConnectionDetails = $hx_exports["LSConnectionDetails"] = function(client) {
	this.serverAddress = com_lightstreamer_client_ConnectionDetails_DEFAULT_SERVER;
	this.client = client;
	this.lock = client.lock;
};
LSConnectionDetails.__name__ = true;
LSConnectionDetails.prototype = {
	getServerAddress: function() {
		return this.serverAddress;
	}
	,setServerAddress: function(serverAddress) {
		var newValue = com_lightstreamer_internal_ServerAddress.fromString(serverAddress);
		if(newValue == this.serverAddress) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("serverAddress changed: " + newValue);
		}
		var oldValue = this.serverAddress;
		this.serverAddress = newValue;
		this.client.eventDispatcher.onPropertyChange("serverAddress");
		if(oldValue != newValue) {
			this.client.machine.evtServerAddressChanged();
		}
	}
	,getAdapterSet: function() {
		return this.adapterSet;
	}
	,setAdapterSet: function(adapterSet) {
		if(adapterSet == this.adapterSet) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("adapterSet changed: " + adapterSet);
		}
		this.adapterSet = adapterSet;
		this.client.eventDispatcher.onPropertyChange("adapterSet");
	}
	,getUser: function() {
		return this.user;
	}
	,setUser: function(user) {
		if(user == this.user) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("user changed: " + user);
		}
		this.user = user;
		this.client.eventDispatcher.onPropertyChange("user");
	}
	,setPassword: function(password) {
		if(password == this.password) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("password changed");
		}
		this.password = password;
		this.client.eventDispatcher.onPropertyChange("password");
	}
	,getSessionId: function() {
		return this.sessionId;
	}
	,setSessionId: function(sessionId) {
		if(sessionId == this.sessionId) {
			return;
		}
		this.sessionId = sessionId;
		this.client.eventDispatcher.onPropertyChange("sessionId");
	}
	,getServerInstanceAddress: function() {
		return this.serverInstanceAddress;
	}
	,setServerInstanceAddress: function(serverInstanceAddress) {
		if(serverInstanceAddress == this.serverInstanceAddress) {
			return;
		}
		this.serverInstanceAddress = serverInstanceAddress;
		this.client.eventDispatcher.onPropertyChange("serverInstanceAddress");
	}
	,getServerSocketName: function() {
		return this.serverSocketName;
	}
	,setServerSocketName: function(serverSocketName) {
		if(serverSocketName == this.serverSocketName) {
			return;
		}
		this.serverSocketName = serverSocketName;
		this.client.eventDispatcher.onPropertyChange("serverSocketName");
	}
	,getClientIp: function() {
		return this.clientIp;
	}
	,setClientIp: function(clientIp) {
		if(clientIp == this.clientIp) {
			return;
		}
		this.clientIp = clientIp;
		this.client.eventDispatcher.onPropertyChange("clientIp");
	}
	,toString: function() {
		var map = com_lightstreamer_internal_InfoMap._new();
		com_lightstreamer_internal_InfoMap.set(map,"serverAddress",this.serverAddress);
		com_lightstreamer_internal_InfoMap.set(map,"adapterSet",this.adapterSet);
		com_lightstreamer_internal_InfoMap.set(map,"user",this.user);
		com_lightstreamer_internal_InfoMap.set(map,"sessionId",this.sessionId);
		com_lightstreamer_internal_InfoMap.set(map,"serverInstanceAddress",this.serverInstanceAddress);
		com_lightstreamer_internal_InfoMap.set(map,"serverSocketName",this.serverSocketName);
		com_lightstreamer_internal_InfoMap.set(map,"clientIp",this.clientIp);
		com_lightstreamer_internal_InfoMap.set(map,"libVersion",LSLightstreamerClient.LIB_NAME + " " + LSLightstreamerClient.LIB_VERSION);
		return haxe_ds_StringMap.stringify(map.h);
	}
	,__class__: LSConnectionDetails
};
var js_Browser = function() { };
js_Browser.__name__ = true;
js_Browser.get_supported = function() {
	if(typeof(window) != "undefined" && typeof(window.location) != "undefined") {
		return typeof(window.location.protocol) == "string";
	} else {
		return false;
	}
};
var com_lightstreamer_internal_ServerAddress = {};
com_lightstreamer_internal_ServerAddress._new = function(address) {
	return address;
};
com_lightstreamer_internal_ServerAddress.fromString = function(address) {
	if(address == null) {
		return null;
	} else if(StringTools.startsWith(address,"http://") || StringTools.startsWith(address,"https://")) {
		return address;
	} else {
		throw new com_lightstreamer_internal_IllegalArgumentException("address is malformed");
	}
};
var haxe_Exception = function(message,previous,native) {
	Error.call(this,message);
	this.message = message;
	this.__previousException = previous;
	this.__nativeException = native != null ? native : this;
	this.__skipStack = 0;
	var old = Error.prepareStackTrace;
	Error.prepareStackTrace = function(e) { return e.stack; }
	if(((native) instanceof Error)) {
		this.stack = native.stack;
	} else {
		var e = null;
		if(Error.captureStackTrace) {
			Error.captureStackTrace(this,haxe_Exception);
			e = this;
		} else {
			e = new Error();
			if(typeof(e.stack) == "undefined") {
				try { throw e; } catch(_) {}
				this.__skipStack++;
			}
		}
		this.stack = e.stack;
	}
	Error.prepareStackTrace = old;
};
haxe_Exception.__name__ = true;
haxe_Exception.caught = function(value) {
	if(((value) instanceof haxe_Exception)) {
		return value;
	} else if(((value) instanceof Error)) {
		return new haxe_Exception(value.message,null,value);
	} else {
		return new haxe_ValueException(value,null,value);
	}
};
haxe_Exception.thrown = function(value) {
	if(((value) instanceof haxe_Exception)) {
		return value.get_native();
	} else if(((value) instanceof Error)) {
		return value;
	} else {
		var e = new haxe_ValueException(value);
		e.__skipStack++;
		return e;
	}
};
haxe_Exception.__super__ = Error;
haxe_Exception.prototype = $extend(Error.prototype,{
	toString: function() {
		return this.get_message();
	}
	,details: function() {
		if(this.get_previous() == null) {
			var tmp = "Exception: " + this.toString();
			var tmp1 = this.get_stack();
			return tmp + (tmp1 == null ? "null" : haxe_CallStack.toString(tmp1));
		} else {
			var result = "";
			var e = this;
			var prev = null;
			while(e != null) {
				if(prev == null) {
					var result1 = "Exception: " + e.get_message();
					var tmp = e.get_stack();
					result = result1 + (tmp == null ? "null" : haxe_CallStack.toString(tmp)) + result;
				} else {
					var prevStack = haxe_CallStack.subtract(e.get_stack(),prev.get_stack());
					result = "Exception: " + e.get_message() + (prevStack == null ? "null" : haxe_CallStack.toString(prevStack)) + "\n\nNext " + result;
				}
				prev = e;
				e = e.get_previous();
			}
			return result;
		}
	}
	,__shiftStack: function() {
		this.__skipStack++;
	}
	,get_message: function() {
		return this.message;
	}
	,get_previous: function() {
		return this.__previousException;
	}
	,get_native: function() {
		return this.__nativeException;
	}
	,get_stack: function() {
		var _g = this.__exceptionStack;
		if(_g == null) {
			var value = haxe_NativeStackTrace.toHaxe(haxe_NativeStackTrace.normalize(this.stack),this.__skipStack);
			this.setProperty("__exceptionStack",value);
			return value;
		} else {
			return _g;
		}
	}
	,setProperty: function(name,value) {
		try {
			Object.defineProperty(this,name,{ value : value});
		} catch( _g ) {
			this[name] = value;
		}
	}
	,__class__: haxe_Exception
});
var com_lightstreamer_internal_IllegalArgumentException = function(message,previous,native) {
	haxe_Exception.call(this,message,previous,native);
	this.__skipStack++;
};
com_lightstreamer_internal_IllegalArgumentException.__name__ = true;
com_lightstreamer_internal_IllegalArgumentException.__super__ = haxe_Exception;
com_lightstreamer_internal_IllegalArgumentException.prototype = $extend(haxe_Exception.prototype,{
	__class__: com_lightstreamer_internal_IllegalArgumentException
});
var LSConnectionOptions = $hx_exports["LSConnectionOptions"] = function(client) {
	this.cookieHandlingRequired = false;
	this.slowingEnabled = false;
	this.serverInstanceAddressIgnored = false;
	this.httpExtraHeadersOnSessionCreationOnly = false;
	this.stalledTimeout = 2000;
	this.sessionRecoveryTimeout = 15000;
	this.reverseHeartbeatInterval = 0;
	this.retryDelay = 4000;
	this.reconnectTimeout = 3000;
	this.pollingInterval = 0;
	this.realMaxBandwidth = null;
	this.requestedMaxBandwidth = com_lightstreamer_internal_RequestedMaxBandwidth.BWUnlimited;
	this.keepaliveInterval = 0;
	this.idleTimeout = 19000;
	this.httpExtraHeaders = null;
	this.forcedTransport = null;
	this.firstRetryMaxDelay = 100;
	this.contentLength = 50000000;
	this.client = client;
	this.lock = client.lock;
};
LSConnectionOptions.__name__ = true;
LSConnectionOptions.prototype = {
	getContentLength: function() {
		return this.contentLength;
	}
	,setContentLength: function(contentLength) {
		var newValue = com_lightstreamer_internal_ContentLength.fromIntGt0(contentLength);
		if(newValue == this.contentLength) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("contentLength changed: " + newValue);
		}
		this.contentLength = newValue;
		this.client.eventDispatcher.onPropertyChange("contentLength");
	}
	,getFirstRetryMaxDelay: function() {
		return this.firstRetryMaxDelay;
	}
	,setFirstRetryMaxDelay: function(firstRetryMaxDelay) {
		var newValue = com_lightstreamer_internal_Millis.fromIntGt0(firstRetryMaxDelay);
		if(newValue == this.firstRetryMaxDelay) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("firstRetryMaxDelay changed: " + newValue);
		}
		this.firstRetryMaxDelay = newValue;
		this.client.eventDispatcher.onPropertyChange("firstRetryMaxDelay");
	}
	,getForcedTransport: function() {
		return this.forcedTransport;
	}
	,setForcedTransport: function(forcedTransport) {
		var newValue = com_lightstreamer_internal_TransportSelection.fromString(forcedTransport);
		if(newValue == this.forcedTransport) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("forcedTransport changed: " + newValue);
		}
		this.forcedTransport = newValue;
		this.client.eventDispatcher.onPropertyChange("forcedTransport");
		this.client.machine.evtExtSetForcedTransport();
	}
	,getHttpExtraHeaders: function() {
		if(this.httpExtraHeaders == null) {
			return null;
		} else {
			return com_lightstreamer_internal_NativeStringMap.fromMapToDynamicAccess(this.httpExtraHeaders);
		}
	}
	,setHttpExtraHeaders: function(httpExtraHeaders) {
		var newValue = httpExtraHeaders != null ? com_lightstreamer_internal_NativeStringMap.toHaxe(httpExtraHeaders) : null;
		if(this.mapEq(newValue,this.httpExtraHeaders)) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("httpExtraHeaders changed: " + (newValue == null ? "null" : haxe_ds_StringMap.stringify(newValue.h)));
		}
		this.httpExtraHeaders = newValue;
		this.client.eventDispatcher.onPropertyChange("httpExtraHeaders");
	}
	,mapEq: function(m1,m2) {
		if(m1 == m2) {
			return true;
		}
		if(m1 == null || m2 == null) {
			return false;
		}
		if(Lambda.count(m1) != Lambda.count(m2)) {
			return false;
		}
		var h = m1.h;
		var _g_keys = Object.keys(h);
		var _g_length = _g_keys.length;
		var _g_current = 0;
		while(_g_current < _g_length) {
			var key = _g_keys[_g_current++];
			if(h[key] != m2.h[key]) {
				return false;
			}
		}
		return true;
	}
	,getIdleTimeout: function() {
		return this.idleTimeout;
	}
	,setIdleTimeout: function(idleTimeout) {
		var newValue = com_lightstreamer_internal_Millis.fromIntGtEq0(idleTimeout);
		if(newValue == this.idleTimeout) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("idleTimeout changed: " + newValue);
		}
		this.idleTimeout = newValue;
		this.client.eventDispatcher.onPropertyChange("idleTimeout");
	}
	,getKeepaliveInterval: function() {
		return this.keepaliveInterval;
	}
	,setKeepaliveInterval: function(keepaliveInterval) {
		var newValue = com_lightstreamer_internal_Millis.fromIntGtEq0(keepaliveInterval);
		if(newValue == this.keepaliveInterval) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("keepaliveInterval changed: " + newValue);
		}
		this.keepaliveInterval = newValue;
		this.client.eventDispatcher.onPropertyChange("keepaliveInterval");
	}
	,getRequestedMaxBandwidth: function() {
		return com_lightstreamer_internal_RequestedMaxBandwidthTools.toString(this.requestedMaxBandwidth);
	}
	,setRequestedMaxBandwidth: function(maxBandwidth) {
		if(maxBandwidth != null) {
			maxBandwidth = maxBandwidth == null ? "null" : "" + maxBandwidth;
		}
		var newValue = com_lightstreamer_internal_RequestedMaxBandwidthTools.fromString(maxBandwidth);
		if(Type.enumEq(newValue,this.requestedMaxBandwidth)) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("requestedMaxBandwidth changed: " + com_lightstreamer_internal_RequestedMaxBandwidthTools.toString(newValue));
		}
		this.requestedMaxBandwidth = newValue;
		this.client.eventDispatcher.onPropertyChange("requestedMaxBandwidth");
		this.client.machine.evtExtSetRequestedMaxBandwidth();
	}
	,getRealMaxBandwidth: function() {
		return com_lightstreamer_internal_RealMaxBandwidthTools.toString(this.realMaxBandwidth);
	}
	,setRealMaxBandwidth: function(newValue) {
		if(com_lightstreamer_internal_RealMaxBandwidthTools.eq(newValue,this.realMaxBandwidth)) {
			return;
		}
		this.realMaxBandwidth = newValue;
		this.client.eventDispatcher.onPropertyChange("realMaxBandwidth");
	}
	,getPollingInterval: function() {
		return this.pollingInterval;
	}
	,setPollingInterval: function(pollingInterval) {
		var newValue = com_lightstreamer_internal_Millis.fromIntGtEq0(pollingInterval);
		if(newValue == this.pollingInterval) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("pollingInterval changed: " + newValue);
		}
		this.pollingInterval = newValue;
		this.client.eventDispatcher.onPropertyChange("pollingInterval");
	}
	,getReconnectTimeout: function() {
		return this.reconnectTimeout;
	}
	,setReconnectTimeout: function(reconnectTimeout) {
		var newValue = com_lightstreamer_internal_Millis.fromIntGt0(reconnectTimeout);
		if(newValue == this.reconnectTimeout) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("reconnectTimeout changed: " + newValue);
		}
		this.reconnectTimeout = newValue;
		this.client.eventDispatcher.onPropertyChange("reconnectTimeout");
	}
	,getRetryDelay: function() {
		return this.retryDelay;
	}
	,setRetryDelay: function(retryDelay) {
		var newValue = com_lightstreamer_internal_Millis.fromIntGt0(retryDelay);
		if(newValue == this.retryDelay) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("retryDelay changed: " + newValue);
		}
		this.retryDelay = newValue;
		this.client.eventDispatcher.onPropertyChange("retryDelay");
	}
	,getReverseHeartbeatInterval: function() {
		return this.reverseHeartbeatInterval;
	}
	,setReverseHeartbeatInterval: function(reverseHeartbeatInterval) {
		var newValue = com_lightstreamer_internal_Millis.fromIntGtEq0(reverseHeartbeatInterval);
		if(newValue == this.reverseHeartbeatInterval) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("reverseHeartbeatInterval changed: " + newValue);
		}
		this.reverseHeartbeatInterval = newValue;
		this.client.eventDispatcher.onPropertyChange("reverseHeartbeatInterval");
		this.client.machine.evtExtSetReverseHeartbeatInterval();
	}
	,getSessionRecoveryTimeout: function() {
		return this.sessionRecoveryTimeout;
	}
	,setSessionRecoveryTimeout: function(sessionRecoveryTimeout) {
		var newValue = com_lightstreamer_internal_Millis.fromIntGtEq0(sessionRecoveryTimeout);
		if(newValue == this.sessionRecoveryTimeout) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("sessionRecoveryTimeout changed: " + newValue);
		}
		this.sessionRecoveryTimeout = newValue;
		this.client.eventDispatcher.onPropertyChange("sessionRecoveryTimeout");
	}
	,getStalledTimeout: function() {
		return this.stalledTimeout;
	}
	,setStalledTimeout: function(stalledTimeout) {
		var newValue = com_lightstreamer_internal_Millis.fromIntGt0(stalledTimeout);
		if(newValue == this.stalledTimeout) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("stalledTimeout changed: " + newValue);
		}
		this.stalledTimeout = newValue;
		this.client.eventDispatcher.onPropertyChange("stalledTimeout");
	}
	,isHttpExtraHeadersOnSessionCreationOnly: function() {
		return this.httpExtraHeadersOnSessionCreationOnly;
	}
	,setHttpExtraHeadersOnSessionCreationOnly: function(httpExtraHeadersOnSessionCreationOnly) {
		if(httpExtraHeadersOnSessionCreationOnly == this.httpExtraHeadersOnSessionCreationOnly) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("httpExtraHeadersOnSessionCreationOnly changed: " + (httpExtraHeadersOnSessionCreationOnly == null ? "null" : "" + httpExtraHeadersOnSessionCreationOnly));
		}
		this.httpExtraHeadersOnSessionCreationOnly = httpExtraHeadersOnSessionCreationOnly;
		this.client.eventDispatcher.onPropertyChange("httpExtraHeadersOnSessionCreationOnly");
	}
	,isServerInstanceAddressIgnored: function() {
		return this.serverInstanceAddressIgnored;
	}
	,setServerInstanceAddressIgnored: function(serverInstanceAddressIgnored) {
		if(serverInstanceAddressIgnored == this.serverInstanceAddressIgnored) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("serverInstanceAddressIgnored changed: " + (serverInstanceAddressIgnored == null ? "null" : "" + serverInstanceAddressIgnored));
		}
		this.serverInstanceAddressIgnored = serverInstanceAddressIgnored;
		this.client.eventDispatcher.onPropertyChange("serverInstanceAddressIgnored");
	}
	,setCookieHandlingRequired: function(newValue) {
		if(newValue == this.cookieHandlingRequired) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("cookieHandlingRequired changed: " + (newValue == null ? "null" : "" + newValue));
		}
		this.cookieHandlingRequired = newValue;
		this.client.eventDispatcher.onPropertyChange("cookieHandlingRequired");
	}
	,isCookieHandlingRequired: function() {
		return this.cookieHandlingRequired;
	}
	,isSlowingEnabled: function() {
		return this.slowingEnabled;
	}
	,setSlowingEnabled: function(slowingEnabled) {
		if(slowingEnabled == this.slowingEnabled) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("slowingEnabled changed: " + (slowingEnabled == null ? "null" : "" + slowingEnabled));
		}
		this.slowingEnabled = slowingEnabled;
		this.client.eventDispatcher.onPropertyChange("slowingEnabled");
	}
	,toString: function() {
		var map = com_lightstreamer_internal_InfoMap._new();
		com_lightstreamer_internal_InfoMap.set(map,"forcedTransport",this.forcedTransport);
		com_lightstreamer_internal_InfoMap.set(map,"requestedMaxBandwidth",this.requestedMaxBandwidth);
		com_lightstreamer_internal_InfoMap.set(map,"realMaxBandwidth",this.realMaxBandwidth);
		com_lightstreamer_internal_InfoMap.set(map,"retryDelay",this.retryDelay);
		com_lightstreamer_internal_InfoMap.set(map,"firstRetryMaxDelay",this.firstRetryMaxDelay);
		com_lightstreamer_internal_InfoMap.set(map,"sessionRecoveryTimeout",this.sessionRecoveryTimeout);
		com_lightstreamer_internal_InfoMap.set(map,"reverseHeartbeatInterval",this.reverseHeartbeatInterval);
		com_lightstreamer_internal_InfoMap.set(map,"stalledTimeout",this.stalledTimeout);
		com_lightstreamer_internal_InfoMap.set(map,"reconnectTimeout",this.reconnectTimeout);
		com_lightstreamer_internal_InfoMap.set(map,"keepaliveInterval",this.keepaliveInterval);
		com_lightstreamer_internal_InfoMap.set(map,"pollingInterval",this.pollingInterval);
		com_lightstreamer_internal_InfoMap.set(map,"idleTimeout",this.idleTimeout);
		com_lightstreamer_internal_InfoMap.set(map,"contentLength",this.contentLength);
		com_lightstreamer_internal_InfoMap.set(map,"slowingEnabled",this.slowingEnabled);
		com_lightstreamer_internal_InfoMap.set(map,"serverInstanceAddressIgnored",this.serverInstanceAddressIgnored);
		com_lightstreamer_internal_InfoMap.set(map,"HTTPExtraHeadersOnSessionCreationOnly",this.httpExtraHeadersOnSessionCreationOnly);
		com_lightstreamer_internal_InfoMap.set(map,"HTTPExtraHeaders",this.httpExtraHeaders);
		com_lightstreamer_internal_InfoMap.set(map,"cookieHandlingRequired",this.cookieHandlingRequired);
		return haxe_ds_StringMap.stringify(map.h);
	}
	,__class__: LSConnectionOptions
};
var com_lightstreamer_internal_EventDispatcher = function() {
	this.listeners = [];
};
com_lightstreamer_internal_EventDispatcher.__name__ = true;
com_lightstreamer_internal_EventDispatcher.prototype = {
	addListener: function(listener) {
		if(this.listeners.indexOf(listener) == -1) {
			this.listeners.push(listener);
			return true;
		}
		return false;
	}
	,removeListener: function(listener) {
		return HxOverrides.remove(this.listeners,listener);
	}
	,getListeners: function() {
		return this.listeners;
	}
	,dispatchToAll: function(func) {
		var _g = 0;
		var _g1 = this.listeners;
		while(_g < _g1.length) this.dispatchToOne(_g1[_g++],func);
	}
	,dispatchToOne: function(listener,func) {
		com_lightstreamer_internal_Timers__setImmediate(function() {
			try {
				func(listener);
			} catch( _g ) {
				var _g1 = haxe_Exception.caught(_g);
				if(com_lightstreamer_log_LoggerTools_actionLogger.isErrorEnabled()) {
					com_lightstreamer_log_LoggerTools_actionLogger.error("Uncaught exception" + "\n" + _g1.details());
				}
			}
		});
	}
	,__class__: com_lightstreamer_internal_EventDispatcher
};
var com_lightstreamer_client_ClientEventDispatcher = function() {
	com_lightstreamer_internal_EventDispatcher.call(this);
};
com_lightstreamer_client_ClientEventDispatcher.__name__ = true;
com_lightstreamer_client_ClientEventDispatcher.__super__ = com_lightstreamer_internal_EventDispatcher;
com_lightstreamer_client_ClientEventDispatcher.prototype = $extend(com_lightstreamer_internal_EventDispatcher.prototype,{
	removeListenerAndFireOnListenEnd: function(listener,client) {
		if(this.removeListener(listener)) {
			this.dispatchToOne(listener,function(l) {
				if(l.onListenEnd != null) {
					l.onListenEnd(client);
				}
			});
		}
	}
	,addListenerAndFireOnListenStart: function(listener,client) {
		if(this.addListener(listener)) {
			this.dispatchToOne(listener,function(l) {
				if(l.onListenStart != null) {
					l.onListenStart(client);
				}
			});
		}
	}
	,onServerError: function(errorCode,errorMessage) {
		this.dispatchToAll(function(listener) {
			if(listener.onServerError != null) {
				listener.onServerError(errorCode,errorMessage);
			}
		});
	}
	,onStatusChange: function(status) {
		this.dispatchToAll(function(listener) {
			if(listener.onStatusChange != null) {
				listener.onStatusChange(status);
			}
		});
	}
	,onPropertyChange: function(property) {
		this.dispatchToAll(function(listener) {
			if(listener.onPropertyChange != null) {
				listener.onPropertyChange(property);
			}
		});
	}
	,onServerKeepalive: function() {
		this.dispatchToAll(function(listener) {
			if(listener.onServerKeepalive != null) {
				listener.onServerKeepalive();
			}
		});
	}
	,__class__: com_lightstreamer_client_ClientEventDispatcher
});
var LSLightstreamerClient = $hx_exports["LSLightstreamerClient"] = function(serverAddress,adapterSet) {
	this.lock = new com_lightstreamer_internal_RLock();
	this.eventDispatcher = new com_lightstreamer_client_ClientEventDispatcher();
	this.connectionDetails = new LSConnectionDetails(this);
	this.connectionOptions = new LSConnectionOptions(this);
	this.machine = new com_lightstreamer_client_internal_ClientMachine(this,new com_lightstreamer_internal_Factory(this));
	if(serverAddress != null) {
		this.connectionDetails.setServerAddress(serverAddress);
	}
	if(adapterSet != null) {
		this.connectionDetails.setAdapterSet(adapterSet);
	}
};
LSLightstreamerClient.__name__ = true;
LSLightstreamerClient.setLoggerProvider = function(provider) {
	com_lightstreamer_log_LogManager.setLoggerProvider(provider);
};
LSLightstreamerClient.prototype = {
	addListener: function(listener) {
		this.eventDispatcher.addListenerAndFireOnListenStart(listener,this);
	}
	,removeListener: function(listener) {
		this.eventDispatcher.removeListenerAndFireOnListenEnd(listener,this);
	}
	,getListeners: function() {
		return this.eventDispatcher.getListeners().slice();
	}
	,connect: function() {
		this.machine.connect();
	}
	,disconnect: function() {
		this.machine.disconnect();
	}
	,getStatus: function() {
		return this.machine.getStatus();
	}
	,sendMessage: function(message,sequence,delayTimeout,listener,enqueueWhileDisconnected) {
		if(enqueueWhileDisconnected == null) {
			enqueueWhileDisconnected = false;
		}
		if(delayTimeout == null) {
			delayTimeout = -1;
		}
		this.machine.sendMessage(message,sequence,delayTimeout != null ? delayTimeout : -1,listener,enqueueWhileDisconnected != null && enqueueWhileDisconnected);
	}
	,subscribe: function(subscription) {
		this.machine.subscribeExt(subscription);
	}
	,unsubscribe: function(subscription) {
		this.machine.unsubscribe(subscription);
	}
	,getSubscriptions: function() {
		return this.machine.getSubscriptions().slice();
	}
	,getSubscriptionWrappers: function() {
		var _g = [];
		var _g1 = 0;
		var _g2 = this.machine.getSubscriptions();
		while(_g1 < _g2.length) {
			var sub = _g2[_g1];
			++_g1;
			if(sub.wrapper != null) {
				_g.push(sub.wrapper);
			}
		}
		return _g.slice();
	}
	,__class__: LSLightstreamerClient
};
var com_lightstreamer_client__$Subscription_SubscriptionState = $hxEnums["com.lightstreamer.client._Subscription.SubscriptionState"] = { __ename__:true,__constructs__:null
	,Inactive: {_hx_name:"Inactive",_hx_index:0,__enum__:"com.lightstreamer.client._Subscription.SubscriptionState",toString:$estr}
	,Active: {_hx_name:"Active",_hx_index:1,__enum__:"com.lightstreamer.client._Subscription.SubscriptionState",toString:$estr}
	,Subscribed: {_hx_name:"Subscribed",_hx_index:2,__enum__:"com.lightstreamer.client._Subscription.SubscriptionState",toString:$estr}
};
com_lightstreamer_client__$Subscription_SubscriptionState.__constructs__ = [com_lightstreamer_client__$Subscription_SubscriptionState.Inactive,com_lightstreamer_client__$Subscription_SubscriptionState.Active,com_lightstreamer_client__$Subscription_SubscriptionState.Subscribed];
var com_lightstreamer_client__$Subscription_SubscriptionEventDispatcher = function() {
	com_lightstreamer_internal_EventDispatcher.call(this);
};
com_lightstreamer_client__$Subscription_SubscriptionEventDispatcher.__name__ = true;
com_lightstreamer_client__$Subscription_SubscriptionEventDispatcher.__super__ = com_lightstreamer_internal_EventDispatcher;
com_lightstreamer_client__$Subscription_SubscriptionEventDispatcher.prototype = $extend(com_lightstreamer_internal_EventDispatcher.prototype,{
	onClearSnapshot: function(itemName,itemPos) {
		this.dispatchToAll(function(listener) {
			if(listener.onClearSnapshot != null) {
				listener.onClearSnapshot(itemName,itemPos);
			}
		});
	}
	,onCommandSecondLevelItemLostUpdates: function(lostUpdates,key) {
		this.dispatchToAll(function(listener) {
			if(listener.onCommandSecondLevelItemLostUpdates != null) {
				listener.onCommandSecondLevelItemLostUpdates(lostUpdates,key);
			}
		});
	}
	,onCommandSecondLevelSubscriptionError: function(code,message,key) {
		this.dispatchToAll(function(listener) {
			if(listener.onCommandSecondLevelSubscriptionError != null) {
				listener.onCommandSecondLevelSubscriptionError(code,message,key);
			}
		});
	}
	,onEndOfSnapshot: function(itemName,itemPos) {
		this.dispatchToAll(function(listener) {
			if(listener.onEndOfSnapshot != null) {
				listener.onEndOfSnapshot(itemName,itemPos);
			}
		});
	}
	,onItemLostUpdates: function(itemName,itemPos,lostUpdates) {
		this.dispatchToAll(function(listener) {
			if(listener.onItemLostUpdates != null) {
				listener.onItemLostUpdates(itemName,itemPos,lostUpdates);
			}
		});
	}
	,onItemUpdate: function(update) {
		this.dispatchToAll(function(listener) {
			if(listener.onItemUpdate != null) {
				listener.onItemUpdate(update);
			}
		});
	}
	,removeListenerAndFireOnListenEnd: function(listener,sub) {
		if(this.removeListener(listener)) {
			this.dispatchToOne(listener,function(l) {
				if(l.onListenEnd != null) {
					l.onListenEnd(sub);
				}
			});
		}
	}
	,addListenerAndFireOnListenStart: function(listener,sub) {
		if(this.addListener(listener)) {
			this.dispatchToOne(listener,function(l) {
				if(l.onListenStart != null) {
					l.onListenStart(sub);
				}
			});
		}
	}
	,onSubscription: function() {
		this.dispatchToAll(function(listener) {
			if(listener.onSubscription != null) {
				listener.onSubscription();
			}
		});
	}
	,onSubscriptionError: function(code,message) {
		this.dispatchToAll(function(listener) {
			if(listener.onSubscriptionError != null) {
				listener.onSubscriptionError(code,message);
			}
		});
	}
	,onUnsubscription: function() {
		this.dispatchToAll(function(listener) {
			if(listener.onUnsubscription != null) {
				listener.onUnsubscription();
			}
		});
	}
	,onRealMaxFrequency: function(frequency) {
		this.dispatchToAll(function(listener) {
			if(listener.onRealMaxFrequency != null) {
				listener.onRealMaxFrequency(frequency);
			}
		});
	}
	,__class__: com_lightstreamer_client__$Subscription_SubscriptionEventDispatcher
});
var LSSubscription = $hx_exports["LSSubscription"] = function(mode,items,fields,wrapper) {
	this.lock = new com_lightstreamer_internal_RLock();
	this.m_internal = false;
	this.state = com_lightstreamer_client__$Subscription_SubscriptionState.Inactive;
	this.eventDispatcher = new com_lightstreamer_client__$Subscription_SubscriptionEventDispatcher();
	this.wrapper = wrapper;
	this.mode = com_lightstreamer_internal_SubscriptionMode.fromString(mode);
	this.initSnapshot();
	this.initItemsAndFields(typeof(items) == "string" ? [items].slice() : items,fields);
};
LSSubscription.__name__ = true;
LSSubscription.prototype = {
	initSnapshot: function() {
		this.snapshot = this.mode == "RAW" ? null : com_lightstreamer_internal_RequestedSnapshot.SnpYes;
	}
	,initItemsAndFields: function(items,fields) {
		if(items != null) {
			if(fields == null) {
				throw new com_lightstreamer_internal_IllegalArgumentException("Please specify a valid field list");
			}
			this.items = com_lightstreamer_internal_Items.fromArray(com_lightstreamer_internal_NativeArray.copy(items,items));
			this.fields = com_lightstreamer_internal_Fields.fromArray(com_lightstreamer_internal_NativeArray.copy(fields,fields));
			if(this.mode == "COMMAND" && this.fields.indexOf("key") == -1) {
				throw new com_lightstreamer_internal_IllegalArgumentException("Field 'key' is missing");
			}
			if(this.mode == "COMMAND" && this.fields.indexOf("command") == -1) {
				throw new com_lightstreamer_internal_IllegalArgumentException("Field 'command' is missing");
			}
		} else if(fields != null) {
			throw new com_lightstreamer_internal_IllegalArgumentException("Please specify a valid item or item list");
		}
	}
	,addListener: function(listener) {
		this.eventDispatcher.addListenerAndFireOnListenStart(listener,this);
	}
	,removeListener: function(listener) {
		this.eventDispatcher.removeListenerAndFireOnListenEnd(listener,this);
	}
	,getListeners: function() {
		return this.eventDispatcher.getListeners().slice();
	}
	,isActive: function() {
		return this.state != com_lightstreamer_client__$Subscription_SubscriptionState.Inactive;
	}
	,isSubscribed: function() {
		return this.state == com_lightstreamer_client__$Subscription_SubscriptionState.Subscribed;
	}
	,getDataAdapter: function() {
		return this.dataAdapter;
	}
	,setDataAdapter: function(dataAdapter) {
		this.checkActive();
		this.dataAdapter = com_lightstreamer_internal_Name.fromString(dataAdapter);
	}
	,getMode: function() {
		return this.mode;
	}
	,getItems: function() {
		if(this.items == null) {
			return null;
		} else {
			return this.items.slice();
		}
	}
	,fetchItems: function() {
		return this.items;
	}
	,setItems: function(items) {
		this.checkActive();
		this.items = com_lightstreamer_internal_Items.fromArray(items == null ? null : com_lightstreamer_internal_NativeArray.copy(items,items));
		this.group = null;
	}
	,getItemGroup: function() {
		return this.group;
	}
	,setItemGroup: function(group) {
		this.checkActive();
		this.group = com_lightstreamer_internal_Name.fromString(group);
		this.items = null;
	}
	,getFields: function() {
		if(this.fields == null) {
			return null;
		} else {
			return this.fields.slice();
		}
	}
	,fetchFields: function() {
		return this.fields;
	}
	,setFields: function(fields) {
		this.checkActive();
		var newValue = com_lightstreamer_internal_Fields.fromArray(fields == null ? null : com_lightstreamer_internal_NativeArray.copy(fields,fields));
		if(this.mode == "COMMAND" && newValue != null) {
			if(newValue.indexOf("command") == -1) {
				throw new com_lightstreamer_internal_IllegalArgumentException("Field 'command' is missing");
			}
			if(newValue.indexOf("key") == -1) {
				throw new com_lightstreamer_internal_IllegalArgumentException("Field 'key' is missing");
			}
		}
		this.fields = newValue;
		this.schema = null;
	}
	,getFieldSchema: function() {
		return this.schema;
	}
	,setFieldSchema: function(schema) {
		this.checkActive();
		this.schema = com_lightstreamer_internal_Name.fromString(schema);
		this.fields = null;
	}
	,getRequestedBufferSize: function() {
		return com_lightstreamer_internal_RequestedBufferSizeTools.toString(this.bufferSize);
	}
	,setRequestedBufferSize: function(size) {
		if(size != null) {
			size = size == null ? "null" : "" + size;
		}
		this.checkActive();
		this.bufferSize = com_lightstreamer_internal_RequestedBufferSizeTools.fromString(size);
	}
	,getRequestedSnapshot: function() {
		return com_lightstreamer_internal_RequestedSnapshotTools.toString(this.snapshot);
	}
	,setRequestedSnapshot: function(snapshot) {
		if(snapshot != null) {
			snapshot = snapshot == null ? "null" : "" + snapshot;
		}
		this.checkActive();
		var newValue = com_lightstreamer_internal_RequestedSnapshotTools.fromString(snapshot);
		switch(this.mode) {
		case "COMMAND":case "MERGE":
			if(newValue != null && (newValue == null ? false : newValue._hx_index == 2)) {
				throw new com_lightstreamer_internal_IllegalArgumentException("Snapshot length is not permitted if MERGE or COMMAND was specified as mode");
			}
			break;
		case "RAW":
			if(newValue != null) {
				throw new com_lightstreamer_internal_IllegalArgumentException("Snapshot is not permitted if RAW was specified as mode");
			}
			break;
		default:
		}
		this.snapshot = newValue;
	}
	,getRequestedMaxFrequency: function() {
		return com_lightstreamer_internal_RequestedMaxFrequencyTools.toString(this.requestedMaxFrequency);
	}
	,setRequestedMaxFrequency: function(freq) {
		var _gthis = this;
		if(freq != null) {
			freq = freq == null ? "null" : "" + freq;
		}
		var _manager;
		this.lock.synchronized(function() {
			var newValue = com_lightstreamer_internal_RequestedMaxFrequencyTools.fromString(freq);
			switch(_gthis.mode) {
			case "COMMAND":case "DISTINCT":case "MERGE":
				break;
			default:
				throw new com_lightstreamer_internal_IllegalArgumentException("The operation in only available on MERGE, DISTINCT and COMMAND Subscripitons");
			}
			if(_gthis.isActive() && (newValue == null || newValue == com_lightstreamer_internal_RequestedMaxFrequency.FreqUnfiltered || _gthis.requestedMaxFrequency == com_lightstreamer_internal_RequestedMaxFrequency.FreqUnfiltered)) {
				throw new com_lightstreamer_internal_IllegalArgumentException("Cannot change the frequency from/to 'unfiltered' or to null while the Subscription is active");
			}
			if(_gthis.subId != null) {
				if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isInfoEnabled()) {
					com_lightstreamer_log_LoggerTools_subscriptionLogger.info("Subscription " + _gthis.subId + " requested max frequency changed: " + freq);
				}
			}
			_gthis.requestedMaxFrequency = newValue;
			_manager = _gthis.manager;
			return _manager;
		});
		if(_manager != null) {
			_manager.evtExtConfigure();
		}
	}
	,getSelector: function() {
		return this.selector;
	}
	,setSelector: function(selector) {
		this.checkActive();
		this.selector = com_lightstreamer_internal_Name.fromString(selector);
	}
	,getCommandPosition: function() {
		this.checkCommand();
		if(this.cmdIdx == null) {
			throw new com_lightstreamer_internal_IllegalStateException("The position of the command field is currently unknown");
		}
		return this.cmdIdx;
	}
	,getKeyPosition: function() {
		this.checkCommand();
		if(this.keyIdx == null) {
			throw new com_lightstreamer_internal_IllegalStateException("The position of the key field is currently unknown");
		}
		return this.keyIdx;
	}
	,getCommandSecondLevelDataAdapter: function() {
		return this.dataAdapter2;
	}
	,setCommandSecondLevelDataAdapter: function(dataAdapter) {
		this.checkActive();
		this.checkCommand();
		this.dataAdapter2 = com_lightstreamer_internal_Name.fromString(dataAdapter);
	}
	,getCommandSecondLevelFields: function() {
		if(this.fields2 == null) {
			return null;
		} else {
			return this.fields2.slice();
		}
	}
	,setCommandSecondLevelFields: function(fields) {
		this.checkActive();
		this.checkCommand();
		this.fields2 = com_lightstreamer_internal_Fields.fromArray(fields == null ? null : com_lightstreamer_internal_NativeArray.copy(fields,fields));
		this.schema2 = null;
	}
	,getCommandSecondLevelFieldSchema: function() {
		return this.schema2;
	}
	,setCommandSecondLevelFieldSchema: function(schema) {
		this.checkActive();
		this.checkCommand();
		this.schema2 = com_lightstreamer_internal_Name.fromString(schema);
		this.fields2 = null;
	}
	,getValue: function(itemNameOrPos,fieldNameOrPos) {
		var isItemPos = typeof(itemNameOrPos) == "number" && ((itemNameOrPos | 0) === itemNameOrPos);
		var isItemName = typeof(itemNameOrPos) == "string";
		var isFieldPos = typeof(fieldNameOrPos) == "number" && ((fieldNameOrPos | 0) === fieldNameOrPos);
		var isFieldName = typeof(fieldNameOrPos) == "string";
		if(isItemPos && isFieldPos) {
			return this.getValuePosPos(itemNameOrPos,fieldNameOrPos);
		} else if(isItemPos && isFieldName) {
			return this.getValuePosName(itemNameOrPos,fieldNameOrPos);
		} else if(isItemName && isFieldPos) {
			return this.getValueNamePos(itemNameOrPos,fieldNameOrPos);
		} else if(isItemName && isFieldName) {
			return this.getValueNameName(itemNameOrPos,fieldNameOrPos);
		} else {
			throw new com_lightstreamer_internal_IllegalArgumentException("Invalid argument type");
		}
	}
	,getCommandValue: function(itemNameOrPos,keyValue,fieldNameOrPos) {
		var isItemPos = typeof(itemNameOrPos) == "number" && ((itemNameOrPos | 0) === itemNameOrPos);
		var isItemName = typeof(itemNameOrPos) == "string";
		var isFieldPos = typeof(fieldNameOrPos) == "number" && ((fieldNameOrPos | 0) === fieldNameOrPos);
		var isFieldName = typeof(fieldNameOrPos) == "string";
		if(isItemPos && isFieldPos) {
			return this.getCommandValuePosPos(itemNameOrPos,keyValue,fieldNameOrPos);
		} else if(isItemPos && isFieldName) {
			return this.getCommandValuePosName(itemNameOrPos,keyValue,fieldNameOrPos);
		} else if(isItemName && isFieldPos) {
			return this.getCommandValueNamePos(itemNameOrPos,keyValue,fieldNameOrPos);
		} else if(isItemName && isFieldName) {
			return this.getCommandValueNameName(itemNameOrPos,keyValue,fieldNameOrPos);
		} else {
			throw new com_lightstreamer_internal_IllegalArgumentException("Invalid argument type");
		}
	}
	,getValuePosPos: function(itemPos,fieldPos) {
		var _gthis = this;
		if(itemPos < 1 || fieldPos < 1) {
			throw new com_lightstreamer_internal_IllegalArgumentException("The specified position is out of bounds");
		}
		var _manager;
		this.lock.synchronized(function() {
			_manager = _gthis.manager;
			return _manager;
		});
		if(_manager != null) {
			return _manager.getValue(itemPos,fieldPos);
		} else {
			return null;
		}
	}
	,getValuePosName: function(itemPos,fieldName) {
		var _gthis = this;
		return this.getValuePosPos(itemPos,this.lock.synchronized(function() {
			return _gthis.getFieldPos(fieldName);
		}));
	}
	,getValueNamePos: function(itemName,fieldPos) {
		var _gthis = this;
		return this.getValuePosPos(this.lock.synchronized(function() {
			return _gthis.getItemPos(itemName);
		}),fieldPos);
	}
	,getValueNameName: function(itemName,fieldName) {
		var _gthis = this;
		return this.getValuePosPos(this.lock.synchronized(function() {
			return _gthis.getItemPos(itemName);
		}),this.lock.synchronized(function() {
			return _gthis.getFieldPos(fieldName);
		}));
	}
	,getCommandValuePosPos: function(itemPos,keyValue,fieldPos) {
		var _gthis = this;
		this.checkCommand();
		if(itemPos < 1 || fieldPos < 1) {
			throw new com_lightstreamer_internal_IllegalArgumentException("The specified position is out of bounds");
		}
		var _manager;
		this.lock.synchronized(function() {
			_manager = _gthis.manager;
			return _manager;
		});
		if(_manager != null) {
			return _manager.getCommandValue(itemPos,keyValue,fieldPos);
		} else {
			return null;
		}
	}
	,getCommandValuePosName: function(itemPos,keyValue,fieldName) {
		var _gthis = this;
		return this.getCommandValuePosPos(itemPos,keyValue,this.lock.synchronized(function() {
			return _gthis.getFieldPos(fieldName);
		}));
	}
	,getCommandValueNamePos: function(itemName,keyValue,fieldPos) {
		var _gthis = this;
		return this.getCommandValuePosPos(this.lock.synchronized(function() {
			return _gthis.getItemPos(itemName);
		}),keyValue,fieldPos);
	}
	,getCommandValueNameName: function(itemName,keyValue,fieldName) {
		var _gthis = this;
		return this.getCommandValuePosPos(this.lock.synchronized(function() {
			return _gthis.getItemPos(itemName);
		}),keyValue,this.lock.synchronized(function() {
			return _gthis.getFieldPos(fieldName);
		}));
	}
	,getItemPos: function(itemName) {
		var itemPos;
		var tmp;
		if(this.items != null) {
			itemPos = com_lightstreamer_internal_Items.getPos(this.items,itemName);
			tmp = itemPos == -1;
		} else {
			tmp = true;
		}
		if(tmp) {
			throw new com_lightstreamer_internal_IllegalArgumentException("Unknown item name");
		}
		return itemPos;
	}
	,getFieldPos: function(fieldName) {
		var fieldPos;
		var tmp;
		if(this.fields != null) {
			fieldPos = com_lightstreamer_internal_Fields.getPos(this.fields,fieldName);
			tmp = fieldPos == -1;
		} else {
			tmp = true;
		}
		if(tmp) {
			throw new com_lightstreamer_internal_IllegalArgumentException("Unknown field name");
		}
		return fieldPos;
	}
	,checkActive: function() {
		if(this.isActive()) {
			throw new com_lightstreamer_internal_IllegalStateException("Cannot modify an active Subscription. Please unsubscribe before applying any change");
		}
	}
	,checkCommand: function() {
		if(this.mode != "COMMAND") {
			throw new com_lightstreamer_internal_IllegalStateException("This method can only be used on COMMAND subscriptions");
		}
	}
	,fetch_items: function() {
		return this.items;
	}
	,fetch_fields: function() {
		return this.fields;
	}
	,fetch_fields2: function() {
		return this.fields2;
	}
	,fetch_mode: function() {
		return this.mode;
	}
	,fetch_requestedBufferSize: function() {
		return this.bufferSize;
	}
	,fetch_requestedSnapshot: function() {
		return this.snapshot;
	}
	,fetch_requestedMaxFrequency: function() {
		return this.requestedMaxFrequency;
	}
	,fetch_nItems: function() {
		return this.nItems;
	}
	,fetch_subManager: function() {
		return this.manager;
	}
	,setActive: function() {
		this.state = com_lightstreamer_client__$Subscription_SubscriptionState.Active;
	}
	,setInactive: function() {
		this.state = com_lightstreamer_client__$Subscription_SubscriptionState.Inactive;
		this.subId = null;
		this.cmdIdx = null;
		this.keyIdx = null;
		this.nItems = null;
		this.nFields = null;
	}
	,setSubscribed: function(subId,nItems,nFields) {
		this.state = com_lightstreamer_client__$Subscription_SubscriptionState.Subscribed;
		this.subId = subId;
		this.nItems = nItems;
		this.nFields = nFields;
	}
	,setSubscribedCMD: function(subId,nItems,nFields,cmdIdx,keyIdx) {
		this.state = com_lightstreamer_client__$Subscription_SubscriptionState.Subscribed;
		this.subId = subId;
		this.cmdIdx = cmdIdx;
		this.keyIdx = keyIdx;
		this.nItems = nItems;
		this.nFields = nFields;
	}
	,fetch_nFields: function() {
		return this.nFields;
	}
	,isInternal: function() {
		return this.m_internal;
	}
	,setInternal: function() {
		this.m_internal = true;
	}
	,getItemName: function(itemIdx) {
		if(this.items != null) {
			return this.items[itemIdx - 1];
		}
		return null;
	}
	,relate: function(manager) {
		this.manager = manager;
	}
	,unrelate: function(manager) {
		if(this.manager != manager) {
			return;
		}
		this.manager = null;
	}
	,hasSnapshot: function() {
		return !(this.snapshot == null || this.snapshot == com_lightstreamer_internal_RequestedSnapshot.SnpNo);
	}
	,getItemNameOrPos: function(itemIdx) {
		if(this.items != null) {
			return this.items[itemIdx - 1];
		} else {
			return "" + itemIdx;
		}
	}
	,fireOnSubscription: function(subId) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.info("Subscription " + subId + " added");
		}
		this.eventDispatcher.onSubscription();
	}
	,fireOnUnsubscription: function(subId) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.info("Subscription " + subId + " deleted");
		}
		this.eventDispatcher.onUnsubscription();
	}
	,fireOnSubscriptionError: function(subId,code,msg) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.warn("Subscription " + subId + " failed: " + code + " - " + msg);
		}
		this.eventDispatcher.onSubscriptionError(code,msg);
	}
	,fireOnEndOfSnapshot: function(itemIdx,subId) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.debug("Subscription " + subId + ":" + this.getItemNameOrPos(itemIdx) + ": snapshot ended");
		}
		this.eventDispatcher.onEndOfSnapshot(this.getItemName(itemIdx),itemIdx);
	}
	,fireOnClearSnapshot: function(itemIdx,subId) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.debug("Subscription " + subId + ":" + this.getItemNameOrPos(itemIdx) + ": snapshot cleared");
		}
		this.eventDispatcher.onClearSnapshot(this.getItemName(itemIdx),itemIdx);
	}
	,fireOnLostUpdates: function(itemIdx,lostUpdates,subId) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.debug("Subscription " + subId + ":" + this.getItemNameOrPos(itemIdx) + ": lost " + lostUpdates + " updates");
		}
		this.eventDispatcher.onItemLostUpdates(this.getItemName(itemIdx),itemIdx,lostUpdates);
	}
	,fireOnItemUpdate: function(update,subId) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.debug("Subscription " + subId + ":" + this.getItemNameOrPos(update.getItemPos()) + " update: " + Std.string(update));
		}
		this.eventDispatcher.onItemUpdate(update);
	}
	,fireOnRealMaxFrequency: function(freq,subId) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.debug("Subscription " + subId + " real max frequency changed: " + Std.string(freq));
		}
		this.eventDispatcher.onRealMaxFrequency(com_lightstreamer_internal_Types_realFrequencyAsString(freq));
	}
	,fireOnSubscriptionError2Level: function(keyName,code,msg,subId,itemIdx) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.warn("Subscription " + subId + ":" + this.getItemNameOrPos(itemIdx) + ":" + keyName + " failed: " + code + " - " + msg);
		}
		this.eventDispatcher.onCommandSecondLevelSubscriptionError(code,msg,keyName);
	}
	,fireOnLostUpdates2Level: function(keyName,lostUpdates,subId,itemIdx) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.debug("Subscription " + subId + ":" + this.getItemNameOrPos(itemIdx) + ":" + keyName + ": lost " + lostUpdates + " updates");
		}
		this.eventDispatcher.onCommandSecondLevelItemLostUpdates(lostUpdates,keyName);
	}
	,toString: function() {
		var map = com_lightstreamer_internal_InfoMap._new();
		com_lightstreamer_internal_InfoMap.set(map,"mode",this.mode);
		com_lightstreamer_internal_InfoMap.set(map,"items",this.items != null ? Std.string(this.items) : this.group);
		com_lightstreamer_internal_InfoMap.set(map,"fields",this.fields != null ? Std.string(this.fields) : this.schema);
		com_lightstreamer_internal_InfoMap.set(map,"dataAdapter",this.dataAdapter);
		com_lightstreamer_internal_InfoMap.set(map,"requestedBufferSize",this.bufferSize);
		com_lightstreamer_internal_InfoMap.set(map,"requestedSnapshot",this.snapshot);
		com_lightstreamer_internal_InfoMap.set(map,"requestedMaxFrequency",this.requestedMaxFrequency);
		com_lightstreamer_internal_InfoMap.set(map,"selector",this.selector);
		com_lightstreamer_internal_InfoMap.set(map,"secondLevelFields",this.fields2 != null ? Std.string(this.fields2) : this.schema2);
		com_lightstreamer_internal_InfoMap.set(map,"secondLevelDataAdapter",this.dataAdapter2);
		return haxe_ds_StringMap.stringify(map.h);
	}
	,__class__: LSSubscription
};
var com_lightstreamer_internal_IPageLifecycle = function() { };
com_lightstreamer_internal_IPageLifecycle.__name__ = true;
com_lightstreamer_internal_IPageLifecycle.__isInterface__ = true;
com_lightstreamer_internal_IPageLifecycle.prototype = {
	__class__: com_lightstreamer_internal_IPageLifecycle
};
var com_lightstreamer_internal_PageLifecycle = function(onEvent) {
	this.frozen = false;
	this.onEvent = onEvent;
};
com_lightstreamer_internal_PageLifecycle.__name__ = true;
com_lightstreamer_internal_PageLifecycle.__interfaces__ = [com_lightstreamer_internal_IPageLifecycle];
com_lightstreamer_internal_PageLifecycle.newLoggingInstance = function() {
	var glb = new com_lightstreamer_internal_PageLifecycle(function(e) {
		if(com_lightstreamer_log_LoggerTools_pageLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_pageLogger.warn("" + Std.string(e) + " event detected");
		}
	});
	glb.startListening();
	return glb;
};
com_lightstreamer_internal_PageLifecycle.prototype = {
	handleFrozen: function() {
		this.frozen = true;
		this.onEvent(com_lightstreamer_internal_PageState.Frozen);
	}
	,handleResumed: function() {
		this.frozen = false;
		this.onEvent(com_lightstreamer_internal_PageState.Resumed);
	}
	,startListening: function() {
		if(js_Browser.get_supported()) {
			window.addEventListener("freeze",$bind(this,this.handleFrozen),{ capture : true});
			window.addEventListener("resume",$bind(this,this.handleResumed),{ capture : true});
		}
	}
	,stopListening: function() {
		if(js_Browser.get_supported()) {
			window.removeEventListener("freeze",$bind(this,this.handleFrozen),{ capture : true});
			window.removeEventListener("resume",$bind(this,this.handleResumed),{ capture : true});
		}
	}
	,__class__: com_lightstreamer_internal_PageLifecycle
};
var com_lightstreamer_log_AbstractLogger = function() { };
com_lightstreamer_log_AbstractLogger.__name__ = true;
com_lightstreamer_log_AbstractLogger.__interfaces__ = [Logger];
com_lightstreamer_log_AbstractLogger.prototype = {
	__class__: com_lightstreamer_log_AbstractLogger
};
var com_lightstreamer_log__$LogManager_EmptyLogger = function() {
};
com_lightstreamer_log__$LogManager_EmptyLogger.__name__ = true;
com_lightstreamer_log__$LogManager_EmptyLogger.__super__ = com_lightstreamer_log_AbstractLogger;
com_lightstreamer_log__$LogManager_EmptyLogger.prototype = $extend(com_lightstreamer_log_AbstractLogger.prototype,{
	fatal: function(line,exception) {
	}
	,error: function(line,exception) {
	}
	,warn: function(line,exception) {
	}
	,info: function(line,exception) {
	}
	,debug: function(line,exception) {
	}
	,trace: function(line,exception) {
	}
	,isFatalEnabled: function() {
		return false;
	}
	,isErrorEnabled: function() {
		return false;
	}
	,isWarnEnabled: function() {
		return false;
	}
	,isInfoEnabled: function() {
		return false;
	}
	,isDebugEnabled: function() {
		return false;
	}
	,isTraceEnabled: function() {
		return false;
	}
	,__class__: com_lightstreamer_log__$LogManager_EmptyLogger
});
var com_lightstreamer_internal_RLock = function() {
};
com_lightstreamer_internal_RLock.__name__ = true;
com_lightstreamer_internal_RLock.prototype = {
	acquire: function() {
	}
	,release: function() {
	}
	,synchronized: function(func) {
		var ex = null;
		var result = null;
		try {
			result = func();
		} catch( _g ) {
			ex = haxe_Exception.caught(_g);
		}
		if(ex != null) {
			throw haxe_Exception.thrown(ex);
		}
		return result;
	}
	,__class__: com_lightstreamer_internal_RLock
};
var com_lightstreamer_log_LogManager = function() { };
com_lightstreamer_log_LogManager.__name__ = true;
com_lightstreamer_log_LogManager.getLogger = function(category) {
	return com_lightstreamer_log_LogManager.lock.synchronized(function() {
		var log = com_lightstreamer_log_LogManager.logInstances.h[category];
		if(log == null) {
			var v = new com_lightstreamer_log__$LogManager_LoggerProxy(com_lightstreamer_log_LogManager.currentLoggerProvider == null ? com_lightstreamer_log_LogManager.emptyLogger : com_lightstreamer_log_LogManager.currentLoggerProvider.getLogger(category));
			com_lightstreamer_log_LogManager.logInstances.h[category] = v;
			log = v;
		}
		return log;
	});
};
com_lightstreamer_log_LogManager.setLoggerProvider = function(provider) {
	com_lightstreamer_log_LogManager.lock.synchronized(function() {
		com_lightstreamer_log_LogManager.currentLoggerProvider = provider;
		var h = com_lightstreamer_log_LogManager.logInstances.h;
		var _g_keys = Object.keys(h);
		var _g_length = _g_keys.length;
		var _g_current = 0;
		while(_g_current < _g_length) {
			var key = _g_keys[_g_current++];
			h[key].wrappedLogger = com_lightstreamer_log_LogManager.currentLoggerProvider == null ? com_lightstreamer_log_LogManager.emptyLogger : com_lightstreamer_log_LogManager.currentLoggerProvider.getLogger(key);
		}
	});
};
com_lightstreamer_log_LogManager.newLogger = function(category) {
	if(com_lightstreamer_log_LogManager.currentLoggerProvider == null) {
		return com_lightstreamer_log_LogManager.emptyLogger;
	} else {
		return com_lightstreamer_log_LogManager.currentLoggerProvider.getLogger(category);
	}
};
var com_lightstreamer_log__$LogManager_LoggerProxy = function(logger) {
	this.wrappedLogger = logger;
};
com_lightstreamer_log__$LogManager_LoggerProxy.__name__ = true;
com_lightstreamer_log__$LogManager_LoggerProxy.__super__ = com_lightstreamer_log_AbstractLogger;
com_lightstreamer_log__$LogManager_LoggerProxy.prototype = $extend(com_lightstreamer_log_AbstractLogger.prototype,{
	fatal: function(line,exception) {
		this.wrappedLogger.fatal(line,exception);
	}
	,error: function(line,exception) {
		this.wrappedLogger.error(line,exception);
	}
	,warn: function(line,exception) {
		this.wrappedLogger.warn(line,exception);
	}
	,info: function(line,exception) {
		this.wrappedLogger.info(line,exception);
	}
	,debug: function(line,exception) {
		this.wrappedLogger.debug(line,exception);
	}
	,trace: function(line,exception) {
		this.wrappedLogger.trace(line,exception);
	}
	,isFatalEnabled: function() {
		return this.wrappedLogger.isFatalEnabled();
	}
	,isErrorEnabled: function() {
		return this.wrappedLogger.isErrorEnabled();
	}
	,isWarnEnabled: function() {
		return this.wrappedLogger.isWarnEnabled();
	}
	,isInfoEnabled: function() {
		return this.wrappedLogger.isInfoEnabled();
	}
	,isDebugEnabled: function() {
		return this.wrappedLogger.isDebugEnabled();
	}
	,isTraceEnabled: function() {
		return this.wrappedLogger.isTraceEnabled();
	}
	,__class__: com_lightstreamer_log__$LogManager_LoggerProxy
});
var js_Boot = function() { };
js_Boot.__name__ = true;
js_Boot.getClass = function(o) {
	if(o == null) {
		return null;
	} else if(((o) instanceof Array)) {
		return Array;
	} else {
		var cl = o.__class__;
		if(cl != null) {
			return cl;
		}
		var name = js_Boot.__nativeClassName(o);
		if(name != null) {
			return js_Boot.__resolveNativeClass(name);
		}
		return null;
	}
};
js_Boot.__string_rec = function(o,s) {
	if(o == null) {
		return "null";
	}
	if(s.length >= 5) {
		return "<...>";
	}
	var t = typeof(o);
	if(t == "function" && (o.__name__ || o.__ename__)) {
		t = "object";
	}
	switch(t) {
	case "function":
		return "<function>";
	case "object":
		if(o.__enum__) {
			var e = $hxEnums[o.__enum__];
			var con = e.__constructs__[o._hx_index];
			var n = con._hx_name;
			if(con.__params__) {
				s = s + "\t";
				return n + "(" + ((function($this) {
					var $r;
					var _g = [];
					{
						var _g1 = 0;
						var _g2 = con.__params__;
						while(true) {
							if(!(_g1 < _g2.length)) {
								break;
							}
							var p = _g2[_g1];
							_g1 = _g1 + 1;
							_g.push(js_Boot.__string_rec(o[p],s));
						}
					}
					$r = _g;
					return $r;
				}(this))).join(",") + ")";
			} else {
				return n;
			}
		}
		if(((o) instanceof Array)) {
			var str = "[";
			s += "\t";
			var _g = 0;
			var _g1 = o.length;
			while(_g < _g1) {
				var i = _g++;
				str += (i > 0 ? "," : "") + js_Boot.__string_rec(o[i],s);
			}
			str += "]";
			return str;
		}
		var tostr;
		try {
			tostr = o.toString;
		} catch( _g ) {
			return "???";
		}
		if(tostr != null && tostr != Object.toString && typeof(tostr) == "function") {
			var s2 = o.toString();
			if(s2 != "[object Object]") {
				return s2;
			}
		}
		var str = "{\n";
		s += "\t";
		var hasp = o.hasOwnProperty != null;
		var k = null;
		for( k in o ) {
		if(hasp && !o.hasOwnProperty(k)) {
			continue;
		}
		if(k == "prototype" || k == "__class__" || k == "__super__" || k == "__interfaces__" || k == "__properties__") {
			continue;
		}
		if(str.length != 2) {
			str += ", \n";
		}
		str += s + k + " : " + js_Boot.__string_rec(o[k],s);
		}
		s = s.substring(1);
		str += "\n" + s + "}";
		return str;
	case "string":
		return o;
	default:
		return String(o);
	}
};
js_Boot.__interfLoop = function(cc,cl) {
	while(true) {
		if(cc == null) {
			return false;
		}
		if(cc == cl) {
			return true;
		}
		var intf = cc.__interfaces__;
		if(intf != null) {
			var _g = 0;
			var _g1 = intf.length;
			while(_g < _g1) {
				var i = intf[_g++];
				if(i == cl || js_Boot.__interfLoop(i,cl)) {
					return true;
				}
			}
		}
		cc = cc.__super__;
	}
};
js_Boot.__instanceof = function(o,cl) {
	if(cl == null) {
		return false;
	}
	switch(cl) {
	case Array:
		return ((o) instanceof Array);
	case Bool:
		return typeof(o) == "boolean";
	case Dynamic:
		return o != null;
	case Float:
		return typeof(o) == "number";
	case Int:
		if(typeof(o) == "number") {
			return ((o | 0) === o);
		} else {
			return false;
		}
		break;
	case String:
		return typeof(o) == "string";
	default:
		if(o != null) {
			if(typeof(cl) == "function") {
				if(js_Boot.__downcastCheck(o,cl)) {
					return true;
				}
			} else if(typeof(cl) == "object" && js_Boot.__isNativeObj(cl)) {
				if(((o) instanceof cl)) {
					return true;
				}
			}
		} else {
			return false;
		}
		if(cl == Class ? o.__name__ != null : false) {
			return true;
		}
		if(cl == Enum ? o.__ename__ != null : false) {
			return true;
		}
		return o.__enum__ != null ? $hxEnums[o.__enum__] == cl : false;
	}
};
js_Boot.__downcastCheck = function(o,cl) {
	if(!((o) instanceof cl)) {
		if(cl.__isInterface__) {
			return js_Boot.__interfLoop(js_Boot.getClass(o),cl);
		} else {
			return false;
		}
	} else {
		return true;
	}
};
js_Boot.__cast = function(o,t) {
	if(o == null || js_Boot.__instanceof(o,t)) {
		return o;
	} else {
		throw haxe_Exception.thrown("Cannot cast " + Std.string(o) + " to " + Std.string(t));
	}
};
js_Boot.__nativeClassName = function(o) {
	var name = js_Boot.__toStr.call(o).slice(8,-1);
	if(name == "Object" || name == "Function" || name == "Math" || name == "JSON") {
		return null;
	}
	return name;
};
js_Boot.__isNativeObj = function(o) {
	return js_Boot.__nativeClassName(o) != null;
};
js_Boot.__resolveNativeClass = function(name) {
	return $global[name];
};
var com_lightstreamer_client_internal_ClientMachine = function(client,factory) {
	this.slw_m = 0.5;
	this.slw_hugeDelayMs = 20000;
	this.slw_maxAvgDelayMs = 7000;
	this.slw_avgDelayMs = 0;
	this.slw_refTime = 0;
	this.rec_clientProg = 0;
	this.rec_serverProg = 0;
	this.subscriptionManagers = new com_lightstreamer_internal_OrderedIntMapImpl();
	this.messageManagers = new com_lightstreamer_internal_MyArray();
	this.sequenceMap = new haxe_ds_StringMap();
	this.disabledTransports = new com_lightstreamer_internal_Set();
	this.suspendedTransports = new com_lightstreamer_internal_Set();
	this.ctrl_connectTs = 0;
	this.recoverTs = 0;
	this.connectTs = 0;
	this.m_nextSubId = 0;
	this.m_nextReqId = 0;
	this.m_status = "DISCONNECTED";
	this.delayCounter = new com_lightstreamer_client_internal_RetryDelayCounter();
	this.state = new com_lightstreamer_client_internal_State();
	this.client = client;
	this.lock = client.lock;
	this.details = client.connectionDetails;
	this.options = client.connectionOptions;
	this.wsFactory = $bind(factory,factory.createWsClient);
	this.httpFactory = $bind(factory,factory.createHttpClient);
	this.ctrlFactory = $bind(factory,factory.createCtrlClient);
	this.timerFactory = $bind(factory,factory.createTimer);
	this.randomGenerator = $bind(factory,factory.randomMillis);
	this.reachabilityFactory = $bind(factory,factory.createReachabilityManager);
	this.frz_pageLifecycleFactory = $bind(factory,factory.createPageLifecycleFactory);
	this.clientEventDispatcher = client.eventDispatcher;
	this.switchRequest = new com_lightstreamer_client_internal_SwitchRequest(this);
	this.constrainRequest = new com_lightstreamer_client_internal_ConstrainRequest(this);
	this.delayCounter.reset(this.options.retryDelay);
};
com_lightstreamer_client_internal_ClientMachine.__name__ = true;
com_lightstreamer_client_internal_ClientMachine.prototype = {
	disposeSession: function() {
		this.disposeWS();
		this.disposeHTTP();
		this.disposeCtrl();
		this.details.setServerInstanceAddress(null);
		this.details.setSessionId(null);
		this.details.setServerSocketName(null);
		this.details.setClientIp(null);
		this.options.setRealMaxBandwidth(null);
		this.lastKnownClientIp = null;
		this.resetSequenceMap();
		this.rec_serverProg = 0;
		this.rec_clientProg = 0;
		this.bw_lastReqId = null;
		this.bw_requestedMaxBandwidth = null;
		this.swt_lastReqId = null;
	}
	,disposeClient: function() {
		this.sessionId = null;
		this.enableAllTransports();
		this.resetCurrentRetryDelay();
		this.resetSequenceMap();
		this.cause = null;
	}
	,evtExtConnect: function() {
		this.traceEvent("connect");
		var forward = true;
		if(this.state.s_m == 100) {
			if(com_lightstreamer_client_internal_ClientMachine.frz_globalPageLifecycle.frozen) {
				if(com_lightstreamer_log_LoggerTools_pageLogger.isWarnEnabled()) {
					com_lightstreamer_log_LoggerTools_pageLogger.warn("connection requested while page is frozen");
				}
				this.cause = "page.frozen";
				this.resetCurrentRetryDelay();
				this.doInstallPageLifecycle();
				this.state.s_m = 117;
				this.state.traceState();
				forward = this.evtExtConnect_NextRegion();
				var tmp = this.frz_pageLifecycle;
				if(tmp != null) {
					tmp.startListening();
				}
				this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
			} else {
				this.cause = "api";
				this.resetCurrentRetryDelay();
				this.doInstallPageLifecycle();
				this.state.s_m = 101;
				this.state.traceState();
				forward = this.evtExtConnect_NextRegion();
				var tmp = this.frz_pageLifecycle;
				if(tmp != null) {
					tmp.startListening();
				}
				this.evtSelectCreate();
			}
		}
		if(forward) {
			this.evtExtConnect_NextRegion();
		}
	}
	,evtExtConnect_NextRegion: function() {
		return this.evtExtConnect_NetworkReachabilityRegion();
	}
	,evtExtConnect_NetworkReachabilityRegion: function() {
		var _gthis = this;
		if(this.state.s_nr == 1400) {
			var hostAddress = new com_lightstreamer_internal_Url(this.getServerAddress()).get_hostname();
			this.nr_reachabilityManager = this.reachabilityFactory(hostAddress);
			this.state.s_nr = 1410;
			this.state.traceState();
			this.nr_reachabilityManager.startListening(function(status) {
				switch(status._hx_index) {
				case 0:
					_gthis.evtNetworkReachable(hostAddress);
					break;
				case 1:
					_gthis.evtNetworkNotReachable(hostAddress);
					break;
				}
			});
		}
		return false;
	}
	,doInstallPageLifecycle: function() {
		var _gthis = this;
		this.frz_pageLifecycle = this.frz_pageLifecycleFactory(function(e) {
			switch(e._hx_index) {
			case 0:
				_gthis.evtPageFrozen();
				break;
			case 1:
				_gthis.evtPageResumed();
				break;
			}
		});
	}
	,doUnistallPageLifecycle: function() {
		var tmp = this.frz_pageLifecycle;
		if(tmp != null) {
			tmp.stopListening();
		}
		this.frz_pageLifecycle = null;
	}
	,evtPageFrozen: function() {
		this.traceEvent("page.frozen");
		switch(this.state.s_m) {
		case 110:case 111:case 112:case 113:case 114:case 115:case 116:
			this.notifyStatus("DISCONNECTED:WILL-RETRY");
			this.cause = "page.frozen";
			this.state.s_m = 117;
			this.state.traceState();
			this.cancel_evtRetryTimeout();
			break;
		case 120:case 121:case 122:
			this.disposeWS();
			this.notifyStatus("DISCONNECTED:WILL-RETRY");
			this.cause = "page.frozen";
			this.state.s_m = 117;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
			break;
		case 130:
			this.disposeHTTP();
			this.notifyStatus("DISCONNECTED:WILL-RETRY");
			this.cause = "page.frozen";
			this.state.s_m = 117;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
			break;
		case 140:
			this.disposeHTTP();
			this.notifyStatus("DISCONNECTED:WILL-RETRY");
			this.cause = "page.frozen";
			this.state.s_m = 117;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
			break;
		case 150:
			var _g = this.state.s_tr;
			if(_g != null) {
				switch(_g) {
				case 210:
					this.sendDestroyWS("page.frozen");
					this.closeWS();
					this.notifyStatus("DISCONNECTED:WILL-RETRY");
					this.cause = "page.frozen";
					this.state.clear_w();
					this.state.goto_m_from_session(117);
					this.exit_w();
					this.evtEndSession();
					this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
					break;
				case 220:
					this.disposeHTTP();
					this.notifyStatus("DISCONNECTED:WILL-RETRY");
					this.cause = "page.frozen";
					this.state.goto_m_from_session(117);
					this.cancel_evtTransportTimeout();
					this.evtEndSession();
					this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
					break;
				case 230:
					this.disposeHTTP();
					this.notifyStatus("DISCONNECTED:WILL-RETRY");
					this.cause = "page.frozen";
					this.state.goto_m_from_session(117);
					this.cancel_evtTransportTimeout();
					this.evtEndSession();
					this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
					break;
				case 240:
					var tmp = this.state.s_ws;
					if((tmp != null ? tmp.m : null) == 500) {
						this.disposeWS();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "page.frozen";
						this.state.goto_m_from_ws(117);
						this.exit_ws_to_m();
						this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
					} else {
						var tmp;
						var tmp1;
						var tmp2 = this.state.s_ws;
						if((tmp2 != null ? tmp2.m : null) != 501) {
							var tmp2 = this.state.s_ws;
							tmp1 = (tmp2 != null ? tmp2.m : null) == 502;
						} else {
							tmp1 = true;
						}
						if(!tmp1) {
							var tmp1 = this.state.s_ws;
							tmp = (tmp1 != null ? tmp1.m : null) == 503;
						} else {
							tmp = true;
						}
						if(tmp) {
							this.sendDestroyWS("page.frozen");
							this.closeWS();
							this.notifyStatus("DISCONNECTED:WILL-RETRY");
							this.cause = "page.frozen";
							this.state.goto_m_from_ws(117);
							this.exit_ws_to_m();
							this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
						}
					}
					break;
				case 250:
					var tmp;
					var tmp1 = this.state.s_wp;
					if((tmp1 != null ? tmp1.m : null) != 600) {
						var tmp1 = this.state.s_wp;
						tmp = (tmp1 != null ? tmp1.m : null) == 601;
					} else {
						tmp = true;
					}
					if(tmp) {
						this.disposeWS();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "page.frozen";
						this.state.goto_m_from_wp(117);
						this.exit_ws_to_m();
						this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
					} else {
						var tmp = this.state.s_wp;
						if((tmp != null ? tmp.m : null) == 602) {
							this.sendDestroyWS("page.frozen");
							this.closeWS();
							this.notifyStatus("DISCONNECTED:WILL-RETRY");
							this.cause = "page.frozen";
							this.state.goto_m_from_wp(117);
							this.exit_wp_to_m();
							this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
						}
					}
					break;
				case 260:
					this.disposeHTTP();
					this.notifyStatus("DISCONNECTED:WILL-RETRY");
					this.cause = "page.frozen";
					this.state.goto_m_from_rec(117);
					this.exit_rec_to_m();
					this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
					break;
				case 270:
					if(this.state.s_h == 710) {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "page.frozen";
						this.state.goto_m_from_hs(117);
						this.exit_hs_to_m();
						this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
					} else if(this.state.s_h == 720) {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "page.frozen";
						this.state.goto_m_from_hp(117);
						this.exit_hp_to_m();
						this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen);
					}
					break;
				default:
				}
			}
			break;
		default:
		}
	}
	,evtPageResumed: function() {
		this.traceEvent("page.resumed");
		if(this.state.s_m == 117) {
			this.state.s_m = 116;
			this.state.traceState();
			this.evtSelectCreate();
		}
	}
	,evtNetworkNotReachable: function(host) {
		if(com_lightstreamer_log_LoggerTools_reachabilityLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_reachabilityLogger.info("" + host + " is NOT reachable");
		}
		this.traceEvent("nr:network.not.reachable");
		if(this.state.s_nr == 1410) {
			this.state.s_nr = 1411;
			this.state.traceState();
		} else if(this.state.s_nr == 1412) {
			this.state.s_nr = 1411;
			this.state.traceState();
		}
	}
	,evtNetworkReachable: function(host) {
		if(com_lightstreamer_log_LoggerTools_reachabilityLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_reachabilityLogger.info("" + host + " is reachable");
		}
		this.traceEvent("nr:network.reachable");
		if(this.state.s_nr == 1410) {
			this.state.s_nr = 1412;
			this.state.traceState();
		} else if(this.state.s_nr == 1411) {
			this.state.s_nr = 1412;
			this.state.traceState();
			this.evtOnlineAgain();
		}
	}
	,evtOnlineAgain: function() {
		this.traceEvent("online.again");
		if(this.state.s_m == 112) {
			this.state.s_m = 116;
			this.state.traceState();
			this.cancel_evtRetryTimeout();
			this.evtSelectCreate();
		} else if(this.state.s_rec == 1003) {
			this.sendRecovery();
			this.state.s_rec = 1001;
			this.state.traceState();
			this.cancel_evtRetryTimeout();
			this.schedule_evtTransportTimeout(this.options.retryDelay);
		}
	}
	,evtServerAddressChanged: function() {
		var _gthis = this;
		this.traceEvent("nr:serverAddress.changed");
		switch(this.state.s_nr) {
		case 1410:case 1411:case 1412:
			var oldManager = this.nr_reachabilityManager;
			var hostAddress = new com_lightstreamer_internal_Url(this.getServerAddress()).get_hostname();
			this.nr_reachabilityManager = this.reachabilityFactory(hostAddress);
			this.state.s_nr = 1410;
			this.state.traceState();
			if(oldManager != null) {
				oldManager.stopListening();
			}
			this.nr_reachabilityManager.startListening(function(status) {
				switch(status._hx_index) {
				case 0:
					_gthis.evtNetworkReachable(hostAddress);
					break;
				case 1:
					_gthis.evtNetworkNotReachable(hostAddress);
					break;
				}
			});
			break;
		default:
		}
	}
	,evtExtDisconnect: function(terminationCause) {
		this.traceEvent("disconnect: cause=" + Std.string(terminationCause));
		switch(this.state.s_m) {
		case 110:case 111:case 112:case 113:case 114:case 115:case 116:case 117:
			this.notifyStatus("DISCONNECTED");
			this.notifyServerErrorIfCauseIsError(terminationCause);
			this.state.s_m = 100;
			this.state.traceState();
			this.cancel_evtRetryTimeout();
			this.evtTerminate(terminationCause);
			break;
		case 120:case 121:case 122:
			this.disposeWS();
			this.notifyStatus("DISCONNECTED");
			this.notifyServerErrorIfCauseIsError(terminationCause);
			this.state.s_m = 100;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtTerminate(terminationCause);
			break;
		case 130:
			this.disposeHTTP();
			this.notifyStatus("DISCONNECTED");
			this.notifyServerErrorIfCauseIsError(terminationCause);
			this.state.s_m = 100;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtTerminate(terminationCause);
			break;
		case 140:
			this.disposeHTTP();
			this.notifyStatus("DISCONNECTED");
			this.notifyServerErrorIfCauseIsError(terminationCause);
			this.state.s_m = 100;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtTerminate(terminationCause);
			break;
		case 150:
			var _g = this.state.s_tr;
			if(_g != null) {
				switch(_g) {
				case 210:
					this.sendDestroyWS();
					this.closeWS();
					this.notifyStatus("DISCONNECTED");
					this.notifyServerErrorIfCauseIsError(terminationCause);
					this.state.clear_w();
					this.state.goto_m_from_session(100);
					this.exit_w();
					this.evtEndSession();
					this.evtTerminate(terminationCause);
					break;
				case 220:
					this.disposeHTTP();
					this.notifyStatus("DISCONNECTED");
					this.notifyServerErrorIfCauseIsError(terminationCause);
					this.state.goto_m_from_session(100);
					this.cancel_evtTransportTimeout();
					this.evtEndSession();
					this.evtTerminate(terminationCause);
					break;
				case 230:
					this.disposeHTTP();
					this.notifyStatus("DISCONNECTED");
					this.notifyServerErrorIfCauseIsError(terminationCause);
					this.state.goto_m_from_session(100);
					this.cancel_evtTransportTimeout();
					this.evtEndSession();
					this.evtTerminate(terminationCause);
					break;
				case 240:
					var tmp = this.state.s_ws;
					if((tmp != null ? tmp.m : null) == 500) {
						this.disposeWS();
						this.notifyStatus("DISCONNECTED");
						this.notifyServerErrorIfCauseIsError(terminationCause);
						this.state.goto_m_from_ws(100);
						this.exit_ws_to_m();
						this.evtTerminate(terminationCause);
					} else {
						var tmp;
						var tmp1;
						var tmp2 = this.state.s_ws;
						if((tmp2 != null ? tmp2.m : null) != 501) {
							var tmp2 = this.state.s_ws;
							tmp1 = (tmp2 != null ? tmp2.m : null) == 502;
						} else {
							tmp1 = true;
						}
						if(!tmp1) {
							var tmp1 = this.state.s_ws;
							tmp = (tmp1 != null ? tmp1.m : null) == 503;
						} else {
							tmp = true;
						}
						if(tmp) {
							this.sendDestroyWS();
							this.closeWS();
							this.notifyStatus("DISCONNECTED");
							this.notifyServerErrorIfCauseIsError(terminationCause);
							this.state.goto_m_from_ws(100);
							this.exit_ws_to_m();
							this.evtTerminate(terminationCause);
						}
					}
					break;
				case 250:
					var tmp;
					var tmp1 = this.state.s_wp;
					if((tmp1 != null ? tmp1.m : null) != 600) {
						var tmp1 = this.state.s_wp;
						tmp = (tmp1 != null ? tmp1.m : null) == 601;
					} else {
						tmp = true;
					}
					if(tmp) {
						this.disposeWS();
						this.notifyStatus("DISCONNECTED");
						this.notifyServerErrorIfCauseIsError(terminationCause);
						this.state.goto_m_from_wp(100);
						this.exit_ws_to_m();
						this.evtTerminate(terminationCause);
					} else {
						var tmp = this.state.s_wp;
						if((tmp != null ? tmp.m : null) == 602) {
							this.sendDestroyWS();
							this.closeWS();
							this.notifyStatus("DISCONNECTED");
							this.notifyServerErrorIfCauseIsError(terminationCause);
							this.state.goto_m_from_wp(100);
							this.exit_wp_to_m();
							this.evtTerminate(terminationCause);
						}
					}
					break;
				case 260:
					this.disposeHTTP();
					this.notifyStatus("DISCONNECTED");
					this.notifyServerErrorIfCauseIsError(terminationCause);
					this.state.goto_m_from_rec(100);
					this.exit_rec_to_m();
					this.evtTerminate(terminationCause);
					break;
				case 270:
					if(this.state.s_h == 710) {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED");
						this.notifyServerErrorIfCauseIsError(terminationCause);
						this.state.goto_m_from_hs(100);
						this.exit_hs_to_m();
						this.evtTerminate(terminationCause);
					} else if(this.state.s_h == 720) {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED");
						this.notifyServerErrorIfCauseIsError(terminationCause);
						this.state.goto_m_from_hp(100);
						this.exit_hp_to_m();
						this.evtTerminate(terminationCause);
					}
					break;
				default:
				}
			}
			break;
		default:
		}
	}
	,evtSelectCreate: function() {
		this.traceEvent("select.create");
		if(this.state.s_m == 101 || this.state.s_m == 116) {
			switch(this.getBestForCreating()._hx_index) {
			case 0:
				this.notifyStatus("CONNECTING");
				this.openWS_Create();
				this.state.s_m = 120;
				this.state.traceState();
				this.evtCreate();
				this.schedule_evtTransportTimeout(this.delayCounter.get_currentRetryDelay());
				break;
			case 1:
				this.notifyStatus("CONNECTING");
				this.sendCreateHTTP();
				this.state.s_m = 130;
				this.state.traceState();
				this.evtCreate();
				this.schedule_evtTransportTimeout(this.delayCounter.get_currentRetryDelay());
				break;
			case 2:
				this.notifyStatus("DISCONNECTED");
				this.state.s_m = 100;
				this.state.traceState();
				this.evtTerminate(com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_otherError("Unable to select a transport"));
				break;
			}
		}
	}
	,evtWSOpen: function() {
		this.traceEvent("ws.open");
		if(this.state.s_m == 120) {
			this.sendCreateWS();
			this.state.s_m = 121;
			this.state.traceState();
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.m : null) == 500) {
				this.sendBindWS_Streaming();
				this.state.s_ws.m = 501;
				this.state.traceState();
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.m : null) == 600) {
					com_lightstreamer_internal_NullTools.sure(this.ws).send("wsok");
					this.state.s_wp.m = 601;
					this.state.traceState();
				}
			}
		}
	}
	,evtMessage: function(line) {
		var matched = true;
		if(StringTools.startsWith(line,"U,")) {
			var update = com_lightstreamer_client_internal_ParseTools_parseUpdate(line);
			this.evtU(update.subId,update.itemIdx,update.values,line);
		} else if(StringTools.startsWith(line,"REQOK")) {
			if(line == "REQOK") {
				this.evtREQOK_withoutReqId();
			} else {
				this.evtREQOK(com_lightstreamer_client_internal_ParseTools_parseInt(line.split(",")[1]));
			}
		} else if(StringTools.startsWith(line,"PROBE")) {
			this.evtPROBE();
		} else if(StringTools.startsWith(line,"LOOP")) {
			this.evtLOOP(com_lightstreamer_client_internal_ParseTools_parseInt(line.split(",")[1]));
		} else if(StringTools.startsWith(line,"CONOK")) {
			var args = line.split(",");
			this.evtCONOK(args[1],com_lightstreamer_client_internal_ParseTools_parseInt(args[2]),com_lightstreamer_client_internal_ParseTools_parseInt(args[3]),args[4]);
		} else if(StringTools.startsWith(line,"WSOK")) {
			this.evtWSOK();
		} else if(StringTools.startsWith(line,"SERVNAME")) {
			this.evtSERVNAME(line.split(",")[1]);
		} else if(StringTools.startsWith(line,"CLIENTIP")) {
			this.evtCLIENTIP(line.split(",")[1]);
		} else if(StringTools.startsWith(line,"CONS")) {
			var bw = line.split(",")[1];
			switch(bw) {
			case "unlimited":
				this.evtCONS(com_lightstreamer_internal_RealMaxBandwidth.BWUnlimited);
				break;
			case "unmanaged":
				this.evtCONS(com_lightstreamer_internal_RealMaxBandwidth.BWUnmanaged);
				break;
			default:
				this.evtCONS(com_lightstreamer_internal_RealMaxBandwidth.BWLimited(com_lightstreamer_client_internal_ParseTools_parseFloat(bw)));
			}
		} else if(StringTools.startsWith(line,"MSGDONE")) {
			var args = line.split(",");
			var seq = args[1];
			if(seq == "*") {
				seq = "UNORDERED_MESSAGES";
			}
			var prog = com_lightstreamer_client_internal_ParseTools_parseInt(args[2]);
			var rawResp = args[3];
			this.evtMSGDONE(seq,prog,rawResp == "" ? "" : com_lightstreamer_client_internal_ParseTools_unquote(rawResp));
		} else if(StringTools.startsWith(line,"MSGFAIL")) {
			var args = line.split(",");
			var seq = args[1];
			if(seq == "*") {
				seq = "UNORDERED_MESSAGES";
			}
			this.evtMSGFAIL(seq,com_lightstreamer_client_internal_ParseTools_parseInt(args[2]),com_lightstreamer_client_internal_ParseTools_parseInt(args[3]),com_lightstreamer_client_internal_ParseTools_unquote(args[4]));
		} else if(StringTools.startsWith(line,"REQERR")) {
			var args = line.split(",");
			this.evtREQERR(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_parseInt(args[2]),com_lightstreamer_client_internal_ParseTools_unquote(args[3]));
		} else if(StringTools.startsWith(line,"PROG")) {
			this.evtPROG(com_lightstreamer_client_internal_ParseTools_parseInt(line.split(",")[1]));
		} else if(StringTools.startsWith(line,"SUBOK")) {
			var args = line.split(",");
			this.evtSUBOK(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_parseInt(args[2]),com_lightstreamer_client_internal_ParseTools_parseInt(args[3]));
		} else if(StringTools.startsWith(line,"SUBCMD")) {
			var args = line.split(",");
			this.evtSUBCMD(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_parseInt(args[2]),com_lightstreamer_client_internal_ParseTools_parseInt(args[3]),com_lightstreamer_client_internal_ParseTools_parseInt(args[4]),com_lightstreamer_client_internal_ParseTools_parseInt(args[5]));
		} else if(StringTools.startsWith(line,"UNSUB")) {
			this.evtUNSUB(com_lightstreamer_client_internal_ParseTools_parseInt(line.split(",")[1]));
		} else if(StringTools.startsWith(line,"CONF")) {
			var args = line.split(",");
			var subId = com_lightstreamer_client_internal_ParseTools_parseInt(args[1]);
			if(args[2] == "unlimited") {
				this.evtCONF(subId,com_lightstreamer_internal_RealMaxFrequency.RFreqUnlimited);
			} else {
				this.evtCONF(subId,com_lightstreamer_internal_RealMaxFrequency.RFreqLimited(com_lightstreamer_client_internal_ParseTools_parseFloat(args[2])));
			}
		} else if(StringTools.startsWith(line,"EOS")) {
			var args = line.split(",");
			this.evtEOS(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_parseInt(args[2]));
		} else if(StringTools.startsWith(line,"CS")) {
			var args = line.split(",");
			this.evtCS(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_parseInt(args[2]));
		} else if(StringTools.startsWith(line,"OV")) {
			var args = line.split(",");
			this.evtOV(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_parseInt(args[2]),com_lightstreamer_client_internal_ParseTools_parseInt(args[3]));
		} else if(StringTools.startsWith(line,"NOOP")) {
			this.evtNOOP();
		} else if(StringTools.startsWith(line,"CONERR")) {
			var args = line.split(",");
			this.evtCONERR(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_unquote(args[2]));
		} else if(StringTools.startsWith(line,"END")) {
			var args = line.split(",");
			this.evtEND(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_unquote(args[2]));
		} else if(StringTools.startsWith(line,"ERROR")) {
			var args = line.split(",");
			this.evtERROR(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_unquote(args[2]));
		} else if(StringTools.startsWith(line,"SYNC")) {
			this.evtSYNC(com_lightstreamer_client_internal_ParseTools_parseInt(line.split(",")[1]));
		} else {
			matched = false;
		}
		return matched;
	}
	,evtCtrlMessage: function(line) {
		if(StringTools.startsWith(line,"REQOK")) {
			if(line == "REQOK") {
				this.evtREQOK_withoutReqId();
			} else {
				this.evtREQOK(com_lightstreamer_client_internal_ParseTools_parseInt(line.split(",")[1]));
			}
		} else if(StringTools.startsWith(line,"REQERR")) {
			var args = line.split(",");
			this.evtREQERR(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_parseInt(args[2]),com_lightstreamer_client_internal_ParseTools_unquote(args[3]));
		} else if(StringTools.startsWith(line,"ERROR")) {
			var args = line.split(",");
			this.evtERROR(com_lightstreamer_client_internal_ParseTools_parseInt(args[1]),com_lightstreamer_client_internal_ParseTools_unquote(args[2]));
		}
	}
	,evtTransportTimeout: function() {
		this.traceEvent("transport.timeout");
		switch(this.state.s_m) {
		case 120:case 121:
			this.suspendWS_Streaming();
			this.disposeWS();
			this.cause = "ws.unavailable";
			this.state.s_m = 115;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.entry_m115(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_unavailable);
			break;
		case 122:
			this.disposeWS();
			this.notifyStatus("DISCONNECTED:WILL-RETRY");
			this.cause = "ws.timeout";
			this.state.s_m = 112;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_timeout);
			break;
		case 130:
			this.disposeHTTP();
			this.notifyStatus("DISCONNECTED:WILL-RETRY");
			this.cause = "http.timeout";
			this.state.s_m = 112;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_timeout);
			break;
		case 140:
			this.disposeHTTP();
			this.notifyStatus("DISCONNECTED:WILL-RETRY");
			this.cause = "ttl.timeout";
			var pauseMs = this.waitingInterval(this.delayCounter.get_currentRetryDelay(),this.connectTs);
			this.state.s_m = 111;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.entry_m111(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error,pauseMs);
			break;
		case 150:
			var _g = this.state.s_tr;
			if(_g != null) {
				switch(_g) {
				case 220:
					if(this.options.sessionRecoveryTimeout == 0) {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "http.timeout";
						this.state.goto_m_from_session(112);
						this.cancel_evtTransportTimeout();
						this.evtEndSession();
						this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_timeout);
					} else {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
						this.cause = "http.timeout";
						var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
						this.state.goto_rec();
						this.cancel_evtTransportTimeout();
						this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_timeout);
					}
					break;
				case 230:
					if(this.options.sessionRecoveryTimeout == 0) {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "ttl.timeout";
						this.state.goto_m_from_session(112);
						this.cancel_evtTransportTimeout();
						this.evtEndSession();
						this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_timeout);
					} else {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
						this.cause = "ttl.timeout";
						var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
						this.state.goto_rec();
						this.cancel_evtTransportTimeout();
						this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error);
					}
					break;
				case 240:
					var tmp = this.state.s_ws;
					var _g = tmp != null ? tmp.m : null;
					if(_g != null) {
						switch(_g) {
						case 500:
							this.disableWS();
							this.disposeWS();
							this.cause = "ws.unavailable";
							this.state.clear_ws();
							this.state.s_tr = 200;
							this.state.traceState();
							this.cancel_evtTransportTimeout();
							this.evtSwitchTransport();
							break;
						case 501:
							if(this.options.sessionRecoveryTimeout == 0) {
								this.disableWS();
								this.disposeWS();
								this.notifyStatus("DISCONNECTED:WILL-RETRY");
								this.cause = "ws.unavailable";
								this.state.goto_m_from_ws(112);
								this.exit_ws_to_m();
								this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_unavailable);
							} else {
								this.disableWS();
								this.disposeWS();
								this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
								this.cause = "ws.unavailable";
								var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
								this.state.goto_rec_from_ws();
								this.exit_ws();
								this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_unavailable);
							}
							break;
						case 502:
							if(this.options.sessionRecoveryTimeout == 0) {
								this.disposeWS();
								this.notifyStatus("DISCONNECTED:WILL-RETRY");
								this.cause = "ws.timeout";
								this.state.goto_m_from_ws(112);
								this.exit_ws_to_m();
								this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_timeout);
							} else {
								this.disposeWS();
								this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
								this.cause = "ws.timeout";
								var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
								this.state.goto_rec_from_ws();
								this.exit_ws();
								this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_timeout);
							}
							break;
						default:
						}
					}
					break;
				case 250:
					var tmp = this.state.s_wp;
					var _g = tmp != null ? tmp.m : null;
					if(_g != null) {
						switch(_g) {
						case 600:case 601:
							this.disableWS();
							this.disposeWS();
							this.cause = "ws.unavailable";
							this.state.clear_wp();
							this.state.s_tr = 200;
							this.state.traceState();
							this.exit_wp();
							this.evtSwitchTransport();
							break;
						default:
						}
					}
					break;
				case 260:
					if(this.state.s_rec == 1001) {
						this.disposeHTTP();
						this.state.s_rec = 1002;
						this.state.traceState();
						this.cancel_evtTransportTimeout();
						this.evtCheckRecoveryTimeout(com_lightstreamer_client_internal__$ClientMachine_RecoveryRetryCause.RRC_transport_timeout);
					}
					break;
				case 270:
					var _g = this.state.s_h;
					if(_g != null) {
						if(_g == 710) {
							var tmp = this.state.s_hs;
							var _g = tmp != null ? tmp.m : null;
							if(_g != null) {
								switch(_g) {
								case 800:
									this.disableHTTP_Streaming();
									this.cause = "http.streaming.unavailable";
									this.state.s_hs.m = 801;
									this.state.traceState();
									this.cancel_evtTransportTimeout();
									this.evtForcePolling();
									this.schedule_evtTransportTimeout(this.options.retryDelay);
									break;
								case 801:
									if(this.options.sessionRecoveryTimeout == 0) {
										this.disposeHTTP();
										this.notifyStatus("DISCONNECTED:WILL-RETRY");
										this.cause = "http.timeout";
										this.state.goto_m_from_hs(112);
										this.exit_hs_to_m();
										this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_timeout);
									} else {
										this.disposeHTTP();
										this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
										this.cause = "http.timeout";
										var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
										this.state.goto_rec_from_hs();
										this.exit_hs_to_rec();
										this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_timeout);
									}
									break;
								default:
								}
							}
						}
					}
					break;
				default:
				}
			}
			break;
		default:
		}
	}
	,evtTransportError: function() {
		this.traceEvent("transport.error");
		switch(this.state.s_m) {
		case 120:case 121:
			this.suspendWS_Streaming();
			this.disposeWS();
			this.cause = "ws.unavailable";
			this.state.s_m = 115;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_unavailable);
			this.evtRetryTimeout();
			break;
		case 122:
			this.disposeWS();
			this.notifyStatus("DISCONNECTED:WILL-RETRY");
			this.cause = "ws.error";
			var pauseMs = this.waitingInterval(this.delayCounter.get_currentRetryDelay(),this.connectTs);
			this.state.s_m = 112;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_error,pauseMs);
			this.schedule_evtRetryTimeout(pauseMs);
			break;
		case 130:
			this.disposeHTTP();
			this.notifyStatus("DISCONNECTED:WILL-RETRY");
			this.cause = "http.error";
			var pauseMs = this.waitingInterval(this.delayCounter.get_currentRetryDelay(),this.connectTs);
			this.state.s_m = 112;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error,pauseMs);
			this.schedule_evtRetryTimeout(pauseMs);
			break;
		case 140:
			this.disposeHTTP();
			this.notifyStatus("DISCONNECTED:WILL-RETRY");
			this.cause = "ttl.error";
			var pauseMs = this.waitingInterval(this.delayCounter.get_currentRetryDelay(),this.connectTs);
			this.state.s_m = 111;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error,pauseMs);
			this.schedule_evtRetryTimeout(pauseMs);
			break;
		case 150:
			var _g = this.state.s_tr;
			if(_g != null) {
				switch(_g) {
				case 210:
					if(this.options.sessionRecoveryTimeout == 0) {
						this.disposeWS();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "ws.error";
						this.state.clear_w();
						this.state.goto_m_from_session(113);
						this.exit_w();
						this.evtEndSession();
						this.entry_m113(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_error);
					} else {
						this.disposeWS();
						this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
						this.cause = "ws.error";
						var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
						this.state.clear_w();
						this.state.s_tr = 260;
						this.state.s_rec = 1000;
						this.state.traceState();
						this.exit_w();
						this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_error);
					}
					break;
				case 220:
					if(this.options.sessionRecoveryTimeout == 0) {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "http.error";
						var pauseMs = this.waitingInterval(this.delayCounter.get_currentRetryDelay(),this.connectTs);
						this.state.goto_m_from_session(112);
						this.cancel_evtTransportTimeout();
						this.evtEndSession();
						this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error,pauseMs);
						this.schedule_evtRetryTimeout(pauseMs);
					} else {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
						this.cause = "http.error";
						var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
						this.state.goto_rec();
						this.cancel_evtTransportTimeout();
						this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error);
					}
					break;
				case 230:
					if(this.options.sessionRecoveryTimeout == 0) {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "ttl.error";
						var pauseMs = this.waitingInterval(this.delayCounter.get_currentRetryDelay(),this.connectTs);
						this.state.goto_m_from_session(112);
						this.cancel_evtTransportTimeout();
						this.evtEndSession();
						this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error,pauseMs);
						this.schedule_evtRetryTimeout(pauseMs);
					} else {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
						this.cause = "ttl.error";
						var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
						this.state.goto_rec();
						this.cancel_evtTransportTimeout();
						this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error);
					}
					break;
				case 240:
					switch(com_lightstreamer_internal_NullTools.sure(this.state.s_ws).m) {
					case 500:
						this.disableWS();
						this.disposeWS();
						this.cause = "ws.unavailable";
						this.state.clear_ws();
						this.state.s_tr = 200;
						this.state.traceState();
						this.cancel_evtTransportTimeout();
						this.evtSwitchTransport();
						break;
					case 501:
						if(this.options.sessionRecoveryTimeout == 0) {
							this.disableWS();
							this.disposeWS();
							this.notifyStatus("DISCONNECTED:WILL-RETRY");
							this.cause = "ws.unavailable";
							this.state.goto_m_from_ws(112);
							this.exit_ws_to_m();
							this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_unavailable);
						} else {
							this.disableWS();
							this.disposeWS();
							this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
							this.cause = "ws.unavailable";
							var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
							this.state.goto_rec_from_ws();
							this.exit_ws();
							this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_unavailable);
						}
						break;
					case 502:
						if(this.options.sessionRecoveryTimeout == 0) {
							this.disposeWS();
							this.notifyStatus("DISCONNECTED:WILL-RETRY");
							this.cause = "ws.error";
							this.state.goto_m_from_ws(112);
							this.exit_ws_to_m();
							this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_error);
						} else {
							this.disposeWS();
							this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
							this.cause = "ws.error";
							var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
							this.state.goto_rec_from_ws();
							this.cancel_evtTransportTimeout();
							this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_error);
						}
						break;
					case 503:
						if(this.options.sessionRecoveryTimeout == 0) {
							this.disposeWS();
							this.notifyStatus("DISCONNECTED:WILL-RETRY");
							this.cause = "ws.error";
							this.state.goto_m_from_ws(113);
							this.exit_ws_to_m();
							this.entry_m113(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_error);
						} else {
							this.disposeWS();
							this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
							this.cause = "ws.error";
							var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
							this.state.goto_rec_from_ws();
							this.exit_ws();
							this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_error);
						}
						break;
					}
					break;
				case 250:
					switch(com_lightstreamer_internal_NullTools.sure(this.state.s_wp).m) {
					case 600:case 601:
						this.disableWS();
						this.disposeWS();
						this.cause = "ws.unavailable";
						this.state.clear_wp();
						this.state.s_tr = 200;
						this.state.traceState();
						this.cancel_evtTransportTimeout();
						this.evtSwitchTransport();
						break;
					case 602:
						if(this.options.sessionRecoveryTimeout == 0) {
							this.disposeWS();
							this.notifyStatus("DISCONNECTED:WILL-RETRY");
							this.cause = "ws.error";
							this.state.goto_m_from_wp(113);
							this.exit_wp_to_m();
							this.entry_m113(com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_error);
						} else {
							this.disposeWS();
							this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
							this.cause = "ws.error";
							var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
							this.state.goto_rec_from_wp();
							this.exit_wp();
							this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_error);
						}
						break;
					}
					break;
				case 260:
					if(this.state.s_rec == 1001) {
						this.disposeHTTP();
						this.state.s_rec = 1002;
						this.state.traceState();
						this.cancel_evtTransportTimeout();
						this.evtCheckRecoveryTimeout(com_lightstreamer_client_internal__$ClientMachine_RecoveryRetryCause.RRC_transport_error);
					}
					break;
				case 270:
					switch(com_lightstreamer_internal_NullTools.sure(this.state.s_h)) {
					case 710:
						switch(com_lightstreamer_internal_NullTools.sure(this.state.s_hs).m) {
						case 800:case 801:
							if(this.options.sessionRecoveryTimeout == 0) {
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED:WILL-RETRY");
								this.cause = "http.error";
								this.state.goto_m_from_hs(112);
								this.exit_hs_to_m();
								this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error);
							} else {
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
								this.cause = "http.error";
								var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
								this.state.goto_rec_from_hs();
								this.exit_hs_to_rec();
								this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error);
							}
							break;
						case 802:
							if(this.options.sessionRecoveryTimeout == 0) {
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED:WILL-RETRY");
								this.cause = "http.error";
								this.state.goto_m_from_hs(113);
								this.exit_hs_to_m();
								this.entry_m113(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error);
							} else {
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
								this.cause = "http.error";
								var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
								this.state.goto_rec_from_hs();
								this.exit_hs_to_rec();
								this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error);
							}
							break;
						}
						break;
					case 720:
						switch(com_lightstreamer_internal_NullTools.sure(this.state.s_hp).m) {
						case 900:case 901:case 902:case 903:case 904:
							if(this.options.sessionRecoveryTimeout == 0) {
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED:WILL-RETRY");
								this.cause = "http.error";
								this.state.goto_m_from_hp(112);
								this.exit_hp_to_m();
								this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error);
							} else {
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
								this.cause = "http.error";
								var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
								this.state.goto_rec_from_hp();
								this.exit_hp_to_rec();
								this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error);
							}
							break;
						}
						break;
					}
					break;
				default:
				}
			}
			break;
		default:
		}
	}
	,evtIdleTimeout: function() {
		this.traceEvent("idle.timeout");
		var tmp;
		var tmp1 = this.state.s_wp;
		var _g = tmp1 != null ? tmp1.p : null;
		if(_g == null) {
			tmp = false;
		} else {
			switch(_g) {
			case 610:case 611:case 613:
				tmp = true;
				break;
			default:
				tmp = false;
			}
		}
		if(tmp) {
			if(this.options.sessionRecoveryTimeout == 0) {
				this.disposeWS();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "ws.idle.timeout";
				this.state.goto_m_from_wp(113);
				this.exit_wp_to_m();
				this.entry_m113(com_lightstreamer_client_internal__$ClientMachine_RetryCause.idle_timeout);
			} else {
				this.disposeWS();
				this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
				this.cause = "ws.idle.timeout";
				var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
				this.state.goto_rec_from_wp();
				this.exit_wp();
				this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.idle_timeout);
			}
		} else {
			var tmp;
			var tmp1 = this.state.s_hp;
			var _g = tmp1 != null ? tmp1.m : null;
			if(_g == null) {
				tmp = false;
			} else {
				switch(_g) {
				case 900:case 901:case 903:
					tmp = true;
					break;
				default:
					tmp = false;
				}
			}
			if(tmp) {
				if(this.options.sessionRecoveryTimeout == 0) {
					this.disposeHTTP();
					this.notifyStatus("DISCONNECTED:WILL-RETRY");
					this.cause = "http.idle.timeout";
					this.state.goto_m_from_hp(112);
					this.exit_hp_to_m();
					this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.idle_timeout);
				} else {
					this.disposeHTTP();
					this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
					this.cause = "http.idle.timeout";
					var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
					this.state.goto_rec_from_hp();
					this.exit_hp_to_rec();
					this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.idle_timeout);
				}
			}
		}
	}
	,evtPollingTimeout: function() {
		this.traceEvent("polling.timeout");
		var tmp = this.state.s_wp;
		if((tmp != null ? tmp.p : null) == 612) {
			this.sendBindWS_Polling();
			this.state.s_wp.p = 613;
			this.state.traceState();
			this.cancel_evtPollingTimeout();
			this.schedule_evtIdleTimeout(com_lightstreamer_internal_Millis.plus(com_lightstreamer_internal_NullTools.sure(this.idleTimeout),this.options.retryDelay));
		} else {
			var tmp = this.state.s_hp;
			if((tmp != null ? tmp.m : null) == 902) {
				this.sendBindHTTP_Polling();
				this.state.s_hp.m = 903;
				this.state.traceState();
				this.cancel_evtPollingTimeout();
				this.schedule_evtIdleTimeout(com_lightstreamer_internal_Millis.plus(com_lightstreamer_internal_NullTools.sure(this.idleTimeout),this.options.retryDelay));
			}
		}
	}
	,evtKeepaliveTimeout: function() {
		this.traceEvent("keepalive.timeout");
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.k : null) == 310) {
			this.state.s_w.k = 311;
			this.state.traceState();
			this.cancel_evtKeepaliveTimeout();
			this.schedule_evtStalledTimeout(this.options.stalledTimeout);
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.k : null) == 520) {
				this.state.s_ws.k = 521;
				this.state.traceState();
				this.cancel_evtKeepaliveTimeout();
				this.schedule_evtStalledTimeout(this.options.stalledTimeout);
			} else {
				var tmp = this.state.s_hs;
				if((tmp != null ? tmp.k : null) == 820) {
					this.state.s_hs.k = 821;
					this.state.traceState();
					this.cancel_evtKeepaliveTimeout();
					this.schedule_evtStalledTimeout(this.options.stalledTimeout);
				}
			}
		}
	}
	,evtStalledTimeout: function() {
		this.traceEvent("stalled.timeout");
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.k : null) == 311) {
			this.state.s_w.k = 312;
			this.state.traceState();
			this.cancel_evtStalledTimeout();
			this.schedule_evtReconnectTimeout(this.options.reconnectTimeout);
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.k : null) == 521) {
				this.state.s_ws.k = 522;
				this.state.traceState();
				this.cancel_evtStalledTimeout();
				this.schedule_evtReconnectTimeout(this.options.reconnectTimeout);
			} else {
				var tmp = this.state.s_hs;
				if((tmp != null ? tmp.k : null) == 821) {
					this.state.s_hs.k = 822;
					this.state.traceState();
					this.cancel_evtStalledTimeout();
					this.schedule_evtReconnectTimeout(this.options.reconnectTimeout);
				}
			}
		}
	}
	,evtReconnectTimeout: function() {
		this.traceEvent("reconnect.timeout");
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.k : null) == 312) {
			if(this.options.sessionRecoveryTimeout == 0) {
				this.disposeWS();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "ws.stalled";
				this.state.goto_m_from_w(113);
				this.exit_w_to_m();
				this.entry_m113(com_lightstreamer_client_internal__$ClientMachine_RetryCause.stalled_timeout);
			} else {
				this.disposeWS();
				this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
				this.cause = "ws.stalled";
				var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
				this.state.goto_rec_from_w();
				this.exit_w();
				this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.stalled_timeout);
			}
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.k : null) == 522) {
				if(this.options.sessionRecoveryTimeout == 0) {
					this.disposeWS();
					this.notifyStatus("DISCONNECTED:WILL-RETRY");
					this.cause = "ws.stalled";
					this.state.goto_m_from_ws(113);
					this.exit_ws_to_m();
					this.entry_m113(com_lightstreamer_client_internal__$ClientMachine_RetryCause.stalled_timeout);
				} else {
					this.disposeWS();
					this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
					this.cause = "ws.stalled";
					var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
					this.state.goto_rec_from_ws();
					this.exit_ws();
					this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.stalled_timeout);
				}
			} else {
				var tmp = this.state.s_hs;
				if((tmp != null ? tmp.k : null) == 822) {
					if(this.options.sessionRecoveryTimeout == 0) {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "http.stalled";
						this.state.goto_m_from_hs(113);
						this.exit_hs_to_m();
						this.entry_m113(com_lightstreamer_client_internal__$ClientMachine_RetryCause.stalled_timeout);
					} else {
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:TRYING-RECOVERY");
						this.cause = "http.stalled";
						var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
						this.state.goto_rec_from_hs();
						this.exit_hs_to_rec();
						this.entry_rec(pauseMs,com_lightstreamer_client_internal__$ClientMachine_RetryCause.stalled_timeout);
					}
				}
			}
		}
	}
	,evtRestartKeepalive: function() {
		this.traceEvent("restart.keepalive");
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.k : null) != null) {
			this.state.s_w.k = 310;
			this.state.traceState();
			this.exit_keepalive_unit();
			this.schedule_evtKeepaliveTimeout(com_lightstreamer_internal_NullTools.sure(this.keepaliveInterval));
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.k : null) != null) {
				this.state.s_ws.k = 520;
				this.state.traceState();
				this.exit_keepalive_unit();
				this.schedule_evtKeepaliveTimeout(com_lightstreamer_internal_NullTools.sure(this.keepaliveInterval));
			} else {
				var tmp = this.state.s_hs;
				if((tmp != null ? tmp.k : null) != null) {
					this.state.s_hs.k = 820;
					this.state.traceState();
					this.exit_keepalive_unit();
					this.schedule_evtKeepaliveTimeout(com_lightstreamer_internal_NullTools.sure(this.keepaliveInterval));
				}
			}
		}
	}
	,evtWSOK: function() {
		this.traceEvent("WSOK");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("WSOK");
		}
		switch(this.state.s_m) {
		case 121:
			this.state.s_m = 122;
			this.state.traceState();
			break;
		case 150:
			var _g = this.state.s_tr;
			if(_g != null) {
				switch(_g) {
				case 240:
					var tmp = this.state.s_ws;
					var _g = tmp != null ? tmp.m : null;
					if(_g != null) {
						if(_g == 501) {
							this.state.s_ws.m = 502;
							this.state.traceState();
						}
					}
					break;
				case 250:
					var tmp = this.state.s_wp;
					var _g = tmp != null ? tmp.m : null;
					if(_g != null) {
						if(_g == 601) {
							this.sendBindWS_FirstPolling();
							this.state.s_wp.m = 602;
							this.state.s_wp.p = 610;
							this.state.s_wp.c = 620;
							this.state.s_wp.s = 630;
							this.state.traceState();
							this.cancel_evtTransportTimeout();
							this.evtSendPendingControls();
							this.evtSendPendingMessages();
							this.schedule_evtIdleTimeout(com_lightstreamer_internal_Millis.plus(com_lightstreamer_internal_NullTools.sure(this.idleTimeout),this.options.retryDelay));
						}
					}
					break;
				default:
				}
			}
			break;
		default:
		}
	}
	,evtCONERR: function(code,msg) {
		this.traceEvent("CONERR");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("CONERR " + code + " " + msg);
		}
		var retryCause = com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg);
		var terminationCause = com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_standardError(code,msg);
		if(this.state.s_m == 122) {
			switch(code) {
			case 5:
				this.disposeWS();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "ws.conerr." + code;
				this.state.s_m = 110;
				this.state.traceState();
				this.cancel_evtTransportTimeout();
				this.evtRetry(retryCause);
				this.evtRetryTimeout();
				break;
			case 4:case 6:case 20:case 40:case 41:case 48:
				this.disposeWS();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "ws.conerr." + code;
				var pauseMs = this.waitingInterval(this.delayCounter.get_currentRetryDelay(),this.connectTs);
				this.state.s_m = 112;
				this.state.traceState();
				this.cancel_evtTransportTimeout();
				this.evtRetry(retryCause,pauseMs);
				this.schedule_evtRetryTimeout(pauseMs);
				break;
			default:
				this.disposeWS();
				this.notifyStatus("DISCONNECTED");
				this.notifyServerError_CONERR(code,msg);
				this.state.s_m = 100;
				this.state.traceState();
				this.cancel_evtTransportTimeout();
				this.evtTerminate(terminationCause);
			}
		} else if(this.state.s_m == 130) {
			switch(code) {
			case 5:
				this.disposeHTTP();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "http.conerr." + code;
				this.state.s_m = 110;
				this.state.traceState();
				this.cancel_evtTransportTimeout();
				this.evtRetry(retryCause);
				this.evtRetryTimeout();
				break;
			case 4:case 6:case 20:case 40:case 41:case 48:
				this.disposeHTTP();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "http.conerr." + code;
				var pauseMs = this.waitingInterval(this.delayCounter.get_currentRetryDelay(),this.connectTs);
				this.state.s_m = 112;
				this.state.traceState();
				this.cancel_evtTransportTimeout();
				this.evtRetry(retryCause,pauseMs);
				this.schedule_evtRetryTimeout(pauseMs);
				break;
			default:
				this.disposeHTTP();
				this.notifyStatus("DISCONNECTED");
				this.notifyServerError_CONERR(code,msg);
				this.state.s_m = 100;
				this.state.traceState();
				this.cancel_evtTransportTimeout();
				this.evtTerminate(terminationCause);
			}
		} else if(this.state.s_m == 140) {
			switch(code) {
			case 5:
				this.disposeHTTP();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "ttl.conerr." + code;
				this.state.s_m = 110;
				this.state.traceState();
				this.cancel_evtTransportTimeout();
				this.evtRetry(retryCause);
				this.evtRetryTimeout();
				break;
			case 4:case 6:case 20:case 40:case 41:case 48:
				this.disposeHTTP();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "ttl.conerr." + code;
				var pauseMs = this.waitingInterval(this.delayCounter.get_currentRetryDelay(),this.connectTs);
				this.state.s_m = 112;
				this.state.traceState();
				this.cancel_evtTransportTimeout();
				this.evtRetry(retryCause,pauseMs);
				this.schedule_evtRetryTimeout(pauseMs);
				break;
			default:
				this.disposeHTTP();
				this.notifyStatus("DISCONNECTED");
				this.notifyServerError_CONERR(code,msg);
				this.state.s_m = 100;
				this.state.traceState();
				this.cancel_evtTransportTimeout();
				this.evtTerminate(terminationCause);
			}
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.m : null) == 502) {
				switch(code) {
				case 4:case 6:case 20:case 40:case 41:case 48:
					this.disposeWS();
					this.notifyStatus("DISCONNECTED:WILL-RETRY");
					this.cause = "ws.conerr." + code;
					this.state.goto_m_from_ws(112);
					this.exit_ws_to_m();
					this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg));
					break;
				default:
					this.disposeWS();
					this.notifyStatus("DISCONNECTED");
					this.notifyServerError_CONERR(code,msg);
					this.state.goto_m_from_ws(100);
					this.exit_ws_to_m();
					this.evtTerminate(terminationCause);
				}
			} else {
				var tmp;
				var tmp1 = this.state.s_wp;
				if((tmp1 != null ? tmp1.p : null) != 610) {
					var tmp1 = this.state.s_wp;
					tmp = (tmp1 != null ? tmp1.p : null) == 613;
				} else {
					tmp = true;
				}
				if(tmp) {
					switch(code) {
					case 4:case 6:case 20:case 40:case 41:case 48:
						this.disposeWS();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "ws.conerr." + code;
						this.state.goto_m_from_wp(112);
						this.exit_wp_to_m();
						this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg));
						break;
					default:
						this.disposeWS();
						this.notifyStatus("DISCONNECTED");
						this.notifyServerError_CONERR(code,msg);
						this.state.goto_m_from_wp(100);
						this.exit_wp_to_m();
						this.evtTerminate(terminationCause);
					}
				} else {
					var tmp;
					var tmp1 = this.state.s_hs;
					if((tmp1 != null ? tmp1.m : null) != 800) {
						var tmp1 = this.state.s_hs;
						tmp = (tmp1 != null ? tmp1.m : null) == 801;
					} else {
						tmp = true;
					}
					if(tmp) {
						switch(code) {
						case 4:case 6:case 20:case 40:case 41:case 48:
							this.disposeHTTP();
							this.notifyStatus("DISCONNECTED:WILL-RETRY");
							this.cause = "http.conerr." + code;
							this.state.goto_m_from_hs(112);
							this.exit_hs_to_m();
							this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg));
							break;
						default:
							this.disposeHTTP();
							this.notifyStatus("DISCONNECTED");
							this.notifyServerError_CONERR(code,msg);
							this.state.goto_m_from_hs(100);
							this.exit_hs_to_m();
							this.evtTerminate(terminationCause);
						}
					} else {
						var tmp;
						var tmp1 = this.state.s_hp;
						if((tmp1 != null ? tmp1.m : null) != 900) {
							var tmp1 = this.state.s_hp;
							tmp = (tmp1 != null ? tmp1.m : null) == 903;
						} else {
							tmp = true;
						}
						if(tmp) {
							switch(code) {
							case 4:case 6:case 20:case 40:case 41:case 48:
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED:WILL-RETRY");
								this.cause = "http.conerr." + code;
								this.state.goto_m_from_hp(112);
								this.exit_hp_to_m();
								this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg));
								break;
							default:
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED");
								this.notifyServerError_CONERR(code,msg);
								this.state.goto_m_from_hp(100);
								this.exit_hp_to_m();
								this.evtTerminate(terminationCause);
							}
						} else if(this.state.s_rec == 1001) {
							switch(code) {
							case 4:case 6:case 20:case 40:case 41:case 48:
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED:WILL-RETRY");
								this.cause = "recovery.conerr." + code;
								this.state.goto_m_from_rec(113);
								this.exit_rec_to_m();
								this.entry_m113(retryCause);
								break;
							default:
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED");
								this.notifyServerError_CONERR(code,msg);
								this.state.goto_m_from_rec(100);
								this.exit_rec_to_m();
								this.evtTerminate(terminationCause);
							}
						}
					}
				}
			}
		}
	}
	,evtEND: function(code,msg) {
		this.traceEvent("END");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("END " + code + " " + msg);
		}
		var retryCause = com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg);
		var terminationCause = com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_standardError(code,msg);
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.p : null) == 300) {
			switch(code) {
			case 41:case 48:
				this.disposeWS();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "ws.end." + code;
				var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
				this.state.clear_w();
				this.state.goto_m_from_session(113);
				this.exit_w();
				this.evtEndSession();
				this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg),pauseMs);
				this.schedule_evtRetryTimeout(pauseMs);
				break;
			default:
				this.disposeWS();
				this.notifyStatus("DISCONNECTED");
				this.notifyServerError_END(code,msg);
				this.state.clear_w();
				this.state.goto_m_from_session(100);
				this.exit_w();
				this.evtEndSession();
				this.evtTerminate(terminationCause);
			}
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.m : null) == 502) {
				switch(code) {
				case 41:case 48:
					this.disposeWS();
					this.notifyStatus("DISCONNECTED:WILL-RETRY");
					this.cause = "ws.end." + code;
					this.state.goto_m_from_ws(112);
					this.exit_ws_to_m();
					this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg));
					break;
				default:
					this.disposeWS();
					this.notifyStatus("DISCONNECTED");
					this.notifyServerError_END(code,msg);
					this.state.goto_m_from_ws(100);
					this.exit_ws_to_m();
					this.evtTerminate(terminationCause);
				}
			} else {
				var tmp = this.state.s_ws;
				if((tmp != null ? tmp.p : null) == 510) {
					switch(code) {
					case 41:case 48:
						this.disposeWS();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "ws.end." + code;
						this.state.goto_m_from_ws(113);
						this.exit_ws_to_m();
						this.entry_m113(retryCause);
						break;
					default:
						this.disposeWS();
						this.notifyStatus("DISCONNECTED");
						this.notifyServerError_END(code,msg);
						this.state.goto_m_from_ws(100);
						this.exit_ws_to_m();
						this.evtTerminate(terminationCause);
					}
				} else {
					var tmp;
					var tmp1 = this.state.s_wp;
					if((tmp1 != null ? tmp1.p : null) != 610) {
						var tmp1 = this.state.s_wp;
						tmp = (tmp1 != null ? tmp1.p : null) == 613;
					} else {
						tmp = true;
					}
					if(tmp) {
						switch(code) {
						case 41:case 48:
							this.disposeWS();
							this.notifyStatus("DISCONNECTED:WILL-RETRY");
							this.cause = "ws.end." + code;
							this.state.goto_m_from_wp(112);
							this.exit_wp_to_m();
							this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg));
							break;
						default:
							this.disposeWS();
							this.notifyStatus("DISCONNECTED");
							this.notifyServerError_END(code,msg);
							this.state.goto_m_from_wp(100);
							this.exit_wp_to_m();
							this.evtTerminate(terminationCause);
						}
					} else {
						var tmp = this.state.s_wp;
						if((tmp != null ? tmp.p : null) == 611) {
							switch(code) {
							case 41:case 48:
								this.disposeWS();
								this.notifyStatus("DISCONNECTED:WILL-RETRY");
								this.cause = "ws.end." + code;
								this.state.goto_m_from_wp(113);
								this.exit_wp_to_m();
								this.entry_m113(retryCause);
								break;
							default:
								this.disposeWS();
								this.notifyStatus("DISCONNECTED");
								this.notifyServerError_END(code,msg);
								this.state.goto_m_from_wp(100);
								this.exit_wp_to_m();
								this.evtTerminate(terminationCause);
							}
						} else {
							var tmp;
							var tmp1 = this.state.s_hs;
							if((tmp1 != null ? tmp1.m : null) != 800) {
								var tmp1 = this.state.s_hs;
								tmp = (tmp1 != null ? tmp1.m : null) == 801;
							} else {
								tmp = true;
							}
							if(tmp) {
								switch(code) {
								case 41:case 48:
									this.disposeHTTP();
									this.notifyStatus("DISCONNECTED:WILL-RETRY");
									this.cause = "http.end." + code;
									this.state.goto_m_from_hs(112);
									this.exit_hs_to_m();
									this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg));
									break;
								default:
									this.disposeHTTP();
									this.notifyStatus("DISCONNECTED");
									this.notifyServerError_END(code,msg);
									this.state.goto_m_from_hs(100);
									this.exit_hs_to_m();
									this.evtTerminate(terminationCause);
								}
							} else {
								var tmp = this.state.s_hs;
								if((tmp != null ? tmp.p : null) == 810) {
									switch(code) {
									case 41:case 48:
										this.disposeHTTP();
										this.notifyStatus("DISCONNECTED:WILL-RETRY");
										this.cause = "http.end." + code;
										this.state.goto_m_from_hs(113);
										this.exit_hs_to_m();
										this.entry_m113(retryCause);
										break;
									default:
										this.disposeHTTP();
										this.notifyStatus("DISCONNECTED");
										this.notifyServerError_END(code,msg);
										this.state.goto_m_from_hs(100);
										this.exit_hs_to_m();
										this.evtTerminate(terminationCause);
									}
								} else {
									var tmp;
									var tmp1 = this.state.s_hp;
									if((tmp1 != null ? tmp1.m : null) != 900) {
										var tmp1 = this.state.s_hp;
										tmp = (tmp1 != null ? tmp1.m : null) == 903;
									} else {
										tmp = true;
									}
									if(tmp) {
										switch(code) {
										case 41:case 48:
											this.disposeHTTP();
											this.notifyStatus("DISCONNECTED:WILL-RETRY");
											this.cause = "http.end." + code;
											this.state.goto_m_from_hp(112);
											this.exit_hp_to_m();
											this.entry_m112(com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg));
											break;
										default:
											this.disposeHTTP();
											this.notifyStatus("DISCONNECTED");
											this.notifyServerError_END(code,msg);
											this.state.goto_m_from_hp(100);
											this.exit_hp_to_m();
											this.evtTerminate(terminationCause);
										}
									} else {
										var tmp = this.state.s_hp;
										if((tmp != null ? tmp.m : null) == 901) {
											switch(code) {
											case 41:case 48:
												this.disposeHTTP();
												this.notifyStatus("DISCONNECTED:WILL-RETRY");
												this.cause = "http.end." + code;
												this.state.goto_m_from_hp(113);
												this.exit_hp_to_m();
												this.entry_m113(retryCause);
												break;
											default:
												this.disposeHTTP();
												this.notifyStatus("DISCONNECTED");
												this.notifyServerError_END(code,msg);
												this.state.goto_m_from_hp(100);
												this.exit_hp_to_m();
												this.evtTerminate(terminationCause);
											}
										} else if(this.state.s_rec == 1001) {
											switch(code) {
											case 41:case 48:
												this.disposeHTTP();
												this.notifyStatus("DISCONNECTED:WILL-RETRY");
												this.cause = "recovery.end." + code;
												this.state.goto_m_from_rec(113);
												this.exit_rec_to_m();
												this.entry_m113(retryCause);
												break;
											default:
												this.disposeHTTP();
												this.notifyStatus("DISCONNECTED");
												this.notifyServerError_END(code,msg);
												this.state.goto_m_from_rec(100);
												this.exit_rec_to_m();
												this.evtTerminate(terminationCause);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	,evtERROR: function(code,msg) {
		this.traceEvent("ERROR");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("ERROR " + code + " " + msg);
		}
		var terminationCause = com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_standardError(code,msg);
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.p : null) == 300) {
			this.disposeWS();
			this.notifyStatus("DISCONNECTED");
			this.notifyServerError_ERROR(code,msg);
			this.state.clear_w();
			this.state.goto_m_from_session(100);
			this.exit_w();
			this.evtEndSession();
			this.evtTerminate(terminationCause);
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.p : null) == 510) {
				this.disposeWS();
				this.notifyStatus("DISCONNECTED");
				this.notifyServerError_ERROR(code,msg);
				this.state.goto_m_from_ws(100);
				this.exit_ws_to_m();
				this.evtTerminate(terminationCause);
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.c : null) == 620) {
					this.disposeWS();
					this.notifyStatus("DISCONNECTED");
					this.notifyServerError_ERROR(code,msg);
					this.state.goto_m_from_wp(100);
					this.exit_wp_to_m();
					this.evtTerminate(terminationCause);
				} else if(this.state.s_ctrl == 1102) {
					this.disposeHTTP();
					this.notifyStatus("DISCONNECTED");
					this.notifyServerError_ERROR(code,msg);
					this.state.goto_m_from_ctrl(100);
					this.exit_ctrl_to_m();
					this.evtTerminate(terminationCause);
				}
			}
		}
	}
	,evtREQOK_withoutReqId: function() {
		this.traceEvent("REQOK");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("REQOK");
		}
		if(this.state.s_ctrl == 1102) {
			this.state.s_ctrl = 1102;
			this.state.traceState();
		}
	}
	,evtREQOK: function(reqId) {
		this.traceEvent("REQOK");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("REQOK " + reqId);
		}
		var forward = true;
		if(this.state.s_swt == 1302 && reqId == this.swt_lastReqId) {
			this.state.s_swt = 1303;
			this.state.traceState();
			forward = this.evtREQOK_TransportRegion(reqId);
		} else if(this.state.s_bw == 1202 && reqId == this.bw_lastReqId) {
			this.state.s_bw = 1200;
			this.state.traceState();
			forward = this.evtREQOK_TransportRegion(reqId);
			this.evtCheckBW();
		} else {
			forward = this.evtREQOK_Forward(reqId);
		}
		if(forward) {
			this.evtREQOK_TransportRegion(reqId);
		}
	}
	,evtREQOK_Forward: function(reqId) {
		return true;
	}
	,evtREQOK_TransportRegion: function(reqId) {
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.p : null) == 300) {
			this.state.s_w.p = 300;
			this.state.traceState();
			this.doREQOK(reqId);
			this.evtRestartKeepalive();
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.p : null) == 510) {
				this.state.s_ws.p = 510;
				this.state.traceState();
				this.doREQOK(reqId);
				this.evtRestartKeepalive();
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.c : null) == 620) {
					this.state.s_wp.c = 620;
					this.state.traceState();
					this.doREQOK(reqId);
				} else if(this.state.s_ctrl == 1102) {
					this.state.s_ctrl = 1102;
					this.state.traceState();
					this.doREQOK(reqId);
				}
			}
		}
		return false;
	}
	,evtREQERR: function(reqId,code,msg) {
		this.traceEvent("REQERR");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("REQERR " + reqId + " " + code + " " + msg);
		}
		var forward = true;
		if(this.state.s_swt == 1302 && reqId == this.swt_lastReqId) {
			this.state.s_swt = 1301;
			this.state.traceState();
			forward = this.evtREQERR_TransportRegion(reqId,code,msg);
		} else if(this.state.s_bw == 1202 && reqId == this.bw_lastReqId) {
			this.state.s_bw = 1200;
			this.state.traceState();
			forward = this.evtREQERR_TransportRegion(reqId,code,msg);
			this.evtCheckBW();
		} else {
			forward = this.evtREQERR_Forward(reqId,code,msg);
		}
		if(forward) {
			this.evtREQERR_TransportRegion(reqId,code,msg);
		}
	}
	,evtREQERR_Forward: function(reqId,code,msg) {
		return true;
	}
	,evtREQERR_TransportRegion: function(reqId,code,msg) {
		var retryCause = com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg);
		var terminationCause = com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_standardError(code,msg);
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.p : null) == 300) {
			switch(code) {
			case 20:
				this.disposeWS();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "ws.reqerr." + code;
				var pauseMs = this.randomGenerator(this.options.firstRetryMaxDelay);
				this.state.clear_w();
				this.state.goto_m_from_session(113);
				this.exit_w();
				this.evtEndSession();
				this.evtRetry(com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError(code,msg),pauseMs);
				this.schedule_evtRetryTimeout(pauseMs);
				break;
			case 11:case 65:case 67:
				this.disposeWS();
				this.notifyStatus("DISCONNECTED");
				this.notifyServerError_REQERR(code,msg);
				this.state.clear_w();
				this.state.goto_m_from_session(100);
				this.exit_w();
				this.evtEndSession();
				this.evtTerminate(terminationCause);
				break;
			default:
				this.state.s_w.p = 300;
				this.state.traceState();
				this.doREQERR(reqId,code,msg);
				this.evtRestartKeepalive();
			}
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.p : null) == 510) {
				switch(code) {
				case 20:
					this.disposeWS();
					this.notifyStatus("DISCONNECTED:WILL-RETRY");
					this.cause = "ws.reqerr." + code + ")";
					this.state.goto_m_from_ws(113);
					this.exit_ws_to_m();
					this.entry_m113(retryCause);
					break;
				case 11:case 65:case 67:
					this.disposeWS();
					this.notifyStatus("DISCONNECTED");
					this.notifyServerError_REQERR(code,msg);
					this.state.goto_m_from_ws(100);
					this.exit_ws_to_m();
					this.evtTerminate(terminationCause);
					break;
				default:
					this.state.s_ws.p = 510;
					this.state.traceState();
					this.doREQERR(reqId,code,msg);
					this.evtRestartKeepalive();
				}
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.c : null) == 620) {
					switch(code) {
					case 20:
						this.disposeWS();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "ws.reqerr." + code;
						this.state.goto_m_from_wp(113);
						this.exit_wp_to_m();
						this.entry_m113(retryCause);
						break;
					case 11:case 65:case 67:
						this.disposeWS();
						this.notifyStatus("DISCONNECTED");
						this.notifyServerError_REQERR(code,msg);
						this.state.goto_m_from_wp(100);
						this.exit_wp_to_m();
						this.evtTerminate(terminationCause);
						break;
					default:
						this.state.s_wp.c = 620;
						this.state.traceState();
						this.doREQERR(reqId,code,msg);
					}
				} else if(this.state.s_ctrl == 1102) {
					switch(code) {
					case 20:
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "http.reqerr." + code;
						this.state.goto_m_from_ctrl(113);
						this.exit_ctrl_to_m();
						this.entry_m113(retryCause);
						break;
					case 11:case 65:case 67:
						this.disposeHTTP();
						this.notifyStatus("DISCONNECTED");
						this.notifyServerError_REQERR(code,msg);
						this.state.goto_m_from_ctrl(100);
						this.exit_ctrl_to_m();
						this.evtTerminate(terminationCause);
						break;
					default:
						this.state.s_ctrl = 1102;
						this.state.traceState();
						this.doREQERR(reqId,code,msg);
					}
				}
			}
		}
		return false;
	}
	,evtPROG: function(prog) {
		this.traceEvent("PROG");
		var retryCause = com_lightstreamer_client_internal__$ClientMachine_RetryCause.prog_mismatch(this.rec_serverProg,prog);
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("PROG " + prog);
		}
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.p : null) == 300) {
			if(prog != this.rec_serverProg) {
				this.disposeWS();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "prog.mismatch." + prog + "." + this.rec_serverProg;
				this.state.clear_w();
				this.state.goto_m_from_session(113);
				this.exit_w();
				this.evtEndSession();
				this.entry_m113(retryCause);
			} else {
				this.state.s_w.p = 300;
				this.state.traceState();
				this.evtRestartKeepalive();
			}
		} else if(this.state.s_tr == 220) {
			if(prog != this.rec_serverProg) {
				this.disposeHTTP();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "prog.mismatch." + prog + "." + this.rec_serverProg;
				this.state.goto_m_from_session(113);
				this.cancel_evtTransportTimeout();
				this.evtEndSession();
				this.entry_m113(retryCause);
			} else {
				this.state.s_tr = 220;
				this.state.traceState();
			}
		} else if(this.state.s_tr == 230) {
			if(prog != this.rec_serverProg) {
				this.disposeHTTP();
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "prog.mismatch." + prog + "." + this.rec_serverProg;
				this.state.goto_m_from_session(113);
				this.cancel_evtTransportTimeout();
				this.evtEndSession();
				this.entry_m113(retryCause);
			} else {
				this.state.s_tr = 230;
				this.state.traceState();
			}
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.p : null) == 510) {
				if(prog != this.rec_serverProg) {
					this.disposeWS();
					this.notifyStatus("DISCONNECTED:WILL-RETRY");
					this.cause = "prog.mismatch." + prog + "." + this.rec_serverProg;
					this.state.goto_m_from_ws(113);
					this.exit_ws_to_m();
					this.entry_m113(retryCause);
				} else {
					this.state.s_ws.p = 510;
					this.state.traceState();
					this.evtRestartKeepalive();
				}
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.p : null) == 611) {
					if(prog != this.rec_serverProg) {
						this.disposeWS();
						this.notifyStatus("DISCONNECTED:WILL-RETRY");
						this.cause = "prog.mismatch." + prog + "." + this.rec_serverProg;
						this.state.goto_m_from_wp(113);
						this.exit_wp_to_m();
						this.entry_m113(retryCause);
					} else {
						this.state.s_wp.p = 611;
						this.state.traceState();
					}
				} else {
					var tmp = this.state.s_hs;
					if((tmp != null ? tmp.p : null) == 810) {
						if(prog != this.rec_serverProg) {
							this.disposeHTTP();
							this.notifyStatus("DISCONNECTED:WILL-RETRY");
							this.cause = "prog.mismatch." + prog + "." + this.rec_serverProg;
							this.state.goto_m_from_hs(113);
							this.exit_hs_to_m();
							this.entry_m113(retryCause);
						} else {
							this.state.s_hs.p = 810;
							this.state.traceState();
							this.evtRestartKeepalive();
						}
					} else {
						var tmp = this.state.s_hp;
						if((tmp != null ? tmp.m : null) == 901) {
							if(prog != this.rec_serverProg) {
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED:WILL-RETRY");
								this.cause = "prog.mismatch." + prog + "." + this.rec_serverProg;
								this.state.goto_m_from_hp(113);
								this.exit_hp_to_m();
								this.entry_m113(retryCause);
							} else {
								this.state.s_hp.m = 901;
								this.state.traceState();
							}
						} else if(this.state.s_rec == 1001) {
							if(prog > this.rec_clientProg) {
								this.disposeHTTP();
								this.notifyStatus("DISCONNECTED:WILL-RETRY");
								this.cause = "prog.mismatch." + prog + "." + this.rec_serverProg;
								this.state.goto_m_from_rec(113);
								this.exit_rec_to_m();
								this.entry_m113(retryCause);
							} else {
								this.state.s_rec = 1001;
								this.state.traceState();
								this.doPROG(prog);
							}
						}
					}
				}
			}
		}
	}
	,evtLOOP: function(pollingMs) {
		this.traceEvent("LOOP");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("LOOP " + pollingMs);
		}
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.p : null) == 300) {
			this.closeWS();
			this.cause = "ws.loop";
			this.state.clear_w();
			this.state.s_tr = 200;
			this.state.traceState();
			this.exit_w();
			this.evtSwitchTransport();
		} else if(this.state.s_tr == 220) {
			this.closeHTTP();
			this.cause = "http.loop";
			this.state.s_tr = 200;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtSwitchTransport();
		} else if(this.state.s_tr == 230) {
			this.closeHTTP();
			this.cause = "ttl.loop";
			this.state.s_tr = 200;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtSwitchTransport();
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.p : null) == 510) {
				this.closeWS();
				this.cause = "ws.loop";
				this.state.clear_ws();
				this.state.s_tr = 200;
				this.state.traceState();
				this.exit_ws();
				this.evtSwitchTransport();
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.p : null) == 611) {
					if(this.isSwitching()) {
						this.closeWS();
						this.cause = "ws.loop";
						this.state.clear_wp();
						this.state.s_tr = 200;
						this.state.traceState();
						this.exit_wp();
						this.evtSwitchTransport();
					} else {
						this.doLOOP(pollingMs);
						this.state.s_wp.p = 612;
						this.state.traceState();
						this.cancel_evtIdleTimeout();
						this.schedule_evtPollingTimeout(this.options.pollingInterval);
					}
				} else {
					var tmp = this.state.s_hs;
					if((tmp != null ? tmp.p : null) == 810) {
						this.closeHTTP();
						this.cause = "http.loop";
						this.state.s_hs.p = 811;
						this.state.traceState();
						this.evtSwitchTransport();
					} else {
						var tmp = this.state.s_hp;
						if((tmp != null ? tmp.m : null) == 901) {
							if(this.isSwitching()) {
								this.closeHTTP();
								this.state.s_hp.m = 904;
								this.state.traceState();
								this.cancel_evtIdleTimeout();
								this.evtSwitchTransport();
							} else {
								this.doLOOP(pollingMs);
								this.closeHTTP();
								this.state.s_hp.m = 902;
								this.state.traceState();
								this.cancel_evtIdleTimeout();
								this.schedule_evtPollingTimeout(this.options.pollingInterval);
							}
						} else if(this.state.s_rec == 1001) {
							this.closeHTTP();
							this.cause = "recovery.loop";
							this.state.goto_200_from_rec();
							this.exit_rec();
							this.evtSwitchTransport();
						}
					}
				}
			}
		}
	}
	,evtCONOK: function(sessionId,reqLimit,keepalive,clink) {
		this.traceEvent("CONOK");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("CONOK " + sessionId + " " + reqLimit + " " + keepalive + " " + clink);
		}
		if(this.state.s_m == 122) {
			this.doCONOK_CreateWS(sessionId,reqLimit,keepalive,clink);
			this.resetCurrentRetryDelay();
			this.notifyStatus("CONNECTED:WS-STREAMING");
			this.state.s_m = 150;
			this.state.s_tr = 210;
			this.state.s_w = new com_lightstreamer_client_internal_StateVar_$w(300,310,340);
			this.state.s_rhb = 320;
			this.state.s_slw = 330;
			this.state.s_swt = 1300;
			this.state.s_bw = 1200;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtSendPendingControls();
			this.evtSendPendingMessages();
			this.evtStartSession();
			this.schedule_evtKeepaliveTimeout(com_lightstreamer_internal_NullTools.sure(this.keepaliveInterval));
			this.evtSelectRhb();
			this.evtCheckTransport();
			this.evtCheckBW();
		} else if(this.state.s_m == 130) {
			this.doCONOK_CreateHTTP(sessionId,reqLimit,keepalive,clink);
			this.resetCurrentRetryDelay();
			this.notifyStatus("CONNECTED:STREAM-SENSING");
			this.state.s_m = 150;
			this.state.s_tr = 220;
			this.state.s_swt = 1300;
			this.state.s_bw = 1200;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtStartSession();
			this.schedule_evtTransportTimeout(this.options.retryDelay);
			this.evtCheckTransport();
			this.evtCheckBW();
		} else if(this.state.s_m == 140) {
			this.doCONOK_CreateHTTP(sessionId,reqLimit,keepalive,clink);
			this.resetCurrentRetryDelay();
			this.notifyStatus("CONNECTED:STREAM-SENSING");
			this.state.s_m = 150;
			this.state.s_tr = 230;
			this.state.s_swt = 1300;
			this.state.s_bw = 1200;
			this.state.traceState();
			this.cancel_evtTransportTimeout();
			this.evtStartSession();
			this.schedule_evtTransportTimeout(this.options.retryDelay);
			this.evtCheckTransport();
			this.evtCheckBW();
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.m : null) == 502) {
				this.doCONOK_BindWS_Streaming(sessionId,reqLimit,keepalive,clink);
				this.notifyStatus("CONNECTED:WS-STREAMING");
				this.state.s_ws.m = 503;
				this.state.s_ws.p = 510;
				this.state.s_ws.k = 520;
				this.state.s_ws.s = 550;
				this.state.s_rhb = 320;
				this.state.s_slw = 330;
				this.state.traceState();
				this.cancel_evtTransportTimeout();
				this.evtSendPendingControls();
				this.evtSendPendingMessages();
				this.schedule_evtKeepaliveTimeout(com_lightstreamer_internal_NullTools.sure(this.keepaliveInterval));
				this.evtSelectRhb();
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.p : null) == 610) {
					this.doCONOK_BindWS_Polling(sessionId,reqLimit,keepalive,clink);
					this.notifyStatus("CONNECTED:WS-POLLING");
					this.state.s_wp.p = 611;
					this.state.traceState();
				} else {
					var tmp = this.state.s_wp;
					if((tmp != null ? tmp.p : null) == 613) {
						this.doCONOK_BindWS_Polling(sessionId,reqLimit,keepalive,clink);
						this.state.s_wp.p = 611;
						this.state.traceState();
					} else {
						var tmp = this.state.s_hs;
						if((tmp != null ? tmp.m : null) == 800) {
							this.doCONOK_BindHTTP_Streaming(sessionId,reqLimit,keepalive,clink);
							this.notifyStatus("CONNECTED:HTTP-STREAMING");
							this.state.s_hs.m = 802;
							this.state.s_hs.p = 810;
							this.state.s_hs.k = 820;
							this.state.s_rhb = 320;
							this.state.s_slw = 330;
							this.state.traceState();
							this.cancel_evtTransportTimeout();
							this.schedule_evtKeepaliveTimeout(com_lightstreamer_internal_NullTools.sure(this.keepaliveInterval));
							this.evtSelectRhb();
						} else {
							var tmp = this.state.s_hs;
							if((tmp != null ? tmp.m : null) == 801) {
								this.doCONOK_BindHTTP_Streaming(sessionId,reqLimit,keepalive,clink);
								this.notifyStatus("CONNECTED:HTTP-STREAMING");
								this.state.s_hs.m = 802;
								this.state.s_hs.p = 810;
								this.state.s_hs.k = 820;
								this.state.s_rhb = 320;
								this.state.s_slw = 330;
								this.state.traceState();
								this.cancel_evtTransportTimeout();
								this.schedule_evtKeepaliveTimeout(com_lightstreamer_internal_NullTools.sure(this.keepaliveInterval));
								this.evtSelectRhb();
							} else {
								var tmp = this.state.s_hp;
								if((tmp != null ? tmp.m : null) == 900) {
									this.doCONOK_BindHTTP_Polling(sessionId,reqLimit,keepalive,clink);
									this.notifyStatus("CONNECTED:HTTP-POLLING");
									this.state.s_hp.m = 901;
									this.state.traceState();
								} else {
									var tmp = this.state.s_hp;
									if((tmp != null ? tmp.m : null) == 903) {
										this.doCONOK_BindHTTP_Polling(sessionId,reqLimit,keepalive,clink);
										this.state.s_hp.m = 901;
										this.state.traceState();
									}
								}
							}
						}
					}
				}
			}
		}
	}
	,evtSERVNAME: function(serverName) {
		this.traceEvent("SERVNAME");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("SERVNAME " + serverName);
		}
		if(this.state.inPushing()) {
			this.doSERVNAME(serverName);
			if(this.state.inStreaming()) {
				this.evtRestartKeepalive();
			}
		}
	}
	,evtCLIENTIP: function(clientIp) {
		this.traceEvent("CLIENTIP");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("CLIENTIP " + clientIp);
		}
		if(this.state.inPushing()) {
			this.doCLIENTIP(clientIp);
			if(this.state.inStreaming()) {
				this.evtRestartKeepalive();
			}
		}
	}
	,evtCONS: function(bandwidth) {
		this.traceEvent("CONS");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("CONS " + Std.string(bandwidth));
		}
		if(this.state.inPushing()) {
			this.doCONS(bandwidth);
			if(this.state.inStreaming()) {
				this.evtRestartKeepalive();
			}
		}
	}
	,evtPROBE: function() {
		this.traceEvent("PROBE");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("PROBE");
		}
		this.clientEventDispatcher.onServerKeepalive();
		if(this.state.inPushing()) {
			if(this.state.inStreaming()) {
				this.evtRestartKeepalive();
			}
		}
	}
	,evtNOOP: function() {
		this.traceEvent("NOOP");
		if(this.state.inPushing()) {
			if(this.state.inStreaming()) {
				this.evtRestartKeepalive();
			}
		}
	}
	,evtSYNC: function(seconds) {
		this.traceEvent("SYNC");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("SYNC " + seconds);
		}
		var forward = true;
		var tmp;
		var tmp1;
		var tmp2 = this.state.s_w;
		if((tmp2 != null ? tmp2.p : null) != 300) {
			var tmp2 = this.state.s_ws;
			tmp1 = (tmp2 != null ? tmp2.p : null) == 510;
		} else {
			tmp1 = true;
		}
		if(!tmp1) {
			var tmp1 = this.state.s_hs;
			tmp = (tmp1 != null ? tmp1.p : null) == 810;
		} else {
			tmp = true;
		}
		if(tmp) {
			forward = this.evtSYNC_PushingRegion(seconds);
			this.evtRestartKeepalive();
		} else {
			var tmp;
			var tmp1;
			if(!(this.state.s_tr == 220 || this.state.s_tr == 230)) {
				var tmp2 = this.state.s_wp;
				tmp1 = (tmp2 != null ? tmp2.p : null) == 611;
			} else {
				tmp1 = true;
			}
			if(!tmp1) {
				var tmp1 = this.state.s_hp;
				tmp = (tmp1 != null ? tmp1.m : null) == 901;
			} else {
				tmp = true;
			}
			if(tmp || this.state.s_rec == 1001) {
				forward = this.evtSYNC_PushingRegion(seconds);
			}
		}
		if(forward) {
			this.evtSYNC_PushingRegion(seconds);
		}
	}
	,evtSYNC_PushingRegion: function(seconds) {
		var syncMs = seconds * 1000;
		if(this.state.s_slw != null) {
			var _g = this.state.s_slw;
			if(_g != null) {
				switch(_g) {
				case 330:
					this.doSYNC(syncMs);
					this.state.s_slw = 331;
					this.state.traceState();
					break;
				case 331:
					var result = this.doSYNC_G(syncMs);
					this.state.s_slw = 332;
					this.state.traceState();
					this.evtCheckAvg(result);
					break;
				case 333:
					var result = this.doSYNC_NG(syncMs);
					this.state.s_slw = 332;
					this.state.traceState();
					this.evtCheckAvg(result);
					break;
				default:
				}
			}
		}
		return false;
	}
	,evtMSGDONE: function(sequence,prog,response) {
		this.traceEvent("MSGDONE");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("MSGDONE " + sequence + " " + prog + " " + response);
		}
		if(this.state.inPushing()) {
			if(this.isFreshData()) {
				this.doMSGDONE(sequence,prog,response);
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			} else {
				this.onStaleData();
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			}
		}
	}
	,evtMSGFAIL: function(sequence,prog,errorCode,errorMsg) {
		this.traceEvent("MSGFAIL");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("MSGFAIL " + sequence + " " + prog + " " + errorCode + " " + errorMsg);
		}
		if(this.state.inPushing()) {
			if(this.isFreshData()) {
				this.doMSGFAIL(sequence,prog,errorCode,errorMsg);
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			} else {
				this.onStaleData();
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			}
		}
	}
	,evtU: function(subId,itemIdx,values,rawValue) {
		this.traceEvent("U");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("" + rawValue);
		}
		if(this.state.inPushing()) {
			if(this.isFreshData()) {
				this.doU(subId,itemIdx,values);
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			} else {
				this.onStaleData();
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			}
		}
	}
	,evtSUBOK: function(subId,nItems,nFields) {
		this.traceEvent("SUBOK");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("SUBOK " + subId + " " + nItems + " " + nFields);
		}
		if(this.state.inPushing()) {
			if(this.isFreshData()) {
				this.doSUBOK(subId,nItems,nFields);
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			} else {
				this.onStaleData();
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			}
		}
	}
	,evtSUBCMD: function(subId,nItems,nFields,keyIdx,cmdIdx) {
		this.traceEvent("SUBCMD");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("SUBCMD " + subId + " " + nItems + " " + nFields + " " + keyIdx + " " + cmdIdx);
		}
		if(this.state.inPushing()) {
			if(this.isFreshData()) {
				this.doSUBCMD(subId,nItems,nFields,keyIdx,cmdIdx);
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			} else {
				this.onStaleData();
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			}
		}
	}
	,evtUNSUB: function(subId) {
		this.traceEvent("UNSUB");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("UNSUB " + subId);
		}
		if(this.state.inPushing()) {
			if(this.isFreshData()) {
				this.doUNSUB(subId);
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			} else {
				this.onStaleData();
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			}
		}
	}
	,evtEOS: function(subId,itemIdx) {
		this.traceEvent("EOS");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("EOS " + subId + " " + itemIdx);
		}
		if(this.state.inPushing()) {
			if(this.isFreshData()) {
				this.doEOS(subId,itemIdx);
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			} else {
				this.onStaleData();
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			}
		}
	}
	,evtCS: function(subId,itemIdx) {
		this.traceEvent("CS");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("CS " + subId + " " + itemIdx);
		}
		if(this.state.inPushing()) {
			if(this.isFreshData()) {
				this.doCS(subId,itemIdx);
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			} else {
				this.onStaleData();
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			}
		}
	}
	,evtOV: function(subId,itemIdx,lostUpdates) {
		this.traceEvent("OV");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("OV " + subId + " " + itemIdx + " " + lostUpdates);
		}
		if(this.state.inPushing()) {
			if(this.isFreshData()) {
				this.doOV(subId,itemIdx,lostUpdates);
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			} else {
				this.onStaleData();
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			}
		}
	}
	,evtCONF: function(subId,freq) {
		this.traceEvent("CONF");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.debug("CONF " + subId + " " + Std.string(freq));
		}
		if(this.state.inPushing()) {
			if(this.isFreshData()) {
				this.doCONF(subId,freq);
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			} else {
				this.onStaleData();
				if(this.state.inStreaming()) {
					this.evtRestartKeepalive();
				}
			}
		}
	}
	,evtCheckAvg: function(result) {
		this.traceEvent("check.avg");
		if(this.state.s_slw == 332) {
			switch(result._hx_index) {
			case 0:
				this.state.s_slw = 331;
				this.state.traceState();
				break;
			case 1:
				this.state.s_slw = 333;
				this.state.traceState();
				break;
			case 2:
				this.disableStreaming();
				this.cause = "slow";
				this.state.s_slw = 334;
				this.state.traceState();
				this.evtForcePolling();
				break;
			}
		}
	}
	,evtSendPendingControls: function() {
		this.traceEvent("send.pending.controls");
		var controls = this.getPendingControls();
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.s : null) == 340 && !Lambda.empty(controls)) {
			this.sendPengingControlsWS(controls);
			this.state.s_w.s = 340;
			this.state.traceState();
			this.evtRestartHeartbeat();
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.s : null) == 550 && !Lambda.empty(controls)) {
				this.sendPengingControlsWS(controls);
				this.state.s_ws.s = 550;
				this.state.traceState();
				this.evtRestartHeartbeat();
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.s : null) == 630 && !Lambda.empty(controls)) {
					this.sendPengingControlsWS(controls);
					this.state.s_wp.s = 630;
					this.state.traceState();
				}
			}
		}
	}
	,evtSendPendingMessages: function() {
		this.traceEvent("send.pending.messages");
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.s : null) == 340 && Lambda.exists(this.messageManagers,function(msg) {
			return msg.isPending();
		})) {
			this.sendPendingMessagesWS();
			this.state.s_w.s = 340;
			this.state.traceState();
			this.genAckMessagesWS();
			this.evtRestartHeartbeat();
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.s : null) == 550 && Lambda.exists(this.messageManagers,function(msg) {
				return msg.isPending();
			})) {
				this.sendPendingMessagesWS();
				this.state.s_ws.s = 550;
				this.state.traceState();
				this.genAckMessagesWS();
				this.evtRestartHeartbeat();
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.s : null) == 630 && Lambda.exists(this.messageManagers,function(msg) {
					return msg.isPending();
				})) {
					this.sendPendingMessagesWS();
					this.state.s_wp.s = 630;
					this.state.traceState();
					this.genAckMessagesWS();
				}
			}
		}
	}
	,evtSelectRhb: function() {
		this.traceEvent("select.rhb");
		if(this.state.s_rhb == 320) {
			if(this.rhb_grantedInterval == 0) {
				if(this.options.reverseHeartbeatInterval == 0) {
					this.state.s_rhb = 321;
					this.state.traceState();
				} else {
					this.rhb_currentInterval = this.options.reverseHeartbeatInterval;
					this.state.s_rhb = 322;
					this.state.traceState();
					this.schedule_evtRhbTimeout(this.rhb_currentInterval);
				}
			} else if(this.options.reverseHeartbeatInterval > 0 && this.options.reverseHeartbeatInterval < com_lightstreamer_internal_NullTools.sure(this.rhb_grantedInterval)) {
				this.rhb_currentInterval = this.options.reverseHeartbeatInterval;
				this.state.s_rhb = 323;
				this.state.traceState();
				this.schedule_evtRhbTimeout(this.rhb_currentInterval);
			} else {
				this.rhb_currentInterval = this.rhb_grantedInterval;
				this.state.s_rhb = 323;
				this.state.traceState();
				this.schedule_evtRhbTimeout(com_lightstreamer_internal_NullTools.sure(this.rhb_currentInterval));
			}
		}
	}
	,evtExtSetReverseHeartbeatInterval: function() {
		this.traceEvent("setReverseHeartbeatInterval");
		if(this.state.s_rhb != null) {
			var _g = this.state.s_rhb;
			if(_g != null) {
				switch(_g) {
				case 321:
					if(this.options.reverseHeartbeatInterval != 0) {
						this.rhb_currentInterval = this.options.reverseHeartbeatInterval;
						this.state.s_rhb = 322;
						this.state.traceState();
						this.schedule_evtRhbTimeout(this.rhb_currentInterval);
					}
					break;
				case 322:
					if(this.options.reverseHeartbeatInterval == 0) {
						this.state.s_rhb = 321;
						this.state.traceState();
						this.cancel_evtRhbTimeout();
					} else {
						this.rhb_currentInterval = this.options.reverseHeartbeatInterval;
						this.state.s_rhb = 322;
						this.state.traceState();
					}
					break;
				case 323:
					if(this.options.reverseHeartbeatInterval > 0 && this.options.reverseHeartbeatInterval < com_lightstreamer_internal_NullTools.sure(this.rhb_grantedInterval)) {
						this.rhb_currentInterval = this.options.reverseHeartbeatInterval;
						this.state.s_rhb = 323;
						this.state.traceState();
					} else {
						this.rhb_currentInterval = this.rhb_grantedInterval;
						this.state.s_rhb = 323;
						this.state.traceState();
					}
					break;
				default:
				}
			}
		}
	}
	,evtRestartHeartbeat: function() {
		this.traceEvent("restart.heartbeat");
		if(this.state.s_rhb != null) {
			var _g = this.state.s_rhb;
			if(_g != null) {
				switch(_g) {
				case 322:
					this.state.s_rhb = 322;
					this.state.traceState();
					this.cancel_evtRhbTimeout();
					this.schedule_evtRhbTimeout(com_lightstreamer_internal_NullTools.sure(this.rhb_currentInterval));
					break;
				case 323:
					this.state.s_rhb = 323;
					this.state.traceState();
					this.cancel_evtRhbTimeout();
					this.schedule_evtRhbTimeout(com_lightstreamer_internal_NullTools.sure(this.rhb_currentInterval));
					break;
				case 324:
					if(this.rhb_grantedInterval == 0) {
						if(this.options.reverseHeartbeatInterval != 0) {
							this.rhb_currentInterval = this.options.reverseHeartbeatInterval;
							this.state.s_rhb = 322;
							this.state.traceState();
							this.schedule_evtRhbTimeout(this.rhb_currentInterval);
						} else {
							this.state.s_rhb = 321;
							this.state.traceState();
						}
					} else if(this.options.reverseHeartbeatInterval > 0 && this.options.reverseHeartbeatInterval < com_lightstreamer_internal_NullTools.sure(this.rhb_grantedInterval)) {
						this.rhb_currentInterval = this.options.reverseHeartbeatInterval;
						this.state.s_rhb = 323;
						this.state.traceState();
						this.schedule_evtRhbTimeout(this.rhb_currentInterval);
					} else {
						this.rhb_currentInterval = this.rhb_grantedInterval;
						this.state.s_rhb = 323;
						this.state.traceState();
						this.schedule_evtRhbTimeout(com_lightstreamer_internal_NullTools.sure(this.rhb_currentInterval));
					}
					break;
				default:
				}
			}
		}
	}
	,evtRhbTimeout: function() {
		this.traceEvent("rhb.timeout");
		if(this.state.s_rhb == 322) {
			this.state.s_rhb = 324;
			this.state.traceState();
			this.cancel_evtRhbTimeout();
			this.evtSendHeartbeat();
		} else if(this.state.s_rhb == 323) {
			this.state.s_rhb = 324;
			this.state.traceState();
			this.cancel_evtRhbTimeout();
			this.evtSendHeartbeat();
		}
	}
	,evtDisposeCtrl: function() {
		this.traceEvent("du:dispose.ctrl");
		this.disposeCtrl();
	}
	,evtStartRecovery: function() {
		this.traceEvent("start.recovery");
		if(this.state.s_rec == 1000) {
			this.recoverTs = HxOverrides.now() / 1000;
			this.state.s_rec = 1000;
			this.state.traceState();
		}
	}
	,evtRecoveryTimeout: function() {
		this.traceEvent("recovery.timeout");
		if(this.state.s_rec == 1000) {
			this.sendRecovery();
			this.state.s_rec = 1001;
			this.state.traceState();
			this.cancel_evtRecoveryTimeout();
			this.schedule_evtTransportTimeout(this.options.retryDelay);
		}
	}
	,evtCheckRecoveryTimeout: function(retryCause) {
		this.traceEvent("check.recovery.timeout");
		if(this.state.s_rec == 1002) {
			var retryDelayMs = this.options.retryDelay;
			if(com_lightstreamer_internal_TimerStamp.plus(this.connectTs,retryDelayMs) < com_lightstreamer_internal_TimerStamp.plus(this.recoverTs,this.options.sessionRecoveryTimeout)) {
				this.cause = "recovery.error";
				var diffMs = com_lightstreamer_internal_TimerStamp.minus(HxOverrides.now() / 1000,this.connectTs);
				var pauseMs = retryDelayMs > com_lightstreamer_internal_TimerMillis.toMillis(diffMs) ? com_lightstreamer_internal_Millis.minus(retryDelayMs,com_lightstreamer_internal_TimerMillis.toMillis(diffMs)) : com_lightstreamer_internal_Millis.ZERO;
				this.state.s_rec = 1003;
				this.state.traceState();
				if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
					if(pauseMs > 0) {
						if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
							com_lightstreamer_log_LoggerTools_sessionLogger.error("Retrying recovery in " + pauseMs + "ms. Cause: " + Std.string(retryCause));
						}
					} else if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
						com_lightstreamer_log_LoggerTools_sessionLogger.error("Retrying recovery. Cause: " + Std.string(retryCause));
					}
				}
				this.schedule_evtRetryTimeout(pauseMs);
			} else {
				this.notifyStatus("DISCONNECTED:WILL-RETRY");
				this.cause = "recovery.timeout";
				this.state.goto_m_from_rec(113);
				this.exit_rec_to_m();
				this.entry_m113(com_lightstreamer_client_internal__$ClientMachine_RetryCause.recovery_timeout);
			}
		}
	}
	,evtCreate: function() {
		this.traceEvent("du:create");
		if(this.state.s_du == 20) {
			this.state.s_du = 21;
			this.state.traceState();
		} else if(this.state.s_du == 23) {
			this.state.s_du = 21;
			this.state.traceState();
		}
	}
	,evtCheckTransport: function() {
		this.traceEvent("check.transport");
		if(this.state.s_swt == 1300) {
			if(this.state.s_tr == 220 || this.state.s_tr == 230 || this.state.s_tr == 260) {
				this.state.s_swt = 1301;
				this.state.traceState();
			} else {
				var best = this.getBestForBinding();
				if(best == com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_streaming && (this.state.s_tr == 210 || this.state.s_tr == 240) || best == com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_streaming && this.state.s_tr == 270 && this.state.s_h == 710 || best == com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_polling && this.state.s_tr == 250 || best == com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_polling && this.state.s_tr == 270 && this.state.s_h == 720) {
					this.state.s_swt = 1301;
					this.state.traceState();
				} else {
					this.state.s_swt = 1302;
					this.state.traceState();
					this.evtSendControl(com_lightstreamer_internal_NullTools.sure(this.switchRequest));
				}
			}
		}
	}
	,evtCheckBW: function() {
		this.traceEvent("check.bw");
		if(this.state.s_bw == 1200) {
			if(this.bw_requestedMaxBandwidth != this.options.requestedMaxBandwidth && this.options.realMaxBandwidth != com_lightstreamer_internal_RealMaxBandwidth.BWUnmanaged) {
				this.bw_requestedMaxBandwidth = this.options.requestedMaxBandwidth;
				this.state.s_bw = 1202;
				this.state.traceState();
				this.evtSendControl(com_lightstreamer_internal_NullTools.sure(this.constrainRequest));
			} else {
				this.state.s_bw = 1201;
				this.state.traceState();
			}
		}
	}
	,evtCheckCtrlRequests: function() {
		this.traceEvent("check.ctrl.requests");
		if(this.state.s_ctrl == 1100) {
			var controls = this.getPendingControls();
			if(!Lambda.empty(controls)) {
				this.sendPendingControlsHTTP(controls);
				this.state.s_ctrl = 1102;
				this.state.traceState();
				this.evtRestartHeartbeat();
				this.schedule_evtCtrlTimeout(this.options.retryDelay);
			} else if(Lambda.exists(this.messageManagers,function(msg) {
				return msg.isPending();
			})) {
				this.sendPendingMessagesHTTP();
				this.state.s_ctrl = 1102;
				this.state.traceState();
				this.evtRestartHeartbeat();
				this.schedule_evtCtrlTimeout(this.options.retryDelay);
			} else if(this.state.s_rhb == 324) {
				this.sendHeartbeatHTTP();
				this.state.s_ctrl = 1102;
				this.state.traceState();
				this.evtRestartHeartbeat();
				this.schedule_evtCtrlTimeout(this.options.retryDelay);
			} else {
				this.state.s_ctrl = 1101;
				this.state.traceState();
			}
		}
	}
	,evtCtrlDone: function() {
		this.traceEvent("ctrl.done");
		if(this.state.s_ctrl == 1102) {
			this.closeCtrl();
			this.state.s_ctrl = 1100;
			this.state.traceState();
			this.cancel_evtCtrlTimeout();
			this.evtCheckCtrlRequests();
		}
	}
	,evtCtrlError: function() {
		this.traceEvent("ctrl.error");
		if(this.state.s_ctrl == 1102) {
			this.disposeCtrl();
			var pauseMs = this.waitingInterval(this.options.retryDelay,this.ctrl_connectTs);
			this.state.s_ctrl = 1103;
			this.state.traceState();
			this.cancel_evtCtrlTimeout();
			this.schedule_evtCtrlTimeout(pauseMs);
		}
	}
	,evtCtrlTimeout: function() {
		this.traceEvent("ctrl.timeout");
		if(this.state.s_ctrl != null) {
			if(this.state.s_ctrl == 1102) {
				this.disposeCtrl();
				var pauseMs = this.waitingInterval(this.options.retryDelay,this.ctrl_connectTs);
				this.state.s_ctrl = 1103;
				this.state.traceState();
				this.cancel_evtCtrlTimeout();
				this.schedule_evtCtrlTimeout(pauseMs);
			} else if(this.state.s_ctrl == 1103) {
				this.state.s_ctrl = 1100;
				this.state.traceState();
				this.cancel_evtCtrlTimeout();
				this.evtCheckCtrlRequests();
			}
		}
	}
	,evtSendControl: function(request) {
		this.traceEvent("send.control");
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.s : null) == 340) {
			this.sendControlWS(request);
			this.state.s_w.s = 340;
			this.state.traceState();
			this.evtRestartHeartbeat();
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.s : null) == 550) {
				this.sendControlWS(request);
				this.state.s_ws.s = 550;
				this.state.traceState();
				this.evtRestartHeartbeat();
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.s : null) == 630) {
					this.sendControlWS(request);
					this.state.s_wp.s = 630;
					this.state.traceState();
				} else if(this.state.s_ctrl == 1101) {
					this.state.s_ctrl = 1100;
					this.state.traceState();
					this.evtCheckCtrlRequests();
				}
			}
		}
	}
	,evtSendHeartbeat: function() {
		this.traceEvent("send.heartbeat");
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.s : null) == 340) {
			this.sendHeartbeatWS();
			this.state.s_w.s = 340;
			this.state.traceState();
			this.evtRestartHeartbeat();
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.s : null) == 550) {
				this.sendHeartbeatWS();
				this.state.s_ws.s = 550;
				this.state.traceState();
				this.evtRestartHeartbeat();
			} else if(this.state.s_ctrl == 1101) {
				this.state.s_ctrl = 1100;
				this.state.traceState();
				this.evtCheckCtrlRequests();
			}
		}
	}
	,evtStartSession: function() {
		this.traceEvent("du:start.session");
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_sessionLogger.info("Starting new session: " + this.sessionId);
		}
		if(this.state.s_du == 21) {
			this.state.s_du = 22;
			this.state.traceState();
		}
	}
	,evtEndSession: function() {
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_sessionLogger.info("Destroying session: " + this.sessionId);
		}
	}
	,evtRetry: function(retryCause,timeout) {
		this.traceEvent("du:retry");
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
			if(timeout != null && timeout > 0) {
				if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
					com_lightstreamer_log_LoggerTools_sessionLogger.error("Retrying connection in " + timeout + "ms. Cause: " + com_lightstreamer_client_internal_ClientMachine_asErrorMsg(retryCause));
				}
			} else if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
				com_lightstreamer_log_LoggerTools_sessionLogger.error("Retrying connection. Cause: " + com_lightstreamer_client_internal_ClientMachine_asErrorMsg(retryCause));
			}
		}
		var forward = true;
		switch(this.state.s_du) {
		case 21:
			this.resetSequenceMap();
			this.state.s_du = 23;
			this.state.traceState();
			forward = this.evtRetry_NextRegion();
			this.genAbortMessages();
			break;
		case 22:
			this.disposeSession();
			this.state.s_du = 23;
			this.state.traceState();
			forward = this.evtRetry_NextRegion();
			this.genAbortSubscriptions();
			this.genAbortMessages();
			break;
		default:
		}
		if(forward) {
			this.evtRetry_NextRegion();
		}
	}
	,evtRetry_NextRegion: function() {
		return false;
	}
	,evtTerminate: function(terminationCause) {
		this.traceEvent("du:terminate");
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isInfoEnabled()) {
			if(terminationCause._hx_index == 2) {
				if(com_lightstreamer_log_LoggerTools_sessionLogger.isInfoEnabled()) {
					com_lightstreamer_log_LoggerTools_sessionLogger.info("Disconnected. Cause: Requested by user");
				}
			}
		}
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
			switch(terminationCause._hx_index) {
			case 0:
				if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
					com_lightstreamer_log_LoggerTools_sessionLogger.error("Disconnected. Cause: " + terminationCause.code + " - " + terminationCause.msg);
				}
				break;
			case 1:
				if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
					com_lightstreamer_log_LoggerTools_sessionLogger.error("Disconnected. Cause: " + terminationCause.error);
				}
				break;
			case 2:
				break;
			}
		}
		var forward = true;
		switch(this.state.s_du) {
		case 20:
			this.disposeClient();
			this.doUnistallPageLifecycle();
			this.state.s_du = 20;
			this.state.traceState();
			forward = this.evtTerminate_NextRegion();
			this.genAbortMessages();
			break;
		case 21:
			this.disposeClient();
			this.doUnistallPageLifecycle();
			this.state.s_du = 20;
			this.state.traceState();
			forward = this.evtTerminate_NextRegion();
			this.genAbortMessages();
			break;
		case 22:
			this.disposeSession();
			this.disposeClient();
			this.doUnistallPageLifecycle();
			this.state.s_du = 20;
			this.state.traceState();
			forward = this.evtTerminate_NextRegion();
			this.genAbortSubscriptions();
			this.genAbortMessages();
			break;
		case 23:
			this.disposeClient();
			this.doUnistallPageLifecycle();
			this.state.s_du = 20;
			this.state.traceState();
			forward = this.evtTerminate_NextRegion();
			this.genAbortMessages();
			break;
		default:
		}
		if(forward) {
			this.evtTerminate_NextRegion();
		}
	}
	,evtTerminate_NextRegion: function() {
		return this.evtTerminate_NetworkReachabilityRegion();
	}
	,evtTerminate_NetworkReachabilityRegion: function() {
		switch(this.state.s_nr) {
		case 1410:case 1411:case 1412:
			var rm = this.nr_reachabilityManager;
			this.nr_reachabilityManager = null;
			this.state.s_nr = 1400;
			this.state.traceState();
			if(rm != null) {
				rm.stopListening();
			}
			break;
		default:
		}
		return false;
	}
	,evtRetryTimeout: function() {
		this.traceEvent("retry.timeout");
		switch(this.state.s_m) {
		case 110:
			this.notifyStatus("CONNECTING");
			this.sendCreateTTL();
			this.state.s_m = 140;
			this.state.traceState();
			this.evtCreate();
			this.schedule_evtTransportTimeout(60000);
			break;
		case 111:
			this.notifyStatus("CONNECTING");
			this.delayCounter.increase();
			this.sendCreateTTL();
			this.state.s_m = 140;
			this.state.traceState();
			this.cancel_evtRetryTimeout();
			this.evtCreate();
			this.schedule_evtTransportTimeout(60000);
			break;
		case 112:
			this.delayCounter.increase();
			this.state.s_m = 116;
			this.state.traceState();
			this.cancel_evtRetryTimeout();
			this.evtSelectCreate();
			break;
		case 113:
			this.state.s_m = 116;
			this.state.traceState();
			this.cancel_evtRetryTimeout();
			this.evtSelectCreate();
			break;
		case 115:
			this.state.s_m = 116;
			this.state.traceState();
			this.evtSelectCreate();
			break;
		case 150:
			if(this.state.s_rec == 1003) {
				this.sendRecovery();
				this.state.s_rec = 1001;
				this.state.traceState();
				this.cancel_evtRetryTimeout();
				this.schedule_evtTransportTimeout(this.options.retryDelay);
			}
			break;
		default:
		}
	}
	,evtExtSetForcedTransport: function() {
		this.traceEvent("setForcedTransport");
		if(this.state.s_swt == 1301) {
			this.state.s_swt = 1300;
			this.state.traceState();
			this.evtCheckTransport();
		}
	}
	,evtExtSetRequestedMaxBandwidth: function() {
		this.traceEvent("setRequestedMaxBandwidth");
		if(this.state.s_bw == 1201) {
			this.state.s_bw = 1200;
			this.state.traceState();
			this.evtCheckBW();
		}
	}
	,evtForceSlowing: function() {
		this.traceEvent("force.slowing");
		if(this.state.s_swt == 1301) {
			this.state.s_swt = 1300;
			this.state.traceState();
			this.evtCheckTransport();
		}
	}
	,evtForcePolling: function() {
		this.traceEvent("force.polling");
		if(this.state.s_swt == 1301) {
			this.state.s_swt = 1300;
			this.state.traceState();
			this.evtCheckTransport();
		}
	}
	,evtSendMessage: function(msg) {
		this.traceEvent("send.message");
		var tmp = this.state.s_w;
		if((tmp != null ? tmp.s : null) == 340) {
			this.sendMsgWS(msg);
			this.state.s_w.s = 340;
			this.state.traceState();
			msg.evtWSSent();
			this.evtRestartHeartbeat();
		} else {
			var tmp = this.state.s_ws;
			if((tmp != null ? tmp.s : null) == 550) {
				this.sendMsgWS(msg);
				this.state.s_ws.s = 550;
				this.state.traceState();
				msg.evtWSSent();
				this.evtRestartHeartbeat();
			} else {
				var tmp = this.state.s_wp;
				if((tmp != null ? tmp.s : null) == 630) {
					this.sendMsgWS(msg);
					this.state.s_wp.s = 630;
					this.state.traceState();
					msg.evtWSSent();
				} else if(this.state.s_ctrl == 1101) {
					this.state.s_ctrl = 1100;
					this.state.traceState();
					this.evtCheckCtrlRequests();
				}
			}
		}
	}
	,evtSwitchTransport: function() {
		this.traceEvent("switch.transport");
		var forward = true;
		if(this.state.s_swt == 1302 || this.state.s_swt == 1303) {
			this.state.s_swt = 1300;
			this.state.traceState();
			forward = this.evtSwitchTransport_forwardToTransportRegion();
			this.evtCheckTransport();
		}
		if(forward) {
			this.evtSwitchTransport_forwardToTransportRegion();
		}
	}
	,evtSwitchTransport_forwardToTransportRegion: function() {
		var terminationCause = com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_otherError("Selected transport " + this.options.forcedTransport + " is not available");
		if(this.state.s_tr == 200) {
			switch(this.getBestForBinding()._hx_index) {
			case 0:
				this.notifyStatus("DISCONNECTED");
				this.state.goto_m_from_session(100);
				this.evtEndSession();
				this.evtTerminate(terminationCause);
				break;
			case 1:
				this.openWS_Bind();
				this.state.s_tr = 240;
				this.state.s_ws = new com_lightstreamer_client_internal_StateVar_$ws(500);
				this.state.traceState();
				this.schedule_evtTransportTimeout(this.options.retryDelay);
				break;
			case 2:
				this.openWS_Bind();
				this.state.s_tr = 250;
				this.state.s_wp = new com_lightstreamer_client_internal_StateVar_$wp(600);
				this.state.traceState();
				this.schedule_evtTransportTimeout(this.options.retryDelay);
				break;
			case 3:
				this.sendBindHTTP_Streaming();
				this.state.s_tr = 270;
				this.state.s_h = 710;
				this.state.s_hs = new com_lightstreamer_client_internal_StateVar_$hs(800);
				this.state.s_ctrl = 1100;
				this.state.traceState();
				this.evtCheckCtrlRequests();
				this.schedule_evtTransportTimeout(this.options.retryDelay);
				break;
			case 4:
				this.sendBindHTTP_Polling();
				this.state.s_tr = 270;
				this.state.s_h = 720;
				this.state.s_hp = new com_lightstreamer_client_internal_StateVar_$hp(900);
				this.state.s_rhb = 320;
				this.state.s_ctrl = 1100;
				this.state.traceState();
				this.evtCheckCtrlRequests();
				this.schedule_evtIdleTimeout(com_lightstreamer_internal_Millis.plus(com_lightstreamer_internal_NullTools.sure(this.idleTimeout),this.options.retryDelay));
				this.evtSelectRhb();
				break;
			}
		} else {
			var tmp = this.state.s_hs;
			if((tmp != null ? tmp.p : null) == 811) {
				switch(this.getBestForBinding()._hx_index) {
				case 0:
					this.notifyStatus("DISCONNECTED");
					this.state.goto_m_from_hs(100);
					this.exit_hs_to_m();
					this.evtTerminate(terminationCause);
					break;
				case 1:
					this.openWS_Bind();
					this.state.clear_hs();
					this.state.s_h = null;
					this.state.s_ctrl = null;
					this.state.s_tr = 240;
					this.state.s_ws = new com_lightstreamer_client_internal_StateVar_$ws(500);
					this.state.traceState();
					this.exit_hs();
					this.exit_ctrl();
					this.schedule_evtTransportTimeout(this.options.retryDelay);
					break;
				case 2:
					this.openWS_Bind();
					this.state.clear_hs();
					this.state.s_h = null;
					this.state.s_ctrl = null;
					this.state.s_tr = 250;
					this.state.s_wp = new com_lightstreamer_client_internal_StateVar_$wp(600);
					this.state.traceState();
					this.exit_hs();
					this.exit_ctrl();
					this.schedule_evtTransportTimeout(this.options.retryDelay);
					break;
				case 3:
					this.sendBindHTTP_Streaming();
					this.state.s_hs = new com_lightstreamer_client_internal_StateVar_$hs(800);
					this.state.traceState();
					this.exit_hs();
					this.schedule_evtTransportTimeout(this.options.retryDelay);
					break;
				case 4:
					this.sendBindHTTP_Polling();
					this.state.clear_hs();
					this.state.s_h = 720;
					this.state.s_hp = new com_lightstreamer_client_internal_StateVar_$hp(900);
					this.state.s_rhb = 320;
					this.state.traceState();
					this.exit_hs();
					this.schedule_evtIdleTimeout(com_lightstreamer_internal_Millis.plus(com_lightstreamer_internal_NullTools.sure(this.idleTimeout),this.options.retryDelay));
					this.evtSelectRhb();
					break;
				}
			} else {
				var tmp = this.state.s_hp;
				if((tmp != null ? tmp.m : null) == 904) {
					switch(this.getBestForBinding()._hx_index) {
					case 0:
						this.notifyStatus("DISCONNECTED");
						this.state.goto_m_from_hp(100);
						this.exit_hp_to_m();
						this.evtTerminate(terminationCause);
						break;
					case 1:
						this.openWS_Bind();
						this.state.clear_hp();
						this.state.s_h = null;
						this.state.s_ctrl = null;
						this.state.s_tr = 240;
						this.state.s_ws = new com_lightstreamer_client_internal_StateVar_$ws(500);
						this.state.traceState();
						this.exit_hp();
						this.exit_ctrl();
						this.schedule_evtTransportTimeout(this.options.retryDelay);
						break;
					case 2:
						this.openWS_Bind();
						this.state.clear_hp();
						this.state.s_h = null;
						this.state.s_ctrl = null;
						this.state.s_tr = 250;
						this.state.s_wp = new com_lightstreamer_client_internal_StateVar_$wp(600);
						this.state.traceState();
						this.exit_hp();
						this.exit_ctrl();
						this.schedule_evtTransportTimeout(this.options.retryDelay);
						break;
					case 3:
						this.sendBindHTTP_Streaming();
						this.state.clear_hp();
						this.state.s_h = 710;
						this.state.s_hs = new com_lightstreamer_client_internal_StateVar_$hs(800);
						this.state.traceState();
						this.exit_hp();
						this.schedule_evtTransportTimeout(this.options.retryDelay);
						break;
					case 4:
						this.sendBindHTTP_Polling();
						this.state.s_hp = new com_lightstreamer_client_internal_StateVar_$hp(900);
						this.state.s_rhb = 320;
						this.state.traceState();
						this.exit_hp();
						this.schedule_evtIdleTimeout(com_lightstreamer_internal_Millis.plus(com_lightstreamer_internal_NullTools.sure(this.idleTimeout),this.options.retryDelay));
						this.evtSelectRhb();
						break;
					}
				}
			}
		}
		return false;
	}
	,onFreshData: function() {
		if(this.rec_serverProg != this.rec_clientProg) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "rec_serverProg == rec_clientProg");
		}
		this.rec_serverProg += 1;
		this.rec_clientProg += 1;
	}
	,onStaleData: function() {
		if(this.rec_serverProg >= this.rec_clientProg) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "rec_serverProg < rec_clientProg");
		}
		this.rec_serverProg += 1;
	}
	,isFreshData: function() {
		return this.rec_serverProg == this.rec_clientProg;
	}
	,openWS: function(url,headers) {
		var _gthis = this;
		var tmp = function(client) {
			com_lightstreamer_internal_Timers__setImmediate(function() {
				_gthis.lock.synchronized(function() {
					if(client.isDisposed()) {
						return;
					}
					_gthis.evtWSOpen();
				});
			});
		};
		var onText = function(client,line) {
			com_lightstreamer_internal_Timers__setImmediate(function() {
				_gthis.lock.synchronized(function() {
					if(client.isDisposed()) {
						return;
					}
					try {
						_gthis.evtMessage(line);
					} catch( _g ) {
						var _g1 = haxe_Exception.caught(_g);
						if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
							com_lightstreamer_log_LoggerTools_sessionLogger.error("" + _g1.get_message() + "\n" + _g1.details());
						}
						_gthis.evtExtDisconnect(com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_standardError(61,_g1.get_message()));
					}
				});
			});
		};
		var tmp1 = function(client,error) {
			com_lightstreamer_internal_Timers__setImmediate(function() {
				_gthis.lock.synchronized(function() {
					if(client.isDisposed()) {
						return;
					}
					_gthis.evtTransportError();
				});
			});
		};
		return this.wsFactory(url,headers,tmp,onText,tmp1);
	}
	,openWS_Create: function() {
		this.connectTs = HxOverrides.now() / 1000;
		this.serverInstanceAddress = this.getServerAddress();
		this.ws = this.openWS(com_lightstreamer_internal_Url.build(this.serverInstanceAddress,"lightstreamer"),this.options.httpExtraHeaders);
	}
	,openWS_Bind: function() {
		this.connectTs = HxOverrides.now() / 1000;
		this.ws = this.openWS(com_lightstreamer_internal_Url.build(com_lightstreamer_internal_NullTools.sure(this.serverInstanceAddress),"lightstreamer"),this.getHeadersForRequestOtherThanCreate());
	}
	,sendCreateWS: function() {
		var req = new com_lightstreamer_internal_RequestBuilder();
		if(this.options.keepaliveInterval > 0) {
			req.LS_keepalive_millis(this.options.keepaliveInterval);
		}
		this.rhb_grantedInterval = this.options.reverseHeartbeatInterval;
		if(this.rhb_grantedInterval > 0) {
			req.LS_inactivity_millis(this.rhb_grantedInterval);
		}
		this.bw_requestedMaxBandwidth = this.options.requestedMaxBandwidth;
		var _g = this.bw_requestedMaxBandwidth;
		if(_g != null) {
			if(_g._hx_index == 0) {
				req.LS_requested_max_bandwidth_Float(_g.bw);
			}
		}
		if(this.details.adapterSet != null) {
			req.LS_adapter_set(this.details.adapterSet);
		}
		if(this.details.user != null) {
			req.LS_user(this.details.user);
		}
		req.LS_cid(com_lightstreamer_internal_Constants_LS_CID);
		if(this.sessionId != null) {
			req.LS_old_session(this.sessionId);
		}
		if(!this.options.slowingEnabled) {
			req.LS_send_sync(false);
		}
		if(this.cause != null) {
			req.LS_cause(this.cause);
			this.cause = null;
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending session create: " + Std.string(req));
		}
		if(this.details.password != null) {
			req.LS_password(this.details.password);
		}
		com_lightstreamer_internal_NullTools.sure(this.ws).send("wsok");
		com_lightstreamer_internal_NullTools.sure(this.ws).send("create_session\r\n" + req.getEncodedString());
	}
	,sendBindWS_Streaming: function() {
		var req = new com_lightstreamer_internal_RequestBuilder();
		req.LS_session(com_lightstreamer_internal_NullTools.sure(this.sessionId));
		if(this.options.keepaliveInterval > 0) {
			req.LS_keepalive_millis(this.options.keepaliveInterval);
		}
		this.rhb_grantedInterval = this.options.reverseHeartbeatInterval;
		if(this.rhb_grantedInterval > 0) {
			req.LS_inactivity_millis(this.rhb_grantedInterval);
		}
		if(!this.options.slowingEnabled) {
			req.LS_send_sync(false);
		}
		if(this.cause != null) {
			req.LS_cause(this.cause);
			this.cause = null;
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending session bind: " + Std.string(req));
		}
		com_lightstreamer_internal_NullTools.sure(this.ws).send("wsok");
		com_lightstreamer_internal_NullTools.sure(this.ws).send("bind_session\r\n" + req.getEncodedString());
	}
	,sendBindWS_FirstPolling: function() {
		var req = new com_lightstreamer_internal_RequestBuilder();
		req.LS_session(com_lightstreamer_internal_NullTools.sure(this.sessionId));
		req.LS_polling(true);
		req.LS_polling_millis(this.options.pollingInterval);
		this.idleTimeout = this.options.idleTimeout;
		req.LS_idle_millis(this.idleTimeout);
		if(this.cause != null) {
			req.LS_cause(this.cause);
			this.cause = null;
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending session bind: " + Std.string(req));
		}
		com_lightstreamer_internal_NullTools.sure(this.ws).send("bind_session\r\n" + req.getEncodedString());
	}
	,sendBindWS_Polling: function() {
		var req = new com_lightstreamer_internal_RequestBuilder();
		req.LS_polling(true);
		req.LS_polling_millis(this.options.pollingInterval);
		this.idleTimeout = this.options.idleTimeout;
		req.LS_idle_millis(this.idleTimeout);
		if(this.cause != null) {
			req.LS_cause(this.cause);
			this.cause = null;
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending session bind: " + Std.string(req));
		}
		com_lightstreamer_internal_NullTools.sure(this.ws).send("bind_session\r\n" + req.getEncodedString());
	}
	,sendDestroyWS: function(cause) {
		if(cause == null) {
			cause = "api";
		}
		var req = new com_lightstreamer_internal_RequestBuilder();
		req.LS_reqId(this.generateFreshReqId());
		req.LS_op("destroy");
		req.LS_close_socket(true);
		req.LS_cause(cause);
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending session destroy: " + Std.string(req));
		}
		com_lightstreamer_internal_NullTools.sure(this.ws).send("control\r\n" + req.getEncodedString());
	}
	,sendHttpRequest: function(url,req,headers) {
		var _gthis = this;
		var onText = function(client,line) {
			com_lightstreamer_internal_Timers__setImmediate(function() {
				_gthis.lock.synchronized(function() {
					if(client.isDisposed()) {
						return;
					}
					try {
						_gthis.evtMessage(line);
					} catch( _g ) {
						var _g1 = haxe_Exception.caught(_g);
						if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
							com_lightstreamer_log_LoggerTools_sessionLogger.error("" + _g1.get_message() + "\n" + _g1.details());
						}
						_gthis.evtExtDisconnect(com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_standardError(61,_g1.get_message()));
					}
				});
			});
		};
		var tmp = function(client,error) {
			com_lightstreamer_internal_Timers__setImmediate(function() {
				_gthis.lock.synchronized(function() {
					if(client.isDisposed()) {
						return;
					}
					_gthis.evtTransportError();
				});
			});
		};
		return this.httpFactory(url,req.getEncodedString(),headers,onText,tmp,function(client) {
		});
	}
	,sendCreateHTTP: function() {
		var req = new com_lightstreamer_internal_RequestBuilder();
		req.LS_polling(true);
		req.LS_polling_millis(0);
		req.LS_idle_millis(0);
		this.bw_requestedMaxBandwidth = this.options.requestedMaxBandwidth;
		var _g = this.bw_requestedMaxBandwidth;
		if(_g != null) {
			if(_g._hx_index == 0) {
				req.LS_requested_max_bandwidth_Float(_g.bw);
			}
		}
		if(this.details.adapterSet != null) {
			req.LS_adapter_set(this.details.adapterSet);
		}
		if(this.details.user != null) {
			req.LS_user(this.details.user);
		}
		req.LS_cid(com_lightstreamer_internal_Constants_LS_CID);
		if(this.sessionId != null) {
			req.LS_old_session(this.sessionId);
		}
		if(this.cause != null) {
			req.LS_cause(this.cause);
			this.cause = null;
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending session create: " + Std.string(req));
		}
		if(this.details.password != null) {
			req.LS_password(this.details.password);
		}
		this.connectTs = HxOverrides.now() / 1000;
		this.serverInstanceAddress = this.getServerAddress();
		this.http = this.sendHttpRequest(com_lightstreamer_internal_Url.build(this.serverInstanceAddress,"/lightstreamer" + com_lightstreamer_internal_Constants_LS_CREATE_REALM + "/create_session.txt?LS_protocol=" + com_lightstreamer_internal_Constants_TLCP_VERSION),req,this.options.httpExtraHeaders);
	}
	,sendBindHTTP_Streaming: function() {
		var req = new com_lightstreamer_internal_RequestBuilder();
		req.LS_session(com_lightstreamer_internal_NullTools.sure(this.sessionId));
		req.LS_content_length(this.options.contentLength);
		if(this.options.keepaliveInterval > 0) {
			req.LS_keepalive_millis(this.options.keepaliveInterval);
		}
		this.rhb_grantedInterval = this.options.reverseHeartbeatInterval;
		if(this.rhb_grantedInterval > 0) {
			req.LS_inactivity_millis(this.rhb_grantedInterval);
		}
		if(!this.options.slowingEnabled) {
			req.LS_send_sync(false);
		}
		if(this.cause != null) {
			req.LS_cause(this.cause);
			this.cause = null;
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending session bind: " + Std.string(req));
		}
		this.connectTs = HxOverrides.now() / 1000;
		this.http = this.sendHttpRequest(com_lightstreamer_internal_Url.build(com_lightstreamer_internal_NullTools.sure(this.serverInstanceAddress),"/lightstreamer/bind_session.txt?LS_protocol=" + com_lightstreamer_internal_Constants_TLCP_VERSION),req,this.getHeadersForRequestOtherThanCreate());
	}
	,sendBindHTTP_Polling: function() {
		var req = new com_lightstreamer_internal_RequestBuilder();
		req.LS_session(com_lightstreamer_internal_NullTools.sure(this.sessionId));
		req.LS_polling(true);
		req.LS_polling_millis(this.options.pollingInterval);
		this.idleTimeout = this.options.idleTimeout;
		req.LS_idle_millis(this.idleTimeout);
		if(this.cause != null) {
			req.LS_cause(this.cause);
			this.cause = null;
		}
		this.rhb_grantedInterval = 0;
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending session bind: " + Std.string(req));
		}
		this.connectTs = HxOverrides.now() / 1000;
		this.http = this.sendHttpRequest(com_lightstreamer_internal_Url.build(com_lightstreamer_internal_NullTools.sure(this.serverInstanceAddress),"/lightstreamer/bind_session.txt?LS_protocol=" + com_lightstreamer_internal_Constants_TLCP_VERSION),req,this.getHeadersForRequestOtherThanCreate());
	}
	,sendCreateTTL: function() {
		var req = new com_lightstreamer_internal_RequestBuilder();
		req.LS_ttl_millis("unlimited");
		req.LS_polling(true);
		req.LS_polling_millis(0);
		req.LS_idle_millis(0);
		this.bw_requestedMaxBandwidth = this.options.requestedMaxBandwidth;
		var _g = this.bw_requestedMaxBandwidth;
		if(_g != null) {
			if(_g._hx_index == 0) {
				req.LS_requested_max_bandwidth_Float(_g.bw);
			}
		}
		if(this.details.adapterSet != null) {
			req.LS_adapter_set(this.details.adapterSet);
		}
		if(this.details.user != null) {
			req.LS_user(this.details.user);
		}
		req.LS_cid(com_lightstreamer_internal_Constants_LS_CID);
		if(this.sessionId != null) {
			req.LS_old_session(this.sessionId);
		}
		if(this.cause != null) {
			req.LS_cause(this.cause);
			this.cause = null;
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending session create: " + Std.string(req));
		}
		if(this.details.password != null) {
			req.LS_password(this.details.password);
		}
		this.connectTs = HxOverrides.now() / 1000;
		this.serverInstanceAddress = this.getServerAddress();
		this.http = this.sendHttpRequest(com_lightstreamer_internal_Url.build(this.serverInstanceAddress,"/lightstreamer" + com_lightstreamer_internal_Constants_LS_CREATE_REALM + "/create_session.txt?LS_protocol=" + com_lightstreamer_internal_Constants_TLCP_VERSION),req,this.options.httpExtraHeaders);
	}
	,sendRecovery: function() {
		var req = new com_lightstreamer_internal_RequestBuilder();
		req.LS_session(com_lightstreamer_internal_NullTools.sure(this.sessionId));
		req.LS_recovery_from(this.rec_clientProg);
		req.LS_polling(true);
		req.LS_polling_millis(0);
		req.LS_idle_millis(0);
		if(this.cause != null) {
			req.LS_cause(this.cause);
			this.cause = null;
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending session recovery: " + Std.string(req));
		}
		this.connectTs = HxOverrides.now() / 1000;
		this.http = this.sendHttpRequest(com_lightstreamer_internal_Url.build(com_lightstreamer_internal_NullTools.sure(this.serverInstanceAddress),"/lightstreamer/bind_session.txt?LS_protocol=" + com_lightstreamer_internal_Constants_TLCP_VERSION),req,this.getHeadersForRequestOtherThanCreate());
	}
	,disposeWS: function() {
		if(this.ws != null) {
			this.ws.dispose();
			this.ws = null;
		}
	}
	,closeWS: function() {
		if(this.ws != null) {
			this.ws.dispose();
			this.ws = null;
		}
	}
	,suspendWS_Streaming: function() {
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_sessionLogger.warn("Websocket suspended");
		}
		this.suspendedTransports.insert("WS-STREAMING");
	}
	,disableWS: function() {
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_sessionLogger.warn("Websocket disabled");
		}
		this.disabledTransports = this.disabledTransports.union(["WS-STREAMING","WS-POLLING"]);
	}
	,disableHTTP_Streaming: function() {
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_sessionLogger.warn("HTTP streaming disabled");
		}
		this.disabledTransports = this.disabledTransports.union(["HTTP-STREAMING"]);
	}
	,disableStreaming: function() {
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_sessionLogger.warn("Streaming disabled");
		}
		this.disabledTransports = this.disabledTransports.union(["WS-STREAMING","HTTP-STREAMING"]);
	}
	,enableAllTransports: function() {
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isInfoEnabled()) {
			if(this.disabledTransports.count() > 0 || this.suspendedTransports.count() > 0) {
				if(com_lightstreamer_log_LoggerTools_sessionLogger.isInfoEnabled()) {
					com_lightstreamer_log_LoggerTools_sessionLogger.info("Transports enabled again.");
				}
			}
		}
		this.disabledTransports = new com_lightstreamer_internal_Set();
		this.suspendedTransports = new com_lightstreamer_internal_Set();
	}
	,disposeHTTP: function() {
		if(this.http != null) {
			this.http.dispose();
			this.http = null;
		}
	}
	,closeHTTP: function() {
		if(this.http != null) {
			this.http.dispose();
			this.http = null;
		}
	}
	,disposeCtrl: function() {
		if(this.ctrl_http != null) {
			this.ctrl_http.dispose();
			this.ctrl_http = null;
		}
	}
	,closeCtrl: function() {
		if(this.ctrl_http != null) {
			this.ctrl_http.dispose();
			this.ctrl_http = null;
		}
	}
	,notifyStatus: function(newStatus) {
		var oldStatus = this.m_status;
		this.m_status = newStatus;
		if(oldStatus != newStatus) {
			if(com_lightstreamer_log_LoggerTools_sessionLogger.isInfoEnabled()) {
				com_lightstreamer_log_LoggerTools_sessionLogger.info("Status: " + newStatus);
			}
			this.clientEventDispatcher.onStatusChange(newStatus);
		}
	}
	,getBestForCreating: function() {
		if(this.getBestForBinding()._hx_index == 0) {
			return com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_none;
		}
		var ft = this.options.forcedTransport;
		var blackList = this.suspendedTransports.union(this.disabledTransports.toArray());
		var headers = this.options.httpExtraHeaders;
		var noHeaders = headers == null || Lambda.empty(headers);
		if(ft == null) {
			if(noHeaders) {
				if(!blackList.contains("WS-STREAMING")) {
					return com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_ws;
				} else {
					return com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_http;
				}
			} else {
				return com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_http;
			}
		} else {
			switch(ft) {
			case "WS":case "WS-STREAMING":
				if(noHeaders) {
					if(!blackList.contains("WS-STREAMING")) {
						return com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_ws;
					} else {
						return com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_http;
					}
				} else {
					return com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_http;
				}
				break;
			default:
				return com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_http;
			}
		}
	}
	,getBestForBinding: function() {
		var ft = this.options.forcedTransport;
		var blackList = this.disabledTransports;
		var headers = this.options.httpExtraHeaders;
		var noHeaders = headers == null || Lambda.empty(headers);
		var sendHeadersOnCreationOnly = this.options.httpExtraHeadersOnSessionCreationOnly;
		if(ft == null) {
			if(noHeaders) {
				if(!blackList.contains("WS-STREAMING")) {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_streaming;
				} else if(!blackList.contains("HTTP-STREAMING")) {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_streaming;
				} else if(!blackList.contains("WS-POLLING")) {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_polling;
				} else {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_polling;
				}
			} else if(sendHeadersOnCreationOnly) {
				if(!blackList.contains("WS-STREAMING")) {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_streaming;
				} else if(!blackList.contains("HTTP-STREAMING")) {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_streaming;
				} else if(!blackList.contains("WS-POLLING")) {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_polling;
				} else {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_polling;
				}
			} else if(!blackList.contains("HTTP-STREAMING")) {
				return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_streaming;
			} else {
				return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_polling;
			}
		} else {
			switch(ft) {
			case "HTTP":
				if(!blackList.contains("HTTP-STREAMING")) {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_streaming;
				} else {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_polling;
				}
				break;
			case "HTTP-POLLING":
				return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_polling;
			case "HTTP-STREAMING":
				if(!blackList.contains("HTTP-STREAMING")) {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_streaming;
				} else {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none;
				}
				break;
			case "WS":
				if(noHeaders) {
					if(!blackList.contains("WS-STREAMING")) {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_streaming;
					} else if(!blackList.contains("WS-POLLING")) {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_polling;
					} else {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none;
					}
				} else if(sendHeadersOnCreationOnly) {
					if(!blackList.contains("WS-STREAMING")) {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_streaming;
					} else if(!blackList.contains("WS-POLLING")) {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_polling;
					} else {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none;
					}
				} else {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none;
				}
				break;
			case "WS-POLLING":
				if(noHeaders) {
					if(!blackList.contains("WS-POLLING")) {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_polling;
					} else {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none;
					}
				} else if(sendHeadersOnCreationOnly) {
					if(!blackList.contains("WS-POLLING")) {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_polling;
					} else {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none;
					}
				} else {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none;
				}
				break;
			case "WS-STREAMING":
				if(noHeaders) {
					if(!blackList.contains("WS-STREAMING")) {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_streaming;
					} else {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none;
					}
				} else if(sendHeadersOnCreationOnly) {
					if(!blackList.contains("WS-STREAMING")) {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_streaming;
					} else {
						return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none;
					}
				} else {
					return com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none;
				}
				break;
			}
		}
	}
	,resetCurrentRetryDelay: function() {
		this.delayCounter.reset(this.options.retryDelay);
	}
	,notifyServerErrorIfCauseIsError: function(terminationCause) {
		switch(terminationCause._hx_index) {
		case 0:
			this.clientEventDispatcher.onServerError(terminationCause.code,terminationCause.msg);
			break;
		case 1:
			this.clientEventDispatcher.onServerError(61,terminationCause.error);
			break;
		case 2:
			break;
		}
	}
	,notifyServerError_CONERR: function(code,msg) {
		this.clientEventDispatcher.onServerError(code,msg);
	}
	,notifyServerError_END: function(code,msg) {
		if(0 < code && code < 30 || code > 39) {
			this.clientEventDispatcher.onServerError(39,msg);
		} else {
			this.clientEventDispatcher.onServerError(code,msg);
		}
	}
	,notifyServerError_ERROR: function(code,msg) {
		this.clientEventDispatcher.onServerError(code,msg);
	}
	,notifyServerError_REQERR: function(code,msg) {
		if(code == 11) {
			this.clientEventDispatcher.onServerError(21,msg);
		} else {
			this.clientEventDispatcher.onServerError(code,msg);
		}
	}
	,doCONOK: function(sessionId,reqLimit,keepalive,idleTimeout,clink) {
		this.sessionId = sessionId;
		this.details.setSessionId(sessionId);
		this.requestLimit = reqLimit;
		if(keepalive != null) {
			this.keepaliveInterval = keepalive;
			this.options.setKeepaliveInterval(keepalive);
		} else if(idleTimeout != null) {
			this.idleTimeout = idleTimeout;
			this.options.setIdleTimeout(idleTimeout);
		}
		if(clink != "*" && !this.options.serverInstanceAddressIgnored) {
			clink = com_lightstreamer_internal_Url.completeControlLink(clink,this.getServerAddress());
			this.serverInstanceAddress = clink;
			this.details.setServerInstanceAddress(clink);
		}
	}
	,doCONOK_CreateWS: function(sessionId,reqLimit,keepalive,clink) {
		this.doCONOK(sessionId,reqLimit,keepalive,null,clink);
	}
	,doCONOK_BindWS_Streaming: function(sessionId,reqLimit,keepalive,clink) {
		this.doCONOK(sessionId,reqLimit,keepalive,null,clink);
	}
	,doCONOK_BindWS_Polling: function(sessionId,reqLimit,idleTimeout,clink) {
		this.doCONOK(sessionId,reqLimit,null,idleTimeout,clink);
	}
	,doCONOK_CreateHTTP: function(sessionId,reqLimit,keepalive,clink) {
		this.doCONOK(sessionId,reqLimit,null,null,clink);
	}
	,doCONOK_BindHTTP_Streaming: function(sessionId,reqLimit,keepalive,clink) {
		this.doCONOK(sessionId,reqLimit,keepalive,null,clink);
	}
	,doCONOK_BindHTTP_Polling: function(sessionId,reqLimit,idleTimeout,clink) {
		this.doCONOK(sessionId,reqLimit,null,idleTimeout,clink);
	}
	,doSERVNAME: function(serverName) {
		this.details.setServerSocketName(serverName);
	}
	,doCLIENTIP: function(clientIp) {
		this.details.setClientIp(clientIp);
		var lastIp = this.lastKnownClientIp;
		if(lastIp != null && lastIp != clientIp) {
			if(com_lightstreamer_log_LoggerTools_sessionLogger.isInfoEnabled()) {
				com_lightstreamer_log_LoggerTools_sessionLogger.info("Client IP changed: " + lastIp + " -> " + clientIp);
			}
			this.enableAllTransports();
		}
		this.lastKnownClientIp = clientIp;
	}
	,doCONS: function(bandwidth) {
		this.options.setRealMaxBandwidth(bandwidth);
	}
	,doLOOP: function(pollingMs) {
		this.options.setPollingInterval(pollingMs);
	}
	,doPROG: function(prog) {
		if(prog > this.rec_clientProg) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "prog <= rec_clientProg");
		}
		this.rec_serverProg = prog;
	}
	,doMSGDONE: function(sequence,prog,response) {
		this.onFreshData();
		var messages = this.messageManagers.filter(function(msg) {
			if(msg.sequence == sequence) {
				return msg.prog == prog;
			} else {
				return false;
			}
		});
		if(messages.values.length - messages.nRemoved > 1) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "messages.length <= 1");
		}
		var msg = new com_lightstreamer_internal__$MyArray_MyArrayIterator(messages);
		while(msg.hasNext()) msg.next().evtMSGDONE(response);
	}
	,doMSGFAIL: function(sequence,prog,errorCode,errorMsg) {
		this.onFreshData();
		if(errorCode == 39) {
			var count = Std.parseInt(errorMsg);
			if(count == null) {
				throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "count != null");
			}
			var _g = prog - count + 1;
			var _g1 = prog + 1;
			while(_g < _g1) {
				var messages = this.messageManagers.filter((function(p) {
					return function(msg) {
						if(msg.sequence == sequence) {
							return msg.prog == p[0];
						} else {
							return false;
						}
					};
				})([_g++]));
				if(messages.values.length - messages.nRemoved > 1) {
					throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "messages.length <= 1");
				}
				var msg = new com_lightstreamer_internal__$MyArray_MyArrayIterator(messages);
				while(msg.hasNext()) msg.next().evtMSGFAIL(errorCode,errorMsg);
			}
		} else {
			var messages = this.messageManagers.filter(function(msg) {
				if(msg.sequence == sequence) {
					return msg.prog == prog;
				} else {
					return false;
				}
			});
			if(messages.values.length - messages.nRemoved > 1) {
				throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "messages.length <= 1");
			}
			var msg = new com_lightstreamer_internal__$MyArray_MyArrayIterator(messages);
			while(msg.hasNext()) msg.next().evtMSGFAIL(errorCode,errorMsg);
		}
	}
	,doU: function(subId,itemIdx,values) {
		this.onFreshData();
		var sub = this.subscriptionManagers._innerMap.h[subId];
		if(sub != null) {
			sub.evtU(itemIdx,values);
		} else {
			new com_lightstreamer_client_internal_SubscriptionManagerZombie(subId,this).evtU(itemIdx,values);
		}
	}
	,doSUBOK: function(subId,nItems,nFields) {
		this.onFreshData();
		var sub = this.subscriptionManagers._innerMap.h[subId];
		if(sub != null) {
			sub.evtSUBOK(nItems,nFields);
		} else {
			new com_lightstreamer_client_internal_SubscriptionManagerZombie(subId,this).evtSUBOK(nItems,nFields);
		}
	}
	,doSUBCMD: function(subId,nItems,nFields,keyIdx,cmdIdx) {
		this.onFreshData();
		var sub = this.subscriptionManagers._innerMap.h[subId];
		if(sub != null) {
			sub.evtSUBCMD(nItems,nFields,keyIdx,cmdIdx);
		} else {
			new com_lightstreamer_client_internal_SubscriptionManagerZombie(subId,this).evtSUBCMD(nItems,nFields,keyIdx,cmdIdx);
		}
	}
	,doUNSUB: function(subId) {
		this.onFreshData();
		var sub = this.subscriptionManagers._innerMap.h[subId];
		if(sub != null) {
			sub.evtUNSUB();
		}
	}
	,doEOS: function(subId,itemIdx) {
		this.onFreshData();
		var sub = this.subscriptionManagers._innerMap.h[subId];
		if(sub != null) {
			sub.evtEOS(itemIdx);
		} else {
			new com_lightstreamer_client_internal_SubscriptionManagerZombie(subId,this).evtEOS(itemIdx);
		}
	}
	,doCS: function(subId,itemIdx) {
		this.onFreshData();
		var sub = this.subscriptionManagers._innerMap.h[subId];
		if(sub != null) {
			sub.evtCS(itemIdx);
		} else {
			new com_lightstreamer_client_internal_SubscriptionManagerZombie(subId,this).evtCS(itemIdx);
		}
	}
	,doOV: function(subId,itemIdx,lostUpdates) {
		this.onFreshData();
		var sub = this.subscriptionManagers._innerMap.h[subId];
		if(sub != null) {
			sub.evtOV(itemIdx,lostUpdates);
		} else {
			new com_lightstreamer_client_internal_SubscriptionManagerZombie(subId,this).evtOV(itemIdx,lostUpdates);
		}
	}
	,doCONF: function(subId,freq) {
		this.onFreshData();
		var sub = this.subscriptionManagers._innerMap.h[subId];
		if(sub != null) {
			sub.evtCONF(freq);
		} else {
			new com_lightstreamer_client_internal_SubscriptionManagerZombie(subId,this).evtCONF(freq);
		}
	}
	,doREQOK: function(reqId) {
		var map = this.subscriptionManagers;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) map.get(_g_keys.next()).evtREQOK(reqId);
		this.subscriptionManagers._orderedKeys.compact();
		var msg = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this.messageManagers);
		while(msg.hasNext()) msg.next().evtREQOK(reqId);
		this.messageManagers.compact();
	}
	,doREQERR: function(reqId,errorCode,errorMsg) {
		var map = this.subscriptionManagers;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) map.get(_g_keys.next()).evtREQERR(reqId,errorCode,errorMsg);
		this.subscriptionManagers._orderedKeys.compact();
		var msg = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this.messageManagers);
		while(msg.hasNext()) msg.next().evtREQERR(reqId,errorCode,errorMsg);
		this.messageManagers.compact();
	}
	,doSYNC: function(syncMs) {
		this.slw_refTime = HxOverrides.now() / 1000;
		this.slw_avgDelayMs = -syncMs;
	}
	,doSYNC_G: function(syncMs) {
		var diffTime = this.diffTimeSync(syncMs);
		if(diffTime > this.slw_hugeDelayMs && diffTime > this.slw_avgDelayMs * 2) {
			if(this.slw_avgDelayMs > this.slw_maxAvgDelayMs && this.options.slowingEnabled) {
				return com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_bad;
			} else {
				return com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_not_good;
			}
		} else {
			this.slw_avgDelayMs = this.slowAvg(diffTime);
			if(this.slw_avgDelayMs > this.slw_maxAvgDelayMs && this.options.slowingEnabled) {
				return com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_bad;
			} else {
				if(this.slw_avgDelayMs < 60) {
					this.slw_avgDelayMs = 0;
				}
				return com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_good;
			}
		}
	}
	,doSYNC_NG: function(syncMs) {
		var diffTime = this.diffTimeSync(syncMs);
		if(diffTime > this.slw_hugeDelayMs && diffTime > this.slw_avgDelayMs * 2) {
			this.slw_avgDelayMs = this.slowAvg(diffTime);
			if(this.slw_avgDelayMs > this.slw_maxAvgDelayMs && this.options.slowingEnabled) {
				return com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_bad;
			} else {
				if(this.slw_avgDelayMs < 60) {
					this.slw_avgDelayMs = 0;
				}
				return com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_good;
			}
		} else {
			this.slw_avgDelayMs = this.slowAvg(diffTime);
			if(this.slw_avgDelayMs > this.slw_maxAvgDelayMs && this.options.slowingEnabled) {
				return com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_bad;
			} else {
				if(this.slw_avgDelayMs < 60) {
					this.slw_avgDelayMs = 0;
				}
				return com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_not_good;
			}
		}
	}
	,diffTimeSync: function(syncMs) {
		return com_lightstreamer_internal_TimerStamp.minus(HxOverrides.now() / 1000,this.slw_refTime) - syncMs;
	}
	,slowAvg: function(diffTime) {
		return this.slw_avgDelayMs * this.slw_m + diffTime * (1.0 - this.slw_m);
	}
	,schedule_evtTransportTimeout: function(timeout) {
		this.transportTimer = this.createTimer("transport.timeout",timeout,$bind(this,this.evtTransportTimeout));
	}
	,schedule_evtRetryTimeout: function(timeout) {
		this.retryTimer = this.createTimer("retry.timeout",timeout,$bind(this,this.evtRetryTimeout));
	}
	,schedule_evtRecoveryTimeout: function(timeout) {
		this.recoveryTimer = this.createTimer("recovery.timeout",timeout,$bind(this,this.evtRecoveryTimeout));
	}
	,schedule_evtIdleTimeout: function(timeout) {
		this.idleTimer = this.createTimer("idle.timeout",timeout,$bind(this,this.evtIdleTimeout));
	}
	,schedule_evtPollingTimeout: function(timeout) {
		this.pollingTimer = this.createTimer("polling.timeout",timeout,$bind(this,this.evtPollingTimeout));
	}
	,schedule_evtCtrlTimeout: function(timeout) {
		this.ctrlTimer = this.createTimer("ctrl.timeout",timeout,$bind(this,this.evtCtrlTimeout));
	}
	,schedule_evtKeepaliveTimeout: function(timeout) {
		this.keepaliveTimer = this.createTimer("keepalive.timeout",timeout,$bind(this,this.evtKeepaliveTimeout));
	}
	,schedule_evtStalledTimeout: function(timeout) {
		this.stalledTimer = this.createTimer("stalled.timeout",timeout,$bind(this,this.evtStalledTimeout));
	}
	,schedule_evtReconnectTimeout: function(timeout) {
		this.reconnectTimer = this.createTimer("reconnect.timeout",timeout,$bind(this,this.evtReconnectTimeout));
	}
	,schedule_evtRhbTimeout: function(timeout) {
		this.rhbTimer = this.createTimer("rhb.timeout",timeout,$bind(this,this.evtRhbTimeout));
	}
	,createTimer: function(id,timeout,callback) {
		var _gthis = this;
		return this.timerFactory(id,timeout,function(timer) {
			_gthis.lock.synchronized(function() {
				if(timer.isCanceled()) {
					return;
				}
				callback();
			});
		});
	}
	,cancel_evtTransportTimeout: function() {
		if(this.transportTimer != null) {
			this.transportTimer.cancel();
			this.transportTimer = null;
		}
	}
	,cancel_evtRetryTimeout: function() {
		if(this.retryTimer != null) {
			this.retryTimer.cancel();
			this.retryTimer = null;
		}
	}
	,cancel_evtKeepaliveTimeout: function() {
		if(this.keepaliveTimer != null) {
			this.keepaliveTimer.cancel();
			this.keepaliveTimer = null;
		}
	}
	,cancel_evtStalledTimeout: function() {
		if(this.stalledTimer != null) {
			this.stalledTimer.cancel();
			this.stalledTimer = null;
		}
	}
	,cancel_evtReconnectTimeout: function() {
		if(this.reconnectTimer != null) {
			this.reconnectTimer.cancel();
			this.reconnectTimer = null;
		}
	}
	,cancel_evtRhbTimeout: function() {
		if(this.rhbTimer != null) {
			this.rhbTimer.cancel();
			this.rhbTimer = null;
		}
	}
	,cancel_evtIdleTimeout: function() {
		if(this.idleTimer != null) {
			this.idleTimer.cancel();
			this.idleTimer = null;
		}
	}
	,cancel_evtPollingTimeout: function() {
		if(this.pollingTimer != null) {
			this.pollingTimer.cancel();
			this.pollingTimer = null;
		}
	}
	,cancel_evtCtrlTimeout: function() {
		if(this.ctrlTimer != null) {
			this.ctrlTimer.cancel();
			this.ctrlTimer = null;
		}
	}
	,cancel_evtRecoveryTimeout: function() {
		if(this.recoveryTimer != null) {
			this.recoveryTimer.cancel();
			this.recoveryTimer = null;
		}
	}
	,waitingInterval: function(expectedMs,startTime) {
		var diffMs = com_lightstreamer_internal_TimerStamp.minus(HxOverrides.now() / 1000,startTime);
		var expected = expectedMs;
		if(diffMs < expected) {
			return expected - diffMs;
		} else {
			return 0;
		}
	}
	,exit_tr: function() {
		this.evtEndSession();
	}
	,entry_m111: function(retryCause,timeout) {
		this.evtRetry(retryCause,timeout);
		this.schedule_evtRetryTimeout(timeout);
	}
	,entry_m112: function(retryCause) {
		var pauseMs = this.waitingInterval(this.delayCounter.get_currentRetryDelay(),this.connectTs);
		this.evtRetry(retryCause,pauseMs);
		this.schedule_evtRetryTimeout(pauseMs);
	}
	,entry_m113: function(retryCause) {
		var pauseMs = this.randomPause(this.options.firstRetryMaxDelay);
		this.evtRetry(retryCause,pauseMs);
		this.schedule_evtRetryTimeout(pauseMs);
	}
	,entry_m115: function(retryCause) {
		this.evtRetry(retryCause);
		this.evtRetryTimeout();
	}
	,entry_rec: function(pause,retryCause) {
		if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
			com_lightstreamer_log_LoggerTools_sessionLogger.error("Recovering connection in " + pause + " ms. Cause: " + com_lightstreamer_client_internal_ClientMachine_asErrorMsg(retryCause));
		}
		this.evtStartRecovery();
		this.schedule_evtRecoveryTimeout(pause);
	}
	,exit_w: function() {
		this.cancel_evtKeepaliveTimeout();
		this.cancel_evtStalledTimeout();
		this.cancel_evtReconnectTimeout();
		this.cancel_evtRhbTimeout();
	}
	,exit_ws: function() {
		this.cancel_evtTransportTimeout();
		this.cancel_evtKeepaliveTimeout();
		this.cancel_evtStalledTimeout();
		this.cancel_evtReconnectTimeout();
		this.cancel_evtRhbTimeout();
	}
	,exit_wp: function() {
		this.cancel_evtTransportTimeout();
		this.cancel_evtIdleTimeout();
		this.cancel_evtPollingTimeout();
	}
	,exit_hs: function() {
		this.cancel_evtTransportTimeout();
		this.cancel_evtKeepaliveTimeout();
		this.cancel_evtStalledTimeout();
		this.cancel_evtReconnectTimeout();
		this.cancel_evtRhbTimeout();
	}
	,exit_hp: function() {
		this.cancel_evtIdleTimeout();
		this.cancel_evtPollingTimeout();
		this.cancel_evtRhbTimeout();
	}
	,exit_ctrl: function() {
		this.cancel_evtCtrlTimeout();
		this.evtDisposeCtrl();
	}
	,exit_rec: function() {
		this.cancel_evtRecoveryTimeout();
		this.cancel_evtTransportTimeout();
		this.cancel_evtRetryTimeout();
	}
	,exit_keepalive_unit: function() {
		this.cancel_evtKeepaliveTimeout();
		this.cancel_evtStalledTimeout();
		this.cancel_evtReconnectTimeout();
	}
	,exit_w_to_m: function() {
		this.exit_w();
		this.exit_tr();
	}
	,exit_ws_to_m: function() {
		this.exit_ws();
		this.exit_tr();
	}
	,exit_wp_to_m: function() {
		this.exit_wp();
		this.exit_tr();
	}
	,exit_hs_to_m: function() {
		this.exit_ctrl();
		this.exit_hs();
		this.exit_tr();
	}
	,exit_hs_to_rec: function() {
		this.exit_ctrl();
		this.exit_hs();
	}
	,exit_hp_to_m: function() {
		this.exit_ctrl();
		this.exit_hp();
		this.exit_tr();
	}
	,exit_hp_to_rec: function() {
		this.exit_ctrl();
		this.exit_hp();
	}
	,exit_ctrl_to_m: function() {
		this.exit_ctrl();
		this.exit_hs();
		this.exit_hp();
		this.exit_tr();
	}
	,exit_rec_to_m: function() {
		this.exit_rec();
		this.exit_tr();
	}
	,randomPause: function(maxPause) {
		return this.randomGenerator(maxPause);
	}
	,generateFreshReqId: function() {
		this.m_nextReqId += 1;
		return this.m_nextReqId;
	}
	,generateFreshSubId: function() {
		this.m_nextSubId += 1;
		return this.m_nextSubId;
	}
	,genAbortSubscriptions: function() {
		var map = this.subscriptionManagers;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) map.get(_g_keys.next()).evtExtAbort();
		this.subscriptionManagers._orderedKeys.compact();
	}
	,genAckMessagesWS: function() {
		var msg = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this.messageManagers.filter(function(msg) {
			return msg.isPending();
		}));
		while(msg.hasNext()) msg.next().evtWSSent();
	}
	,genAbortMessages: function() {
		var msg = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this.messageManagers);
		while(msg.hasNext()) msg.next().evtAbort();
		this.messageManagers.compact();
	}
	,resetSequenceMap: function() {
		this.sequenceMap.h = Object.create(null);
	}
	,isSwitching: function() {
		if(this.state.s_m == 150) {
			if(this.state.s_swt != 1302) {
				return this.state.s_swt == 1303;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}
	,encodeSwitch: function(isWS) {
		var req = new com_lightstreamer_internal_RequestBuilder();
		this.swt_lastReqId = this.generateFreshReqId();
		req.LS_reqId(this.swt_lastReqId);
		req.LS_op("force_rebind");
		if(isWS) {
			req.LS_close_socket(true);
		}
		if(this.cause != null) {
			req.LS_cause(this.cause);
			this.cause = null;
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending transport switch: " + Std.string(req));
		}
		return req.getEncodedString();
	}
	,encodeConstrain: function() {
		var req = new com_lightstreamer_internal_RequestBuilder();
		this.bw_lastReqId = this.generateFreshReqId();
		req.LS_reqId(this.bw_lastReqId);
		req.LS_op("constrain");
		var _g = com_lightstreamer_internal_NullTools.sure(this.bw_requestedMaxBandwidth);
		switch(_g._hx_index) {
		case 0:
			req.LS_requested_max_bandwidth_Float(_g.bw);
			break;
		case 1:
			req.LS_requested_max_bandwidth("unlimited");
			break;
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending bandwidth constrain: " + Std.string(req));
		}
		return req.getEncodedString();
	}
	,getPendingControls: function() {
		var res = [];
		if(com_lightstreamer_internal_NullTools.sure(this.switchRequest).isPending()) {
			res.push(com_lightstreamer_internal_NullTools.sure(this.switchRequest));
		}
		if(com_lightstreamer_internal_NullTools.sure(this.constrainRequest).isPending()) {
			res.push(com_lightstreamer_internal_NullTools.sure(this.constrainRequest));
		}
		var map = this.subscriptionManagers;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) {
			var _g_value = map.get(_g_keys.next());
			if(_g_value.isPending()) {
				res.push(_g_value);
			}
		}
		return res;
	}
	,sendControlWS: function(request) {
		com_lightstreamer_internal_NullTools.sure(this.ws).send(request.encodeWS());
	}
	,sendMsgWS: function(msg) {
		com_lightstreamer_internal_NullTools.sure(this.ws).send(msg.encodeWS());
	}
	,sendPengingControlsWS: function(pendings) {
		this.sendBatchWS(this.prepareBatchWS("control",pendings,com_lightstreamer_internal_NullTools.sure(this.requestLimit)));
	}
	,sendPendingMessagesWS: function() {
		var _g = [];
		var msg = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this.messageManagers);
		while(msg.hasNext()) {
			var msg1 = msg.next();
			if(msg1.isPending()) {
				_g.push(msg1);
			}
		}
		this.sendBatchWS(this.prepareBatchWS("msg",_g,com_lightstreamer_internal_NullTools.sure(this.requestLimit)));
	}
	,sendBatchWS: function(batches) {
		var _g = 0;
		while(_g < batches.length) {
			var batch = batches[_g++];
			com_lightstreamer_internal_NullTools.sure(this.ws).send(batch);
		}
	}
	,sendHeartbeatWS: function() {
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Heartbeat request");
		}
		com_lightstreamer_internal_NullTools.sure(this.ws).send("heartbeat\r\n\r\n");
	}
	,sendPendingControlsHTTP: function(pendings) {
		this.sendBatchHTTP(this.prepareBatchHTTP(pendings,com_lightstreamer_internal_NullTools.sure(this.requestLimit)),"control");
	}
	,sendPendingMessagesHTTP: function() {
		var _g = [];
		var msg = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this.messageManagers);
		while(msg.hasNext()) {
			var msg1 = msg.next();
			if(msg1.isPending()) {
				_g.push(msg1);
			}
		}
		this.sendBatchHTTP(this.prepareBatchHTTP(_g,com_lightstreamer_internal_NullTools.sure(this.requestLimit)),"msg");
	}
	,sendHeartbeatHTTP: function() {
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Heartbeat request");
		}
		this.sendBatchHTTP("\r\n","heartbeat");
	}
	,sendBatchHTTP: function(body,reqType) {
		var _gthis = this;
		this.ctrl_connectTs = HxOverrides.now() / 1000;
		var url = com_lightstreamer_internal_Url.build(com_lightstreamer_internal_NullTools.sure(this.serverInstanceAddress),"/lightstreamer/" + reqType + ".txt?LS_protocol=" + com_lightstreamer_internal_Constants_TLCP_VERSION + "&LS_session=" + this.sessionId);
		var headers = this.getHeadersForRequestOtherThanCreate();
		var tmp = function(client,line) {
			com_lightstreamer_internal_Timers__setImmediate(function() {
				_gthis.lock.synchronized(function() {
					if(client.isDisposed()) {
						return;
					}
					_gthis.evtCtrlMessage(line);
				});
			});
		};
		var tmp1 = function(client,error) {
			com_lightstreamer_internal_Timers__setImmediate(function() {
				_gthis.lock.synchronized(function() {
					if(client.isDisposed()) {
						return;
					}
					_gthis.evtCtrlError();
				});
			});
		};
		var tmp2 = function(client) {
			com_lightstreamer_internal_Timers__setImmediate(function() {
				_gthis.lock.synchronized(function() {
					if(client.isDisposed()) {
						return;
					}
					_gthis.evtCtrlDone();
				});
			});
		};
		this.ctrl_http = this.ctrlFactory(url,body,headers,tmp,tmp1,tmp2);
	}
	,prepareBatchWS: function(reqType,pendings,requestLimit) {
		if(pendings.length <= 0) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "pendings.length > 0");
		}
		var out = [];
		var i = 0;
		var subReq = pendings[0].encode(true);
		while(i < pendings.length) {
			var mainReq = new com_lightstreamer_client_internal_Request();
			mainReq.addSubRequest(reqType);
			mainReq.addSubRequest(subReq);
			++i;
			while(i < pendings.length) {
				subReq = pendings[i].encode(true);
				if(mainReq.addSubRequestOnlyIfBodyIsLessThan(subReq,requestLimit)) {
					++i;
				} else {
					break;
				}
			}
			out.push(mainReq.getBody());
		}
		return out;
	}
	,prepareBatchHTTP: function(pendings,requestLimit) {
		if(pendings.length <= 0) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "pendings.length > 0");
		}
		var mainReq = new com_lightstreamer_client_internal_Request();
		var i = 0;
		var subReq = pendings[0].encode(false);
		mainReq.addSubRequest(subReq);
		i = 1;
		while(i < pendings.length) {
			subReq = pendings[i].encode(false);
			if(mainReq.addSubRequestOnlyIfBodyIsLessThan(subReq,requestLimit)) {
				++i;
			} else {
				break;
			}
		}
		return mainReq.getBody();
	}
	,getHeadersForRequestOtherThanCreate: function() {
		if(this.options.httpExtraHeadersOnSessionCreationOnly) {
			return null;
		} else {
			return this.options.httpExtraHeaders;
		}
	}
	,getServerAddress: function() {
		var addr = this.details.serverAddress;
		if(addr != null) {
			return addr;
		} else {
			return com_lightstreamer_internal_NullTools.sure(this.defaultServerAddress);
		}
	}
	,relateSubManager: function(subManager) {
		if(this.subscriptionManagers._innerMap.h[subManager.subId] != null) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "subscriptionManagers[subManager.subId] == null");
		}
		this.subscriptionManagers.set(subManager.subId,subManager);
	}
	,unrelateSubManager: function(subManager) {
		this.subscriptionManagers.remove(subManager.subId);
	}
	,isRelatedWithSubManager: function(subManager) {
		return Lambda.has(this.subscriptionManagers,subManager);
	}
	,relateMsgManager: function(msgManager) {
		this.messageManagers.values.push(new com_lightstreamer_internal__$MyArray_Pair(msgManager));
	}
	,unrelateMsgManager: function(msgManager) {
		this.messageManagers.remove(msgManager);
	}
	,getAndSetNextMsgProg: function(sequence) {
		var prog = this.sequenceMap.h[sequence];
		if(prog == null) {
			prog = 1;
		}
		this.sequenceMap.h[sequence] = prog + 1;
		return prog;
	}
	,onPropertyChange: function(property) {
		this.clientEventDispatcher.onPropertyChange(property);
	}
	,traceEvent: function(event) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("event: " + event + " " + this.state.toString());
		}
	}
	,connect: function() {
		var serverAddress = this.details.getServerAddress();
		if(serverAddress == null) {
			throw new com_lightstreamer_internal_IllegalStateException("Configure the server address before trying to connect");
		}
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("Connection requested: details: " + Std.string(this.details) + " options: " + Std.string(this.options) + " env: " + Std.string(com_lightstreamer_internal_Globals.instance));
		}
		this.defaultServerAddress = serverAddress;
		this.evtExtConnect();
	}
	,disconnect: function() {
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("Disconnection requested");
		}
		this.evtExtDisconnect(com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_api);
	}
	,getStatus: function() {
		return this.m_status;
	}
	,sendMessage: function(message,sequence,delayTimeout,listener,enqueueWhileDisconnected) {
		if(!enqueueWhileDisconnected && (this.state.inDisconnected() || this.state.inRetryUnit())) {
			if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
				var _g = new haxe_ds_StringMap();
				_g.h["text"] = message;
				_g.h["sequence"] = sequence != null ? sequence : "UNORDERED_MESSAGES";
				_g.h["timeout"] = delayTimeout == null ? "null" : "" + delayTimeout;
				_g.h["enqueueWhileDisconnected"] = enqueueWhileDisconnected == null ? "null" : "" + enqueueWhileDisconnected;
				if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
					com_lightstreamer_log_LoggerTools_actionLogger.info("Message sending requested: " + (_g == null ? "null" : haxe_ds_StringMap.stringify(_g.h)));
				}
			}
			if(com_lightstreamer_log_LoggerTools_messageLogger.isWarnEnabled()) {
				com_lightstreamer_log_LoggerTools_messageLogger.warn("Message " + (sequence != null ? sequence : "UNORDERED_MESSAGES") + " " + message + " aborted");
			}
			if(listener != null) {
				var dispatcher = new com_lightstreamer_client_internal_MessageEventDispatcher();
				dispatcher.addListener(listener);
				dispatcher.onAbort(message,false);
			}
			return;
		}
		if(sequence != null) {
			if(!new EReg("^[a-zA-Z0-9_]*$","").match(sequence)) {
				throw new com_lightstreamer_internal_IllegalArgumentException("The given sequence name is not valid. Use only alphanumeric characters plus underscore or null");
			}
			var msg = new com_lightstreamer_client_internal_MessageManager(message,sequence,delayTimeout,listener,enqueueWhileDisconnected,this);
			if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
				com_lightstreamer_log_LoggerTools_actionLogger.info("Message sending requested: " + Std.string(msg));
			}
			msg.evtExtSendMessage();
		} else {
			var msg = new com_lightstreamer_client_internal_MessageManager(message,"UNORDERED_MESSAGES",delayTimeout,listener,enqueueWhileDisconnected,this);
			if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
				com_lightstreamer_log_LoggerTools_actionLogger.info("Message sending requested: " + Std.string(msg));
			}
			msg.evtExtSendMessage();
		}
	}
	,subscribeExt: function(subscription,isInternal) {
		if(isInternal == null) {
			isInternal = false;
		}
		if(subscription.isActive()) {
			throw new com_lightstreamer_internal_IllegalStateException("Cannot subscribe to an active Subscription");
		}
		if(subscription.fetchItems() == null && subscription.getItemGroup() == null) {
			throw new com_lightstreamer_internal_IllegalArgumentException("Specify property 'items' or 'itemGroup'");
		}
		if(subscription.fetchFields() == null && subscription.getFieldSchema() == null) {
			throw new com_lightstreamer_internal_IllegalArgumentException("Specify property 'fields' or 'fieldSchema'");
		}
		var sm = new com_lightstreamer_client_internal_SubscriptionManagerLiving(subscription,this);
		if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_actionLogger.info("" + (isInternal ? "Internal subscription" : "Subscription") + " requested: subId: " + sm.subId + " " + Std.string(subscription));
		}
		sm.evtExtSubscribe();
	}
	,unsubscribe: function(subscription) {
		var sm = subscription.fetch_subManager();
		if(sm != null) {
			if(!this.isRelatedWithSubManager(sm)) {
				throw new com_lightstreamer_internal_IllegalArgumentException("The Subscription is not subscribed to this Client");
			}
			if(com_lightstreamer_log_LoggerTools_actionLogger.isInfoEnabled()) {
				com_lightstreamer_log_LoggerTools_actionLogger.info("Unsubscription requested: subId: " + sm.subId + " " + Std.string(subscription));
			}
			sm.evtExtUnsubscribe();
		}
	}
	,getSubscriptions: function() {
		var ls = [];
		var map = this.subscriptionManagers;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) {
			var _g_value = map.get(_g_keys.next());
			var sml = ((_g_value) instanceof com_lightstreamer_client_internal_SubscriptionManagerLiving) ? _g_value : null;
			if(sml != null) {
				var sub = sml.m_subscription;
				if(sub.isActive() && !sub.isInternal()) {
					ls.push(sub);
				}
			}
		}
		return ls;
	}
	,__class__: com_lightstreamer_client_internal_ClientMachine
};
var com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum = $hxEnums["com.lightstreamer.client.internal._ClientMachine.BestForCreatingEnum"] = { __ename__:true,__constructs__:null
	,BFC_ws: {_hx_name:"BFC_ws",_hx_index:0,__enum__:"com.lightstreamer.client.internal._ClientMachine.BestForCreatingEnum",toString:$estr}
	,BFC_http: {_hx_name:"BFC_http",_hx_index:1,__enum__:"com.lightstreamer.client.internal._ClientMachine.BestForCreatingEnum",toString:$estr}
	,BFC_none: {_hx_name:"BFC_none",_hx_index:2,__enum__:"com.lightstreamer.client.internal._ClientMachine.BestForCreatingEnum",toString:$estr}
};
com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.__constructs__ = [com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_ws,com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_http,com_lightstreamer_client_internal__$ClientMachine_BestForCreatingEnum.BFC_none];
var com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum = $hxEnums["com.lightstreamer.client.internal._ClientMachine.BestForBindingEnum"] = { __ename__:true,__constructs__:null
	,BFB_none: {_hx_name:"BFB_none",_hx_index:0,__enum__:"com.lightstreamer.client.internal._ClientMachine.BestForBindingEnum",toString:$estr}
	,BFB_ws_streaming: {_hx_name:"BFB_ws_streaming",_hx_index:1,__enum__:"com.lightstreamer.client.internal._ClientMachine.BestForBindingEnum",toString:$estr}
	,BFB_ws_polling: {_hx_name:"BFB_ws_polling",_hx_index:2,__enum__:"com.lightstreamer.client.internal._ClientMachine.BestForBindingEnum",toString:$estr}
	,BFB_http_streaming: {_hx_name:"BFB_http_streaming",_hx_index:3,__enum__:"com.lightstreamer.client.internal._ClientMachine.BestForBindingEnum",toString:$estr}
	,BFB_http_polling: {_hx_name:"BFB_http_polling",_hx_index:4,__enum__:"com.lightstreamer.client.internal._ClientMachine.BestForBindingEnum",toString:$estr}
};
com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.__constructs__ = [com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_none,com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_streaming,com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_ws_polling,com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_streaming,com_lightstreamer_client_internal__$ClientMachine_BestForBindingEnum.BFB_http_polling];
var com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult = $hxEnums["com.lightstreamer.client.internal._ClientMachine.SyncCheckResult"] = { __ename__:true,__constructs__:null
	,SCR_good: {_hx_name:"SCR_good",_hx_index:0,__enum__:"com.lightstreamer.client.internal._ClientMachine.SyncCheckResult",toString:$estr}
	,SCR_not_good: {_hx_name:"SCR_not_good",_hx_index:1,__enum__:"com.lightstreamer.client.internal._ClientMachine.SyncCheckResult",toString:$estr}
	,SCR_bad: {_hx_name:"SCR_bad",_hx_index:2,__enum__:"com.lightstreamer.client.internal._ClientMachine.SyncCheckResult",toString:$estr}
};
com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.__constructs__ = [com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_good,com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_not_good,com_lightstreamer_client_internal__$ClientMachine_SyncCheckResult.SCR_bad];
var com_lightstreamer_client_internal__$ClientMachine_RetryCause = $hxEnums["com.lightstreamer.client.internal._ClientMachine.RetryCause"] = { __ename__:true,__constructs__:null
	,standardError: ($_=function(code,msg) { return {_hx_index:0,code:code,msg:msg,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}; },$_._hx_name="standardError",$_.__params__ = ["code","msg"],$_)
	,ws_unavailable: {_hx_name:"ws_unavailable",_hx_index:1,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}
	,ws_error: {_hx_name:"ws_error",_hx_index:2,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}
	,http_error: {_hx_name:"http_error",_hx_index:3,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}
	,idle_timeout: {_hx_name:"idle_timeout",_hx_index:4,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}
	,stalled_timeout: {_hx_name:"stalled_timeout",_hx_index:5,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}
	,ws_timeout: {_hx_name:"ws_timeout",_hx_index:6,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}
	,http_timeout: {_hx_name:"http_timeout",_hx_index:7,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}
	,recovery_timeout: {_hx_name:"recovery_timeout",_hx_index:8,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}
	,prog_mismatch: ($_=function(expected,actual) { return {_hx_index:9,expected:expected,actual:actual,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}; },$_._hx_name="prog_mismatch",$_.__params__ = ["expected","actual"],$_)
	,page_frozen: {_hx_name:"page_frozen",_hx_index:10,__enum__:"com.lightstreamer.client.internal._ClientMachine.RetryCause",toString:$estr}
};
com_lightstreamer_client_internal__$ClientMachine_RetryCause.__constructs__ = [com_lightstreamer_client_internal__$ClientMachine_RetryCause.standardError,com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_unavailable,com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_error,com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_error,com_lightstreamer_client_internal__$ClientMachine_RetryCause.idle_timeout,com_lightstreamer_client_internal__$ClientMachine_RetryCause.stalled_timeout,com_lightstreamer_client_internal__$ClientMachine_RetryCause.ws_timeout,com_lightstreamer_client_internal__$ClientMachine_RetryCause.http_timeout,com_lightstreamer_client_internal__$ClientMachine_RetryCause.recovery_timeout,com_lightstreamer_client_internal__$ClientMachine_RetryCause.prog_mismatch,com_lightstreamer_client_internal__$ClientMachine_RetryCause.page_frozen];
var com_lightstreamer_client_internal__$ClientMachine_TerminationCause = $hxEnums["com.lightstreamer.client.internal._ClientMachine.TerminationCause"] = { __ename__:true,__constructs__:null
	,TC_standardError: ($_=function(code,msg) { return {_hx_index:0,code:code,msg:msg,__enum__:"com.lightstreamer.client.internal._ClientMachine.TerminationCause",toString:$estr}; },$_._hx_name="TC_standardError",$_.__params__ = ["code","msg"],$_)
	,TC_otherError: ($_=function(error) { return {_hx_index:1,error:error,__enum__:"com.lightstreamer.client.internal._ClientMachine.TerminationCause",toString:$estr}; },$_._hx_name="TC_otherError",$_.__params__ = ["error"],$_)
	,TC_api: {_hx_name:"TC_api",_hx_index:2,__enum__:"com.lightstreamer.client.internal._ClientMachine.TerminationCause",toString:$estr}
};
com_lightstreamer_client_internal__$ClientMachine_TerminationCause.__constructs__ = [com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_standardError,com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_otherError,com_lightstreamer_client_internal__$ClientMachine_TerminationCause.TC_api];
var com_lightstreamer_client_internal__$ClientMachine_RecoveryRetryCause = $hxEnums["com.lightstreamer.client.internal._ClientMachine.RecoveryRetryCause"] = { __ename__:true,__constructs__:null
	,RRC_transport_timeout: {_hx_name:"RRC_transport_timeout",_hx_index:0,__enum__:"com.lightstreamer.client.internal._ClientMachine.RecoveryRetryCause",toString:$estr}
	,RRC_transport_error: {_hx_name:"RRC_transport_error",_hx_index:1,__enum__:"com.lightstreamer.client.internal._ClientMachine.RecoveryRetryCause",toString:$estr}
};
com_lightstreamer_client_internal__$ClientMachine_RecoveryRetryCause.__constructs__ = [com_lightstreamer_client_internal__$ClientMachine_RecoveryRetryCause.RRC_transport_timeout,com_lightstreamer_client_internal__$ClientMachine_RecoveryRetryCause.RRC_transport_error];
function com_lightstreamer_client_internal_ClientMachine_asErrorMsg(cause) {
	switch(cause._hx_index) {
	case 0:
		return "" + cause.code + " - " + cause.msg;
	case 1:
		return "Websocket transport not available";
	case 2:
		return "Websocket error";
	case 3:
		return "HTTP error";
	case 4:
		return "idleTimeout expired";
	case 5:
		return "stalledTimeout expired";
	case 6:
		return "Websocket connect timeout expired";
	case 7:
		return "HTTP connect timeout expired";
	case 8:
		return "sessionRecoveryTimeout expired";
	case 9:
		return "Recovery counter mismatch: expected " + cause.expected + " but found " + cause.actual;
	case 10:
		return "page frozen";
	}
}
var com_lightstreamer_client_internal_Encodable = function() { };
com_lightstreamer_client_internal_Encodable.__name__ = true;
com_lightstreamer_client_internal_Encodable.__isInterface__ = true;
com_lightstreamer_client_internal_Encodable.prototype = {
	__class__: com_lightstreamer_client_internal_Encodable
};
var com_lightstreamer_client_internal_SwitchRequest = function(client) {
	this.client = client;
};
com_lightstreamer_client_internal_SwitchRequest.__name__ = true;
com_lightstreamer_client_internal_SwitchRequest.__interfaces__ = [com_lightstreamer_client_internal_Encodable];
com_lightstreamer_client_internal_SwitchRequest.prototype = {
	isPending: function() {
		if(this.client.state.s_m == 150) {
			return this.client.state.s_swt == 1302;
		} else {
			return false;
		}
	}
	,encode: function(isWS) {
		return this.client.encodeSwitch(isWS);
	}
	,encodeWS: function() {
		return "control\r\n" + this.encode(true);
	}
	,__class__: com_lightstreamer_client_internal_SwitchRequest
};
var com_lightstreamer_client_internal_ConstrainRequest = function(client) {
	this.client = client;
};
com_lightstreamer_client_internal_ConstrainRequest.__name__ = true;
com_lightstreamer_client_internal_ConstrainRequest.__interfaces__ = [com_lightstreamer_client_internal_Encodable];
com_lightstreamer_client_internal_ConstrainRequest.prototype = {
	isPending: function() {
		return this.client.state.s_bw == 1202;
	}
	,encode: function(isWS) {
		return this.client.encodeConstrain();
	}
	,encodeWS: function() {
		return "control\r\n" + this.encode(true);
	}
	,__class__: com_lightstreamer_client_internal_ConstrainRequest
};
var com_lightstreamer_client_internal_StateVar_$w = function(p,k,s) {
	this.p = p;
	this.k = k;
	this.s = s;
};
com_lightstreamer_client_internal_StateVar_$w.__name__ = true;
com_lightstreamer_client_internal_StateVar_$w.prototype = {
	toString: function() {
		return "<w_p=" + this.p + " w_k=" + this.k + " w_s=" + this.s + ">";
	}
	,__class__: com_lightstreamer_client_internal_StateVar_$w
};
var com_lightstreamer_client_internal_StateVar_$ws = function(m) {
	this.m = m;
};
com_lightstreamer_client_internal_StateVar_$ws.__name__ = true;
com_lightstreamer_client_internal_StateVar_$ws.prototype = {
	toString: function() {
		var str = "<ws_m=" + this.m;
		if(this.p != null) {
			str += " ws_p=" + this.p;
		}
		if(this.k != null) {
			str += " ws_k=" + this.k;
		}
		if(this.s != null) {
			str += " ws_s=" + this.s;
		}
		str += ">";
		return str;
	}
	,__class__: com_lightstreamer_client_internal_StateVar_$ws
};
var com_lightstreamer_client_internal_StateVar_$wp = function(m) {
	this.m = m;
};
com_lightstreamer_client_internal_StateVar_$wp.__name__ = true;
com_lightstreamer_client_internal_StateVar_$wp.prototype = {
	toString: function() {
		var str = "<wp_m=" + this.m;
		if(this.p != null) {
			str += " wp_p=" + this.p;
		}
		if(this.c != null) {
			str += " wp_c=" + this.c;
		}
		if(this.s != null) {
			str += " wp_s=" + this.s;
		}
		str += ">";
		return str;
	}
	,__class__: com_lightstreamer_client_internal_StateVar_$wp
};
var com_lightstreamer_client_internal_StateVar_$hs = function(m) {
	this.m = m;
};
com_lightstreamer_client_internal_StateVar_$hs.__name__ = true;
com_lightstreamer_client_internal_StateVar_$hs.prototype = {
	toString: function() {
		var str = "<hs_m=" + this.m;
		if(this.p != null) {
			str += " hs_p=" + this.p;
		}
		if(this.k != null) {
			str += " hs_k=" + this.k;
		}
		str += ">";
		return str;
	}
	,__class__: com_lightstreamer_client_internal_StateVar_$hs
};
var com_lightstreamer_client_internal_StateVar_$hp = function(m) {
	this.m = m;
};
com_lightstreamer_client_internal_StateVar_$hp.__name__ = true;
com_lightstreamer_client_internal_StateVar_$hp.prototype = {
	toString: function() {
		return "<hp_m=" + this.m + ">";
	}
	,__class__: com_lightstreamer_client_internal_StateVar_$hp
};
var com_lightstreamer_client_internal_State = function() {
	this.s_m = 100;
	this.s_du = 20;
	this.s_nr = 1400;
};
com_lightstreamer_client_internal_State.__name__ = true;
com_lightstreamer_client_internal_State.prototype = {
	goto_m_from_w: function(m) {
		this.clear_w();
		this.goto_m_from_session(m);
	}
	,goto_m_from_ws: function(m) {
		this.clear_ws();
		this.goto_m_from_session(m);
	}
	,goto_rec_from_w: function() {
		this.clear_w();
		this.goto_rec();
	}
	,goto_rec_from_ws: function() {
		this.clear_ws();
		this.goto_rec();
	}
	,goto_m_from_wp: function(m) {
		this.clear_wp();
		this.goto_m_from_session(m);
	}
	,goto_rec_from_wp: function() {
		this.clear_wp();
		this.goto_rec();
	}
	,goto_m_from_hs: function(m) {
		this.clear_hs();
		this.s_ctrl = null;
		this.s_h = null;
		this.goto_m_from_session(m);
	}
	,goto_m_from_rec: function(m) {
		this.s_tr = null;
		this.goto_m_from_session(m);
	}
	,goto_rec_from_hs: function() {
		this.clear_hs();
		this.s_ctrl = null;
		this.s_h = null;
		this.goto_rec();
	}
	,goto_m_from_hp: function(m) {
		this.clear_hp();
		this.s_ctrl = null;
		this.s_h = null;
		this.goto_m_from_session(m);
	}
	,goto_rec_from_hp: function() {
		this.clear_hp();
		this.s_ctrl = null;
		this.s_h = null;
		this.goto_rec();
	}
	,goto_rec: function() {
		this.s_tr = 260;
		this.s_rec = 1000;
		this.traceState();
	}
	,goto_m_from_session: function(m) {
		this.s_tr = null;
		this.s_swt = null;
		this.s_bw = null;
		this.s_m = m;
		this.traceState();
	}
	,goto_m_from_ctrl: function(m) {
		this.clear_hs();
		this.clear_hp();
		this.s_ctrl = null;
		this.s_h = null;
		this.goto_m_from_session(m);
	}
	,goto_200_from_rec: function() {
		this.s_rec = null;
		this.s_tr = 200;
		this.traceState();
	}
	,clear_w: function() {
		this.s_w = null;
		this.s_rhb = null;
		this.s_slw = null;
	}
	,clear_ws: function() {
		this.s_ws = null;
		this.s_rhb = null;
		this.s_slw = null;
	}
	,clear_wp: function() {
		this.s_wp = null;
	}
	,clear_hs: function() {
		this.s_hs = null;
		this.s_rhb = null;
		this.s_slw = null;
	}
	,clear_hp: function() {
		this.s_hp = null;
		this.s_rhb = null;
	}
	,isSwitching: function() {
		if(this.s_m == 150) {
			if(this.s_swt != 1302) {
				return this.s_swt == 1303;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}
	,inPushing: function() {
		if(!this.inStreaming()) {
			return this.inPolling();
		} else {
			return true;
		}
	}
	,inStreaming: function() {
		var tmp;
		var tmp1 = this.s_w;
		if((tmp1 != null ? tmp1.p : null) != 300) {
			var tmp1 = this.s_ws;
			tmp = (tmp1 != null ? tmp1.p : null) == 510;
		} else {
			tmp = true;
		}
		if(!tmp) {
			var tmp = this.s_hs;
			return (tmp != null ? tmp.p : null) == 810;
		} else {
			return true;
		}
	}
	,inPolling: function() {
		var tmp;
		var tmp1;
		if(!(this.s_tr == 220 || this.s_tr == 230)) {
			var tmp2 = this.s_wp;
			tmp1 = (tmp2 != null ? tmp2.p : null) == 611;
		} else {
			tmp1 = true;
		}
		if(!tmp1) {
			var tmp1 = this.s_hp;
			tmp = (tmp1 != null ? tmp1.m : null) == 901;
		} else {
			tmp = true;
		}
		if(!tmp) {
			return this.s_rec == 1001;
		} else {
			return true;
		}
	}
	,inRetryUnit: function() {
		switch(this.s_m) {
		case 110:case 111:case 112:case 113:case 114:case 115:case 116:
			return true;
		default:
			return false;
		}
	}
	,inDisconnected: function() {
		return this.s_m == 100;
	}
	,toString: function() {
		var str = "<m=" + this.s_m;
		str += " du=" + this.s_du;
		if(this.s_tr != null) {
			str += " tr=" + this.s_tr;
		}
		if(this.s_w != null) {
			str += " " + Std.string(this.s_w);
		}
		if(this.s_ws != null) {
			str += " " + Std.string(this.s_ws);
		}
		if(this.s_wp != null) {
			str += " " + Std.string(this.s_wp);
		}
		if(this.s_hs != null) {
			str += " " + Std.string(this.s_hs);
		}
		if(this.s_hp != null) {
			str += " " + Std.string(this.s_hp);
		}
		if(this.s_rec != null) {
			str += " rec=" + this.s_rec;
		}
		if(this.s_h != null) {
			str += " h=" + this.s_h;
		}
		if(this.s_ctrl != null) {
			str += " ctrl=" + this.s_ctrl;
		}
		if(this.s_swt != null) {
			str += " swt=" + this.s_swt;
		}
		if(this.s_bw != null) {
			str += " bw=" + this.s_bw;
		}
		if(this.s_rhb != null) {
			str += " rhb=" + this.s_rhb;
		}
		if(this.s_slw != null) {
			str += " slw=" + this.s_slw;
		}
		str += " nr=" + this.s_nr;
		str += ">";
		return str;
	}
	,traceState: function() {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("goto: " + this.toString());
		}
	}
	,__class__: com_lightstreamer_client_internal_State
};
var com_lightstreamer_client_internal_MessageEventDispatcher = function() {
	com_lightstreamer_internal_EventDispatcher.call(this);
};
com_lightstreamer_client_internal_MessageEventDispatcher.__name__ = true;
com_lightstreamer_client_internal_MessageEventDispatcher.__super__ = com_lightstreamer_internal_EventDispatcher;
com_lightstreamer_client_internal_MessageEventDispatcher.prototype = $extend(com_lightstreamer_internal_EventDispatcher.prototype,{
	onAbort: function(originalMessage,sentOnNetwork) {
		this.dispatchToAll(function(listener) {
			if(listener.onAbort != null) {
				listener.onAbort(originalMessage,sentOnNetwork);
			}
		});
	}
	,onDeny: function(originalMessage,code,error) {
		this.dispatchToAll(function(listener) {
			if(listener.onDeny != null) {
				listener.onDeny(originalMessage,code,error);
			}
		});
	}
	,onDiscarded: function(originalMessage) {
		this.dispatchToAll(function(listener) {
			if(listener.onDiscarded != null) {
				listener.onDiscarded(originalMessage);
			}
		});
	}
	,onError: function(originalMessage) {
		this.dispatchToAll(function(listener) {
			if(listener.onError != null) {
				listener.onError(originalMessage);
			}
		});
	}
	,onProcessed: function(originalMessage,response) {
		this.dispatchToAll(function(listener) {
			if(listener.onProcessed != null) {
				listener.onProcessed(originalMessage,response);
			}
		});
	}
	,__class__: com_lightstreamer_client_internal_MessageEventDispatcher
});
var com_lightstreamer_client_internal_MessageManager = function(txt,sequence,maxWait,delegate,enqueueWhileDisconnected,client) {
	this.eventDispatcher = new com_lightstreamer_client_internal_MessageEventDispatcher();
	this.txt = txt;
	this.sequence = sequence;
	if(sequence != "UNORDERED_MESSAGES" || delegate != null) {
		this.prog = client.getAndSetNextMsgProg(sequence);
	} else {
		this.prog = -1;
	}
	this.maxWait = maxWait;
	this.delegate = delegate;
	if(delegate != null) {
		this.eventDispatcher.addListener(delegate);
	}
	this.enqueueWhileDisconnected = enqueueWhileDisconnected;
	this.client = client;
	if(delegate != null) {
		this.s_m = 10;
	} else if(sequence != "UNORDERED_MESSAGES") {
		this.s_m = 20;
	} else {
		this.s_m = 30;
	}
	client.relateMsgManager(this);
};
com_lightstreamer_client_internal_MessageManager.__name__ = true;
com_lightstreamer_client_internal_MessageManager.__interfaces__ = [com_lightstreamer_client_internal_Encodable];
com_lightstreamer_client_internal_MessageManager.prototype = {
	finalize: function() {
		this.client.unrelateMsgManager(this);
	}
	,evtExtSendMessage: function() {
		this.traceEvent("sendMessage");
		switch(this.s_m) {
		case 10:
			this.goto(11);
			this.client.evtSendMessage(this);
			break;
		case 20:
			this.goto(21);
			this.client.evtSendMessage(this);
			break;
		case 30:
			this.goto(31);
			this.client.evtSendMessage(this);
			break;
		default:
		}
	}
	,evtMSGFAIL: function(code,msg) {
		this.traceEvent("MSGFAIL");
		switch(this.s_m) {
		case 10:
			this.finalize();
			this.goto(13);
			break;
		case 11:
			this.doMSGFAIL(code,msg);
			this.finalize();
			this.goto(14);
			break;
		case 12:
			this.doMSGFAIL(code,msg);
			this.finalize();
			this.goto(14);
			break;
		default:
		}
	}
	,evtMSGDONE: function(response) {
		this.traceEvent("MSGDONE");
		switch(this.s_m) {
		case 10:
			this.finalize();
			this.goto(13);
			break;
		case 11:
			this.doMSGDONE(response);
			this.finalize();
			this.goto(13);
			break;
		case 12:
			this.doMSGDONE(response);
			this.finalize();
			this.goto(13);
			break;
		default:
		}
	}
	,evtREQOK: function(reqId) {
		this.traceEvent("REQOK");
		switch(this.s_m) {
		case 11:
			if(reqId == this.lastReqId) {
				this.goto(12);
			}
			break;
		case 21:
			if(reqId == this.lastReqId) {
				this.finalize();
				this.goto(22);
			}
			break;
		case 31:
			if(reqId == this.lastReqId) {
				this.finalize();
				this.goto(33);
			}
			break;
		default:
		}
	}
	,evtREQERR: function(reqId,code,msg) {
		this.traceEvent("REQERR");
		switch(this.s_m) {
		case 11:
			if(reqId == this.lastReqId) {
				this.doREQERR(code,msg);
				this.finalize();
				this.goto(14);
			}
			break;
		case 21:
			if(reqId == this.lastReqId) {
				this.finalize();
				this.goto(23);
			}
			break;
		case 31:
			if(reqId == this.lastReqId) {
				this.finalize();
				this.goto(34);
			}
			break;
		default:
		}
	}
	,evtAbort: function() {
		this.traceEvent("abort");
		switch(this.s_m) {
		case 11:
			this.doAbort();
			this.finalize();
			this.goto(15);
			break;
		case 12:
			this.doAbort();
			this.finalize();
			this.goto(15);
			break;
		case 21:
			if(com_lightstreamer_log_LoggerTools_messageLogger.isWarnEnabled()) {
				com_lightstreamer_log_LoggerTools_messageLogger.warn("Message " + this.sequence + ":" + this.prog + " aborted");
			}
			this.finalize();
			this.goto(24);
			break;
		case 31:
			if(com_lightstreamer_log_LoggerTools_messageLogger.isWarnEnabled()) {
				com_lightstreamer_log_LoggerTools_messageLogger.warn("Message " + this.sequence + ":" + this.prog + " aborted");
			}
			this.finalize();
			this.goto(35);
			break;
		default:
		}
	}
	,evtWSSent: function() {
		this.traceEvent("ws.sent");
		if(this.s_m == 31) {
			this.finalize();
			this.goto(32);
		}
	}
	,isPending: function() {
		if(!(this.s_m == 11 || this.s_m == 21)) {
			return this.s_m == 31;
		} else {
			return true;
		}
	}
	,encode: function(isWS) {
		return this.encodeMsg(isWS);
	}
	,encodeWS: function() {
		return "msg\r\n" + this.encode(true);
	}
	,doMSGDONE: function(response) {
		this.fireOnProcessed(response);
	}
	,doMSGFAIL: function(code,msg) {
		if(code == 38 || code == 39) {
			this.fireOnDiscarded();
		} else if(code <= 0) {
			this.fireOnDeny(code,msg);
		} else if(code != 32 && code != 33) {
			this.fireOnError();
		}
	}
	,doREQERR: function(code,msg) {
		if(code != 32 && code != 33) {
			this.fireOnError();
		}
	}
	,doAbort: function() {
		this.fireOnAbort();
	}
	,encodeMsg: function(isWS) {
		var isOrdered = this.sequence != "UNORDERED_MESSAGES";
		var hasListener = this.delegate != null;
		var req = new com_lightstreamer_internal_RequestBuilder();
		this.lastReqId = this.client.generateFreshReqId();
		req.LS_reqId(this.lastReqId);
		req.LS_message(this.txt);
		if(isOrdered && hasListener) {
			req.LS_sequence(this.sequence);
			req.LS_msg_prog(this.prog);
			if(this.maxWait >= 0) {
				req.LS_max_wait(this.maxWait);
			}
		} else if(!isOrdered && hasListener) {
			req.LS_msg_prog(this.prog);
		} else if(isOrdered && !hasListener) {
			req.LS_outcome(false);
			req.LS_sequence(this.sequence);
			req.LS_msg_prog(this.prog);
			if(this.maxWait >= 0) {
				req.LS_max_wait(this.maxWait);
			}
		} else if(!isOrdered && !hasListener) {
			req.LS_outcome(false);
			if(isWS) {
				req.LS_ack(false);
			}
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending message: " + Std.string(req));
		}
		return req.getEncodedString();
	}
	,fireOnProcessed: function(response) {
		if(com_lightstreamer_log_LoggerTools_messageLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_messageLogger.info("Message " + this.sequence + ":" + this.prog + " processed");
		}
		this.eventDispatcher.onProcessed(this.txt,response);
	}
	,fireOnDiscarded: function() {
		if(com_lightstreamer_log_LoggerTools_messageLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_messageLogger.warn("Message " + this.sequence + ":" + this.prog + " discarded");
		}
		this.eventDispatcher.onDiscarded(this.txt);
	}
	,fireOnDeny: function(code,msg) {
		if(com_lightstreamer_log_LoggerTools_messageLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_messageLogger.warn("Message " + this.sequence + ":" + this.prog + " denied: " + code + " - " + msg);
		}
		this.eventDispatcher.onDeny(this.txt,code,msg);
	}
	,fireOnError: function() {
		if(com_lightstreamer_log_LoggerTools_messageLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_messageLogger.warn("Message " + this.sequence + ":" + this.prog + " failed");
		}
		this.eventDispatcher.onError(this.txt);
	}
	,fireOnAbort: function() {
		if(com_lightstreamer_log_LoggerTools_messageLogger.isWarnEnabled()) {
			com_lightstreamer_log_LoggerTools_messageLogger.warn("Message " + this.sequence + ":" + this.prog + " aborted");
		}
		this.eventDispatcher.onAbort(this.txt,false);
	}
	,traceEvent: function(evt) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("msg#" + evt + "(" + this.sequence + ":" + this.prog + ") in " + this.s_m);
		}
	}
	,goto: function(target) {
		this.s_m = target;
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("msg#goto(" + this.sequence + ":" + this.prog + ") " + this.s_m);
		}
	}
	,toString: function() {
		var map = com_lightstreamer_internal_InfoMap._new();
		com_lightstreamer_internal_InfoMap.set(map,"text",this.txt);
		com_lightstreamer_internal_InfoMap.set(map,"sequence",this.sequence);
		com_lightstreamer_internal_InfoMap.set(map,"prog",this.prog);
		com_lightstreamer_internal_InfoMap.set(map,"timeout",this.maxWait);
		com_lightstreamer_internal_InfoMap.set(map,"enqueueWhileDisconnected",this.enqueueWhileDisconnected);
		return haxe_ds_StringMap.stringify(map.h);
	}
	,__class__: com_lightstreamer_client_internal_MessageManager
};
var com_lightstreamer_client_internal_ModeStrategy = function(sub,client,subId) {
	this.items = new haxe_ds_IntMap();
	this.s_m = 1;
	this.lock = client.lock;
	this.client = client;
	this.subscription = sub;
	this.m_subId = subId;
};
com_lightstreamer_client_internal_ModeStrategy.__name__ = true;
com_lightstreamer_client_internal_ModeStrategy.prototype = {
	finalize: function() {
	}
	,evtAbort: function() {
		this.traceEvent("abort");
		if(this.s_m == 1) {
			this.doAbort();
			this.goto(1);
		} else if(this.s_m == 2) {
			this.doAbort();
			this.goto(1);
			this.genDisposeItems();
		}
	}
	,evtOnSUB: function(nItems,nFields,cmdIdx,keyIdx,currentFreq) {
		this.traceEvent("onSUB");
		if(this.s_m == 1) {
			this.doSUB(nItems,nFields);
			this.goto(2);
		}
	}
	,evtOnCONF: function(freq) {
		this.traceEvent("onCONF");
		if(this.s_m == 2) {
			this.doCONF(freq);
			this.goto(2);
		}
	}
	,evtOnCS: function(itemIdx) {
		this.traceEvent("onCS");
		if(this.s_m == 2) {
			this.doCS(itemIdx);
			this.goto(2);
		}
	}
	,evtOnEOS: function(itemIdx) {
		this.traceEvent("onEOS");
		if(this.s_m == 2) {
			this.doEOS(itemIdx);
			this.goto(2);
		}
	}
	,evtUpdate: function(itemIdx,values) {
		this.traceEvent("update");
		if(this.s_m == 2) {
			this.doUpdate(itemIdx,values);
			this.goto(2);
		}
	}
	,evtUnsubscribe: function() {
		this.traceEvent("unsubscribe");
		if(this.s_m == 1) {
			this.finalize();
			this.goto(3);
		} else if(this.s_m == 2) {
			this.finalize();
			this.goto(3);
			this.genDisposeItems();
		}
	}
	,evtOnUNSUB: function() {
		this.traceEvent("onUNSUB");
		if(this.s_m == 2) {
			this.finalize();
			this.goto(3);
			this.genDisposeItems();
		}
	}
	,evtDispose: function() {
		this.traceEvent("dispose");
		if(this.s_m == 1 || this.s_m == 2) {
			this.finalize();
			this.goto(3);
			this.genDisposeItems();
		}
	}
	,evtSetRequestedMaxFrequency: function(freq) {
	}
	,getValue: function(itemPos,fieldPos) {
		var item = this.items.h[itemPos];
		if(item != null) {
			return item.getValue(fieldPos);
		} else {
			return null;
		}
	}
	,getCommandValue: function(itemPos,key,fieldPos) {
		throw new com_lightstreamer_internal_IllegalStateException("Unsupported operation");
	}
	,createItem: function(itemIdx) {
		throw new com_lightstreamer_internal_IllegalStateException("Abstract method");
	}
	,doSUB: function(nItems,nFields) {
		var items = this.subscription.fetchItems();
		var fields = this.subscription.fetchFields();
		if(!(items != null ? nItems == items.length : true)) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "items != null ? nItems == items.length : true");
		}
		if(!(fields != null ? nFields == fields.length : true)) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "fields != null ? nFields == fields.length : true");
		}
	}
	,doUpdate: function(itemIdx,values) {
		this.selectItem(itemIdx).evtUpdate(values);
	}
	,doEOS: function(itemIdx) {
		this.selectItem(itemIdx).evtOnEOS();
	}
	,doCS: function(itemIdx) {
		this.selectItem(itemIdx).evtOnCS();
	}
	,doCONF: function(freq) {
		this.realMaxFrequency = freq;
		this.subscription.fireOnRealMaxFrequency(freq,this.m_subId);
	}
	,doAbort: function() {
		this.realMaxFrequency = null;
	}
	,genDisposeItems: function() {
		var map = this.items.copy();
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) map.get(_g_keys.next()).evtDispose(this);
	}
	,selectItem: function(itemIdx) {
		var item = this.items.h[itemIdx];
		if(item == null) {
			item = this.createItem(itemIdx);
			this.items.h[itemIdx] = item;
		}
		return item;
	}
	,unrelate: function(itemIdx) {
		this.items.remove(itemIdx);
	}
	,traceEvent: function(evt) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#mod#" + evt + "(" + this.m_subId + ") in " + this.s_m);
		}
	}
	,goto: function(to) {
		this.s_m = to;
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#mod#goto(" + this.m_subId + ") " + this.s_m);
		}
	}
	,__class__: com_lightstreamer_client_internal_ModeStrategy
};
var com_lightstreamer_client_internal_ModeStrategyCommand = function(sub,client,subId) {
	com_lightstreamer_client_internal_ModeStrategy.call(this,sub,client,subId);
};
com_lightstreamer_client_internal_ModeStrategyCommand.__name__ = true;
com_lightstreamer_client_internal_ModeStrategyCommand.__super__ = com_lightstreamer_client_internal_ModeStrategy;
com_lightstreamer_client_internal_ModeStrategyCommand.prototype = $extend(com_lightstreamer_client_internal_ModeStrategy.prototype,{
	evtOnSUB: function(nItems,nFields,cmdIdx,keyIdx,currentFreq) {
		this.traceEvent("onSUB");
		if(this.s_m == 1) {
			this.doSUBCMD(nItems,nFields,cmdIdx,keyIdx);
			this.goto(2);
		}
	}
	,getCommandValue: function(itemPos,key,fieldPos) {
		var item = this.items.h[itemPos];
		if(item != null) {
			return item.getCommandValue(key,fieldPos);
		} else {
			return null;
		}
	}
	,doSUBCMD: function(nItems,nFields,cmdIdx,keyIdx) {
		var items = this.subscription.fetchItems();
		var fields = this.subscription.fetchFields();
		if(!(items != null ? nItems == items.length : true)) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "items != null ? nItems == items.length : true");
		}
		if(!(fields != null ? nFields == fields.length : true)) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "fields != null ? nFields == fields.length : true");
		}
		if(!(fields != null ? cmdIdx - 1 == fields.indexOf("command") : true)) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "fields != null ? cmdIdx - 1 == fields.indexOf(\"command\") : true");
		}
		if(!(fields != null ? keyIdx - 1 == fields.indexOf("key") : true)) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "fields != null ? keyIdx - 1 == fields.indexOf(\"key\") : true");
		}
	}
	,__class__: com_lightstreamer_client_internal_ModeStrategyCommand
});
var com_lightstreamer_client_internal_ModeStrategyCommand1Level = function(sub,client,subId) {
	com_lightstreamer_client_internal_ModeStrategyCommand.call(this,sub,client,subId);
};
com_lightstreamer_client_internal_ModeStrategyCommand1Level.__name__ = true;
com_lightstreamer_client_internal_ModeStrategyCommand1Level.__super__ = com_lightstreamer_client_internal_ModeStrategyCommand;
com_lightstreamer_client_internal_ModeStrategyCommand1Level.prototype = $extend(com_lightstreamer_client_internal_ModeStrategyCommand.prototype,{
	createItem: function(itemIdx) {
		return new com_lightstreamer_client_internal_update_ItemCommand1Level(itemIdx,this.subscription,this.client,this.m_subId);
	}
	,__class__: com_lightstreamer_client_internal_ModeStrategyCommand1Level
});
var com_lightstreamer_client_internal_ModeStrategyCommand2Level = function(sub,client,subId) {
	com_lightstreamer_client_internal_ModeStrategyCommand.call(this,sub,client,subId);
};
com_lightstreamer_client_internal_ModeStrategyCommand2Level.__name__ = true;
com_lightstreamer_client_internal_ModeStrategyCommand2Level.__super__ = com_lightstreamer_client_internal_ModeStrategyCommand;
com_lightstreamer_client_internal_ModeStrategyCommand2Level.prototype = $extend(com_lightstreamer_client_internal_ModeStrategyCommand.prototype,{
	evtOnSUB: function(nItems,nFields,cmdIdx,keyIdx,currentFreq) {
		this.traceEvent("onSUB");
		if(this.s_m == 1) {
			this.doSUBCMD2Level(nItems,nFields,cmdIdx,keyIdx,currentFreq);
			this.goto(2);
		}
	}
	,evtSetRequestedMaxFrequency: function(freq) {
		this.traceEvent("setRequestedMaxFrequency");
		switch(this.s_m) {
		case 1:case 2:
			this.doSetRequestedMaxFrequency(freq);
			this.goto(this.s_m);
			this.genSetRequestedMaxFrequency();
			break;
		default:
		}
	}
	,evtOnRealMaxFrequency2LevelAdded: function(freq) {
		this.traceEvent("onRealMaxFrequency2LevelAdded");
		if(this.s_m == 2) {
			this.doAggregateFrequenciesWhenFreqIsAdded(freq);
			this.goto(2);
		}
	}
	,evtOnRealMaxFrequency2LevelRemoved: function() {
		this.traceEvent("onRealMaxFrequency2LevelRemoved");
		if(this.s_m == 2) {
			this.doAggregateFrequenciesWhenFreqIsRemoved();
			this.goto(2);
		}
	}
	,evtOnCONF: function(freq) {
		this.traceEvent("onCONF");
		if(this.s_m == 2) {
			this.doCONF2Level(freq);
			this.doAggregateFrequenciesWhenFreqIsAdded(freq);
			this.goto(2);
		}
	}
	,createItem: function(itemIdx) {
		return new com_lightstreamer_client_internal_update_ItemCommand2Level(itemIdx,this.subscription,this,this.client,this.m_subId);
	}
	,doSUBCMD2Level: function(nItems,nFields,cmdIdx,keyIdx,currentFreq) {
		var items = this.subscription.fetchItems();
		var fields = this.subscription.fetchFields();
		if(!(items != null ? nItems == items.length : true)) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "items != null ? nItems == items.length : true");
		}
		if(!(fields != null ? nFields == fields.length : true)) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "fields != null ? nFields == fields.length : true");
		}
		if(!(fields != null ? cmdIdx - 1 == fields.indexOf("command") : true)) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "fields != null ? cmdIdx - 1 == fields.indexOf(\"command\") : true");
		}
		if(!(fields != null ? keyIdx - 1 == fields.indexOf("key") : true)) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "fields != null ? keyIdx - 1 == fields.indexOf(\"key\") : true");
		}
		this.requestedMaxFrequency = currentFreq;
	}
	,doSetRequestedMaxFrequency: function(maxFrequency) {
		this.requestedMaxFrequency = maxFrequency;
	}
	,genSetRequestedMaxFrequency: function() {
		var this1 = this.items;
		var _g_keys = this1.keys();
		while(_g_keys.hasNext()) (js_Boot.__cast(this1.get(_g_keys.next()) , com_lightstreamer_client_internal_update_ItemCommand2Level)).evtSetRequestedMaxFrequency();
	}
	,doCONF2Level: function(maxFrequency) {
		this.realMaxFrequency = maxFrequency;
	}
	,maxFreq: function(cumulated,freq) {
		var newMax;
		if(cumulated == null) {
			newMax = freq;
		} else {
			switch(cumulated._hx_index) {
			case 0:
				var _g = cumulated.freq;
				if(freq == null) {
					newMax = cumulated;
				} else {
					switch(freq._hx_index) {
					case 0:
						newMax = com_lightstreamer_internal_RealMaxFrequency.RFreqLimited(Math.max(_g,freq.freq));
						break;
					case 1:
						newMax = freq;
						break;
					}
				}
				break;
			case 1:
				newMax = cumulated;
				break;
			}
		}
		return newMax;
	}
	,doAggregateFrequenciesWhenFreqIsAdded: function(freq) {
		var newMax = this.maxFreq(this.aggregateRealMaxFrequency,freq);
		var prevMax = this.aggregateRealMaxFrequency;
		this.aggregateRealMaxFrequency = newMax;
		if(!com_lightstreamer_internal_Types_realFrequencyEquals(prevMax,newMax)) {
			this.subscription.fireOnRealMaxFrequency(newMax,this.m_subId);
		}
	}
	,doAggregateFrequenciesWhenFreqIsRemoved: function() {
		var newMax = this.realMaxFrequency;
		var breakOuterLoop = false;
		var this1 = this.items;
		var _g_keys = this1.keys();
		while(_g_keys.hasNext()) {
			var h = (js_Boot.__cast(this1.get(_g_keys.next()) , com_lightstreamer_client_internal_update_ItemCommand2Level)).keys.h;
			var _g_keys1 = Object.keys(h);
			var _g_length = _g_keys1.length;
			var _g_current = 0;
			while(_g_current < _g_length) {
				newMax = this.maxFreq(newMax,(js_Boot.__cast(h[_g_keys1[_g_current++]] , com_lightstreamer_client_internal_update_Key2Level)).realMaxFrequency);
				if(newMax == com_lightstreamer_internal_RealMaxFrequency.RFreqUnlimited) {
					breakOuterLoop = true;
					break;
				}
			}
			if(breakOuterLoop) {
				break;
			}
		}
		var prevMax = this.aggregateRealMaxFrequency;
		this.aggregateRealMaxFrequency = newMax;
		if(!com_lightstreamer_internal_Types_realFrequencyEquals(prevMax,newMax)) {
			this.subscription.fireOnRealMaxFrequency(newMax,this.m_subId);
		}
	}
	,__class__: com_lightstreamer_client_internal_ModeStrategyCommand2Level
});
var com_lightstreamer_client_internal_ModeStrategyDistinct = function(sub,client,subId) {
	com_lightstreamer_client_internal_ModeStrategy.call(this,sub,client,subId);
};
com_lightstreamer_client_internal_ModeStrategyDistinct.__name__ = true;
com_lightstreamer_client_internal_ModeStrategyDistinct.__super__ = com_lightstreamer_client_internal_ModeStrategy;
com_lightstreamer_client_internal_ModeStrategyDistinct.prototype = $extend(com_lightstreamer_client_internal_ModeStrategy.prototype,{
	createItem: function(itemIdx) {
		return new com_lightstreamer_client_internal_update_ItemDistinct(itemIdx,this.subscription,this.client,this.m_subId);
	}
	,__class__: com_lightstreamer_client_internal_ModeStrategyDistinct
});
var com_lightstreamer_client_internal_ModeStrategyMerge = function(sub,client,subId) {
	com_lightstreamer_client_internal_ModeStrategy.call(this,sub,client,subId);
};
com_lightstreamer_client_internal_ModeStrategyMerge.__name__ = true;
com_lightstreamer_client_internal_ModeStrategyMerge.__super__ = com_lightstreamer_client_internal_ModeStrategy;
com_lightstreamer_client_internal_ModeStrategyMerge.prototype = $extend(com_lightstreamer_client_internal_ModeStrategy.prototype,{
	createItem: function(itemIdx) {
		return new com_lightstreamer_client_internal_update_ItemMerge(itemIdx,this.subscription,this.client,this.m_subId);
	}
	,__class__: com_lightstreamer_client_internal_ModeStrategyMerge
});
var com_lightstreamer_client_internal_ModeStrategyRaw = function(sub,client,subId) {
	com_lightstreamer_client_internal_ModeStrategy.call(this,sub,client,subId);
};
com_lightstreamer_client_internal_ModeStrategyRaw.__name__ = true;
com_lightstreamer_client_internal_ModeStrategyRaw.__super__ = com_lightstreamer_client_internal_ModeStrategy;
com_lightstreamer_client_internal_ModeStrategyRaw.prototype = $extend(com_lightstreamer_client_internal_ModeStrategy.prototype,{
	createItem: function(itemIdx) {
		return new com_lightstreamer_client_internal_update_ItemRaw(itemIdx,this.subscription,this.client,this.m_subId);
	}
	,__class__: com_lightstreamer_client_internal_ModeStrategyRaw
});
var com_lightstreamer_client_internal_UpdateInfo = function(subId,itemIdx,values) {
	this.subId = subId;
	this.itemIdx = itemIdx;
	this.values = values;
};
com_lightstreamer_client_internal_UpdateInfo.__name__ = true;
com_lightstreamer_client_internal_UpdateInfo.prototype = {
	__class__: com_lightstreamer_client_internal_UpdateInfo
};
function com_lightstreamer_client_internal_ParseTools_parseInt(s) {
	return com_lightstreamer_internal_NullTools.sure(Std.parseInt(s));
}
function com_lightstreamer_client_internal_ParseTools_parseFloat(s) {
	return com_lightstreamer_internal_NullTools.sure(parseFloat(s));
}
function com_lightstreamer_client_internal_ParseTools_parseLong(s) {
	return com_lightstreamer_client_internal_ParseTools_parseInt(s);
}
function com_lightstreamer_client_internal_ParseTools_parseUpdate(message) {
	var tableIndex = com_lightstreamer_client_internal_ParseTools_checkedIndexOf(message,",") + 1;
	var itemIndex = com_lightstreamer_client_internal_ParseTools_checkedIndexOf(message,",",tableIndex) + 1;
	var fieldsIndex = com_lightstreamer_client_internal_ParseTools_checkedIndexOf(message,",",itemIndex) + 1;
	var table = com_lightstreamer_client_internal_ParseTools_parseInt(message.substring(tableIndex,itemIndex - 1));
	var item = com_lightstreamer_client_internal_ParseTools_parseInt(message.substring(itemIndex,fieldsIndex - 1));
	var values = new haxe_ds_IntMap();
	var fieldStart = fieldsIndex - 1;
	var nextFieldIndex = 1;
	while(fieldStart < message.length) {
		var fieldEnd = message.indexOf("|",fieldStart + 1);
		if(fieldEnd == -1) {
			fieldEnd = message.length;
		}
		var value = message.substring(fieldStart + 1,fieldEnd);
		if(value == "") {
			values.h[nextFieldIndex] = com_lightstreamer_internal_FieldValue.unchanged;
			++nextFieldIndex;
		} else if(value == "#") {
			values.h[nextFieldIndex] = com_lightstreamer_internal_FieldValue.changed(null);
			++nextFieldIndex;
		} else if(value == "$") {
			values.h[nextFieldIndex] = com_lightstreamer_internal_FieldValue.changed("");
			++nextFieldIndex;
		} else if(value.charAt(0) == "^") {
			if(value.charAt(1) == "P") {
				var unquoted = com_lightstreamer_client_internal_ParseTools_unquote(value.substring(2));
				try {
					var patch = com_lightstreamer_internal_patch_Json._new(unquoted);
					values.h[nextFieldIndex] = com_lightstreamer_internal_FieldValue.jsonPatch(patch);
					++nextFieldIndex;
				} catch( _g ) {
					var _g1 = haxe_Exception.caught(_g);
					if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
						com_lightstreamer_log_LoggerTools_sessionLogger.error("Invalid JSON patch " + unquoted + ": " + _g1.get_message() + "\n" + _g1.details());
					}
					throw new com_lightstreamer_internal_IllegalStateException("The JSON Patch for the field " + nextFieldIndex + " is not well-formed");
				}
			} else if(value.charAt(1) == "T") {
				var patch1 = com_lightstreamer_client_internal_ParseTools_unquote(value.substring(2));
				values.h[nextFieldIndex] = com_lightstreamer_internal_FieldValue.diffPatch(patch1);
				++nextFieldIndex;
			} else {
				var count = com_lightstreamer_client_internal_ParseTools_parseInt(value.substring(1));
				var _g2 = 0;
				while(_g2 < count) {
					++_g2;
					values.h[nextFieldIndex] = com_lightstreamer_internal_FieldValue.unchanged;
					++nextFieldIndex;
				}
			}
		} else {
			var v = com_lightstreamer_internal_FieldValue.changed(com_lightstreamer_client_internal_ParseTools_unquote(value));
			values.h[nextFieldIndex] = v;
			++nextFieldIndex;
		}
		fieldStart = fieldEnd;
	}
	return new com_lightstreamer_client_internal_UpdateInfo(table,item,values);
}
function com_lightstreamer_client_internal_ParseTools_checkedIndexOf(s,needle,startIndex) {
	var i = s.indexOf(needle,startIndex);
	if(i == -1) {
		throw new com_lightstreamer_internal_IllegalStateException("string not found");
	}
	return i;
}
function com_lightstreamer_client_internal_ParseTools_unquote(s) {
	var bb = haxe_io_Bytes.ofString(s,haxe_io_Encoding.UTF8);
	var i = 0;
	var j = 0;
	while(i < bb.length) if(bb.b[i] == 37) {
		bb.b[j++] = (com_lightstreamer_client_internal_ParseTools_hexToNum(bb.b[i + 1]) << 4) + com_lightstreamer_client_internal_ParseTools_hexToNum(bb.b[i + 2]);
		i += 3;
	} else {
		bb.b[j++] = bb.b[i++];
	}
	return bb.getString(0,j,haxe_io_Encoding.UTF8);
}
function com_lightstreamer_client_internal_ParseTools_hexToNum(ascii) {
	var hex = 0;
	hex = ascii - 97 + 10;
	if(hex <= 9) {
		hex = ascii - 65 + 10;
		if(hex <= 9) {
			hex = ascii - 48;
		}
	}
	return hex;
}
var haxe_io_Bytes = function(data) {
	this.length = data.byteLength;
	this.b = new Uint8Array(data);
	this.b.bufferValue = data;
	data.hxBytes = this;
	data.bytes = this.b;
};
haxe_io_Bytes.__name__ = true;
haxe_io_Bytes.ofString = function(s,encoding) {
	if(encoding == haxe_io_Encoding.RawNative) {
		var buf = new Uint8Array(s.length << 1);
		var _g = 0;
		var _g1 = s.length;
		while(_g < _g1) {
			var i = _g++;
			var c = s.charCodeAt(i);
			buf[i << 1] = c & 255;
			buf[i << 1 | 1] = c >> 8;
		}
		return new haxe_io_Bytes(buf.buffer);
	}
	var a = [];
	var i = 0;
	while(i < s.length) {
		var c = s.charCodeAt(i++);
		if(55296 <= c && c <= 56319) {
			c = c - 55232 << 10 | s.charCodeAt(i++) & 1023;
		}
		if(c <= 127) {
			a.push(c);
		} else if(c <= 2047) {
			a.push(192 | c >> 6);
			a.push(128 | c & 63);
		} else if(c <= 65535) {
			a.push(224 | c >> 12);
			a.push(128 | c >> 6 & 63);
			a.push(128 | c & 63);
		} else {
			a.push(240 | c >> 18);
			a.push(128 | c >> 12 & 63);
			a.push(128 | c >> 6 & 63);
			a.push(128 | c & 63);
		}
	}
	return new haxe_io_Bytes(new Uint8Array(a).buffer);
};
haxe_io_Bytes.prototype = {
	getString: function(pos,len,encoding) {
		if(pos < 0 || len < 0 || pos + len > this.length) {
			throw haxe_Exception.thrown(haxe_io_Error.OutsideBounds);
		}
		if(encoding == null) {
			encoding = haxe_io_Encoding.UTF8;
		}
		var s = "";
		var b = this.b;
		var i = pos;
		var max = pos + len;
		switch(encoding._hx_index) {
		case 0:
			while(i < max) {
				var c = b[i++];
				if(c < 128) {
					if(c == 0) {
						break;
					}
					s += String.fromCodePoint(c);
				} else if(c < 224) {
					var code = (c & 63) << 6 | b[i++] & 127;
					s += String.fromCodePoint(code);
				} else if(c < 240) {
					var code1 = (c & 31) << 12 | (b[i++] & 127) << 6 | b[i++] & 127;
					s += String.fromCodePoint(code1);
				} else {
					var u = (c & 15) << 18 | (b[i++] & 127) << 12 | (b[i++] & 127) << 6 | b[i++] & 127;
					s += String.fromCodePoint(u);
				}
			}
			break;
		case 1:
			while(i < max) {
				var c = b[i++] | b[i++] << 8;
				s += String.fromCodePoint(c);
			}
			break;
		}
		return s;
	}
	,__class__: haxe_io_Bytes
};
var haxe_io_Encoding = $hxEnums["haxe.io.Encoding"] = { __ename__:true,__constructs__:null
	,UTF8: {_hx_name:"UTF8",_hx_index:0,__enum__:"haxe.io.Encoding",toString:$estr}
	,RawNative: {_hx_name:"RawNative",_hx_index:1,__enum__:"haxe.io.Encoding",toString:$estr}
};
haxe_io_Encoding.__constructs__ = [haxe_io_Encoding.UTF8,haxe_io_Encoding.RawNative];
var com_lightstreamer_client_internal_Request = function() {
	this.body = "";
};
com_lightstreamer_client_internal_Request.__name__ = true;
com_lightstreamer_client_internal_Request.isEmpty = function(s) {
	return s.length == 0;
};
com_lightstreamer_client_internal_Request.lengthOfBytes = function(req) {
	return haxe_io_Bytes.ofString(req,haxe_io_Encoding.UTF8).length;
};
com_lightstreamer_client_internal_Request.prototype = {
	getByteSize: function() {
		return com_lightstreamer_client_internal_Request.lengthOfBytes(this.body);
	}
	,getBody: function() {
		return this.body;
	}
	,addSubRequest: function(req) {
		if(com_lightstreamer_client_internal_Request.isEmpty(this.body)) {
			this.body = req;
		} else {
			this.body += "\r\n" + req;
		}
	}
	,addSubRequestOnlyIfBodyIsLessThan: function(req,requestLimit) {
		if(com_lightstreamer_client_internal_Request.isEmpty(this.body) && com_lightstreamer_client_internal_Request.lengthOfBytes(req) <= requestLimit) {
			this.body = req;
			return true;
		} else if(com_lightstreamer_client_internal_Request.lengthOfBytes(this.body) + com_lightstreamer_client_internal_Request.EOL_LEN + com_lightstreamer_client_internal_Request.lengthOfBytes(req) <= requestLimit) {
			this.body += "\r\n" + req;
			return true;
		}
		return false;
	}
	,__class__: com_lightstreamer_client_internal_Request
};
var com_lightstreamer_client_internal_RetryDelayCounter = function() {
	this.attempt = 1;
	this._currentRetryDelay = 0;
};
com_lightstreamer_client_internal_RetryDelayCounter.__name__ = true;
com_lightstreamer_client_internal_RetryDelayCounter.prototype = {
	get_currentRetryDelay: function() {
		return this._currentRetryDelay;
	}
	,increase: function() {
		if(this.attempt > 10) {
			if(this._currentRetryDelay < 60000) {
				if(this._currentRetryDelay * 2 < 60000) {
					this._currentRetryDelay *= 2;
				} else {
					this._currentRetryDelay = 60000;
				}
			}
		} else {
			this.attempt += 1;
		}
	}
	,reset: function(retryDelay) {
		this.attempt = 1;
		this._currentRetryDelay = retryDelay;
	}
	,__class__: com_lightstreamer_client_internal_RetryDelayCounter
};
var com_lightstreamer_client_internal_SubscriptionManager = function() { };
com_lightstreamer_client_internal_SubscriptionManager.__name__ = true;
com_lightstreamer_client_internal_SubscriptionManager.__isInterface__ = true;
com_lightstreamer_client_internal_SubscriptionManager.__interfaces__ = [com_lightstreamer_client_internal_Encodable];
com_lightstreamer_client_internal_SubscriptionManager.prototype = {
	__class__: com_lightstreamer_client_internal_SubscriptionManager
};
var com_lightstreamer_client_internal__$SubscriptionManager_State = function(subId) {
	this.subId = subId;
	this.s_m = 1;
};
com_lightstreamer_client_internal__$SubscriptionManager_State.__name__ = true;
com_lightstreamer_client_internal__$SubscriptionManager_State.prototype = {
	toString: function() {
		var s = "<m=" + this.s_m;
		if(this.s_s != null) {
			s += " s=" + this.s_s;
		}
		if(this.s_c != null) {
			s += " c=" + this.s_c;
		}
		s += ">";
		return s;
	}
	,traceState: function() {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#goto(" + this.subId + ") " + this.toString());
		}
	}
	,__class__: com_lightstreamer_client_internal__$SubscriptionManager_State
};
var com_lightstreamer_client_internal_SubscriptionManagerLiving = function(sub,client) {
	this.lock = client.lock;
	this.subId = client.generateFreshSubId();
	var tmp;
	switch(sub.fetch_mode()) {
	case "COMMAND":
		tmp = com_lightstreamer_client_internal_SubscriptionManagerLiving.is2LevelCommand(sub) ? new com_lightstreamer_client_internal_ModeStrategyCommand2Level(sub,client,this.subId) : new com_lightstreamer_client_internal_ModeStrategyCommand1Level(sub,client,this.subId);
		break;
	case "DISTINCT":
		tmp = new com_lightstreamer_client_internal_ModeStrategyDistinct(sub,client,this.subId);
		break;
	case "MERGE":
		tmp = new com_lightstreamer_client_internal_ModeStrategyMerge(sub,client,this.subId);
		break;
	case "RAW":
		tmp = new com_lightstreamer_client_internal_ModeStrategyRaw(sub,client,this.subId);
		break;
	}
	this.m_strategy = tmp;
	this.state = new com_lightstreamer_client_internal__$SubscriptionManager_State(this.subId);
	this.m_client = client;
	this.m_subscription = sub;
	this.m_client.relateSubManager(this);
	this.m_subscription.relate(this);
};
com_lightstreamer_client_internal_SubscriptionManagerLiving.__name__ = true;
com_lightstreamer_client_internal_SubscriptionManagerLiving.__interfaces__ = [com_lightstreamer_client_internal_SubscriptionManager];
com_lightstreamer_client_internal_SubscriptionManagerLiving.is2LevelCommand = function(sub) {
	if(sub.fetch_mode() == "COMMAND") {
		if(sub.getCommandSecondLevelFields() == null) {
			return sub.getCommandSecondLevelFieldSchema() != null;
		} else {
			return true;
		}
	} else {
		return false;
	}
};
com_lightstreamer_client_internal_SubscriptionManagerLiving.prototype = {
	finalize: function() {
		this.m_strategy.evtDispose();
		this.m_client.unrelateSubManager(this);
		this.m_subscription.unrelate(this);
	}
	,evtExtSubscribe: function() {
		this.traceEvent("subscribe");
		if(this.state.s_m == 1) {
			this.doSetActive();
			this.doSubscribe();
			this.state.s_m = 2;
			this.state.traceState();
			this.genSendControl();
		}
	}
	,evtExtUnsubscribe: function() {
		this.traceEvent("unsubscribe");
		if(this.state.s_m == 2) {
			this.doSetInactive();
			this.finalize();
			this.state.s_m = 30;
			this.state.traceState();
		} else if(this.state.s_m == 3) {
			this.doUnsubscribe();
			this.doSetInactive();
			this.state.s_m = 5;
			this.state.traceState();
			this.genSendControl();
		} else if(this.state.s_s == 10) {
			this.doUnsubscribe();
			this.m_subscription.setInactive();
			this.notifyOnUnsubscription();
			this.state.s_m = 5;
			this.state.s_s = null;
			this.state.s_c = null;
			this.state.traceState();
			this.genSendControl();
		}
	}
	,evtExtAbort: function() {
		this.traceEvent("abort");
		if(this.state.s_m == 2) {
			this.state.s_m = 2;
			this.state.traceState();
		} else if(this.state.s_m == 3) {
			this.doAbort();
			this.doSetActive();
			this.state.s_m = 2;
			this.state.traceState();
		} else if(this.state.s_s == 10) {
			this.doAbort();
			this.doSetActive();
			this.notifyOnUnsubscription();
			this.state.s_s = null;
			this.state.s_c = null;
			this.state.s_m = 2;
			this.state.traceState();
		} else if(this.state.s_m == 5) {
			this.finalize();
			this.state.s_m = 32;
			this.state.traceState();
		}
	}
	,evtREQERR: function(reqId,code,msg) {
		this.traceEvent("REQERR");
		if(this.state.s_m == 2 && reqId == this.m_lastAddReqId) {
			this.doSetInactive();
			this.notifyOnSubscriptionError(code,msg);
			this.finalize();
			this.state.s_m = 30;
			this.state.traceState();
		} else if(this.state.s_m == 5 && reqId == this.m_lastDeleteReqId) {
			this.finalize();
			this.state.s_m = 32;
			this.state.traceState();
		} else if(this.state.s_c == 22 && reqId == this.m_lastReconfReqId) {
			if(com_lightstreamer_internal_RequestedMaxFrequencyTools.extEquals(this.m_reqMaxFrequency,this.m_subscription.fetch_requestedMaxFrequency())) {
				this.state.s_c = 21;
				this.state.traceState();
			} else {
				this.state.s_c = 20;
				this.state.traceState();
				this.evtCheckFrequency();
			}
		}
	}
	,evtREQOK: function(reqId) {
		this.traceEvent("REQOK");
		if(this.state.s_m == 2 && reqId == this.m_lastAddReqId) {
			this.state.s_m = 3;
			this.state.traceState();
		} else if(this.state.s_m == 5 && reqId == this.m_lastDeleteReqId) {
			this.finalize();
			this.state.s_m = 32;
			this.state.traceState();
		} else if(this.state.s_c == 22 && reqId == this.m_lastReconfReqId) {
			this.doREQOKConfigure();
			this.state.s_c = 20;
			this.state.traceState();
			this.evtCheckFrequency();
		}
	}
	,checkItems: function(expItems,nItems) {
		if(expItems != null) {
			return expItems == nItems;
		} else {
			return true;
		}
	}
	,checkFields: function(expFields,nFields) {
		if(expFields != null) {
			return expFields == nFields;
		} else {
			return true;
		}
	}
	,checkItemsAndFields: function(expItems,expFields,nItems,nFields) {
		if(this.checkItems(expItems,nItems)) {
			return this.checkFields(expFields,nFields);
		} else {
			return false;
		}
	}
	,evtSUBOK: function(nItems,nFields) {
		this.traceEvent("SUBOK");
		if(this.state.s_m == 2 || this.state.s_m == 3) {
			var tmp = this.m_subscription.fetch_items();
			var expItems = tmp != null ? tmp.length : null;
			var tmp = this.m_subscription.fetch_fields();
			var expFields = tmp != null ? tmp.length : null;
			if(this.checkItemsAndFields(expItems,expFields,nItems,nFields)) {
				this.doSUBOK(nItems,nFields);
				this.notifyOnSubscription();
				this.state.s_m = 4;
				this.state.s_s = 10;
				this.state.s_c = 20;
				this.state.traceState();
				this.evtCheckFrequency();
			} else {
				this.doUnsubscribe();
				this.doSetInactive();
				this.notifyOnSubscriptionError_CountMismatch(expItems,expFields,nItems,nFields);
				this.state.s_m = 5;
				this.state.traceState();
				this.genSendControl();
			}
		}
	}
	,evtSUBCMD: function(nItems,nFields,keyIdx,cmdIdx) {
		this.traceEvent("SUBCMD");
		if(this.state.s_m == 2 || this.state.s_m == 3) {
			var tmp = this.m_subscription.fetch_items();
			var expItems = tmp != null ? tmp.length : null;
			var tmp = this.m_subscription.fetch_fields();
			var expFields = tmp != null ? tmp.length : null;
			if(this.checkItemsAndFields(expItems,expFields,nItems,nFields)) {
				this.doSUBCMD(nItems,nFields,cmdIdx,keyIdx);
				this.notifyOnSubscription();
				this.state.s_m = 4;
				this.state.s_s = 10;
				this.state.s_c = 20;
				this.state.traceState();
				this.evtCheckFrequency();
			} else {
				this.doUnsubscribe();
				this.doSetInactive();
				this.notifyOnSubscriptionError_CountMismatch(expItems,expFields,nItems,nFields);
				this.state.s_m = 5;
				this.state.traceState();
				this.genSendControl();
			}
		}
	}
	,evtUNSUB: function() {
		this.traceEvent("UNSUB");
		if(this.state.s_s == 10) {
			this.doUNSUB();
			this.doSetInactive();
			this.notifyOnUnsubscription();
			this.finalize();
			this.state.s_m = 31;
			this.state.s_s = null;
			this.state.s_c = null;
			this.state.traceState();
		} else if(this.state.s_m == 5) {
			this.finalize();
			this.state.s_m = 32;
			this.state.traceState();
		}
	}
	,evtU: function(itemIdx,values) {
		this.traceEvent("U");
		if(this.state.s_s == 10) {
			this.doU(itemIdx,values);
			this.state.s_s = 10;
			this.state.traceState();
		}
	}
	,evtEOS: function(itemIdx) {
		this.traceEvent("EOS");
		if(this.state.s_s == 10) {
			this.doEOS(itemIdx);
			this.state.s_s = 10;
			this.state.traceState();
		}
	}
	,evtCS: function(itemIdx) {
		this.traceEvent("CS");
		if(this.state.s_s == 10) {
			this.doCS(itemIdx);
			this.state.s_s = 10;
			this.state.traceState();
		}
	}
	,evtOV: function(itemIdx,lostUpdates) {
		this.traceEvent("OV");
		if(this.state.s_s == 10) {
			this.doOV(itemIdx,lostUpdates);
			this.state.s_s = 10;
			this.state.traceState();
		}
	}
	,evtCONF: function(freq) {
		this.traceEvent("CONF");
		if(this.state.s_s == 10) {
			this.doCONF(freq);
			this.state.s_s = 10;
			this.state.traceState();
		}
	}
	,evtCheckFrequency: function() {
		this.traceEvent("check.frequency");
		if(this.state.s_c == 20) {
			if(!com_lightstreamer_internal_RequestedMaxFrequencyTools.extEquals(this.m_subscription.fetch_requestedMaxFrequency(),this.m_currentMaxFrequency)) {
				this.doConfigure();
				this.state.s_c = 22;
				this.state.traceState();
				this.genSendControl();
			} else {
				this.state.s_c = 21;
				this.state.traceState();
			}
		}
	}
	,evtExtConfigure: function() {
		this.traceEvent("configure");
		if(this.state.s_c == 21) {
			this.state.s_c = 20;
			this.state.traceState();
			this.evtCheckFrequency();
		}
	}
	,isPending: function() {
		if(!(this.state.s_m == 2 || this.state.s_m == 5)) {
			return this.state.s_c == 22;
		} else {
			return true;
		}
	}
	,encode: function(isWS) {
		if(this.state.s_m == 2) {
			return this.encodeAdd(isWS);
		} else if(this.state.s_m == 5) {
			return this.encodeDelete(isWS);
		} else if(this.state.s_c == 22) {
			return this.encodeReconf(isWS);
		} else {
			throw new com_lightstreamer_internal_IllegalStateException("Can't encode request");
		}
	}
	,encodeWS: function() {
		return "control\r\n" + this.encode(true);
	}
	,getValue: function(itemPos,fieldPos) {
		return this.m_strategy.getValue(itemPos,fieldPos);
	}
	,getCommandValue: function(itemPos,key,fieldPos) {
		return this.m_strategy.getCommandValue(itemPos,key,fieldPos);
	}
	,encodeAdd: function(isWS) {
		var req = new com_lightstreamer_internal_RequestBuilder();
		this.m_lastAddReqId = this.m_client.generateFreshReqId();
		req.LS_reqId(this.m_lastAddReqId);
		req.LS_op("add");
		req.LS_subId(this.subId);
		req.LS_mode(this.m_subscription.fetch_mode());
		var group = this.m_subscription.getItemGroup();
		var items = this.m_subscription.fetchItems();
		if(group != null) {
			req.LS_group(group);
		} else if(items != null) {
			req.LS_group(items.join(" "));
		}
		var schema = this.m_subscription.getFieldSchema();
		var fields = this.m_subscription.fetchFields();
		if(schema != null) {
			req.LS_schema(schema);
		} else if(fields != null) {
			req.LS_schema(fields.join(" "));
		}
		var adapter = this.m_subscription.getDataAdapter();
		if(adapter != null) {
			req.LS_data_adapter(adapter);
		}
		var selector = this.m_subscription.getSelector();
		if(selector != null) {
			req.LS_selector(selector);
		}
		var snapshot = this.m_subscription.fetch_requestedSnapshot();
		if(snapshot != null) {
			switch(snapshot._hx_index) {
			case 0:
				req.LS_snapshot(true);
				break;
			case 1:
				req.LS_snapshot(false);
				break;
			case 2:
				req.LS_snapshot_Int(snapshot.len);
				break;
			}
		}
		var freq = this.m_currentMaxFrequency;
		if(freq != null) {
			switch(freq._hx_index) {
			case 0:
				req.LS_requested_max_frequency_Float(freq.max);
				break;
			case 1:
				req.LS_requested_max_frequency("unlimited");
				break;
			case 2:
				req.LS_requested_max_frequency("unfiltered");
				break;
			}
		}
		var buff = this.m_subscription.fetch_requestedBufferSize();
		if(buff != null) {
			switch(buff._hx_index) {
			case 0:
				req.LS_requested_buffer_size_Int(buff.size);
				break;
			case 1:
				req.LS_requested_buffer_size("unlimited");
				break;
			}
		}
		if(isWS) {
			req.LS_ack(false);
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending Subscription add: " + Std.string(req));
		}
		return req.getEncodedString();
	}
	,encodeDelete: function(isWS) {
		var req = new com_lightstreamer_internal_RequestBuilder();
		this.m_lastDeleteReqId = this.m_client.generateFreshReqId();
		req.LS_reqId(this.m_lastDeleteReqId);
		req.LS_subId(this.subId);
		req.LS_op("delete");
		if(isWS) {
			req.LS_ack(false);
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending Subscription delete: " + Std.string(req));
		}
		return req.getEncodedString();
	}
	,encodeReconf: function(isWS) {
		var req = new com_lightstreamer_internal_RequestBuilder();
		this.m_lastReconfReqId = this.m_client.generateFreshReqId();
		req.LS_reqId(this.m_lastReconfReqId);
		req.LS_subId(this.subId);
		req.LS_op("reconf");
		var freq = this.m_reqMaxFrequency;
		if(freq != null) {
			switch(freq._hx_index) {
			case 0:
				req.LS_requested_max_frequency_Float(freq.max);
				break;
			case 1:
				req.LS_requested_max_frequency("unlimited");
				break;
			case 2:
				req.LS_requested_max_frequency("unfiltered");
				break;
			}
		}
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending Subscription configuration: " + Std.string(req));
		}
		return req.getEncodedString();
	}
	,doSetActive: function() {
		this.m_subscription.setActive();
	}
	,doSetInactive: function() {
		this.m_subscription.setInactive();
	}
	,doSubscribe: function() {
		this.m_currentMaxFrequency = this.m_subscription.fetch_requestedMaxFrequency();
	}
	,doUnsubscribe: function() {
		this.m_strategy.evtUnsubscribe();
		this.m_subscription.unrelate(this);
	}
	,doAbort: function() {
		this.m_lastAddReqId = null;
		this.m_lastDeleteReqId = null;
		this.m_lastReconfReqId = null;
		this.m_reqMaxFrequency = null;
		this.m_currentMaxFrequency = this.m_subscription.fetch_requestedMaxFrequency();
		this.m_strategy.evtAbort();
	}
	,genSendControl: function() {
		this.m_client.evtSendControl(this);
	}
	,notifyOnSubscription: function() {
		this.m_subscription.fireOnSubscription(this.subId);
	}
	,notifyOnUnsubscription: function() {
		this.m_subscription.fireOnUnsubscription(this.subId);
	}
	,notifyOnSubscriptionError: function(code,msg) {
		this.m_subscription.fireOnSubscriptionError(this.subId,code,msg);
	}
	,notifyOnSubscriptionError_CountMismatch: function(expItems,expFields,nItems,nFields) {
		if(!this.checkItems(expItems,nItems)) {
			this.m_subscription.fireOnSubscriptionError(this.subId,61,"Expected " + expItems + " items but got " + nItems);
		} else {
			this.m_subscription.fireOnSubscriptionError(this.subId,61,"Expected " + expFields + " fields but got " + nFields);
		}
	}
	,doConfigure: function() {
		this.m_reqMaxFrequency = this.m_subscription.fetch_requestedMaxFrequency();
		this.m_strategy.evtSetRequestedMaxFrequency(this.m_reqMaxFrequency);
	}
	,doREQOKConfigure: function() {
		this.m_currentMaxFrequency = this.m_reqMaxFrequency;
	}
	,doSUBOK: function(nItems,nFields) {
		this.m_subscription.setSubscribed(this.subId,nItems,nFields);
		this.m_strategy.evtOnSUB(nItems,nFields,null,null,null);
	}
	,doSUBCMD: function(nItems,nFields,cmdIdx,keyIdx) {
		this.m_subscription.setSubscribedCMD(this.subId,nItems,nFields,cmdIdx,keyIdx);
		this.m_strategy.evtOnSUB(nItems,nFields,cmdIdx,keyIdx,this.m_currentMaxFrequency);
	}
	,doUNSUB: function() {
		this.m_strategy.evtOnUNSUB();
	}
	,doU: function(itemIdx,values) {
		if(itemIdx > com_lightstreamer_internal_NullTools.sure(this.m_subscription.fetch_nItems())) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "itemIdx <= m_subscription.fetch_nItems().sure()");
		}
		this.m_strategy.evtUpdate(itemIdx,values);
	}
	,doEOS: function(itemIdx) {
		this.m_strategy.evtOnEOS(itemIdx);
		this.m_subscription.fireOnEndOfSnapshot(itemIdx,this.subId);
	}
	,doCS: function(itemIdx) {
		this.m_strategy.evtOnCS(itemIdx);
		this.m_subscription.fireOnClearSnapshot(itemIdx,this.subId);
	}
	,doOV: function(itemIdx,lostUpdates) {
		this.m_subscription.fireOnLostUpdates(itemIdx,lostUpdates,this.subId);
	}
	,doCONF: function(freq) {
		this.m_strategy.evtOnCONF(freq);
	}
	,traceEvent: function(evt) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#" + evt + "(" + this.subId + ") in " + Std.string(this.state));
		}
	}
	,__class__: com_lightstreamer_client_internal_SubscriptionManagerLiving
};
var com_lightstreamer_client_internal_SubscriptionManagerZombie = function(subId,client) {
	this.s_m = 1;
	this.subId = subId;
	this.lock = client.lock;
	this.m_client = client;
	client.relateSubManager(this);
};
com_lightstreamer_client_internal_SubscriptionManagerZombie.__name__ = true;
com_lightstreamer_client_internal_SubscriptionManagerZombie.__interfaces__ = [com_lightstreamer_client_internal_SubscriptionManager];
com_lightstreamer_client_internal_SubscriptionManagerZombie.prototype = {
	finalize: function() {
		this.m_client.unrelateSubManager(this);
	}
	,evtExtAbort: function() {
		this.traceEvent("abort");
		if(this.s_m == 2) {
			this.finalize();
			this.goto(3);
		}
	}
	,evtREQERR: function(reqId,code,msg) {
		this.traceEvent("REQERR");
		if(this.s_m == 2 && reqId == this.m_lastDeleteReqId) {
			this.finalize();
			this.goto(3);
		}
	}
	,evtREQOK: function(reqId) {
		this.traceEvent("REQOK");
		if(this.s_m == 2 && reqId == this.m_lastDeleteReqId) {
			this.finalize();
			this.goto(3);
		}
	}
	,evtSUBOK: function(nItems,nFields) {
		this.traceEvent("SUBOK");
		if(this.s_m == 1) {
			this.goto(2);
			this.genSendControl();
		}
	}
	,evtSUBCMD: function(nItems,nFields,keyIdx,cmdIdx) {
		this.traceEvent("SUBCMD");
		if(this.s_m == 1) {
			this.goto(2);
			this.genSendControl();
		}
	}
	,evtUNSUB: function() {
		this.traceEvent("UNSUB");
		if(this.s_m == 2) {
			this.finalize();
			this.goto(3);
		}
	}
	,evtU: function(itemIdx,values) {
		this.traceEvent("U");
		if(this.s_m == 1) {
			this.goto(2);
			this.genSendControl();
		}
	}
	,evtEOS: function(itemIdx) {
		this.traceEvent("EOS");
		if(this.s_m == 1) {
			this.goto(2);
			this.genSendControl();
		}
	}
	,evtCS: function(itemIdx) {
		this.traceEvent("CS");
		if(this.s_m == 1) {
			this.goto(2);
			this.genSendControl();
		}
	}
	,evtOV: function(itemIdx,lostUpdates) {
		this.traceEvent("OV");
		if(this.s_m == 1) {
			this.goto(2);
			this.genSendControl();
		}
	}
	,evtCONF: function(freq) {
		this.traceEvent("CONF");
		if(this.s_m == 1) {
			this.goto(2);
			this.genSendControl();
		}
	}
	,isPending: function() {
		return this.s_m == 2;
	}
	,encode: function(isWS) {
		if(this.isPending()) {
			return this.encodeDelete(isWS);
		} else {
			throw new com_lightstreamer_internal_IllegalStateException("Can't encode unsubscription request");
		}
	}
	,encodeWS: function() {
		return "control\r\n" + this.encode(true);
	}
	,genSendControl: function() {
		this.m_client.evtSendControl(this);
	}
	,encodeDelete: function(isWS) {
		var req = new com_lightstreamer_internal_RequestBuilder();
		this.m_lastDeleteReqId = this.m_client.generateFreshReqId();
		req.LS_reqId(this.m_lastDeleteReqId);
		req.LS_subId(this.subId);
		req.LS_op("delete");
		if(isWS) {
			req.LS_ack(false);
		}
		req.LS_cause("zombie");
		if(com_lightstreamer_log_LoggerTools_protocolLogger.isInfoEnabled()) {
			com_lightstreamer_log_LoggerTools_protocolLogger.info("Sending Subscription delete: " + Std.string(req));
		}
		return req.getEncodedString();
	}
	,traceEvent: function(evt) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("zsub#" + evt + "(" + this.subId + ") in " + this.s_m);
		}
	}
	,goto: function(target) {
		this.s_m = target;
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("zsub#goto(" + this.subId + ") " + this.s_m);
		}
	}
	,__class__: com_lightstreamer_client_internal_SubscriptionManagerZombie
};
var com_lightstreamer_client_internal_update_AbstractItemUpdate = function() { };
com_lightstreamer_client_internal_update_AbstractItemUpdate.__name__ = true;
com_lightstreamer_client_internal_update_AbstractItemUpdate.__interfaces__ = [ItemUpdate];
com_lightstreamer_client_internal_update_AbstractItemUpdate.prototype = {
	__class__: com_lightstreamer_client_internal_update_AbstractItemUpdate
};
var com_lightstreamer_client_internal_update_ItemBase = function(itemIdx,sub,client,subId) {
	this.m_subId = subId;
	this.itemIdx = itemIdx;
	this.subscription = sub;
	this.client = client;
	this.lock = client.lock;
};
com_lightstreamer_client_internal_update_ItemBase.__name__ = true;
com_lightstreamer_client_internal_update_ItemBase.prototype = {
	finalize: function() {
	}
	,evtUpdate: function(values) {
		this.fatalError();
	}
	,evtOnEOS: function() {
		this.fatalError();
	}
	,evtOnCS: function() {
		this.fatalError();
	}
	,evtDispose: function(strategy) {
		this.fatalError();
	}
	,getValue: function(fieldIdx) {
		if(this.currValues != null) {
			return com_lightstreamer_client_internal_update_CurrFieldValTools.toString(this.currValues.h[fieldIdx]);
		} else {
			return null;
		}
	}
	,getCommandValue: function(keyName,fieldIdx) {
		throw new com_lightstreamer_internal_IllegalStateException("Unsupported operation");
	}
	,fatalError: function() {
		throw new com_lightstreamer_internal_IllegalStateException("Unsupported operation");
	}
	,doFirstUpdate: function(values) {
		this.doUpdate(values,false);
	}
	,doUpdate0: function(values) {
		this.doUpdate(values,false);
	}
	,doFirstSnapshot: function(values) {
		this.doUpdate(values,true);
	}
	,doSnapshot: function(values) {
		this.doUpdate(values,true);
	}
	,doUpdate: function(values,snapshot) {
		var prevValues = this.currValues;
		this.currValues = com_lightstreamer_client_internal_update_UpdateUtils_applyUpatesToCurrentFields(prevValues,values);
		var changedFields = com_lightstreamer_client_internal_update_UpdateUtils_findChangedFields(prevValues,this.currValues);
		var jsonPatches = com_lightstreamer_client_internal_update_UpdateUtils_computeJsonPatches(prevValues,values);
		var update = new com_lightstreamer_client_internal_update_ItemUpdateBase(this.itemIdx,this.subscription,this.currValues,changedFields,snapshot,jsonPatches);
		this.subscription.fireOnItemUpdate(update,this.m_subId);
	}
	,__class__: com_lightstreamer_client_internal_update_ItemBase
};
var com_lightstreamer_client_internal_update_ItemCommand = function(itemIdx,sub,client,subId) {
	this.keys = new haxe_ds_StringMap();
	com_lightstreamer_client_internal_update_ItemBase.call(this,itemIdx,sub,client,subId);
	this.s_m = sub.hasSnapshot() ? 3 : 1;
};
com_lightstreamer_client_internal_update_ItemCommand.__name__ = true;
com_lightstreamer_client_internal_update_ItemCommand.__super__ = com_lightstreamer_client_internal_update_ItemBase;
com_lightstreamer_client_internal_update_ItemCommand.prototype = $extend(com_lightstreamer_client_internal_update_ItemBase.prototype,{
	finalize: function() {
	}
	,unrelate: function(keyName) {
		var _this = this.keys;
		if(Object.prototype.hasOwnProperty.call(_this.h,keyName)) {
			delete(_this.h[keyName]);
		}
	}
	,evtUpdate: function(values) {
		this.traceEvent("update");
		switch(this.s_m) {
		case 1:
			this.doFirstUpdate(values);
			this.goto(2);
			break;
		case 2:
			this.doUpdate0(values);
			this.goto(2);
			break;
		case 3:
			this.doFirstSnapshot(values);
			this.goto(4);
			break;
		case 4:
			this.doSnapshot(values);
			this.goto(4);
			break;
		default:
		}
	}
	,evtOnEOS: function() {
		this.traceEvent("onEOS");
		switch(this.s_m) {
		case 3:
			this.goto(1);
			break;
		case 4:
			this.goto(2);
			break;
		default:
		}
	}
	,evtOnCS: function() {
		throw new com_lightstreamer_internal_IllegalStateException("Unsupported method");
	}
	,evtDispose: function(strategy) {
		this.traceEvent("dispose");
		switch(this.s_m) {
		case 1:case 2:case 3:case 4:
			this.finalize();
			this.goto(5);
			this.genDisposeKeys();
			strategy.unrelate(this.itemIdx);
			break;
		default:
		}
	}
	,getCommandValue: function(keyName,fieldIdx) {
		var key = this.keys.h[keyName];
		if(key != null) {
			return key.getCommandValue(fieldIdx);
		} else {
			return null;
		}
	}
	,createKey: function(keyName) {
		throw new com_lightstreamer_internal_IllegalStateException("Abstract method");
	}
	,doUpdate: function(values,snapshot) {
		this.currValues = com_lightstreamer_client_internal_update_UpdateUtils_applyUpatesToCurrentFields(this.currValues,values);
		this.selectKey().evtUpdate(this.currValues,snapshot);
	}
	,genDisposeKeys: function() {
		var h = haxe_ds_StringMap.createCopy(this.keys.h).h;
		var _g_keys = Object.keys(h);
		var _g_length = _g_keys.length;
		var _g_current = 0;
		while(_g_current < _g_length) h[_g_keys[_g_current++]].evtDispose();
	}
	,selectKey: function() {
		var this1 = this.currValues;
		var key = this.subscription.getKeyPosition();
		var keyName = com_lightstreamer_client_internal_update_CurrFieldValTools.toString(this1.h[key]);
		var key = this.keys.h[keyName];
		if(key == null) {
			key = this.createKey(keyName);
			this.keys.h[keyName] = key;
		}
		return key;
	}
	,goto: function(to) {
		this.s_m = to;
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#itm#goto(" + this.m_subId + ":" + this.itemIdx + ") in " + this.s_m);
		}
	}
	,traceEvent: function(evt) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#itm#" + evt + "(" + this.m_subId + ":" + this.itemIdx + ") in " + this.s_m);
		}
	}
	,__class__: com_lightstreamer_client_internal_update_ItemCommand
});
var com_lightstreamer_client_internal_update_ItemCommand1Level = function(itemIdx,sub,client,subId) {
	com_lightstreamer_client_internal_update_ItemCommand.call(this,itemIdx,sub,client,subId);
};
com_lightstreamer_client_internal_update_ItemCommand1Level.__name__ = true;
com_lightstreamer_client_internal_update_ItemCommand1Level.__super__ = com_lightstreamer_client_internal_update_ItemCommand;
com_lightstreamer_client_internal_update_ItemCommand1Level.prototype = $extend(com_lightstreamer_client_internal_update_ItemCommand.prototype,{
	evtOnCS: function() {
		this.traceEvent("onCS");
		switch(this.s_m) {
		case 1:case 2:case 3:case 4:
			this.goto(this.s_m);
			this.genDisposeKeys();
			break;
		default:
		}
	}
	,createKey: function(keyName) {
		return new com_lightstreamer_client_internal_update_Key1Level(keyName,this);
	}
	,__class__: com_lightstreamer_client_internal_update_ItemCommand1Level
});
var com_lightstreamer_client_internal_update_ItemCommand2Level = function(itemIdx,sub,strategy,client,subId) {
	com_lightstreamer_client_internal_update_ItemCommand.call(this,itemIdx,sub,client,subId);
	this.strategy = strategy;
};
com_lightstreamer_client_internal_update_ItemCommand2Level.__name__ = true;
com_lightstreamer_client_internal_update_ItemCommand2Level.__super__ = com_lightstreamer_client_internal_update_ItemCommand;
com_lightstreamer_client_internal_update_ItemCommand2Level.prototype = $extend(com_lightstreamer_client_internal_update_ItemCommand.prototype,{
	evtOnCS: function() {
		this.traceEvent("onCS");
		switch(this.s_m) {
		case 1:case 2:case 3:case 4:
			this.goto(this.s_m);
			this.genDisposeKeys();
			this.genOnRealMaxFrequency2LevelRemoved();
			break;
		default:
		}
	}
	,evtSetRequestedMaxFrequency: function() {
		this.traceEvent("setRequestedMaxFrequency");
		switch(this.s_m) {
		case 1:case 2:case 3:case 4:
			this.goto(this.s_m);
			this.genSetRequestedMaxFrequency();
			break;
		default:
		}
	}
	,createKey: function(keyName) {
		return new com_lightstreamer_client_internal_update_Key2Level(keyName,this);
	}
	,genSetRequestedMaxFrequency: function() {
		var h = this.keys.h;
		var _g_keys = Object.keys(h);
		var _g_length = _g_keys.length;
		var _g_current = 0;
		while(_g_current < _g_length) h[_g_keys[_g_current++]].evtSetRequestedMaxFrequency();
	}
	,genOnRealMaxFrequency2LevelRemoved: function() {
		this.strategy.evtOnRealMaxFrequency2LevelRemoved();
	}
	,__class__: com_lightstreamer_client_internal_update_ItemCommand2Level
});
var com_lightstreamer_client_internal_update_ItemDistinct = function(itemIdx,sub,client,subId) {
	com_lightstreamer_client_internal_update_ItemBase.call(this,itemIdx,sub,client,subId);
	this.s_m = sub.hasSnapshot() ? 3 : 1;
};
com_lightstreamer_client_internal_update_ItemDistinct.__name__ = true;
com_lightstreamer_client_internal_update_ItemDistinct.__super__ = com_lightstreamer_client_internal_update_ItemBase;
com_lightstreamer_client_internal_update_ItemDistinct.prototype = $extend(com_lightstreamer_client_internal_update_ItemBase.prototype,{
	evtUpdate: function(values) {
		this.traceEvent("update");
		switch(this.s_m) {
		case 1:
			this.doFirstUpdate(values);
			this.goto(2);
			break;
		case 2:
			this.doUpdate0(values);
			this.goto(2);
			break;
		case 3:
			this.doFirstSnapshot(values);
			this.goto(4);
			break;
		case 4:
			this.doSnapshot(values);
			this.goto(4);
			break;
		default:
		}
	}
	,evtOnEOS: function() {
		this.traceEvent("onEOS");
		switch(this.s_m) {
		case 3:
			this.goto(1);
			break;
		case 4:
			this.goto(2);
			break;
		default:
		}
	}
	,evtOnCS: function() {
	}
	,evtDispose: function(strategy) {
		this.traceEvent("dispose");
		switch(this.s_m) {
		case 1:case 2:case 3:case 4:
			this.finalize();
			this.goto(5);
			strategy.unrelate(this.itemIdx);
			break;
		default:
		}
	}
	,goto: function(to) {
		this.s_m = to;
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#itm#goto(" + this.m_subId + ":" + this.itemIdx + ") " + this.s_m);
		}
	}
	,traceEvent: function(evt) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#itm#" + evt + "(" + this.m_subId + ":" + this.itemIdx + ") in " + this.s_m);
		}
	}
	,__class__: com_lightstreamer_client_internal_update_ItemDistinct
});
var com_lightstreamer_client_internal_update_ItemKey = function() { };
com_lightstreamer_client_internal_update_ItemKey.__name__ = true;
com_lightstreamer_client_internal_update_ItemKey.__isInterface__ = true;
com_lightstreamer_client_internal_update_ItemKey.prototype = {
	__class__: com_lightstreamer_client_internal_update_ItemKey
};
var com_lightstreamer_client_internal_update_ItemMerge = function(itemIdx,sub,client,subId) {
	this.s_m = 1;
	com_lightstreamer_client_internal_update_ItemBase.call(this,itemIdx,sub,client,subId);
};
com_lightstreamer_client_internal_update_ItemMerge.__name__ = true;
com_lightstreamer_client_internal_update_ItemMerge.__super__ = com_lightstreamer_client_internal_update_ItemBase;
com_lightstreamer_client_internal_update_ItemMerge.prototype = $extend(com_lightstreamer_client_internal_update_ItemBase.prototype,{
	evtUpdate: function(values) {
		this.traceEvent("update");
		switch(this.s_m) {
		case 1:
			if(this.subscription.hasSnapshot()) {
				this.doSnapshot(values);
				this.goto(2);
			} else {
				this.doFirstUpdate(values);
				this.goto(3);
			}
			break;
		case 2:
			this.doUpdate0(values);
			this.goto(3);
			break;
		case 3:
			this.doUpdate0(values);
			this.goto(3);
			break;
		default:
		}
	}
	,evtDispose: function(strategy) {
		this.traceEvent("dispose");
		switch(this.s_m) {
		case 1:case 2:case 3:
			this.finalize();
			this.goto(4);
			strategy.unrelate(this.itemIdx);
			break;
		default:
		}
	}
	,goto: function(to) {
		this.s_m = to;
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#itm#goto(" + this.m_subId + ":" + this.itemIdx + ") " + this.s_m);
		}
	}
	,traceEvent: function(evt) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#itm#" + evt + "(" + this.m_subId + ":" + this.itemIdx + ") in " + this.s_m);
		}
	}
	,__class__: com_lightstreamer_client_internal_update_ItemMerge
});
var com_lightstreamer_client_internal_update_ItemRaw = function(itemIdx,sub,client,subId) {
	this.s_m = 1;
	com_lightstreamer_client_internal_update_ItemBase.call(this,itemIdx,sub,client,subId);
};
com_lightstreamer_client_internal_update_ItemRaw.__name__ = true;
com_lightstreamer_client_internal_update_ItemRaw.__super__ = com_lightstreamer_client_internal_update_ItemBase;
com_lightstreamer_client_internal_update_ItemRaw.prototype = $extend(com_lightstreamer_client_internal_update_ItemBase.prototype,{
	evtUpdate: function(values) {
		this.traceEvent("update");
		switch(this.s_m) {
		case 1:
			this.doFirstUpdate(values);
			this.goto(2);
			break;
		case 2:
			this.doUpdate0(values);
			this.goto(2);
			break;
		default:
		}
	}
	,evtDispose: function(strategy) {
		this.traceEvent("dispose");
		switch(this.s_m) {
		case 1:case 2:
			this.finalize();
			this.goto(3);
			strategy.unrelate(this.itemIdx);
			break;
		default:
		}
	}
	,goto: function(to) {
		this.s_m = to;
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#itm#goto(" + this.m_subId + ":" + this.itemIdx + ") " + this.s_m);
		}
	}
	,traceEvent: function(evt) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#itm#" + evt + "(" + this.m_subId + ":" + this.itemIdx + ") in " + this.s_m);
		}
	}
	,__class__: com_lightstreamer_client_internal_update_ItemRaw
});
var com_lightstreamer_client_internal_update_ItemUpdate2Level = function(itemIdx,sub,newValues,changedFields,isSnapshot,jsonPatches) {
	var items = sub.fetch_items();
	var fields = sub.fetch_fields();
	var fields2 = sub.fetch_fields2();
	this.m_itemIdx = itemIdx;
	this.m_items = com_lightstreamer_client_internal_update_UpdateUtils_toMap(items);
	this.m_nFields = com_lightstreamer_internal_NullTools.sure(sub.fetch_nFields());
	this.m_fields = com_lightstreamer_client_internal_update_UpdateUtils_toMap(fields);
	this.m_fields2 = com_lightstreamer_client_internal_update_UpdateUtils_toMap(fields2);
	this.m_newValues = newValues.copy();
	this.m_changedFields = changedFields.copy();
	this.m_isSnapshot = isSnapshot;
	this.m_jsonPatches = jsonPatches;
};
com_lightstreamer_client_internal_update_ItemUpdate2Level.__name__ = true;
com_lightstreamer_client_internal_update_ItemUpdate2Level.__super__ = com_lightstreamer_client_internal_update_AbstractItemUpdate;
com_lightstreamer_client_internal_update_ItemUpdate2Level.prototype = $extend(com_lightstreamer_client_internal_update_AbstractItemUpdate.prototype,{
	getItemName: function() {
		if(this.m_items != null) {
			return this.m_items.h[this.m_itemIdx];
		} else {
			return null;
		}
	}
	,getItemPos: function() {
		return this.m_itemIdx;
	}
	,isSnapshot: function() {
		return this.m_isSnapshot;
	}
	,getValue: function(fieldNameOrPos) {
		if(typeof(fieldNameOrPos) == "number" && ((fieldNameOrPos | 0) === fieldNameOrPos)) {
			return this.getValuePos(fieldNameOrPos);
		} else {
			return this.getValueName(Std.string(fieldNameOrPos));
		}
	}
	,isValueChanged: function(fieldNameOrPos) {
		if(typeof(fieldNameOrPos) == "number" && ((fieldNameOrPos | 0) === fieldNameOrPos)) {
			return this.isValueChangedPos(fieldNameOrPos);
		} else {
			return this.isValueChangedName(Std.string(fieldNameOrPos));
		}
	}
	,_getValueAsJSONPatchIfAvailable: function(fieldNameOrPos) {
		if(typeof(fieldNameOrPos) == "number" && ((fieldNameOrPos | 0) === fieldNameOrPos)) {
			return this.m_jsonPatches.h[fieldNameOrPos];
		} else {
			var fieldPos = this.getFieldIdxFromName(Std.string(fieldNameOrPos));
			if(fieldPos != null) {
				return this.m_jsonPatches.h[fieldPos];
			} else {
				return null;
			}
		}
	}
	,getValueAsJSONPatchIfAvailable: function(fieldNameOrPos) {
		return this._getValueAsJSONPatchIfAvailable(fieldNameOrPos);
	}
	,forEachChangedField: function(iterator) {
		var _g = this.m_changedFields.iterator();
		while(_g.current < _g.array.length) {
			var fieldPos = _g.array[_g.current++];
			try {
				iterator(this.getFieldNameFromIdx(fieldPos),fieldPos,com_lightstreamer_client_internal_update_CurrFieldValTools.toString(this.m_newValues.h[fieldPos]));
			} catch( _g1 ) {
				var _g2 = haxe_Exception.caught(_g1);
				if(com_lightstreamer_log_LoggerTools_actionLogger.isErrorEnabled()) {
					com_lightstreamer_log_LoggerTools_actionLogger.error("An exception was thrown while executing the Function passed to the forEachChangedField method" + "\n" + _g2.details());
				}
			}
		}
	}
	,forEachField: function(iterator) {
		var this1 = this.m_newValues;
		var _g_keys = this1.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			var _g_value = this1.get(key);
			try {
				iterator(this.getFieldNameFromIdx(key),key,com_lightstreamer_client_internal_update_CurrFieldValTools.toString(_g_value));
			} catch( _g ) {
				var _g1 = haxe_Exception.caught(_g);
				if(com_lightstreamer_log_LoggerTools_actionLogger.isErrorEnabled()) {
					com_lightstreamer_log_LoggerTools_actionLogger.error("An exception was thrown while executing the Function passed to the forEachField method" + "\n" + _g1.details());
				}
			}
		}
	}
	,getValuePos: function(fieldPos) {
		return com_lightstreamer_client_internal_update_CurrFieldValTools.toString(this.m_newValues.h[fieldPos]);
	}
	,getValueName: function(fieldName) {
		if(this.m_fields == null && this.m_fields2 == null) {
			throw new com_lightstreamer_internal_IllegalStateException(com_lightstreamer_client_internal_update_ItemUpdateBase_NO_FIELDS);
		}
		var fieldPos = this.getFieldIdxFromName(fieldName);
		if(fieldPos == null) {
			throw new com_lightstreamer_internal_IllegalArgumentException(com_lightstreamer_client_internal_update_ItemUpdateBase_UNKNOWN_FIELD_NAME);
		}
		return com_lightstreamer_client_internal_update_CurrFieldValTools.toString(this.m_newValues.h[fieldPos]);
	}
	,isValueChangedPos: function(fieldPos) {
		return this.m_changedFields.contains(fieldPos);
	}
	,isValueChangedName: function(fieldName) {
		if(this.m_fields == null && this.m_fields2 == null) {
			throw new com_lightstreamer_internal_IllegalStateException(com_lightstreamer_client_internal_update_ItemUpdateBase_NO_FIELDS);
		}
		var fieldPos = this.getFieldIdxFromName(fieldName);
		if(fieldPos == null) {
			throw new com_lightstreamer_internal_IllegalArgumentException(com_lightstreamer_client_internal_update_ItemUpdateBase_UNKNOWN_FIELD_NAME);
		}
		return this.m_changedFields.contains(fieldPos);
	}
	,getFieldNameFromIdx: function(fieldIdx) {
		if(fieldIdx <= this.m_nFields) {
			if(this.m_fields != null) {
				return this.m_fields.h[fieldIdx];
			} else {
				return null;
			}
		} else if(this.m_fields2 != null) {
			return this.m_fields2.h[fieldIdx - this.m_nFields];
		} else {
			return null;
		}
	}
	,getFieldIdxFromName: function(fieldName) {
		var fields2;
		var fieldPos;
		var tmp;
		var fields = this.m_fields;
		if(fields != null) {
			fieldPos = com_lightstreamer_client_internal_update_UpdateUtils_findFirstIndex(com_lightstreamer_internal_NullTools.sure(fields),fieldName);
			tmp = fieldPos != null;
		} else {
			tmp = false;
		}
		if(tmp) {
			return fieldPos;
		} else {
			var tmp;
			fields2 = this.m_fields2;
			if(fields2 != null) {
				fieldPos = com_lightstreamer_client_internal_update_UpdateUtils_findFirstIndex(com_lightstreamer_internal_NullTools.sure(fields2),fieldName);
				tmp = fieldPos != null;
			} else {
				tmp = false;
			}
			if(tmp) {
				return this.m_nFields + com_lightstreamer_internal_NullTools.sure(fieldPos);
			} else {
				return null;
			}
		}
	}
	,toString: function() {
		var s_b = "";
		s_b = "[";
		var this1 = this.m_newValues;
		var _g_keys = this1.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			var _g_value = this1.get(key);
			var tmp = this.getFieldNameFromIdx(key);
			var fieldName = tmp != null ? tmp : key == null ? "null" : "" + key;
			var fieldVal = Std.string(_g_value);
			if(key > 1) {
				s_b += ",";
			}
			s_b += fieldName == null ? "null" : "" + fieldName;
			s_b += ":";
			s_b += fieldVal == null ? "null" : "" + fieldVal;
		}
		s_b += "]";
		return s_b;
	}
	,__class__: com_lightstreamer_client_internal_update_ItemUpdate2Level
});
var com_lightstreamer_client_internal_update_ItemUpdateBase = function(itemIdx,sub,newValues,changedFields,isSnapshot,jsonPatches) {
	var items = sub.fetch_items();
	var fields = sub.fetch_fields();
	this.m_itemIdx = itemIdx;
	this.m_items = com_lightstreamer_client_internal_update_UpdateUtils_toMap(items);
	this.m_nFields = com_lightstreamer_internal_NullTools.sure(sub.fetch_nFields());
	this.m_fields = com_lightstreamer_client_internal_update_UpdateUtils_toMap(fields);
	if(fields != null && fields.length != this.m_nFields) {
		if(com_lightstreamer_log_LoggerTools_subscriptionLogger.isErrorEnabled()) {
			com_lightstreamer_log_LoggerTools_subscriptionLogger.error("Expected " + this.m_nFields + " field names but got " + fields.length + ": " + Std.string(fields));
		}
	}
	this.m_newValues = newValues.copy();
	this.m_changedFields = changedFields.copy();
	this.m_isSnapshot = isSnapshot;
	this.m_jsonPatches = jsonPatches;
};
com_lightstreamer_client_internal_update_ItemUpdateBase.__name__ = true;
com_lightstreamer_client_internal_update_ItemUpdateBase.__super__ = com_lightstreamer_client_internal_update_AbstractItemUpdate;
com_lightstreamer_client_internal_update_ItemUpdateBase.prototype = $extend(com_lightstreamer_client_internal_update_AbstractItemUpdate.prototype,{
	getItemName: function() {
		if(this.m_items != null) {
			return this.m_items.h[this.m_itemIdx];
		} else {
			return null;
		}
	}
	,getItemPos: function() {
		return this.m_itemIdx;
	}
	,isSnapshot: function() {
		return this.m_isSnapshot;
	}
	,getValue: function(fieldNameOrPos) {
		if(typeof(fieldNameOrPos) == "number" && ((fieldNameOrPos | 0) === fieldNameOrPos)) {
			return this.getValuePos(fieldNameOrPos);
		} else {
			return this.getValueName(Std.string(fieldNameOrPos));
		}
	}
	,isValueChanged: function(fieldNameOrPos) {
		if(typeof(fieldNameOrPos) == "number" && ((fieldNameOrPos | 0) === fieldNameOrPos)) {
			return this.isValueChangedPos(fieldNameOrPos);
		} else {
			return this.isValueChangedName(Std.string(fieldNameOrPos));
		}
	}
	,_getValueAsJSONPatchIfAvailable: function(fieldNameOrPos) {
		if(typeof(fieldNameOrPos) == "number" && ((fieldNameOrPos | 0) === fieldNameOrPos)) {
			return this.m_jsonPatches.h[fieldNameOrPos];
		} else {
			var fieldPos = this.getFieldIdxFromName(Std.string(fieldNameOrPos));
			return this.m_jsonPatches.h[fieldPos];
		}
	}
	,getValueAsJSONPatchIfAvailable: function(fieldNameOrPos) {
		return this._getValueAsJSONPatchIfAvailable(fieldNameOrPos);
	}
	,forEachChangedField: function(iterator) {
		var _g = this.m_changedFields.iterator();
		while(_g.current < _g.array.length) {
			var fieldPos = _g.array[_g.current++];
			try {
				iterator(this.m_fields != null ? this.m_fields.h[fieldPos] : null,fieldPos,com_lightstreamer_client_internal_update_CurrFieldValTools.toString(this.m_newValues.h[fieldPos]));
			} catch( _g1 ) {
				var _g2 = haxe_Exception.caught(_g1);
				if(com_lightstreamer_log_LoggerTools_actionLogger.isErrorEnabled()) {
					com_lightstreamer_log_LoggerTools_actionLogger.error("An exception was thrown while executing the Function passed to the forEachChangedField method" + "\n" + _g2.details());
				}
			}
		}
	}
	,forEachField: function(iterator) {
		var this1 = this.m_newValues;
		var _g_keys = this1.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			var _g_value = this1.get(key);
			try {
				iterator(this.m_fields != null ? this.m_fields.h[key] : null,key,com_lightstreamer_client_internal_update_CurrFieldValTools.toString(_g_value));
			} catch( _g ) {
				var _g1 = haxe_Exception.caught(_g);
				if(com_lightstreamer_log_LoggerTools_actionLogger.isErrorEnabled()) {
					com_lightstreamer_log_LoggerTools_actionLogger.error("An exception was thrown while executing the Function passed to the forEachField method" + "\n" + _g1.details());
				}
			}
		}
	}
	,getValuePos: function(fieldPos) {
		if(!(1 <= fieldPos && fieldPos <= this.m_nFields)) {
			throw new com_lightstreamer_internal_IllegalArgumentException(com_lightstreamer_client_internal_update_ItemUpdateBase_POS_OUT_BOUNDS);
		}
		return com_lightstreamer_client_internal_update_CurrFieldValTools.toString(this.m_newValues.h[fieldPos]);
	}
	,getValueName: function(fieldName) {
		var fieldPos = this.getFieldIdxFromName(fieldName);
		return com_lightstreamer_client_internal_update_CurrFieldValTools.toString(this.m_newValues.h[fieldPos]);
	}
	,isValueChangedPos: function(fieldPos) {
		if(!(1 <= fieldPos && fieldPos <= this.m_nFields)) {
			throw new com_lightstreamer_internal_IllegalArgumentException(com_lightstreamer_client_internal_update_ItemUpdateBase_POS_OUT_BOUNDS);
		}
		return this.m_changedFields.contains(fieldPos);
	}
	,isValueChangedName: function(fieldName) {
		var fieldPos = this.getFieldIdxFromName(fieldName);
		return this.m_changedFields.contains(fieldPos);
	}
	,getFieldNameOrNullFromIdx: function(fieldIdx) {
		if(this.m_fields != null) {
			return this.m_fields.h[fieldIdx];
		} else {
			return null;
		}
	}
	,getFieldIdxFromName: function(fieldName) {
		if(this.m_fields == null) {
			throw new com_lightstreamer_internal_IllegalStateException(com_lightstreamer_client_internal_update_ItemUpdateBase_NO_FIELDS);
		}
		var fieldPos = com_lightstreamer_client_internal_update_UpdateUtils_findFirstIndex(this.m_fields,fieldName);
		if(fieldPos == null) {
			throw new com_lightstreamer_internal_IllegalArgumentException(com_lightstreamer_client_internal_update_ItemUpdateBase_UNKNOWN_FIELD_NAME);
		}
		return fieldPos;
	}
	,toString: function() {
		var s_b = "";
		s_b = "[";
		var this1 = this.m_newValues;
		var _g_keys = this1.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			var _g_value = this1.get(key);
			var tmp = this.getFieldNameOrNullFromIdx(key);
			var fieldName = tmp != null ? tmp : key == null ? "null" : "" + key;
			var fieldVal = Std.string(_g_value);
			if(key > 1) {
				s_b += ",";
			}
			s_b += fieldName == null ? "null" : "" + fieldName;
			s_b += ":";
			s_b += fieldVal == null ? "null" : "" + fieldVal;
		}
		s_b += "]";
		return s_b;
	}
	,__class__: com_lightstreamer_client_internal_update_ItemUpdateBase
});
var com_lightstreamer_client_internal_update_Key1Level = function(keyName,item) {
	this.s_m = 1;
	this.keyName = keyName;
	this.item = item;
	this.lock = item.lock;
};
com_lightstreamer_client_internal_update_Key1Level.__name__ = true;
com_lightstreamer_client_internal_update_Key1Level.__interfaces__ = [com_lightstreamer_client_internal_update_ItemKey];
com_lightstreamer_client_internal_update_Key1Level.prototype = {
	finalize: function() {
		this.currKeyValues = null;
		this.item.unrelate(this.keyName);
	}
	,evtUpdate: function(keyValues,snapshot) {
		this.traceEvent("update");
		switch(this.s_m) {
		case 1:
			if(!this.isDelete(keyValues)) {
				this.doFirstUpdate(keyValues,snapshot);
				this.goto(2);
			} else {
				this.doLightDelete(keyValues,snapshot);
				this.finalize();
				this.goto(3);
			}
			break;
		case 2:
			if(!this.isDelete(keyValues)) {
				this.doUpdate(keyValues,snapshot);
				this.goto(2);
			} else {
				this.doDelete(keyValues,snapshot);
				this.finalize();
				this.goto(3);
			}
			break;
		default:
		}
	}
	,evtDispose: function() {
		this.traceEvent("dispose");
		switch(this.s_m) {
		case 1:case 2:
			this.finalize();
			this.goto(3);
			break;
		default:
		}
	}
	,evtSetRequestedMaxFrequency: function() {
	}
	,getCommandValue: function(fieldIdx) {
		if(this.currKeyValues != null) {
			return com_lightstreamer_client_internal_update_CurrFieldValTools.toString(this.currKeyValues.h[fieldIdx]);
		} else {
			return null;
		}
	}
	,doFirstUpdate: function(keyValues,snapshot) {
		var nFields = com_lightstreamer_internal_NullTools.sure(this.item.subscription.fetch_nFields());
		var cmdIdx = this.item.subscription.getCommandPosition();
		this.currKeyValues = keyValues;
		this.currKeyValues.h[cmdIdx] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal("ADD");
		var changedFields = new com_lightstreamer_internal_Set(new IntIterator(1,nFields + 1));
		this.fireOnItemUpdate(new com_lightstreamer_client_internal_update_ItemUpdateBase(this.item.itemIdx,this.item.subscription,this.currKeyValues,changedFields,snapshot,new haxe_ds_IntMap()));
	}
	,doUpdate: function(keyValues,snapshot) {
		var cmdIdx = this.item.subscription.getCommandPosition();
		var prevKeyValues = this.currKeyValues;
		this.currKeyValues = keyValues;
		this.currKeyValues.h[cmdIdx] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal("UPDATE");
		var changedFields = com_lightstreamer_client_internal_update_UpdateUtils_findChangedFields(prevKeyValues,this.currKeyValues);
		this.fireOnItemUpdate(new com_lightstreamer_client_internal_update_ItemUpdateBase(this.item.itemIdx,this.item.subscription,this.currKeyValues,changedFields,snapshot,new haxe_ds_IntMap()));
	}
	,doLightDelete: function(keyValues,snapshot) {
		this.currKeyValues = null;
		var changedFields = new com_lightstreamer_internal_Set(keyValues.keys());
		var update = new com_lightstreamer_client_internal_update_ItemUpdateBase(this.item.itemIdx,this.item.subscription,this.nullify(keyValues),changedFields,snapshot,new haxe_ds_IntMap());
		this.item.unrelate(this.keyName);
		this.fireOnItemUpdate(update);
	}
	,doDelete: function(keyValues,snapshot) {
		this.currKeyValues = null;
		var changedFields = new com_lightstreamer_internal_Set(keyValues.keys()).subtracting([this.item.subscription.getKeyPosition()]);
		var update = new com_lightstreamer_client_internal_update_ItemUpdateBase(this.item.itemIdx,this.item.subscription,this.nullify(keyValues),changedFields,snapshot,new haxe_ds_IntMap());
		this.item.unrelate(this.keyName);
		this.fireOnItemUpdate(update);
	}
	,nullify: function(keyValues) {
		var values = new haxe_ds_IntMap();
		var map = keyValues;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			var _g_value = map.get(key);
			var newVal = key == this.item.subscription.getCommandPosition() || key == this.item.subscription.getKeyPosition();
			values.h[key] = newVal ? _g_value : null;
		}
		return values;
	}
	,isDelete: function(keyValues) {
		var key = this.item.subscription.getCommandPosition();
		return com_lightstreamer_client_internal_update_CurrFieldValTools.toString(keyValues.h[key]) == "DELETE";
	}
	,fireOnItemUpdate: function(update) {
		this.item.subscription.fireOnItemUpdate(update,this.item.m_subId);
	}
	,goto: function(to) {
		this.s_m = to;
		this.traceEvent("goto");
	}
	,traceEvent: function(evt) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			var subId = this.item.m_subId;
			var itemIdx = this.item.itemIdx;
			if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
				com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#key#" + evt + "(" + subId + ":" + itemIdx + ":" + this.keyName + ") in " + this.s_m);
			}
		}
	}
	,__class__: com_lightstreamer_client_internal_update_Key1Level
};
var com_lightstreamer_client_internal_update_Key2Level = function(keyName,item) {
	this.s_m = 1;
	this.keyName = keyName;
	this.item = item;
	this.lock = item.lock;
};
com_lightstreamer_client_internal_update_Key2Level.__name__ = true;
com_lightstreamer_client_internal_update_Key2Level.__interfaces__ = [com_lightstreamer_client_internal_update_ItemKey];
com_lightstreamer_client_internal_update_Key2Level.prototype = {
	finalize: function() {
		this.currKeyValues = null;
		this.currKey2Values = null;
		this.item.unrelate(this.keyName);
	}
	,evtUpdate: function(keyValues,snapshot) {
		this.traceEvent("update");
		switch(this.s_m) {
		case 1:
			if(!this.isDelete(keyValues)) {
				var sub = this.create2LevelSubscription();
				if(sub != null) {
					this.doFirstUpdate(keyValues,snapshot);
					this.subscription2Level = sub;
					this.goto(4);
					this.item.strategy.client.subscribeExt(sub,true);
				} else {
					this.doFirstUpdate(keyValues,snapshot);
					this.notify2LevelIllegalArgument();
					this.goto(3);
				}
			} else {
				this.doLightDelete(keyValues,snapshot);
				this.finalize();
				this.goto(11);
				this.genOnRealMaxFrequency2LevelRemoved();
			}
			break;
		case 3:
			if(!this.isDelete(keyValues)) {
				this.doUpdate(keyValues,snapshot);
				this.goto(3);
			} else {
				this.doDelete1LevelOnly(keyValues,snapshot);
				this.finalize();
				this.goto(11);
				this.genOnRealMaxFrequency2LevelRemoved();
			}
			break;
		case 4:
			if(!this.isDelete(keyValues)) {
				this.doUpdate(keyValues,snapshot);
				this.goto(4);
			} else {
				this.doDelete(keyValues,snapshot);
				this.finalize();
				this.goto(11);
				this.genOnRealMaxFrequency2LevelRemoved();
			}
			break;
		case 5:
			if(!this.isDelete(keyValues)) {
				this.doUpdate1Level(keyValues,snapshot);
				this.goto(5);
			} else {
				this.doDeleteExt(keyValues,snapshot);
				this.finalize();
				this.goto(11);
				this.genOnRealMaxFrequency2LevelRemoved();
			}
			break;
		default:
		}
	}
	,evtDispose: function() {
		this.traceEvent("dispose");
		switch(this.s_m) {
		case 1:case 2:case 3:
			this.finalize();
			this.goto(10);
			break;
		case 4:case 5:
			this.doUnsubscribe();
			this.finalize();
			this.goto(12);
			break;
		default:
		}
	}
	,evtOnSubscriptionError2Level: function(code,msg) {
		this.traceEvent("onSubscriptionError2Level");
		if(this.s_m == 4) {
			this.notify2LevelSubscriptionError(code,msg);
			this.goto(3);
		}
	}
	,evtUpdate2Level: function(update) {
		this.traceEvent("update2Level");
		switch(this.s_m) {
		case 4:
			this.doUpdate2Level(update);
			this.goto(5);
			break;
		case 5:
			this.doUpdate2Level(update);
			this.goto(5);
			break;
		default:
		}
	}
	,evtOnUnsubscription2Level: function() {
		this.traceEvent("onUnsubscription2Level");
		switch(this.s_m) {
		case 4:case 5:
			this.doUnsetRealMaxFrequency();
			this.goto(this.s_m);
			this.genOnRealMaxFrequency2LevelRemoved();
			break;
		default:
		}
	}
	,evtOnItemLostUpdates2Level: function(lostUpdates) {
		this.traceEvent("onItemLostUpdates2Level");
		switch(this.s_m) {
		case 4:case 5:
			this.goto(this.s_m);
			this.notify2LevelLostUpdates(lostUpdates);
			break;
		default:
		}
	}
	,evtOnRealMaxFrequency2Level: function(maxFrequency) {
		this.traceEvent("onRealMaxFrequency2Level");
		switch(this.s_m) {
		case 4:case 5:
			this.doSetRealMaxFrequency(maxFrequency);
			this.goto(this.s_m);
			this.genOnRealMaxFrequency2LevelAdded();
			break;
		default:
		}
	}
	,evtSetRequestedMaxFrequency: function() {
		this.traceEvent("setRequestedMaxFrequency");
		switch(this.s_m) {
		case 4:case 5:
			this.doChangeRequestedMaxFrequency();
			this.goto(this.s_m);
			break;
		default:
		}
	}
	,getCommandValue: function(fieldIdx) {
		var values = this.currKeyValues;
		if(values != null && values.h[fieldIdx] != null) {
			return com_lightstreamer_client_internal_update_CurrFieldValTools.toString(values.h[fieldIdx]);
		} else {
			var values = this.currKey2Values;
			var nFields = this.item.subscription.fetch_nFields();
			if(values != null && nFields != null) {
				return values.h[fieldIdx - nFields];
			} else {
				return null;
			}
		}
	}
	,doFirstUpdate: function(keyValues,snapshot) {
		var cmdIdx = this.item.subscription.getCommandPosition();
		this.currKeyValues = keyValues;
		this.currKeyValues.h[cmdIdx] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal("ADD");
		var changedFields = com_lightstreamer_client_internal_update_UpdateUtils_findChangedFields(null,this.currKeyValues);
		this.fireOnItemUpdate(new com_lightstreamer_client_internal_update_ItemUpdate2Level(this.item.itemIdx,this.item.subscription,this.currKeyValues,changedFields,snapshot,new haxe_ds_IntMap()));
	}
	,doUpdate: function(keyValues,snapshot) {
		var cmdIdx = this.item.subscription.getCommandPosition();
		var prevKeyValues = this.currKeyValues;
		this.currKeyValues = keyValues;
		this.currKeyValues.h[cmdIdx] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal("UPDATE");
		var changedFields = com_lightstreamer_client_internal_update_UpdateUtils_findChangedFields(prevKeyValues,this.currKeyValues);
		this.fireOnItemUpdate(new com_lightstreamer_client_internal_update_ItemUpdate2Level(this.item.itemIdx,this.item.subscription,this.currKeyValues,changedFields,snapshot,new haxe_ds_IntMap()));
	}
	,doUpdate2Level: function(update) {
		var cmdIdx = this.item.subscription.getCommandPosition();
		var nFields = com_lightstreamer_internal_NullTools.sure(this.item.subscription.fetch_nFields());
		var prevKeyValues = com_lightstreamer_internal_NullTools.sure(this.currKeyValues).copy();
		this.currKeyValues.h[cmdIdx] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal("UPDATE");
		this.currKey2Values = com_lightstreamer_client_internal_update_UpdateUtils_getFieldsByPosition(update);
		var extKeyValues = com_lightstreamer_internal_NullTools.sure(this.currKeyValues).copy();
		var this1 = this.currKey2Values;
		var _g_keys = this1.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			var _g_value = this1.get(key);
			extKeyValues.h[key + nFields] = _g_value == null ? null : com_lightstreamer_client_internal_update_CurrFieldVal.StringVal(_g_value);
		}
		var changedFields = new com_lightstreamer_internal_Set();
		if(com_lightstreamer_client_internal_update_CurrFieldValTools.toString(prevKeyValues.h[cmdIdx]) != com_lightstreamer_client_internal_update_CurrFieldValTools.toString(this.currKeyValues.h[cmdIdx])) {
			changedFields.insert(cmdIdx);
		}
		var this1 = com_lightstreamer_client_internal_update_UpdateUtils_getChangedFieldsByPosition(update);
		var _g_keys = this1.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			this1.get(key);
			changedFields.insert(key + nFields);
		}
		var snapshot = update.isSnapshot();
		var jsonPatches = new haxe_ds_IntMap();
		var this1 = this.currKey2Values;
		var _g_keys = this1.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			this1.get(key);
			var u = update.getValueAsJSONPatchIfAvailable(key);
			if(u != null) {
				jsonPatches.h[key + nFields] = u;
			}
		}
		this.fireOnItemUpdate(new com_lightstreamer_client_internal_update_ItemUpdate2Level(this.item.itemIdx,this.item.subscription,extKeyValues,changedFields,snapshot,jsonPatches));
	}
	,doUpdate1Level: function(keyValues,snapshot) {
		var cmdIdx = this.item.subscription.getCommandPosition();
		var nFields = com_lightstreamer_internal_NullTools.sure(this.item.subscription.fetch_nFields());
		var prevKeyValues = com_lightstreamer_internal_NullTools.sure(this.currKeyValues);
		this.currKeyValues = keyValues;
		this.currKeyValues.h[cmdIdx] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal("UPDATE");
		var extKeyValues = com_lightstreamer_internal_NullTools.sure(this.currKeyValues).copy();
		var this1 = com_lightstreamer_internal_NullTools.sure(this.currKey2Values);
		var _g_keys = this1.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			var _g_value = this1.get(key);
			extKeyValues.h[key + nFields] = _g_value == null ? null : com_lightstreamer_client_internal_update_CurrFieldVal.StringVal(_g_value);
		}
		var changedFields = new com_lightstreamer_internal_Set();
		var _g = 1;
		var _g1 = nFields + 1;
		while(_g < _g1) {
			var f = _g++;
			if(com_lightstreamer_client_internal_update_CurrFieldValTools.toString(prevKeyValues.h[f]) != com_lightstreamer_client_internal_update_CurrFieldValTools.toString(extKeyValues.h[f])) {
				changedFields.insert(f);
			}
		}
		this.fireOnItemUpdate(new com_lightstreamer_client_internal_update_ItemUpdate2Level(this.item.itemIdx,this.item.subscription,extKeyValues,changedFields,snapshot,new haxe_ds_IntMap()));
	}
	,doDelete: function(keyValues,snapshot) {
		var n = com_lightstreamer_internal_NullTools.sure(this.item.subscription.fetch_nFields());
		var keyIdx = this.item.subscription.getKeyPosition();
		var cmdIdx = this.item.subscription.getCommandPosition();
		this.currKeyValues = null;
		var changedFields = new com_lightstreamer_internal_Set(new IntIterator(1,n + 1)).subtracting([keyIdx]);
		var extKeyValues = new haxe_ds_IntMap();
		var _g = 1;
		var _g1 = n + 1;
		while(_g < _g1) extKeyValues.h[_g++] = null;
		extKeyValues.h[keyIdx] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal(this.keyName);
		extKeyValues.h[cmdIdx] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal("DELETE");
		var update = new com_lightstreamer_client_internal_update_ItemUpdate2Level(this.item.itemIdx,this.item.subscription,extKeyValues,changedFields,snapshot,new haxe_ds_IntMap());
		this.item.unrelate(this.keyName);
		var sub = com_lightstreamer_internal_NullTools.sure(this.subscription2Level);
		sub.removeListener(com_lightstreamer_internal_NullTools.sure(this.listener2Level));
		com_lightstreamer_internal_NullTools.sure(this.listener2Level).disable();
		this.subscription2Level = null;
		this.listener2Level = null;
		this.item.strategy.client.unsubscribe(sub);
		this.fireOnItemUpdate(update);
	}
	,doDeleteExt: function(keyValues,snapshot) {
		var nFields = com_lightstreamer_internal_NullTools.sure(this.item.subscription.fetch_nFields());
		var keyIdx = this.item.subscription.getKeyPosition();
		var cmdIdx = this.item.subscription.getCommandPosition();
		var n = nFields + Lambda.count(com_lightstreamer_internal_NullTools.sure(this.currKey2Values));
		this.currKeyValues = null;
		this.currKey2Values = null;
		var changedFields = new com_lightstreamer_internal_Set(new IntIterator(1,n + 1)).subtracting([keyIdx]);
		var extKeyValues = new haxe_ds_IntMap();
		var _g = 1;
		var _g1 = n + 1;
		while(_g < _g1) extKeyValues.h[_g++] = null;
		extKeyValues.h[keyIdx] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal(this.keyName);
		extKeyValues.h[cmdIdx] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal("DELETE");
		var update = new com_lightstreamer_client_internal_update_ItemUpdate2Level(this.item.itemIdx,this.item.subscription,extKeyValues,changedFields,snapshot,new haxe_ds_IntMap());
		this.item.unrelate(this.keyName);
		var sub = com_lightstreamer_internal_NullTools.sure(this.subscription2Level);
		sub.removeListener(com_lightstreamer_internal_NullTools.sure(this.listener2Level));
		com_lightstreamer_internal_NullTools.sure(this.listener2Level).disable();
		this.subscription2Level = null;
		this.listener2Level = null;
		this.item.strategy.client.unsubscribe(sub);
		this.fireOnItemUpdate(update);
	}
	,doLightDelete: function(keyValues,snapshot) {
		var nFields = com_lightstreamer_internal_NullTools.sure(this.item.subscription.fetch_nFields());
		var keyIdx = this.item.subscription.getKeyPosition();
		var cmdIdx = this.item.subscription.getCommandPosition();
		this.currKeyValues = null;
		var changedFields = new com_lightstreamer_internal_Set(new IntIterator(1,nFields + 1));
		var values = new haxe_ds_IntMap();
		var _g = 1;
		var _g1 = nFields + 1;
		while(_g < _g1) values.h[_g++] = null;
		values.h[keyIdx] = keyValues.h[keyIdx];
		values.h[cmdIdx] = keyValues.h[cmdIdx];
		this.fireOnItemUpdate(new com_lightstreamer_client_internal_update_ItemUpdate2Level(this.item.itemIdx,this.item.subscription,values,changedFields,snapshot,new haxe_ds_IntMap()));
	}
	,doDelete1LevelOnly: function(keyValues,snapshot) {
		var nFields = com_lightstreamer_internal_NullTools.sure(this.item.subscription.fetch_nFields());
		var keyIdx = this.item.subscription.getKeyPosition();
		var cmdIdx = this.item.subscription.getCommandPosition();
		this.currKeyValues = null;
		var changedFields = new com_lightstreamer_internal_Set(new IntIterator(1,nFields + 1)).subtracting([keyIdx]);
		var values = new haxe_ds_IntMap();
		var _g = 1;
		var _g1 = nFields + 1;
		while(_g < _g1) values.h[_g++] = null;
		values.h[keyIdx] = keyValues.h[keyIdx];
		values.h[cmdIdx] = keyValues.h[cmdIdx];
		this.fireOnItemUpdate(new com_lightstreamer_client_internal_update_ItemUpdate2Level(this.item.itemIdx,this.item.subscription,values,changedFields,snapshot,new haxe_ds_IntMap()));
	}
	,doChangeRequestedMaxFrequency: function() {
		com_lightstreamer_internal_NullTools.sure(this.subscription2Level).setRequestedMaxFrequency(com_lightstreamer_internal_RequestedMaxFrequencyTools.toString(this.item.strategy.requestedMaxFrequency));
	}
	,doSetRealMaxFrequency: function(maxFrequency) {
		this.realMaxFrequency = maxFrequency;
	}
	,doUnsetRealMaxFrequency: function() {
		this.realMaxFrequency = null;
	}
	,genOnRealMaxFrequency2LevelAdded: function() {
		this.item.strategy.evtOnRealMaxFrequency2LevelAdded(this.realMaxFrequency);
	}
	,genOnRealMaxFrequency2LevelRemoved: function() {
		this.item.strategy.evtOnRealMaxFrequency2LevelRemoved();
	}
	,doUnsubscribe: function() {
		if(this.subscription2Level == null) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "subscription2Level != null");
		}
		if(this.listener2Level == null) {
			throw new com_lightstreamer_internal_IllegalStateException("Assertion failure: " + "listener2Level != null");
		}
		var sub = this.subscription2Level;
		sub.removeListener(this.listener2Level);
		this.listener2Level.disable();
		this.subscription2Level = null;
		this.listener2Level = null;
		this.item.strategy.client.unsubscribe(sub);
	}
	,notify2LevelIllegalArgument: function() {
		this.listener2Level = null;
		this.subscription2Level = null;
		this.item.subscription.fireOnSubscriptionError2Level(this.keyName,14,"The received key value is not a valid name for an Item",this.item.m_subId,this.item.itemIdx);
	}
	,notify2LevelSubscriptionError: function(code,msg) {
		this.listener2Level = null;
		this.subscription2Level = null;
		this.item.subscription.fireOnSubscriptionError2Level(this.keyName,code,msg,this.item.m_subId,this.item.itemIdx);
	}
	,notify2LevelLostUpdates: function(lostUpdates) {
		this.item.subscription.fireOnLostUpdates2Level(this.keyName,lostUpdates,this.item.m_subId,this.item.itemIdx);
	}
	,fireOnItemUpdate: function(update) {
		this.item.subscription.fireOnItemUpdate(update,this.item.m_subId);
	}
	,create2LevelSubscription: function() {
		this.listener2Level = new com_lightstreamer_client_internal_update__$Key2Level_Sub2LevelDelegate(this);
		var sub = this.item.subscription;
		var sub2 = new LSSubscription("MERGE",null,null);
		var items = [this.keyName];
		if(!com_lightstreamer_internal_Items.allValidNames(items)) {
			return null;
		}
		sub2.setItems(items.slice());
		var fields2 = sub.getCommandSecondLevelFields();
		if(fields2 != null) {
			sub2.setFields(fields2);
		} else {
			sub2.setFieldSchema(sub.getCommandSecondLevelFieldSchema());
		}
		sub2.setDataAdapter(sub.getCommandSecondLevelDataAdapter());
		sub2.setRequestedSnapshot(com_lightstreamer_internal_RequestedSnapshotTools.toString(com_lightstreamer_internal_RequestedSnapshot.SnpYes));
		sub2.setRequestedMaxFrequency(com_lightstreamer_internal_RequestedMaxFrequencyTools.toString(this.item.strategy.requestedMaxFrequency));
		sub2.addListener(com_lightstreamer_internal_NullTools.sure(this.listener2Level));
		sub2.setInternal();
		return sub2;
	}
	,isDelete: function(keyValues) {
		var key = this.item.subscription.getCommandPosition();
		return com_lightstreamer_client_internal_update_CurrFieldValTools.toString(keyValues.h[key]) == "DELETE";
	}
	,goto: function(to) {
		this.s_m = to;
		this.traceEvent("goto");
	}
	,traceEvent: function(evt) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
			var subId = this.item.m_subId;
			var itemIdx = this.item.itemIdx;
			if(com_lightstreamer_log_LoggerTools_internalLogger.isTraceEnabled()) {
				com_lightstreamer_log_LoggerTools_internalLogger.trace("sub#key#" + evt + "(" + subId + ":" + itemIdx + ":" + this.keyName + ") in " + this.s_m);
			}
		}
	}
	,__class__: com_lightstreamer_client_internal_update_Key2Level
};
var com_lightstreamer_client_internal_update__$Key2Level_Sub2LevelDelegate = function(key) {
	this.m_disabled = false;
	this.key = key;
};
com_lightstreamer_client_internal_update__$Key2Level_Sub2LevelDelegate.__name__ = true;
com_lightstreamer_client_internal_update__$Key2Level_Sub2LevelDelegate.__interfaces__ = [SubscriptionListener];
com_lightstreamer_client_internal_update__$Key2Level_Sub2LevelDelegate.prototype = {
	disable: function() {
		var _gthis = this;
		this.key.lock.synchronized(function() {
			return _gthis.m_disabled = true;
		});
	}
	,synchronized: function(block) {
		var _gthis = this;
		this.key.lock.synchronized(function() {
			if(!_gthis.m_disabled) {
				block();
			}
		});
	}
	,onSubscriptionError: function(code,message) {
		var _gthis = this;
		this.synchronized(function() {
			_gthis.key.evtOnSubscriptionError2Level(code,message);
		});
	}
	,onItemUpdate: function(itemUpdate) {
		var _gthis = this;
		this.synchronized(function() {
			_gthis.key.evtUpdate2Level(itemUpdate);
		});
	}
	,onItemLostUpdates: function(itemName,itemPos,lostUpdates) {
		var _gthis = this;
		this.synchronized(function() {
			_gthis.key.evtOnItemLostUpdates2Level(lostUpdates);
		});
	}
	,onRealMaxFrequency: function(frequency) {
		var _gthis = this;
		this.synchronized(function() {
			var _gthis1 = _gthis.key;
			var tmp;
			if(frequency == null) {
				tmp = null;
			} else {
				var _hx_tmp;
				if(frequency == null) {
					_hx_tmp = parseFloat(frequency);
					var max = _hx_tmp;
					tmp = !isNaN(max) ? com_lightstreamer_internal_RealMaxFrequency.RFreqLimited(max) : null;
				} else if(frequency == "unlimited") {
					tmp = com_lightstreamer_internal_RealMaxFrequency.RFreqUnlimited;
				} else {
					_hx_tmp = parseFloat(frequency);
					var max = _hx_tmp;
					tmp = !isNaN(max) ? com_lightstreamer_internal_RealMaxFrequency.RFreqLimited(max) : null;
				}
			}
			_gthis1.evtOnRealMaxFrequency2Level(tmp);
		});
	}
	,onUnsubscription: function() {
		var _gthis = this;
		this.synchronized(function() {
			_gthis.key.evtOnUnsubscription2Level();
		});
	}
	,onClearSnapshot: function(itemName,itemPos) {
	}
	,onCommandSecondLevelItemLostUpdates: function(lostUpdates,key) {
	}
	,onCommandSecondLevelSubscriptionError: function(code,message,key) {
	}
	,onEndOfSnapshot: function(itemName,itemPos) {
	}
	,onListenEnd: function(sub) {
	}
	,onListenStart: function(sub) {
	}
	,onSubscription: function() {
	}
	,__class__: com_lightstreamer_client_internal_update__$Key2Level_Sub2LevelDelegate
};
var com_lightstreamer_client_internal_update_CurrFieldVal = $hxEnums["com.lightstreamer.client.internal.update.CurrFieldVal"] = { __ename__:true,__constructs__:null
	,StringVal: ($_=function(string) { return {_hx_index:0,string:string,__enum__:"com.lightstreamer.client.internal.update.CurrFieldVal",toString:$estr}; },$_._hx_name="StringVal",$_.__params__ = ["string"],$_)
	,JsonVal: ($_=function(json) { return {_hx_index:1,json:json,__enum__:"com.lightstreamer.client.internal.update.CurrFieldVal",toString:$estr}; },$_._hx_name="JsonVal",$_.__params__ = ["json"],$_)
};
com_lightstreamer_client_internal_update_CurrFieldVal.__constructs__ = [com_lightstreamer_client_internal_update_CurrFieldVal.StringVal,com_lightstreamer_client_internal_update_CurrFieldVal.JsonVal];
var com_lightstreamer_client_internal_update_CurrFieldValTools = function() { };
com_lightstreamer_client_internal_update_CurrFieldValTools.__name__ = true;
com_lightstreamer_client_internal_update_CurrFieldValTools.toString = function(val) {
	if(val == null) {
		return null;
	} else {
		switch(val._hx_index) {
		case 0:
			return val.string;
		case 1:
			return com_lightstreamer_internal_patch_Json.toString(val.json);
		}
	}
};
function com_lightstreamer_client_internal_update_UpdateUtils_applyUpatesToCurrentFields(currentValues,incomingValues) {
	if(currentValues != null) {
		var newValues = new haxe_ds_IntMap();
		var map = incomingValues;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			var _g_value = map.get(key);
			switch(_g_value._hx_index) {
			case 0:
				newValues.h[key] = currentValues.h[key];
				break;
			case 1:
				var _g = _g_value.val;
				if(_g == null) {
					newValues.h[key] = null;
				} else {
					newValues.h[key] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal(_g);
				}
				break;
			case 2:
				var _g1 = _g_value.patch;
				var _g2 = currentValues.h[key];
				if(_g2 == null) {
					throw new com_lightstreamer_internal_IllegalStateException("Cannot apply the JSON patch to the field " + key + " because the field is null");
				} else {
					switch(_g2._hx_index) {
					case 0:
						var _g3 = _g2.string;
						var json = null;
						try {
							json = com_lightstreamer_internal_patch_Json._new(_g3);
						} catch( _g4 ) {
							var _g5 = haxe_Exception.caught(_g4);
							if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
								com_lightstreamer_log_LoggerTools_sessionLogger.error("" + _g5.get_message() + "\n" + _g5.details());
							}
							throw new com_lightstreamer_internal_IllegalStateException("Cannot convert the field " + key + " to JSON");
						}
						try {
							var v = com_lightstreamer_client_internal_update_CurrFieldVal.JsonVal(com_lightstreamer_internal_patch_Json.apply(com_lightstreamer_internal_NullTools.sure(json),_g1));
							newValues.h[key] = v;
						} catch( _g6 ) {
							var _g7 = haxe_Exception.caught(_g6);
							if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
								com_lightstreamer_log_LoggerTools_sessionLogger.error("" + _g7.get_message() + "\n" + _g7.details());
							}
							throw new com_lightstreamer_internal_IllegalStateException("Cannot apply the JSON Patch to the field " + key);
						}
						break;
					case 1:
						var _g8 = _g2.json;
						try {
							var v1 = com_lightstreamer_client_internal_update_CurrFieldVal.JsonVal(com_lightstreamer_internal_patch_Json.apply(_g8,_g1));
							newValues.h[key] = v1;
						} catch( _g9 ) {
							var _g10 = haxe_Exception.caught(_g9);
							if(com_lightstreamer_log_LoggerTools_sessionLogger.isErrorEnabled()) {
								com_lightstreamer_log_LoggerTools_sessionLogger.error("" + _g10.get_message() + "\n" + _g10.details());
							}
							throw new com_lightstreamer_internal_IllegalStateException("Cannot apply the JSON Patch to the field " + key);
						}
						break;
					}
				}
				break;
			case 3:
				var _g11 = _g_value.patch;
				var _g12 = currentValues.h[key];
				if(_g12 == null) {
					throw new com_lightstreamer_internal_IllegalStateException("Cannot apply the TLCP-diff to the field " + key + " because the field is null");
				} else {
					switch(_g12._hx_index) {
					case 0:
						var v2 = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal(new com_lightstreamer_internal_patch_DiffDecoder(_g12.string,_g11).decode());
						newValues.h[key] = v2;
						break;
					case 1:
						throw new com_lightstreamer_internal_IllegalStateException("Cannot apply the TLCP-diff to the field " + key + " because the field is JSON");
					}
				}
				break;
			}
		}
		return newValues;
	} else {
		var newValues = new haxe_ds_IntMap();
		var map = incomingValues;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			var _g_value = map.get(key);
			switch(_g_value._hx_index) {
			case 0:
				throw new com_lightstreamer_internal_IllegalStateException("Cannot set the field " + key + " because the first update is UNCHANGED");
			case 1:
				var _g = _g_value.val;
				if(_g == null) {
					newValues.h[key] = null;
				} else {
					newValues.h[key] = com_lightstreamer_client_internal_update_CurrFieldVal.StringVal(_g);
				}
				break;
			case 2:
				throw new com_lightstreamer_internal_IllegalStateException("Cannot set the field " + key + " because the first update is a JSONPatch");
			case 3:
				throw new com_lightstreamer_internal_IllegalStateException("Cannot set the field " + key + " because the first update is a TLCP-diff");
			}
		}
		return newValues;
	}
}
function com_lightstreamer_client_internal_update_UpdateUtils_findChangedFields(prev,curr) {
	if(prev != null) {
		var changedFields = new com_lightstreamer_internal_Set();
		var map = curr;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			map.get(key);
			if(com_lightstreamer_client_internal_update_CurrFieldValTools.toString(prev.h[key]) != com_lightstreamer_client_internal_update_CurrFieldValTools.toString(curr.h[key])) {
				changedFields.insert(key);
			}
		}
		return changedFields;
	} else {
		var changedFields = new com_lightstreamer_internal_Set();
		var map = curr;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			map.get(key);
			changedFields.insert(key);
		}
		return changedFields;
	}
}
function com_lightstreamer_client_internal_update_UpdateUtils_computeJsonPatches(currentValues,incomingValues) {
	if(currentValues != null) {
		var res = new haxe_ds_IntMap();
		var map = incomingValues;
		var _g_keys = map.keys();
		while(_g_keys.hasNext()) {
			var key = _g_keys.next();
			var _g_value = map.get(key);
			switch(_g_value._hx_index) {
			case 0:
				var curr = currentValues.h[key];
				if(curr != null && (curr == null ? false : curr._hx_index == 1)) {
					var v = com_lightstreamer_internal_patch_Json._new("[]");
					res.h[key] = v;
				}
				break;
			case 2:
				res.h[key] = _g_value.patch;
				break;
			default:
			}
		}
		return res;
	} else {
		return new haxe_ds_IntMap();
	}
}
function com_lightstreamer_client_internal_update_UpdateUtils_toMap(array) {
	if(array != null) {
		var map = new haxe_ds_IntMap();
		var _g_current = 0;
		while(_g_current < array.length) {
			var _g_value = array[_g_current++];
			map.h[_g_current - 1 + 1] = _g_value;
		}
		return map;
	}
	return null;
}
function com_lightstreamer_client_internal_update_UpdateUtils_findFirstIndex(map,value) {
	var _g = 1;
	var _g1 = Lambda.count(map) + 1;
	while(_g < _g1) {
		var i = _g++;
		if(map.h[i] == value) {
			return i;
		}
	}
	return null;
}
function com_lightstreamer_client_internal_update_UpdateUtils_getFieldsByPosition(update) {
	var res = new haxe_ds_IntMap();
	update.forEachField(function(name,pos,val) {
		res.h[pos] = val;
	});
	return res;
}
function com_lightstreamer_client_internal_update_UpdateUtils_getChangedFieldsByPosition(update) {
	var res = new haxe_ds_IntMap();
	update.forEachChangedField(function(name,pos,val) {
		res.h[pos] = val;
	});
	return res;
}
function com_lightstreamer_internal_ArrayTools_removeAll(array) {
	array.splice(0,array.length);
}
var com_lightstreamer_internal_Executor = function() {
};
com_lightstreamer_internal_Executor.__name__ = true;
com_lightstreamer_internal_Executor.prototype = {
	submit: function(cb) {
		com_lightstreamer_internal_Timers__setImmediate(cb);
	}
	,schedule: function(callback,delay) {
		return new com_lightstreamer_internal_TaskHandle(com_lightstreamer_internal_Timers__setTimeout(callback,delay));
	}
	,stop: function() {
	}
	,__class__: com_lightstreamer_internal_Executor
};
var com_lightstreamer_internal_TaskHandle = function(handle) {
	this.handle = handle;
};
com_lightstreamer_internal_TaskHandle.__name__ = true;
com_lightstreamer_internal_TaskHandle.prototype = {
	isCanceled: function() {
		return this.handle == null;
	}
	,cancel: function() {
		if(this.handle != null) {
			com_lightstreamer_internal_Timers__clearTimeout(this.handle);
			this.handle = null;
		}
	}
	,__class__: com_lightstreamer_internal_TaskHandle
};
var com_lightstreamer_internal_Globals = function() {
	this.hasMicroTasks = typeof($global.queueMicrotask) != "undefined";
	this.hasWorkers = typeof(Worker) != "undefined";
};
com_lightstreamer_internal_Globals.__name__ = true;
com_lightstreamer_internal_Globals.prototype = {
	toString: function() {
		return "{ hasWorkers: " + Std.string(this.hasWorkers) + ", hasMicroTasks: " + Std.string(this.hasMicroTasks) + " }";
	}
	,__class__: com_lightstreamer_internal_Globals
};
var com_lightstreamer_internal_IHttpClient = function() { };
com_lightstreamer_internal_IHttpClient.__name__ = true;
com_lightstreamer_internal_IHttpClient.__isInterface__ = true;
com_lightstreamer_internal_IHttpClient.prototype = {
	__class__: com_lightstreamer_internal_IHttpClient
};
var com_lightstreamer_internal_HttpClient = function(url,body,headers,withCredentials,onText,onError,onDone) {
	this.isCanceled = false;
	this.reader = new com_lightstreamer_internal_StreamReader();
	var _gthis = this;
	if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
		com_lightstreamer_log_LoggerTools_streamLogger.debug("HTTP sending: " + url + " " + body + " headers(" + (headers == null ? "null" : haxe_ds_StringMap.stringify(headers.h)) + ")");
	}
	this.xhr = new XMLHttpRequest();
	this.xhr.open("POST",url);
	this.xhr.withCredentials = withCredentials;
	if(headers != null) {
		var h = headers.h;
		var _g_keys = Object.keys(h);
		var _g_length = _g_keys.length;
		var _g_current = 0;
		while(_g_current < _g_length) {
			var key = _g_keys[_g_current++];
			this.xhr.setRequestHeader(key,h[key]);
		}
	}
	this.xhr.setRequestHeader("Content-Type","application/x-www-form-urlencoded");
	this.xhr.send(body);
	this.xhr.onreadystatechange = function() {
		if(_gthis.isCanceled) {
			return;
		}
		var state = _gthis.xhr.readyState;
		if(state == 2) {
			var status = _gthis.xhr.status;
			if(!(status == 0 || status >= 200 && status < 300)) {
				if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
					com_lightstreamer_log_LoggerTools_streamLogger.debug("HTTP event: error(HTTP code " + status + ")");
				}
				onError(_gthis,"Unexpected HTTP code: " + status);
				_gthis.xhr.abort();
			}
		} else if(state > 2) {
			var lines = state == 3 ? _gthis.reader.extractNewData(_gthis.xhr.responseText,false) : _gthis.reader.extractNewData(_gthis.xhr.responseText,true);
			var _g = 0;
			while(_g < lines.length) {
				var line = lines[_g];
				++_g;
				if(_gthis.isCanceled) {
					return;
				}
				if(line == "") {
					continue;
				}
				if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
					com_lightstreamer_log_LoggerTools_streamLogger.debug("HTTP event: text(" + line + ")");
				}
				onText(_gthis,line);
			}
			if(state == 4) {
				if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
					com_lightstreamer_log_LoggerTools_streamLogger.debug("HTTP event: complete");
				}
				onDone(_gthis);
			}
		}
	};
	this.xhr.onerror = function() {
		if(_gthis.isCanceled) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_streamLogger.debug("HTTP event: error(" + "Network error" + ")");
		}
		onError(_gthis,"Network error");
	};
};
com_lightstreamer_internal_HttpClient.__name__ = true;
com_lightstreamer_internal_HttpClient.__interfaces__ = [com_lightstreamer_internal_IHttpClient];
com_lightstreamer_internal_HttpClient.prototype = {
	dispose: function() {
		if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_streamLogger.debug("HTTP disposing");
		}
		this.isCanceled = true;
		this.xhr.abort();
	}
	,isDisposed: function() {
		return this.isCanceled;
	}
	,__class__: com_lightstreamer_internal_HttpClient
};
var com_lightstreamer_internal_InfoMap = {};
com_lightstreamer_internal_InfoMap._new = function() {
	return new haxe_ds_StringMap();
};
com_lightstreamer_internal_InfoMap.set = function(this1,k,v) {
	if(v != null) {
		this1.h[k] = v == null ? "null" : Std.string(v);
	}
};
var com_lightstreamer_internal_MyArray = function() {
	this.values = [];
	this.nRemoved = 0;
};
com_lightstreamer_internal_MyArray.__name__ = true;
com_lightstreamer_internal_MyArray.prototype = {
	get_length: function() {
		return this.values.length - this.nRemoved;
	}
	,compact: function() {
		if(this.nRemoved > this.values.length / 2) {
			this.nRemoved = 0;
			this.values = this.copy().values;
		}
	}
	,push: function(e) {
		this.values.push(new com_lightstreamer_internal__$MyArray_Pair(e));
	}
	,remove: function(e) {
		var _g = 0;
		var _g1 = this.values.length;
		while(_g < _g1) {
			var i = _g++;
			if(this.values[i].item == e && this.values[i].isValid) {
				this.nRemoved++;
				this.values[i].isValid = false;
				break;
			}
		}
	}
	,filter: function(pred) {
		var res = new com_lightstreamer_internal_MyArray();
		var e = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this);
		while(e.hasNext()) {
			var e1 = e.next();
			if(pred(e1)) {
				res.values.push(new com_lightstreamer_internal__$MyArray_Pair(e1));
			}
		}
		return res;
	}
	,map: function(f) {
		var res = new com_lightstreamer_internal_MyArray();
		var e = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this);
		while(e.hasNext()) {
			var e1 = f(e.next());
			res.values.push(new com_lightstreamer_internal__$MyArray_Pair(e1));
		}
		return res;
	}
	,exists: function(f) {
		return Lambda.exists(this,f);
	}
	,find: function(f) {
		return Lambda.find(this,f);
	}
	,contains: function(x) {
		return Lambda.has(this,x);
	}
	,iterator: function() {
		return new com_lightstreamer_internal__$MyArray_MyArrayIterator(this);
	}
	,copy: function() {
		var arr = new com_lightstreamer_internal_MyArray();
		var e = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this);
		while(e.hasNext()) {
			var e1 = e.next();
			arr.values.push(new com_lightstreamer_internal__$MyArray_Pair(e1));
		}
		return arr;
	}
	,toString: function() {
		var i = 0;
		var len = this.values.length - this.nRemoved;
		var str = "[";
		var k = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this);
		while(k.hasNext()) {
			str += Std.string(k.next()) + (i != len - 1 ? ", " : "");
			++i;
		}
		return str + "]";
	}
	,__class__: com_lightstreamer_internal_MyArray
};
var com_lightstreamer_internal__$MyArray_Pair = function(item) {
	this.isValid = true;
	this.item = item;
};
com_lightstreamer_internal__$MyArray_Pair.__name__ = true;
com_lightstreamer_internal__$MyArray_Pair.prototype = {
	__class__: com_lightstreamer_internal__$MyArray_Pair
};
var com_lightstreamer_internal__$MyArray_MyArrayIterator = function(a) {
	this.a = a;
	this.index = 0;
};
com_lightstreamer_internal__$MyArray_MyArrayIterator.__name__ = true;
com_lightstreamer_internal__$MyArray_MyArrayIterator.prototype = {
	hasNext: function() {
		var _g = this.index;
		var _g1 = this.a.values.length;
		while(_g < _g1) if(this.a.values[_g++].isValid) {
			return true;
		}
		return false;
	}
	,next: function() {
		var _g = this.index;
		var _g1 = this.a.values.length;
		while(_g < _g1) {
			var j = _g++;
			if(this.a.values[j].isValid) {
				this.index = j + 1;
				return this.a.values[j].item;
			}
		}
		throw new haxe_Exception("No such element");
	}
	,__class__: com_lightstreamer_internal__$MyArray_MyArrayIterator
};
var com_lightstreamer_internal_NativeException = {};
com_lightstreamer_internal_NativeException.details = function(this1) {
	return haxe_Exception.caught(this1).details();
};
var com_lightstreamer_internal_IllegalStateException = function(message,previous,native) {
	haxe_Exception.call(this,message,previous,native);
	this.__skipStack++;
};
com_lightstreamer_internal_IllegalStateException.__name__ = true;
com_lightstreamer_internal_IllegalStateException.__super__ = haxe_Exception;
com_lightstreamer_internal_IllegalStateException.prototype = $extend(haxe_Exception.prototype,{
	__class__: com_lightstreamer_internal_IllegalStateException
});
var com_lightstreamer_internal_NativeStringMap = {};
com_lightstreamer_internal_NativeStringMap.fromHaxeMap = function(map) {
	return com_lightstreamer_internal_NativeStringMap.fromMapToDynamicAccess(map);
};
com_lightstreamer_internal_NativeStringMap.toHaxeMap = function(this1) {
	return com_lightstreamer_internal_NativeStringMap.toHaxe(this1);
};
com_lightstreamer_internal_NativeStringMap.fromMapToDynamicAccess = function(map) {
	var out = { };
	var h = map.h;
	var _g_keys = Object.keys(h);
	var _g_length = _g_keys.length;
	var _g_current = 0;
	while(_g_current < _g_length) {
		var key = _g_keys[_g_current++];
		out[key] = h[key];
	}
	return out;
};
com_lightstreamer_internal_NativeStringMap.toHaxe = function(this1) {
	var out = new haxe_ds_StringMap();
	var _g_keys = Reflect.fields(this1);
	var _g_index = 0;
	while(_g_index < _g_keys.length) {
		var key = _g_keys[_g_index++];
		out.h[key] = this1[key];
	}
	return out;
};
com_lightstreamer_internal_NativeStringMap.toDynamicAccess = function(this1) {
	return Reflect.copy(this1);
};
var com_lightstreamer_internal_NativeList = {};
com_lightstreamer_internal_NativeList._new = function(lst) {
	return lst.slice();
};
com_lightstreamer_internal_NativeList.toHaxe = function(this1) {
	return this1.slice();
};
var com_lightstreamer_internal_NativeArray = {};
com_lightstreamer_internal_NativeArray.fromHaxeArray = function(a) {
	return a.slice();
};
com_lightstreamer_internal_NativeArray.toHaxeArray = function(this1) {
	return com_lightstreamer_internal_NativeArray.copy(this1,this1);
};
com_lightstreamer_internal_NativeArray._new = function(a) {
	return a.slice();
};
com_lightstreamer_internal_NativeArray.toHaxe = function(this1) {
	return com_lightstreamer_internal_NativeArray.copy(this1,this1);
};
com_lightstreamer_internal_NativeArray.copy = function(this1,a) {
	var b = [];
	var i = 0;
	var len = a.length;
	while(i < len) {
		b[i] = a[i];
		++i;
	}
	return b;
};
var com_lightstreamer_internal_NullTools = function() { };
com_lightstreamer_internal_NullTools.__name__ = true;
com_lightstreamer_internal_NullTools.sure = function(value) {
	if(value == null) {
		throw haxe_Exception.thrown("null pointer in .sure() call");
	}
	return value;
};
com_lightstreamer_internal_NullTools.or = function(value,defaultValue) {
	if(value == null) {
		return defaultValue;
	}
	return value;
};
var com_lightstreamer_internal_OrderedIntMap = {};
com_lightstreamer_internal_OrderedIntMap._new = function() {
	return new com_lightstreamer_internal_OrderedIntMapImpl();
};
com_lightstreamer_internal_OrderedIntMap._get = function(this1,key) {
	return this1._innerMap.h[key];
};
com_lightstreamer_internal_OrderedIntMap._set = function(this1,key,value) {
	this1.set(key,value);
	return value;
};
com_lightstreamer_internal_OrderedIntMap.containsValue = function(this1,val) {
	return Lambda.has(this1,val);
};
var haxe_IMap = function() { };
haxe_IMap.__name__ = true;
haxe_IMap.__isInterface__ = true;
haxe_IMap.prototype = {
	__class__: haxe_IMap
};
var com_lightstreamer_internal_OrderedIntMapImpl = function() {
	this._innerMap = new haxe_ds_IntMap();
	this._orderedKeys = new com_lightstreamer_internal_MyArray();
};
com_lightstreamer_internal_OrderedIntMapImpl.__name__ = true;
com_lightstreamer_internal_OrderedIntMapImpl.__interfaces__ = [haxe_IMap];
com_lightstreamer_internal_OrderedIntMapImpl.prototype = {
	compact: function() {
		this._orderedKeys.compact();
	}
	,set: function(key,value) {
		if(!this._innerMap.h.hasOwnProperty(key)) {
			this._orderedKeys.values.push(new com_lightstreamer_internal__$MyArray_Pair(key));
		}
		this._innerMap.h[key] = value;
	}
	,get: function(key) {
		return this._innerMap.h[key];
	}
	,exists: function(key) {
		return this._innerMap.h.hasOwnProperty(key);
	}
	,remove: function(key) {
		var removed = this._innerMap.remove(key);
		if(removed) {
			this._orderedKeys.remove(key);
		}
		return removed;
	}
	,keys: function() {
		return new com_lightstreamer_internal__$MyArray_MyArrayIterator(this._orderedKeys);
	}
	,iterator: function() {
		return new com_lightstreamer_internal__$OrderedIntMap_OrderedIntMapIterator(this);
	}
	,keyValueIterator: function() {
		return new haxe_iterators_MapKeyValueIterator(this);
	}
	,copy: function() {
		var clone = new com_lightstreamer_internal_OrderedIntMapImpl();
		clone._orderedKeys = this._orderedKeys.copy();
		clone._innerMap = this._innerMap.copy();
		return clone;
	}
	,get_length: function() {
		var _this = this._orderedKeys;
		return _this.values.length - _this.nRemoved;
	}
	,keysCopy: function() {
		return this._orderedKeys.copy();
	}
	,clear: function() {
		this._orderedKeys = new com_lightstreamer_internal_MyArray();
		this._innerMap = new haxe_ds_IntMap();
	}
	,toString: function() {
		var i = 0;
		var _this = this._orderedKeys;
		var len = _this.values.length - _this.nRemoved;
		var str = "[";
		var k = new com_lightstreamer_internal__$MyArray_MyArrayIterator(this._orderedKeys);
		while(k.hasNext()) {
			var k1 = k.next();
			str += k1 + " => " + Std.string(this._innerMap.h[k1]) + (i != len - 1 ? ", " : "");
			++i;
		}
		return str + "]";
	}
	,__class__: com_lightstreamer_internal_OrderedIntMapImpl
};
var com_lightstreamer_internal__$OrderedIntMap_OrderedIntMapIterator = function(omap) {
	this.map = omap;
	this.it = new com_lightstreamer_internal__$MyArray_MyArrayIterator(omap._orderedKeys);
};
com_lightstreamer_internal__$OrderedIntMap_OrderedIntMapIterator.__name__ = true;
com_lightstreamer_internal__$OrderedIntMap_OrderedIntMapIterator.prototype = {
	hasNext: function() {
		return this.it.hasNext();
	}
	,next: function() {
		var _this = this.map;
		var key = this.it.next();
		return _this._innerMap.h[key];
	}
	,__class__: com_lightstreamer_internal__$OrderedIntMap_OrderedIntMapIterator
};
var com_lightstreamer_internal_IWsClient = function() { };
com_lightstreamer_internal_IWsClient.__name__ = true;
com_lightstreamer_internal_IWsClient.__isInterface__ = true;
com_lightstreamer_internal_IWsClient.prototype = {
	__class__: com_lightstreamer_internal_IWsClient
};
var com_lightstreamer_internal_ITimer = function() { };
com_lightstreamer_internal_ITimer.__name__ = true;
com_lightstreamer_internal_ITimer.__isInterface__ = true;
com_lightstreamer_internal_ITimer.prototype = {
	__class__: com_lightstreamer_internal_ITimer
};
var com_lightstreamer_internal_ReachabilityStatus = $hxEnums["com.lightstreamer.internal.ReachabilityStatus"] = { __ename__:true,__constructs__:null
	,RSReachable: {_hx_name:"RSReachable",_hx_index:0,__enum__:"com.lightstreamer.internal.ReachabilityStatus",toString:$estr}
	,RSNotReachable: {_hx_name:"RSNotReachable",_hx_index:1,__enum__:"com.lightstreamer.internal.ReachabilityStatus",toString:$estr}
};
com_lightstreamer_internal_ReachabilityStatus.__constructs__ = [com_lightstreamer_internal_ReachabilityStatus.RSReachable,com_lightstreamer_internal_ReachabilityStatus.RSNotReachable];
var com_lightstreamer_internal_IReachability = function() { };
com_lightstreamer_internal_IReachability.__name__ = true;
com_lightstreamer_internal_IReachability.__isInterface__ = true;
com_lightstreamer_internal_IReachability.prototype = {
	__class__: com_lightstreamer_internal_IReachability
};
var com_lightstreamer_internal_PageState = $hxEnums["com.lightstreamer.internal.PageState"] = { __ename__:true,__constructs__:null
	,Frozen: {_hx_name:"Frozen",_hx_index:0,__enum__:"com.lightstreamer.internal.PageState",toString:$estr}
	,Resumed: {_hx_name:"Resumed",_hx_index:1,__enum__:"com.lightstreamer.internal.PageState",toString:$estr}
};
com_lightstreamer_internal_PageState.__constructs__ = [com_lightstreamer_internal_PageState.Frozen,com_lightstreamer_internal_PageState.Resumed];
var com_lightstreamer_internal_IFactory = function() { };
com_lightstreamer_internal_IFactory.__name__ = true;
com_lightstreamer_internal_IFactory.__isInterface__ = true;
com_lightstreamer_internal_IFactory.prototype = {
	__class__: com_lightstreamer_internal_IFactory
};
var com_lightstreamer_internal_Factory = function(client) {
	this.connectionOptions = client.connectionOptions;
};
com_lightstreamer_internal_Factory.__name__ = true;
com_lightstreamer_internal_Factory.__interfaces__ = [com_lightstreamer_internal_IFactory];
com_lightstreamer_internal_Factory.prototype = {
	createWsClient: function(url,headers,onOpen,onText,onError) {
		return new com_lightstreamer_internal_WsClient(url,onOpen,onText,onError);
	}
	,createHttpClient: function(url,body,headers,onText,onError,onDone) {
		return new com_lightstreamer_internal_HttpClient(url,body,headers,this.connectionOptions.isCookieHandlingRequired(),onText,onError,onDone);
	}
	,createCtrlClient: function(url,body,headers,onText,onError,onDone) {
		return this.createHttpClient(url,body,headers,onText,onError,onDone);
	}
	,createReachabilityManager: function(host) {
		return new com_lightstreamer_internal_ReachabilityManager();
	}
	,createTimer: function(id,delay,callback) {
		return new com_lightstreamer_internal_TimerImpl(id,delay,callback);
	}
	,randomMillis: function(max) {
		return Std.random(max);
	}
	,createPageLifecycleFactory: function(onEvent) {
		return new com_lightstreamer_internal_PageLifecycle(onEvent);
	}
	,__class__: com_lightstreamer_internal_Factory
};
var com_lightstreamer_internal_ReachabilityManager = function() {
};
com_lightstreamer_internal_ReachabilityManager.__name__ = true;
com_lightstreamer_internal_ReachabilityManager.__interfaces__ = [com_lightstreamer_internal_IReachability];
com_lightstreamer_internal_ReachabilityManager.prototype = {
	startListening: function(onUpdate) {
		if(js_Browser.get_supported()) {
			window.ononline = function() {
				onUpdate(com_lightstreamer_internal_ReachabilityStatus.RSReachable);
			};
			window.onoffline = function() {
				onUpdate(com_lightstreamer_internal_ReachabilityStatus.RSNotReachable);
			};
		}
	}
	,stopListening: function() {
		if(js_Browser.get_supported()) {
			window.ononline = null;
			window.onoffline = null;
		}
	}
	,__class__: com_lightstreamer_internal_ReachabilityManager
};
var com_lightstreamer_internal__$RequestBuilder_KeyVal = function(key,val) {
	this.key = key;
	this.val = val;
};
com_lightstreamer_internal__$RequestBuilder_KeyVal.__name__ = true;
com_lightstreamer_internal__$RequestBuilder_KeyVal.prototype = {
	toEncodedPair: function() {
		var s = this.key;
		var tmp = encodeURIComponent(s) + "=";
		var s = this.val;
		return tmp + encodeURIComponent(s);
	}
	,toString: function() {
		return this.key + "=" + this.val;
	}
	,__class__: com_lightstreamer_internal__$RequestBuilder_KeyVal
};
var com_lightstreamer_internal_RequestBuilder = function() {
	this.params = [];
};
com_lightstreamer_internal_RequestBuilder.__name__ = true;
com_lightstreamer_internal_RequestBuilder.prototype = {
	LS_reqId: function(val) {
		this.addParamAny("LS_reqId",val);
	}
	,LS_message: function(val) {
		this.addParamString("LS_message",val);
	}
	,LS_sequence: function(val) {
		this.addParamString("LS_sequence",val);
	}
	,LS_msg_prog: function(val) {
		this.addParamAny("LS_msg_prog",val);
	}
	,LS_max_wait: function(val) {
		this.addParamAny("LS_max_wait",val);
	}
	,LS_outcome: function(val) {
		this.addParamBool("LS_outcome",val);
	}
	,LS_ack: function(val) {
		this.addParamBool("LS_ack",val);
	}
	,LS_op: function(val) {
		this.addParamString("LS_op",val);
	}
	,LS_subId: function(val) {
		this.addParamAny("LS_subId",val);
	}
	,LS_mode: function(val) {
		this.addParamString("LS_mode",val);
	}
	,LS_group: function(val) {
		this.addParamString("LS_group",val);
	}
	,LS_schema: function(val) {
		this.addParamString("LS_schema",val);
	}
	,LS_data_adapter: function(val) {
		this.addParamString("LS_data_adapter",val);
	}
	,LS_requested_max_frequency: function(val) {
		this.addParamString("LS_requested_max_frequency",val);
	}
	,LS_requested_max_frequency_Float: function(val) {
		this.addParamAny("LS_requested_max_frequency",val);
	}
	,LS_requested_buffer_size: function(val) {
		this.addParamString("LS_requested_buffer_size",val);
	}
	,LS_requested_buffer_size_Int: function(val) {
		this.addParamAny("LS_requested_buffer_size",val);
	}
	,LS_cause: function(val) {
		this.addParamString("LS_cause",val);
	}
	,LS_keepalive_millis: function(val) {
		this.addParamAny("LS_keepalive_millis",val);
	}
	,LS_inactivity_millis: function(val) {
		this.addParamAny("LS_inactivity_millis",val);
	}
	,LS_requested_max_bandwidth: function(val) {
		this.addParamString("LS_requested_max_bandwidth",val);
	}
	,LS_requested_max_bandwidth_Float: function(val) {
		this.addParamAny("LS_requested_max_bandwidth",val);
	}
	,LS_adapter_set: function(val) {
		this.addParamString("LS_adapter_set",val);
	}
	,LS_user: function(val) {
		this.addParamString("LS_user",val);
	}
	,LS_password: function(val) {
		this.addParamString("LS_password",val);
	}
	,LS_cid: function(val) {
		this.addParamString("LS_cid",val);
	}
	,LS_old_session: function(val) {
		this.addParamString("LS_old_session",val);
	}
	,LS_session: function(val) {
		this.addParamString("LS_session",val);
	}
	,LS_send_sync: function(val) {
		this.addParamBool("LS_send_sync",val);
	}
	,LS_polling: function(val) {
		this.addParamBool("LS_polling",val);
	}
	,LS_polling_millis: function(val) {
		this.addParamAny("LS_polling_millis",val);
	}
	,LS_idle_millis: function(val) {
		this.addParamAny("LS_idle_millis",val);
	}
	,LS_content_length: function(val) {
		this.addParamAny("LS_content_length",val);
	}
	,LS_ttl_millis: function(val) {
		this.addParamString("LS_ttl_millis",val);
	}
	,LS_recovery_from: function(val) {
		this.addParamAny("LS_recovery_from",val);
	}
	,LS_close_socket: function(val) {
		this.addParamBool("LS_close_socket",val);
	}
	,LS_selector: function(val) {
		this.addParamString("LS_selector",val);
	}
	,LS_snapshot: function(val) {
		this.addParamBool("LS_snapshot",val);
	}
	,LS_snapshot_Int: function(val) {
		this.addParamAny("LS_snapshot",val);
	}
	,addParamString: function(key,val) {
		this.params.push(new com_lightstreamer_internal__$RequestBuilder_KeyVal(key,val));
	}
	,addParamAny: function(key,val) {
		this.addParamString(key,"" + (val == null ? "null" : Std.string(val)));
	}
	,addParamBool: function(key,val) {
		this.addParamString(key,val ? "true" : "false");
	}
	,getEncodedString: function() {
		var _this = this.params;
		var result = new Array(_this.length);
		var _g = 0;
		var _g1 = _this.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = _this[i].toEncodedPair();
		}
		return result.join("&");
	}
	,toString: function() {
		return this.params.join(" ");
	}
	,__class__: com_lightstreamer_internal_RequestBuilder
};
var com_lightstreamer_internal_Set = function(it) {
	this.values = [];
	if(it != null) {
		while(it.hasNext()) this.insert(it.next());
	}
};
com_lightstreamer_internal_Set.__name__ = true;
com_lightstreamer_internal_Set.prototype = {
	count: function() {
		return this.values.length;
	}
	,insert: function(x) {
		if(this.values.indexOf(x) == -1) {
			this.values.push(x);
		}
	}
	,contains: function(x) {
		return this.values.indexOf(x) != -1;
	}
	,remove: function(x) {
		HxOverrides.remove(this.values,x);
	}
	,removeAll: function() {
		this.values.splice(0,this.values.length);
	}
	,copy: function() {
		return new com_lightstreamer_internal_Set(this.iterator());
	}
	,union: function(other) {
		var res = new com_lightstreamer_internal_Set();
		var _g = 0;
		var _g1 = this.values;
		while(_g < _g1.length) res.insert(_g1[_g++]);
		var _g = 0;
		while(_g < other.length) res.insert(other[_g++]);
		return res;
	}
	,subtracting: function(other) {
		var res = new com_lightstreamer_internal_Set();
		var _g = 0;
		var _g1 = this.values;
		while(_g < _g1.length) {
			var v = _g1[_g];
			++_g;
			if(other.indexOf(v) == -1) {
				res.insert(v);
			}
		}
		return res;
	}
	,iterator: function() {
		return new haxe_iterators_ArrayIterator(this.values);
	}
	,toArray: function() {
		return this.values;
	}
	,__class__: com_lightstreamer_internal_Set
};
var com_lightstreamer_internal_StreamReader = function() {
	this.progress = 0;
};
com_lightstreamer_internal_StreamReader.__name__ = true;
com_lightstreamer_internal_StreamReader.prototype = {
	extractNewData: function(stream,isComplete) {
		var endIndex;
		if(isComplete) {
			endIndex = stream.length;
		} else {
			endIndex = stream.lastIndexOf("\r\n");
			if(endIndex < 0) {
				return [];
			} else {
				endIndex += 2;
			}
		}
		if(endIndex <= this.progress) {
			return [];
		}
		var newData = stream.substring(this.progress,endIndex);
		this.progress = endIndex;
		return newData.split("\r\n");
	}
	,streamProgress: function(stream) {
		return this.extractNewData(stream,false);
	}
	,streamComplete: function(stream) {
		return this.extractNewData(stream,true);
	}
	,__class__: com_lightstreamer_internal_StreamReader
};
function com_lightstreamer_internal_Threads_createExecutor() {
	return new com_lightstreamer_internal_Executor();
}
var com_lightstreamer_internal_TimerMillis = {};
com_lightstreamer_internal_TimerMillis._new = function(millis) {
	return millis;
};
com_lightstreamer_internal_TimerMillis.toMillis = function(this1) {
	return this1;
};
com_lightstreamer_internal_TimerMillis.toLong = function(this1) {
	return this1;
};
var com_lightstreamer_internal_TimerStamp = {};
com_lightstreamer_internal_TimerStamp._new = function(seconds) {
	return seconds;
};
com_lightstreamer_internal_TimerStamp.now = function() {
	return HxOverrides.now() / 1000;
};
com_lightstreamer_internal_TimerStamp.plus = function(this1,rhs) {
	return this1 * 1000 + rhs;
};
com_lightstreamer_internal_TimerStamp.minus = function(this1,rhs) {
	return (this1 - rhs) * 1000;
};
com_lightstreamer_internal_TimerStamp.toFloat = function(this1) {
	return this1;
};
var com_lightstreamer_internal_TimerImpl = function(id,delay,callback) {
	var _gthis = this;
	this.task = new com_lightstreamer_internal_TaskHandle(com_lightstreamer_internal_Timers__setTimeout(function() {
		callback(_gthis);
	},delay));
};
com_lightstreamer_internal_TimerImpl.__name__ = true;
com_lightstreamer_internal_TimerImpl.__interfaces__ = [com_lightstreamer_internal_ITimer];
com_lightstreamer_internal_TimerImpl.prototype = {
	cancel: function() {
		this.task.cancel();
	}
	,isCanceled: function() {
		return this.task.handle == null;
	}
	,__class__: com_lightstreamer_internal_TimerImpl
};
var com_lightstreamer_internal_WorkerTimer = require("./worker-timers.js");
function com_lightstreamer_internal_Timers_setTimeout(callback,delay) {
	return com_lightstreamer_internal_Timers__setTimeout(callback,delay);
}
function com_lightstreamer_internal_Timers_clearTimeout(handle) {
	com_lightstreamer_internal_Timers__clearTimeout(handle);
}
function com_lightstreamer_internal_Timers_setImmediate(callback) {
	com_lightstreamer_internal_Timers__setImmediate(callback);
}
function com_lightstreamer_internal_Timers_handleQueue() {
	var task = com_lightstreamer_internal_Timers_queue.shift();
	if(task == null) {
		if(com_lightstreamer_log_LoggerTools_internalLogger.isErrorEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.error("Timer callback not found");
		}
		return;
	}
	try {
		task();
	} catch( _g ) {
		var _g1 = haxe_Exception.caught(_g);
		if(com_lightstreamer_log_LoggerTools_internalLogger.isErrorEnabled()) {
			com_lightstreamer_log_LoggerTools_internalLogger.error("Timer callback failed" + "\n" + _g1.details());
		}
	}
}
var com_lightstreamer_internal_FieldValue = $hxEnums["com.lightstreamer.internal.FieldValue"] = { __ename__:true,__constructs__:null
	,unchanged: {_hx_name:"unchanged",_hx_index:0,__enum__:"com.lightstreamer.internal.FieldValue",toString:$estr}
	,changed: ($_=function(val) { return {_hx_index:1,val:val,__enum__:"com.lightstreamer.internal.FieldValue",toString:$estr}; },$_._hx_name="changed",$_.__params__ = ["val"],$_)
	,jsonPatch: ($_=function(patch) { return {_hx_index:2,patch:patch,__enum__:"com.lightstreamer.internal.FieldValue",toString:$estr}; },$_._hx_name="jsonPatch",$_.__params__ = ["patch"],$_)
	,diffPatch: ($_=function(patch) { return {_hx_index:3,patch:patch,__enum__:"com.lightstreamer.internal.FieldValue",toString:$estr}; },$_._hx_name="diffPatch",$_.__params__ = ["patch"],$_)
};
com_lightstreamer_internal_FieldValue.__constructs__ = [com_lightstreamer_internal_FieldValue.unchanged,com_lightstreamer_internal_FieldValue.changed,com_lightstreamer_internal_FieldValue.jsonPatch,com_lightstreamer_internal_FieldValue.diffPatch];
var com_lightstreamer_internal_Millis = {};
com_lightstreamer_internal_Millis._new = function(millis) {
	return millis;
};
com_lightstreamer_internal_Millis.fromIntGt0 = function(millis) {
	if(millis <= 0) {
		throw new com_lightstreamer_internal_IllegalArgumentException("value must be greater than zero");
	}
	return millis;
};
com_lightstreamer_internal_Millis.fromIntGtEq0 = function(millis) {
	if(millis < 0) {
		throw new com_lightstreamer_internal_IllegalArgumentException("value must be greater than or equal to zero");
	}
	return millis;
};
com_lightstreamer_internal_Millis.gti = function(this1,rhs) {
	return this1 > rhs;
};
com_lightstreamer_internal_Millis.gt = function(this1,rhs) {
	return this1 > rhs;
};
com_lightstreamer_internal_Millis.lt = function(this1,rhs) {
	return this1 < rhs;
};
com_lightstreamer_internal_Millis.plus = function(this1,rhs) {
	return this1 + rhs;
};
com_lightstreamer_internal_Millis.minus = function(this1,rhs) {
	return this1 - rhs;
};
com_lightstreamer_internal_Millis.toInt = function(this1) {
	return this1;
};
var com_lightstreamer_internal_Timestamp = {};
com_lightstreamer_internal_Timestamp._new = function(ts) {
	return ts;
};
var com_lightstreamer_internal_ContentLength = {};
com_lightstreamer_internal_ContentLength._new = function(length) {
	return length;
};
com_lightstreamer_internal_ContentLength.fromIntGt0 = function(millis) {
	if(millis <= 0) {
		throw new com_lightstreamer_internal_IllegalArgumentException("value must be greater than zero");
	}
	return millis;
};
var com_lightstreamer_internal_TransportSelection = {};
com_lightstreamer_internal_TransportSelection.fromString = function(transport) {
	if(transport == null) {
		return null;
	} else {
		switch(transport) {
		case "HTTP":
			return "HTTP";
		case "HTTP-POLLING":
			return "HTTP-POLLING";
		case "HTTP-STREAMING":
			return "HTTP-STREAMING";
		case "WS":
			return "WS";
		case "WS-POLLING":
			return "WS-POLLING";
		case "WS-STREAMING":
			return "WS-STREAMING";
		default:
			throw new com_lightstreamer_internal_IllegalArgumentException("The given value is not valid. Use one of: HTTP-STREAMING, HTTP-POLLING, WS-STREAMING, WS-POLLING, WS, HTTP or null");
		}
	}
};
var com_lightstreamer_internal_RequestedMaxBandwidth = $hxEnums["com.lightstreamer.internal.RequestedMaxBandwidth"] = { __ename__:true,__constructs__:null
	,BWLimited: ($_=function(bw) { return {_hx_index:0,bw:bw,__enum__:"com.lightstreamer.internal.RequestedMaxBandwidth",toString:$estr}; },$_._hx_name="BWLimited",$_.__params__ = ["bw"],$_)
	,BWUnlimited: {_hx_name:"BWUnlimited",_hx_index:1,__enum__:"com.lightstreamer.internal.RequestedMaxBandwidth",toString:$estr}
};
com_lightstreamer_internal_RequestedMaxBandwidth.__constructs__ = [com_lightstreamer_internal_RequestedMaxBandwidth.BWLimited,com_lightstreamer_internal_RequestedMaxBandwidth.BWUnlimited];
var com_lightstreamer_internal_RequestedMaxBandwidthTools = function() { };
com_lightstreamer_internal_RequestedMaxBandwidthTools.__name__ = true;
com_lightstreamer_internal_RequestedMaxBandwidthTools.fromString = function(bandwidth) {
	var _hx_tmp;
	if(bandwidth.toLowerCase() == "unlimited") {
		return com_lightstreamer_internal_RequestedMaxBandwidth.BWUnlimited;
	} else {
		_hx_tmp = parseFloat(bandwidth);
		if(!isNaN(_hx_tmp) && _hx_tmp > 0) {
			return com_lightstreamer_internal_RequestedMaxBandwidth.BWLimited(_hx_tmp);
		} else {
			throw new com_lightstreamer_internal_IllegalArgumentException("The given value is a not valid value for RequestedMaxBandwidth. Use a positive number or the string \"unlimited\"");
		}
	}
};
com_lightstreamer_internal_RequestedMaxBandwidthTools.toString = function(bandwidth) {
	switch(bandwidth._hx_index) {
	case 0:
		var _g = bandwidth.bw;
		if(_g == null) {
			return "null";
		} else {
			return "" + _g;
		}
		break;
	case 1:
		return "unlimited";
	}
};
var com_lightstreamer_internal_RequestedBufferSize = $hxEnums["com.lightstreamer.internal.RequestedBufferSize"] = { __ename__:true,__constructs__:null
	,BSLimited: ($_=function(size) { return {_hx_index:0,size:size,__enum__:"com.lightstreamer.internal.RequestedBufferSize",toString:$estr}; },$_._hx_name="BSLimited",$_.__params__ = ["size"],$_)
	,BSUnlimited: {_hx_name:"BSUnlimited",_hx_index:1,__enum__:"com.lightstreamer.internal.RequestedBufferSize",toString:$estr}
};
com_lightstreamer_internal_RequestedBufferSize.__constructs__ = [com_lightstreamer_internal_RequestedBufferSize.BSLimited,com_lightstreamer_internal_RequestedBufferSize.BSUnlimited];
var com_lightstreamer_internal_RequestedBufferSizeTools = function() { };
com_lightstreamer_internal_RequestedBufferSizeTools.__name__ = true;
com_lightstreamer_internal_RequestedBufferSizeTools.fromString = function(size) {
	if(size == null) {
		return null;
	} else {
		var _hx_tmp;
		if(size.toLowerCase() == "unlimited") {
			return com_lightstreamer_internal_RequestedBufferSize.BSUnlimited;
		} else {
			_hx_tmp = Std.parseInt(size);
			if(_hx_tmp != null && _hx_tmp > 0) {
				return com_lightstreamer_internal_RequestedBufferSize.BSLimited(_hx_tmp);
			} else {
				throw new com_lightstreamer_internal_IllegalArgumentException("The given value is not valid for this setting; use null, 'unlimited' or a positive integer instead");
			}
		}
	}
};
com_lightstreamer_internal_RequestedBufferSizeTools.toString = function(size) {
	if(size == null) {
		return null;
	} else {
		switch(size._hx_index) {
		case 0:
			var _g = size.size;
			if(_g == null) {
				return "null";
			} else {
				return "" + _g;
			}
			break;
		case 1:
			return "unlimited";
		}
	}
};
com_lightstreamer_internal_RequestedBufferSizeTools.extEquals = function(a,b) {
	if(a == null) {
		if(b == null) {
			return true;
		} else {
			return false;
		}
	} else if(b == null) {
		return false;
	} else {
		return Type.enumEq(a,b);
	}
};
var com_lightstreamer_internal_RequestedSnapshot = $hxEnums["com.lightstreamer.internal.RequestedSnapshot"] = { __ename__:true,__constructs__:null
	,SnpYes: {_hx_name:"SnpYes",_hx_index:0,__enum__:"com.lightstreamer.internal.RequestedSnapshot",toString:$estr}
	,SnpNo: {_hx_name:"SnpNo",_hx_index:1,__enum__:"com.lightstreamer.internal.RequestedSnapshot",toString:$estr}
	,SnpLength: ($_=function(len) { return {_hx_index:2,len:len,__enum__:"com.lightstreamer.internal.RequestedSnapshot",toString:$estr}; },$_._hx_name="SnpLength",$_.__params__ = ["len"],$_)
};
com_lightstreamer_internal_RequestedSnapshot.__constructs__ = [com_lightstreamer_internal_RequestedSnapshot.SnpYes,com_lightstreamer_internal_RequestedSnapshot.SnpNo,com_lightstreamer_internal_RequestedSnapshot.SnpLength];
var com_lightstreamer_internal_RequestedSnapshotTools = function() { };
com_lightstreamer_internal_RequestedSnapshotTools.__name__ = true;
com_lightstreamer_internal_RequestedSnapshotTools.fromString = function(snapshot) {
	if(snapshot == null) {
		return null;
	} else {
		var _hx_tmp;
		switch(snapshot.toLowerCase()) {
		case "no":
			return com_lightstreamer_internal_RequestedSnapshot.SnpNo;
		case "yes":
			return com_lightstreamer_internal_RequestedSnapshot.SnpYes;
		default:
			_hx_tmp = Std.parseInt(snapshot);
			if(_hx_tmp != null && _hx_tmp > 0) {
				return com_lightstreamer_internal_RequestedSnapshot.SnpLength(_hx_tmp);
			} else {
				throw new com_lightstreamer_internal_IllegalArgumentException("The given value is not valid for this setting; use null, 'yes', 'no' or a positive number instead");
			}
		}
	}
};
com_lightstreamer_internal_RequestedSnapshotTools.toString = function(snapshot) {
	if(snapshot == null) {
		return null;
	} else {
		switch(snapshot._hx_index) {
		case 0:
			return "yes";
		case 1:
			return "no";
		case 2:
			var _g = snapshot.len;
			if(_g == null) {
				return "null";
			} else {
				return "" + _g;
			}
			break;
		}
	}
};
var com_lightstreamer_internal_RequestedMaxFrequency = $hxEnums["com.lightstreamer.internal.RequestedMaxFrequency"] = { __ename__:true,__constructs__:null
	,FreqLimited: ($_=function(max) { return {_hx_index:0,max:max,__enum__:"com.lightstreamer.internal.RequestedMaxFrequency",toString:$estr}; },$_._hx_name="FreqLimited",$_.__params__ = ["max"],$_)
	,FreqUnlimited: {_hx_name:"FreqUnlimited",_hx_index:1,__enum__:"com.lightstreamer.internal.RequestedMaxFrequency",toString:$estr}
	,FreqUnfiltered: {_hx_name:"FreqUnfiltered",_hx_index:2,__enum__:"com.lightstreamer.internal.RequestedMaxFrequency",toString:$estr}
};
com_lightstreamer_internal_RequestedMaxFrequency.__constructs__ = [com_lightstreamer_internal_RequestedMaxFrequency.FreqLimited,com_lightstreamer_internal_RequestedMaxFrequency.FreqUnlimited,com_lightstreamer_internal_RequestedMaxFrequency.FreqUnfiltered];
var com_lightstreamer_internal_RequestedMaxFrequencyTools = function() { };
com_lightstreamer_internal_RequestedMaxFrequencyTools.__name__ = true;
com_lightstreamer_internal_RequestedMaxFrequencyTools.fromString = function(freq) {
	if(freq == null) {
		return null;
	} else {
		var _hx_tmp;
		switch(freq.toLowerCase()) {
		case "unfiltered":
			return com_lightstreamer_internal_RequestedMaxFrequency.FreqUnfiltered;
		case "unlimited":
			return com_lightstreamer_internal_RequestedMaxFrequency.FreqUnlimited;
		default:
			_hx_tmp = parseFloat(freq);
			if(!isNaN(_hx_tmp) && _hx_tmp > 0) {
				return com_lightstreamer_internal_RequestedMaxFrequency.FreqLimited(_hx_tmp);
			} else {
				throw new com_lightstreamer_internal_IllegalArgumentException("The given value is not valid for this setting; use null, 'unlimited', 'unfiltered' or a positive number instead");
			}
		}
	}
};
com_lightstreamer_internal_RequestedMaxFrequencyTools.toString = function(freq) {
	if(freq == null) {
		return null;
	} else {
		switch(freq._hx_index) {
		case 0:
			var _g = freq.max;
			if(_g == null) {
				return "null";
			} else {
				return "" + _g;
			}
			break;
		case 1:
			return "unlimited";
		case 2:
			return "unfiltered";
		}
	}
};
com_lightstreamer_internal_RequestedMaxFrequencyTools.extEquals = function(a,b) {
	if(a == null) {
		if(b == null) {
			return true;
		} else {
			return false;
		}
	} else if(b == null) {
		return false;
	} else {
		return Type.enumEq(a,b);
	}
};
var com_lightstreamer_internal_RealMaxFrequency = $hxEnums["com.lightstreamer.internal.RealMaxFrequency"] = { __ename__:true,__constructs__:null
	,RFreqLimited: ($_=function(freq) { return {_hx_index:0,freq:freq,__enum__:"com.lightstreamer.internal.RealMaxFrequency",toString:$estr}; },$_._hx_name="RFreqLimited",$_.__params__ = ["freq"],$_)
	,RFreqUnlimited: {_hx_name:"RFreqUnlimited",_hx_index:1,__enum__:"com.lightstreamer.internal.RealMaxFrequency",toString:$estr}
};
com_lightstreamer_internal_RealMaxFrequency.__constructs__ = [com_lightstreamer_internal_RealMaxFrequency.RFreqLimited,com_lightstreamer_internal_RealMaxFrequency.RFreqUnlimited];
var com_lightstreamer_internal_RealMaxBandwidth = $hxEnums["com.lightstreamer.internal.RealMaxBandwidth"] = { __ename__:true,__constructs__:null
	,BWLimited: ($_=function(bw) { return {_hx_index:0,bw:bw,__enum__:"com.lightstreamer.internal.RealMaxBandwidth",toString:$estr}; },$_._hx_name="BWLimited",$_.__params__ = ["bw"],$_)
	,BWUnlimited: {_hx_name:"BWUnlimited",_hx_index:1,__enum__:"com.lightstreamer.internal.RealMaxBandwidth",toString:$estr}
	,BWUnmanaged: {_hx_name:"BWUnmanaged",_hx_index:2,__enum__:"com.lightstreamer.internal.RealMaxBandwidth",toString:$estr}
};
com_lightstreamer_internal_RealMaxBandwidth.__constructs__ = [com_lightstreamer_internal_RealMaxBandwidth.BWLimited,com_lightstreamer_internal_RealMaxBandwidth.BWUnlimited,com_lightstreamer_internal_RealMaxBandwidth.BWUnmanaged];
var com_lightstreamer_internal_RealMaxBandwidthTools = function() { };
com_lightstreamer_internal_RealMaxBandwidthTools.__name__ = true;
com_lightstreamer_internal_RealMaxBandwidthTools.toString = function(bandwidth) {
	if(bandwidth == null) {
		return null;
	} else {
		switch(bandwidth._hx_index) {
		case 0:
			var _g = bandwidth.bw;
			if(_g == null) {
				return "null";
			} else {
				return "" + _g;
			}
			break;
		case 1:
			return "unlimited";
		case 2:
			return "unmanaged";
		}
	}
};
com_lightstreamer_internal_RealMaxBandwidthTools.eq = function(a,b) {
	if(a == null) {
		if(b == null) {
			return true;
		} else {
			return false;
		}
	} else if(b == null) {
		return false;
	} else {
		return Type.enumEq(a,b);
	}
};
var com_lightstreamer_internal_SubscriptionMode = {};
com_lightstreamer_internal_SubscriptionMode.fromString = function(mode) {
	switch(mode) {
	case "COMMAND":
		return "COMMAND";
	case "DISTINCT":
		return "DISTINCT";
	case "MERGE":
		return "MERGE";
	case "RAW":
		return "RAW";
	default:
		throw new com_lightstreamer_internal_IllegalArgumentException("The given value is not a valid subscription mode. Admitted values are MERGE, DISTINCT, RAW, COMMAND");
	}
};
var com_lightstreamer_internal_Items = {};
com_lightstreamer_internal_Items._new = function(a) {
	return a;
};
com_lightstreamer_internal_Items.getPos = function(this1,name) {
	var pos = this1.indexOf(name);
	if(pos != -1) {
		return pos + 1;
	} else {
		return -1;
	}
};
com_lightstreamer_internal_Items.allValidNames = function(names) {
	return !Lambda.exists(names,function(item) {
		return new EReg("^$| |^\\d+$","").match(item);
	});
};
com_lightstreamer_internal_Items.fromArray = function(array) {
	if(array == null) {
		return null;
	} else if(array.length == 0) {
		throw new com_lightstreamer_internal_IllegalArgumentException("Item List is empty");
	} else if(!com_lightstreamer_internal_Items.allValidNames(array)) {
		throw new com_lightstreamer_internal_IllegalArgumentException("Item List is invalid");
	} else {
		return array;
	}
};
var com_lightstreamer_internal_Fields = {};
com_lightstreamer_internal_Fields._new = function(a) {
	return a;
};
com_lightstreamer_internal_Fields.getPos = function(this1,name) {
	var pos = this1.indexOf(name);
	if(pos != -1) {
		return pos + 1;
	} else {
		return -1;
	}
};
com_lightstreamer_internal_Fields.hasKeyField = function(this1) {
	return this1.indexOf("key") != -1;
};
com_lightstreamer_internal_Fields.hasCommandField = function(this1) {
	return this1.indexOf("command") != -1;
};
com_lightstreamer_internal_Fields.fromArray = function(array) {
	if(array == null) {
		return null;
	} else if(array.length == 0) {
		throw new com_lightstreamer_internal_IllegalArgumentException("Field List is empty");
	} else if(Lambda.exists(array,function(field) {
		return new EReg("^$| ","").match(field);
	})) {
		throw new com_lightstreamer_internal_IllegalArgumentException("Field List is invalid");
	} else {
		return array;
	}
};
var com_lightstreamer_internal_Name = {};
com_lightstreamer_internal_Name._new = function(name) {
	return name;
};
com_lightstreamer_internal_Name.fromString = function(name) {
	if(name == null) {
		return null;
	} else if(name == "") {
		throw new com_lightstreamer_internal_IllegalArgumentException("The value is empty");
	} else {
		return name;
	}
};
var com_lightstreamer_internal_TriggerExpression = {};
com_lightstreamer_internal_TriggerExpression._new = function(trigger) {
	return trigger;
};
com_lightstreamer_internal_TriggerExpression.fromString = function(trigger) {
	if(trigger == null) {
		return null;
	} else {
		return trigger;
	}
};
var com_lightstreamer_internal_NotificationFormat = {};
com_lightstreamer_internal_NotificationFormat._new = function(format) {
	return format;
};
com_lightstreamer_internal_NotificationFormat.fromString = function(format) {
	if(format == null) {
		return null;
	} else {
		return format;
	}
};
function com_lightstreamer_internal_Types_realFrequencyAsString(freq) {
	if(freq == null) {
		return "null";
	} else {
		switch(freq._hx_index) {
		case 0:
			return "" + freq.freq;
		case 1:
			return "unlimited";
		}
	}
}
function com_lightstreamer_internal_Types_realFrequencyEquals(a,b) {
	if(a == null) {
		if(b == null) {
			return true;
		} else {
			return false;
		}
	} else if(b == null) {
		return false;
	} else {
		return Type.enumEq(a,b);
	}
}
var com_lightstreamer_internal_Url = function(url,path) {
	var schemaEnd = url.indexOf("://");
	if(schemaEnd != -1) {
		this._protocol = url.substring(0,schemaEnd) + ":";
		url = url.substring(schemaEnd + 3);
	} else {
		this._protocol = "";
	}
	var pathStart = url.indexOf("/");
	if(pathStart != -1) {
		this._pathname = url.substring(pathStart);
		url = url.substring(0,pathStart);
	} else {
		this._pathname = "/";
	}
	var portStart = this.extractPortStart(url);
	if(portStart != -1) {
		this._port = url.substring(portStart);
		this._hostname = url.substring(0,portStart - 1);
	} else {
		this._port = "";
		this._hostname = url;
	}
	if(path != null) {
		this.set_pathname(path);
	}
};
com_lightstreamer_internal_Url.__name__ = true;
com_lightstreamer_internal_Url.build = function(base,path) {
	if(path != null) {
		var baseEndsWithSlash = StringTools.endsWith(base,"/");
		var pathStartsWithSlash = StringTools.startsWith(path,"/");
		if(!baseEndsWithSlash) {
			if(!pathStartsWithSlash) {
				base += "/" + path;
			} else {
				base += path;
			}
		} else if(!pathStartsWithSlash) {
			base += path;
		} else {
			base += path.substring(1);
		}
	}
	return base;
};
com_lightstreamer_internal_Url.completeControlLink = function(clink,baseAddress) {
	var baseUrl = new com_lightstreamer_internal_Url(baseAddress);
	var clUrl = new com_lightstreamer_internal_Url(clink);
	if(clUrl.get_protocol() == "") {
		clUrl.set_protocol(baseUrl.get_protocol());
	}
	if(clUrl.get_port() == "") {
		clUrl.set_port(baseUrl.get_port());
	}
	return clUrl.get_href();
};
com_lightstreamer_internal_Url.prototype = {
	get_protocol: function() {
		return this._protocol;
	}
	,set_protocol: function(newValue) {
		if(newValue != "" && !StringTools.endsWith(newValue,":")) {
			newValue += ":";
		}
		this._protocol = newValue;
		return this._protocol;
	}
	,get_hostname: function() {
		return this._hostname;
	}
	,set_hostname: function(newValue) {
		this._hostname = newValue;
		return this._hostname;
	}
	,get_port: function() {
		return this._port;
	}
	,set_port: function(newValue) {
		this._port = newValue;
		return this._port;
	}
	,get_pathname: function() {
		return this._pathname;
	}
	,set_pathname: function(newValue) {
		if(!StringTools.startsWith(newValue,"/")) {
			newValue = "/" + newValue;
		}
		this._pathname = newValue;
		return this._pathname;
	}
	,get_href: function() {
		var url = this._hostname;
		if(this._protocol != "") {
			url = this._protocol + "//" + url;
		}
		if(this._port != "") {
			url += ":" + this._port;
		}
		if(this.get_pathname() != "") {
			url += this._pathname;
		}
		return url;
	}
	,toString: function() {
		return this.get_href();
	}
	,extractPortStart: function(address) {
		var portStarts = address.indexOf(":");
		if(portStarts <= -1) {
			return -1;
		}
		if(address.indexOf("]") > -1) {
			portStarts = address.indexOf("]:");
			if(portStarts <= -1) {
				return -1;
			}
			return portStarts + 2;
		} else if(portStarts != address.lastIndexOf(":")) {
			return -1;
		} else {
			return portStarts + 1;
		}
	}
	,__class__: com_lightstreamer_internal_Url
};
var com_lightstreamer_internal_WsClient = function(url,onOpen,onText,onError) {
	this.isCanceled = false;
	var _gthis = this;
	if(StringTools.startsWith(url,"https://")) {
		url = StringTools.replace(url,"https://","wss://");
	} else if(StringTools.startsWith(url,"http://")) {
		url = StringTools.replace(url,"http://","ws://");
	}
	if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
		com_lightstreamer_log_LoggerTools_streamLogger.debug("WS connecting: " + url);
	}
	this.ws = new WebSocket(url,com_lightstreamer_internal_Constants_FULL_TLCP_VERSION);
	this.ws.onopen = function() {
		if(_gthis.isCanceled) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_streamLogger.debug("WS event: open");
		}
		onOpen(_gthis);
	};
	this.ws.onmessage = function(evt) {
		if(_gthis.isCanceled) {
			return;
		}
		var _g = 0;
		var _g1 = evt.data.split("\r\n");
		while(_g < _g1.length) {
			var line = _g1[_g];
			++_g;
			if(_gthis.isCanceled) {
				return;
			}
			if(line == "") {
				continue;
			}
			if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
				com_lightstreamer_log_LoggerTools_streamLogger.debug("WS event: text(" + line + ")");
			}
			onText(_gthis,line);
		}
	};
	this.ws.onerror = function() {
		if(_gthis.isCanceled) {
			return;
		}
		if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_streamLogger.debug("WS event: error(" + "Network error" + ")");
		}
		onError(_gthis,"Network error");
		_gthis.ws.close();
	};
	this.ws.onclose = function(evt) {
		if(_gthis.isCanceled) {
			return;
		}
		var msg = "unexpected disconnection: " + evt.code + " - " + evt.reason;
		if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_streamLogger.debug("WS event: error(" + msg + ")");
		}
		onError(_gthis,msg);
		_gthis.ws.close();
	};
};
com_lightstreamer_internal_WsClient.__name__ = true;
com_lightstreamer_internal_WsClient.__interfaces__ = [com_lightstreamer_internal_IWsClient];
com_lightstreamer_internal_WsClient.prototype = {
	send: function(txt) {
		if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_streamLogger.debug("WS sending: " + txt);
		}
		this.ws.send(txt);
	}
	,dispose: function() {
		if(com_lightstreamer_log_LoggerTools_streamLogger.isDebugEnabled()) {
			com_lightstreamer_log_LoggerTools_streamLogger.debug("WS disposing");
		}
		this.isCanceled = true;
		this.ws.close();
	}
	,isDisposed: function() {
		return this.isCanceled;
	}
	,__class__: com_lightstreamer_internal_WsClient
};
var com_lightstreamer_internal_patch_DiffPatch = {};
com_lightstreamer_internal_patch_DiffPatch._new = function(patch) {
	return patch;
};
com_lightstreamer_internal_patch_DiffPatch.apply = function(this1,base) {
	return new com_lightstreamer_internal_patch_DiffDecoder(base,this1).decode();
};
var com_lightstreamer_internal_patch_DiffDecoder = function(base,diff) {
	this.buf = new StringBuf();
	this.basePos = 0;
	this.diffPos = 0;
	this.diff = diff;
	this.base = base;
};
com_lightstreamer_internal_patch_DiffDecoder.__name__ = true;
com_lightstreamer_internal_patch_DiffDecoder.apply = function(base,diff) {
	return new com_lightstreamer_internal_patch_DiffDecoder(base,diff).decode();
};
com_lightstreamer_internal_patch_DiffDecoder.prototype = {
	decode: function() {
		try {
			while(this.diffPos != this.diff.length) {
				this.applyCopy();
				if(this.diffPos == this.diff.length) {
					break;
				}
				this.applyAdd();
				if(this.diffPos == this.diff.length) {
					break;
				}
				this.applyDel();
			}
			return this.buf.b;
		} catch( _g ) {
			throw new haxe_Exception("Bad TLCP-diff",haxe_Exception.caught(_g));
		}
	}
	,applyCopy: function() {
		var count = this.decodeVarint();
		if(count > 0) {
			this.appendToBuf(this.base,this.basePos,count);
			this.basePos += count;
		}
	}
	,applyAdd: function() {
		var count = this.decodeVarint();
		if(count > 0) {
			this.appendToBuf(this.diff,this.diffPos,count);
			this.diffPos += count;
		}
	}
	,applyDel: function() {
		var count = this.decodeVarint();
		if(count > 0) {
			this.basePos += count;
		}
	}
	,decodeVarint: function() {
		var n = 0;
		while(true) {
			var c = this.charAt(this.diff,this.diffPos);
			this.diffPos += 1;
			if(c >= 97 && c < 123) {
				return n * 26 + (c - 97);
			} else if(c >= 65 && c < 91) {
				n = n * 26 + (c - 65);
			} else {
				throw new haxe_Exception("The code point " + c + " is not in the range A-Z");
			}
		}
	}
	,appendToBuf: function(s,startIndex,count) {
		if(startIndex + count <= s.length) {
			this.buf.b += count == null ? HxOverrides.substr(s,startIndex,null) : HxOverrides.substr(s,startIndex,count);
		} else {
			throw new haxe_Exception("Index out of range: startIndex=" + startIndex + " count=" + count + " length=" + s.length);
		}
	}
	,charAt: function(s,pos) {
		if(pos < s.length) {
			return HxOverrides.cca(s.charAt(pos),0);
		} else {
			throw new haxe_Exception("Index out of range: pos=" + pos + " length=" + s.length);
		}
	}
	,__class__: com_lightstreamer_internal_patch_DiffDecoder
};
var com_lightstreamer_internal_patch_Json = {};
com_lightstreamer_internal_patch_Json._new = function(str) {
	return JSON.parse(str);
};
com_lightstreamer_internal_patch_Json.apply = function(this1,patch) {
	return com_lightstreamer_internal_patch_JsonPatcher.apply_patch(this1,patch);
};
com_lightstreamer_internal_patch_Json.toString = function(this1) {
	return JSON.stringify(this1);
};
var com_lightstreamer_internal_patch_JsonPatcher = require("jsonpatch");
var LSConsoleLogLevel = $hx_exports["LSConsoleLogLevel"] = function() {
};
LSConsoleLogLevel.__name__ = true;
LSConsoleLogLevel.prototype = {
	__class__: LSConsoleLogLevel
};
var LSConsoleLoggerProvider = $hx_exports["LSConsoleLoggerProvider"] = function(level) {
	this.level = level;
};
LSConsoleLoggerProvider.__name__ = true;
LSConsoleLoggerProvider.__interfaces__ = [LoggerProvider];
LSConsoleLoggerProvider.prototype = {
	getLogger: function(category) {
		return new com_lightstreamer_log__$ConsoleLoggerProvider_ConsoleLogger(this.level,category);
	}
	,__class__: LSConsoleLoggerProvider
};
var com_lightstreamer_log__$ConsoleLoggerProvider_ConsoleLogger = function(level,category) {
	this.level = level;
	this.category = category;
	this.traceEnabled = level <= LSConsoleLogLevel.TRACE;
	this.debugEnabled = level <= LSConsoleLogLevel.DEBUG;
	this.infoEnabled = level <= LSConsoleLogLevel.INFO;
	this.warnEnabled = level <= LSConsoleLogLevel.WARN;
	this.errorEnabled = level <= LSConsoleLogLevel.ERROR;
	this.fatalEnabled = level <= LSConsoleLogLevel.FATAL;
};
com_lightstreamer_log__$ConsoleLoggerProvider_ConsoleLogger.__name__ = true;
com_lightstreamer_log__$ConsoleLoggerProvider_ConsoleLogger.__super__ = com_lightstreamer_log_AbstractLogger;
com_lightstreamer_log__$ConsoleLoggerProvider_ConsoleLogger.prototype = $extend(com_lightstreamer_log_AbstractLogger.prototype,{
	printLog: function(msg) {
		$global.console.log(msg);
	}
	,format: function(level,line) {
		var now = HxOverrides.dateStr(new Date());
		return "" + now + "|" + level + "|" + this.category + "|" + line;
	}
	,logFatal: function(msg) {
		$global.console.log(msg);
	}
	,logError: function(msg) {
		$global.console.log(msg);
	}
	,logWarn: function(msg) {
		$global.console.log(msg);
	}
	,logInfo: function(msg) {
		$global.console.log(msg);
	}
	,logDebug: function(msg) {
		$global.console.log(msg);
	}
	,logTrace: function(msg) {
		$global.console.log(msg);
	}
	,fatal: function(line,exception) {
		if(this.fatalEnabled) {
			var msg = this.format("FATAL",line);
			$global.console.log(msg);
			if(exception != null) {
				var msg = haxe_Exception.caught(exception).details();
				$global.console.log(msg);
			}
		}
	}
	,error: function(line,exception) {
		if(this.errorEnabled) {
			var msg = this.format("ERROR",line);
			$global.console.log(msg);
			if(exception != null) {
				var msg = haxe_Exception.caught(exception).details();
				$global.console.log(msg);
			}
		}
	}
	,warn: function(line,exception) {
		if(this.warnEnabled) {
			var msg = this.format("WARN ",line);
			$global.console.log(msg);
			if(exception != null) {
				var msg = haxe_Exception.caught(exception).details();
				$global.console.log(msg);
			}
		}
	}
	,info: function(line,exception) {
		if(this.infoEnabled) {
			var msg = this.format("INFO ",line);
			$global.console.log(msg);
			if(exception != null) {
				var msg = haxe_Exception.caught(exception).details();
				$global.console.log(msg);
			}
		}
	}
	,debug: function(line,exception) {
		if(this.debugEnabled) {
			var msg = this.format("DEBUG",line);
			$global.console.log(msg);
			if(exception != null) {
				var msg = haxe_Exception.caught(exception).details();
				$global.console.log(msg);
			}
		}
	}
	,trace: function(line,exception) {
		if(this.traceEnabled) {
			var msg = this.format("TRACE",line);
			$global.console.log(msg);
			if(exception != null) {
				var msg = haxe_Exception.caught(exception).details();
				$global.console.log(msg);
			}
		}
	}
	,isFatalEnabled: function() {
		return this.fatalEnabled;
	}
	,isErrorEnabled: function() {
		return this.errorEnabled;
	}
	,isWarnEnabled: function() {
		return this.warnEnabled;
	}
	,isInfoEnabled: function() {
		return this.infoEnabled;
	}
	,isDebugEnabled: function() {
		return this.debugEnabled;
	}
	,isTraceEnabled: function() {
		return this.traceEnabled;
	}
	,__class__: com_lightstreamer_log__$ConsoleLoggerProvider_ConsoleLogger
});
var haxe_StackItem = $hxEnums["haxe.StackItem"] = { __ename__:true,__constructs__:null
	,CFunction: {_hx_name:"CFunction",_hx_index:0,__enum__:"haxe.StackItem",toString:$estr}
	,Module: ($_=function(m) { return {_hx_index:1,m:m,__enum__:"haxe.StackItem",toString:$estr}; },$_._hx_name="Module",$_.__params__ = ["m"],$_)
	,FilePos: ($_=function(s,file,line,column) { return {_hx_index:2,s:s,file:file,line:line,column:column,__enum__:"haxe.StackItem",toString:$estr}; },$_._hx_name="FilePos",$_.__params__ = ["s","file","line","column"],$_)
	,Method: ($_=function(classname,method) { return {_hx_index:3,classname:classname,method:method,__enum__:"haxe.StackItem",toString:$estr}; },$_._hx_name="Method",$_.__params__ = ["classname","method"],$_)
	,LocalFunction: ($_=function(v) { return {_hx_index:4,v:v,__enum__:"haxe.StackItem",toString:$estr}; },$_._hx_name="LocalFunction",$_.__params__ = ["v"],$_)
};
haxe_StackItem.__constructs__ = [haxe_StackItem.CFunction,haxe_StackItem.Module,haxe_StackItem.FilePos,haxe_StackItem.Method,haxe_StackItem.LocalFunction];
var haxe_CallStack = {};
haxe_CallStack.toString = function(stack) {
	var b = new StringBuf();
	var _g = 0;
	var _g1 = stack;
	while(_g < _g1.length) {
		var s = _g1[_g++];
		b.b += "\nCalled from ";
		haxe_CallStack.itemToString(b,s);
	}
	return b.b;
};
haxe_CallStack.subtract = function(this1,stack) {
	var startIndex = -1;
	var i = -1;
	while(++i < this1.length) {
		var _g = 0;
		var _g1 = stack.length;
		while(_g < _g1) if(haxe_CallStack.equalItems(this1[i],stack[_g++])) {
			if(startIndex < 0) {
				startIndex = i;
			}
			++i;
			if(i >= this1.length) {
				break;
			}
		} else {
			startIndex = -1;
		}
		if(startIndex >= 0) {
			break;
		}
	}
	if(startIndex >= 0) {
		return this1.slice(0,startIndex);
	} else {
		return this1;
	}
};
haxe_CallStack.equalItems = function(item1,item2) {
	if(item1 == null) {
		if(item2 == null) {
			return true;
		} else {
			return false;
		}
	} else {
		switch(item1._hx_index) {
		case 0:
			if(item2 == null) {
				return false;
			} else if(item2._hx_index == 0) {
				return true;
			} else {
				return false;
			}
			break;
		case 1:
			if(item2 == null) {
				return false;
			} else if(item2._hx_index == 1) {
				return item1.m == item2.m;
			} else {
				return false;
			}
			break;
		case 2:
			if(item2 == null) {
				return false;
			} else if(item2._hx_index == 2) {
				if(item1.file == item2.file && item1.line == item2.line && item1.column == item2.column) {
					return haxe_CallStack.equalItems(item1.s,item2.s);
				} else {
					return false;
				}
			} else {
				return false;
			}
			break;
		case 3:
			if(item2 == null) {
				return false;
			} else if(item2._hx_index == 3) {
				if(item1.classname == item2.classname) {
					return item1.method == item2.method;
				} else {
					return false;
				}
			} else {
				return false;
			}
			break;
		case 4:
			if(item2 == null) {
				return false;
			} else if(item2._hx_index == 4) {
				return item1.v == item2.v;
			} else {
				return false;
			}
			break;
		}
	}
};
haxe_CallStack.itemToString = function(b,s) {
	switch(s._hx_index) {
	case 0:
		b.b += "a C function";
		break;
	case 1:
		var _g = s.m;
		b.b = (b.b += "module ") + (_g == null ? "null" : "" + _g);
		break;
	case 2:
		var _g = s.s;
		var _g1 = s.file;
		var _g2 = s.line;
		var _g3 = s.column;
		if(_g != null) {
			haxe_CallStack.itemToString(b,_g);
			b.b += " (";
		}
		b.b = (b.b += _g1 == null ? "null" : "" + _g1) + " line ";
		b.b += _g2 == null ? "null" : "" + _g2;
		if(_g3 != null) {
			b.b = (b.b += " column ") + (_g3 == null ? "null" : "" + _g3);
		}
		if(_g != null) {
			b.b += ")";
		}
		break;
	case 3:
		var _g = s.classname;
		var _g1 = s.method;
		b.b = (b.b += Std.string(_g == null ? "<unknown>" : _g)) + ".";
		b.b += _g1 == null ? "null" : "" + _g1;
		break;
	case 4:
		var _g = s.v;
		b.b = (b.b += "local function #") + (_g == null ? "null" : "" + _g);
		break;
	}
};
var haxe_NativeStackTrace = function() { };
haxe_NativeStackTrace.__name__ = true;
haxe_NativeStackTrace.toHaxe = function(s,skip) {
	if(skip == null) {
		skip = 0;
	}
	if(s == null) {
		return [];
	} else if(typeof(s) == "string") {
		var stack = s.split("\n");
		if(stack[0] == "Error") {
			stack.shift();
		}
		var m = [];
		var _g = 0;
		var _g1 = stack.length;
		while(_g < _g1) {
			var i = _g++;
			if(skip > i) {
				continue;
			}
			var line = stack[i];
			var matched = line.match(/^    at ([$A-Za-z0-9_. ]+) \(([^)]+):([0-9]+):([0-9]+)\)$/);
			if(matched != null) {
				var path = matched[1].split(".");
				if(path[0] == "$hxClasses") {
					path.shift();
				}
				var meth = path.pop();
				var file = matched[2];
				var line1 = Std.parseInt(matched[3]);
				var column = Std.parseInt(matched[4]);
				m.push(haxe_StackItem.FilePos(meth == "Anonymous function" ? haxe_StackItem.LocalFunction() : meth == "Global code" ? null : haxe_StackItem.Method(path.join("."),meth),file,line1,column));
			} else {
				m.push(haxe_StackItem.Module(StringTools.trim(line)));
			}
		}
		return m;
	} else if(skip > 0 && Array.isArray(s)) {
		return s.slice(skip);
	} else {
		return s;
	}
};
haxe_NativeStackTrace.normalize = function(stack,skipItems) {
	if(skipItems == null) {
		skipItems = 0;
	}
	if(Array.isArray(stack) && skipItems > 0) {
		return stack.slice(skipItems);
	} else if(typeof(stack) == "string") {
		switch(stack.substring(0,6)) {
		case "Error\n":case "Error:":
			++skipItems;
			break;
		default:
		}
		return haxe_NativeStackTrace.skipLines(stack,skipItems);
	} else {
		return stack;
	}
};
haxe_NativeStackTrace.skipLines = function(stack,skip,pos) {
	if(pos == null) {
		pos = 0;
	}
	while(true) if(skip > 0) {
		pos = stack.indexOf("\n",pos);
		if(pos < 0) {
			return "";
		} else {
			skip = --skip;
			pos += 1;
			continue;
		}
	} else {
		return stack.substring(pos);
	}
};
var haxe_ValueException = function(value,previous,native) {
	haxe_Exception.call(this,String(value),previous,native);
	this.value = value;
	this.__skipStack++;
};
haxe_ValueException.__name__ = true;
haxe_ValueException.__super__ = haxe_Exception;
haxe_ValueException.prototype = $extend(haxe_Exception.prototype,{
	__class__: haxe_ValueException
});
var haxe_ds_IntMap = function() {
	this.h = { };
};
haxe_ds_IntMap.__name__ = true;
haxe_ds_IntMap.__interfaces__ = [haxe_IMap];
haxe_ds_IntMap.prototype = {
	get: function(key) {
		return this.h[key];
	}
	,remove: function(key) {
		if(!this.h.hasOwnProperty(key)) {
			return false;
		}
		delete(this.h[key]);
		return true;
	}
	,keys: function() {
		var a = [];
		for( var key in this.h ) if(this.h.hasOwnProperty(key)) a.push(+key);
		return new haxe_iterators_ArrayIterator(a);
	}
	,iterator: function() {
		return { ref : this.h, it : this.keys(), hasNext : function() {
			return this.it.hasNext();
		}, next : function() {
			var i = this.it.next();
			return this.ref[i];
		}};
	}
	,copy: function() {
		var copied = new haxe_ds_IntMap();
		var key = this.keys();
		while(key.hasNext()) {
			var key1 = key.next();
			copied.h[key1] = this.h[key1];
		}
		return copied;
	}
	,__class__: haxe_ds_IntMap
};
var haxe_ds_StringMap = function() {
	this.h = Object.create(null);
};
haxe_ds_StringMap.__name__ = true;
haxe_ds_StringMap.__interfaces__ = [haxe_IMap];
haxe_ds_StringMap.createCopy = function(h) {
	var copy = new haxe_ds_StringMap();
	for (var key in h) copy.h[key] = h[key];
	return copy;
};
haxe_ds_StringMap.stringify = function(h) {
	var s = "[";
	var first = true;
	for (var key in h) {
		if (first) first = false; else s += ',';
		s += key + ' => ' + Std.string(h[key]);
	}
	return s + "]";
};
haxe_ds_StringMap.prototype = {
	get: function(key) {
		return this.h[key];
	}
	,keys: function() {
		return new haxe_ds__$StringMap_StringMapKeyIterator(this.h);
	}
	,iterator: function() {
		return new haxe_ds__$StringMap_StringMapValueIterator(this.h);
	}
	,__class__: haxe_ds_StringMap
};
var haxe_ds__$StringMap_StringMapKeyIterator = function(h) {
	this.h = h;
	this.keys = Object.keys(h);
	this.length = this.keys.length;
	this.current = 0;
};
haxe_ds__$StringMap_StringMapKeyIterator.__name__ = true;
haxe_ds__$StringMap_StringMapKeyIterator.prototype = {
	hasNext: function() {
		return this.current < this.length;
	}
	,next: function() {
		return this.keys[this.current++];
	}
	,__class__: haxe_ds__$StringMap_StringMapKeyIterator
};
var haxe_ds__$StringMap_StringMapValueIterator = function(h) {
	this.h = h;
	this.keys = Object.keys(h);
	this.length = this.keys.length;
	this.current = 0;
};
haxe_ds__$StringMap_StringMapValueIterator.__name__ = true;
haxe_ds__$StringMap_StringMapValueIterator.prototype = {
	hasNext: function() {
		return this.current < this.length;
	}
	,next: function() {
		return this.h[this.keys[this.current++]];
	}
	,__class__: haxe_ds__$StringMap_StringMapValueIterator
};
var haxe_io_Error = $hxEnums["haxe.io.Error"] = { __ename__:true,__constructs__:null
	,Blocked: {_hx_name:"Blocked",_hx_index:0,__enum__:"haxe.io.Error",toString:$estr}
	,Overflow: {_hx_name:"Overflow",_hx_index:1,__enum__:"haxe.io.Error",toString:$estr}
	,OutsideBounds: {_hx_name:"OutsideBounds",_hx_index:2,__enum__:"haxe.io.Error",toString:$estr}
	,Custom: ($_=function(e) { return {_hx_index:3,e:e,__enum__:"haxe.io.Error",toString:$estr}; },$_._hx_name="Custom",$_.__params__ = ["e"],$_)
};
haxe_io_Error.__constructs__ = [haxe_io_Error.Blocked,haxe_io_Error.Overflow,haxe_io_Error.OutsideBounds,haxe_io_Error.Custom];
var haxe_iterators_ArrayIterator = function(array) {
	this.current = 0;
	this.array = array;
};
haxe_iterators_ArrayIterator.__name__ = true;
haxe_iterators_ArrayIterator.prototype = {
	hasNext: function() {
		return this.current < this.array.length;
	}
	,next: function() {
		return this.array[this.current++];
	}
	,__class__: haxe_iterators_ArrayIterator
};
var haxe_iterators_MapKeyValueIterator = function(map) {
	this.map = map;
	this.keys = map.keys();
};
haxe_iterators_MapKeyValueIterator.__name__ = true;
haxe_iterators_MapKeyValueIterator.prototype = {
	hasNext: function() {
		return this.keys.hasNext();
	}
	,next: function() {
		var key = this.keys.next();
		return { value : this.map.get(key), key : key};
	}
	,__class__: haxe_iterators_MapKeyValueIterator
};
var haxe_macro_Error = function(message,pos,previous) {
	haxe_Exception.call(this,message,previous);
	this.pos = pos;
	this.__skipStack++;
};
haxe_macro_Error.__name__ = true;
haxe_macro_Error.__super__ = haxe_Exception;
haxe_macro_Error.prototype = $extend(haxe_Exception.prototype,{
	__class__: haxe_macro_Error
});
function $getIterator(o) { if( o instanceof Array ) return new haxe_iterators_ArrayIterator(o); else return o.iterator(); }
function $bind(o,m) { if( m == null ) return null; if( m.__id__ == null ) m.__id__ = $global.$haxeUID++; var f; if( o.hx__closures__ == null ) o.hx__closures__ = {}; else f = o.hx__closures__[m.__id__]; if( f == null ) { f = m.bind(o); o.hx__closures__[m.__id__] = f; } return f; }
$global.$haxeUID |= 0;
if(typeof(performance) != "undefined" ? typeof(performance.now) == "function" : false) {
	HxOverrides.now = performance.now.bind(performance);
}
if( String.fromCodePoint == null ) String.fromCodePoint = function(c) { return c < 0x10000 ? String.fromCharCode(c) : String.fromCharCode((c>>10)+0xD7C0)+String.fromCharCode((c&0x3FF)+0xDC00); }
Object.defineProperty(String.prototype,"__class__",{ value : String, enumerable : false, writable : true});
String.__name__ = true;
Array.__name__ = true;
Date.prototype.__class__ = Date;
Date.__name__ = "Date";
var Int = { };
var Dynamic = { };
var Float = Number;
var Bool = Boolean;
var Class = { };
var Enum = { };
js_Boot.__toStr = ({ }).toString;
var com_lightstreamer_client_ConnectionDetails_DEFAULT_SERVER = (function($this) {
	var $r;
	var tmp;
	if(js_Browser.get_supported()) {
		var tmp1 = $global.location;
		if((tmp1 != null ? tmp1.protocol : null) != "http:") {
			var tmp1 = $global.location;
			tmp = (tmp1 != null ? tmp1.protocol : null) != "https:";
		} else {
			tmp = false;
		}
	} else {
		tmp = true;
	}
	$r = tmp ? null : com_lightstreamer_internal_ServerAddress.fromString($global.location.protocol + "//" + $global.location.hostname + ($global.location.port != "" ? ":" + $global.location.port : "") + "/");
	return $r;
}(this));
var com_lightstreamer_internal_Constants_TLCP_VERSION = "TLCP-2.5.0";
var com_lightstreamer_internal_Constants_FULL_TLCP_VERSION = com_lightstreamer_internal_Constants_TLCP_VERSION + ".lightstreamer.com";
var com_lightstreamer_internal_Constants_LS_LIB_VERSION = "9.2.1" + " build " + "20241029";
var com_lightstreamer_internal_Constants_LS_CID = "pcYgxn8m8 feOojyA1V681g3g2.pz482hA6BMDo";
var com_lightstreamer_internal_Constants_LS_LIB_NAME = "javascript_client";
var com_lightstreamer_internal_Constants_LS_CREATE_REALM = "";
LSLightstreamerClient.LIB_NAME = com_lightstreamer_internal_Constants_LS_LIB_NAME;
LSLightstreamerClient.LIB_VERSION = com_lightstreamer_internal_Constants_LS_LIB_VERSION;
com_lightstreamer_log_LogManager.lock = new com_lightstreamer_internal_RLock();
com_lightstreamer_log_LogManager.logInstances = new haxe_ds_StringMap();
com_lightstreamer_log_LogManager.emptyLogger = new com_lightstreamer_log__$LogManager_EmptyLogger();
var com_lightstreamer_log_LoggerTools_streamLogger = com_lightstreamer_log_LogManager.getLogger("lightstreamer.stream");
var com_lightstreamer_log_LoggerTools_protocolLogger = com_lightstreamer_log_LogManager.getLogger("lightstreamer.protocol");
var com_lightstreamer_log_LoggerTools_internalLogger = com_lightstreamer_log_LogManager.getLogger("lightstreamer.internal");
var com_lightstreamer_log_LoggerTools_sessionLogger = com_lightstreamer_log_LogManager.getLogger("lightstreamer.session");
var com_lightstreamer_log_LoggerTools_actionLogger = com_lightstreamer_log_LogManager.getLogger("lightstreamer.actions");
var com_lightstreamer_log_LoggerTools_reachabilityLogger = com_lightstreamer_log_LogManager.getLogger("lightstreamer.reachability");
var com_lightstreamer_log_LoggerTools_subscriptionLogger = com_lightstreamer_log_LogManager.getLogger("lightstreamer.subscriptions");
var com_lightstreamer_log_LoggerTools_messageLogger = com_lightstreamer_log_LogManager.getLogger("lightstreamer.messages");
var com_lightstreamer_log_LoggerTools_cookieLogger = com_lightstreamer_log_LogManager.getLogger("lightstreamer.cookies");
var com_lightstreamer_log_LoggerTools_pageLogger = com_lightstreamer_log_LogManager.getLogger("lightstreamer.page");
com_lightstreamer_client_internal_ClientMachine.frz_globalPageLifecycle = com_lightstreamer_internal_PageLifecycle.newLoggingInstance();
com_lightstreamer_client_internal_Request.EOL_LEN = com_lightstreamer_client_internal_Request.lengthOfBytes("\r\n");
var com_lightstreamer_client_internal_update_ItemUpdateBase_NO_FIELDS = "The Subscription was initiated using a Field Schema: the field names are not available";
var com_lightstreamer_client_internal_update_ItemUpdateBase_POS_OUT_BOUNDS = "The field position is out of bounds";
var com_lightstreamer_client_internal_update_ItemUpdateBase_UNKNOWN_FIELD_NAME = "The field name is unknown";
com_lightstreamer_internal_Globals.instance = new com_lightstreamer_internal_Globals();
var com_lightstreamer_internal_Threads_userThread = com_lightstreamer_internal_Threads_createExecutor();
var com_lightstreamer_internal_Threads_sessionThread = com_lightstreamer_internal_Threads_createExecutor();
var com_lightstreamer_internal_Timers__setTimeout = com_lightstreamer_internal_Globals.instance.hasWorkers ? com_lightstreamer_internal_WorkerTimer.setTimeout : $global.setTimeout;
var com_lightstreamer_internal_Timers__clearTimeout = com_lightstreamer_internal_Globals.instance.hasWorkers ? com_lightstreamer_internal_WorkerTimer.clearTimeout : $global.clearTimeout;
var com_lightstreamer_internal_Timers__setImmediate = com_lightstreamer_internal_Globals.instance.hasMicroTasks ? $global.queueMicrotask : function(callback) {
	com_lightstreamer_internal_Timers_queue.push(callback);
	com_lightstreamer_internal_Timers__setTimeout(com_lightstreamer_internal_Timers_handleQueue,0);
};
var com_lightstreamer_internal_Timers_queue = [];
com_lightstreamer_internal_Millis.ZERO = 0;
com_lightstreamer_internal_TransportSelection.WS = "WS";
com_lightstreamer_internal_TransportSelection.WS_STREAMING = "WS-STREAMING";
com_lightstreamer_internal_TransportSelection.WS_POLLING = "WS-POLLING";
com_lightstreamer_internal_TransportSelection.HTTP = "HTTP";
com_lightstreamer_internal_TransportSelection.HTTP_STREAMING = "HTTP-STREAMING";
com_lightstreamer_internal_TransportSelection.HTTP_POLLING = "HTTP-POLLING";
com_lightstreamer_internal_SubscriptionMode.Merge = "MERGE";
com_lightstreamer_internal_SubscriptionMode.Distinct = "DISTINCT";
com_lightstreamer_internal_SubscriptionMode.Command = "COMMAND";
com_lightstreamer_internal_SubscriptionMode.Raw = "RAW";
com_lightstreamer_internal_patch_DiffDecoder.VARINT_RADIX = 26;
LSConsoleLogLevel.TRACE = 0;
LSConsoleLogLevel.DEBUG = 10;
LSConsoleLogLevel.INFO = 20;
LSConsoleLogLevel.WARN = 30;
LSConsoleLogLevel.ERROR = 40;
LSConsoleLogLevel.FATAL = 50;
})(typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this);
var LSConnectionDetails = $hx_exports["LSConnectionDetails"];
var LSConnectionOptions = $hx_exports["LSConnectionOptions"];
var LSLightstreamerClient = $hx_exports["LSLightstreamerClient"];
var LSSubscription = $hx_exports["LSSubscription"];
var LSConsoleLogLevel = $hx_exports["LSConsoleLogLevel"];
var LSConsoleLoggerProvider = $hx_exports["LSConsoleLoggerProvider"];

export { LSConnectionDetails, LSConnectionOptions, LSLightstreamerClient, LSSubscription, LSConsoleLogLevel, LSConsoleLoggerProvider };